const APP_VERSION = require('../../public/version.json');
export default {
  //#region sidebar
  sidebar: {
    Home: 'Accueil',
    deviceManagement: "Gestion d'appareils",
    changePassword: 'Changer le mot de passe',
    listAllUsers: 'Modifier la liste des membres de votre boutique',
    createGroup: 'Construisez votre boutique',
    lockMessage1: 'Construisez votre boutique',
    lockMessage2: 'Commencez à gérer vos données de test',
    createPoints: 'Créer un code de point',
    testReport: 'Rapport de test',
    'bt2200-record-bt': 'Test de batterie BT2200',
    'bt2300plus-record-bt': 'Test de batterie BT2300+',
    'bt2300plus-record-st': 'Test du système BT2300+',
    'btw300-record-bt': 'Test de batterie BTW300',
    'btw300-record-st': 'Test du système BTW300',
    'btw200-record-bt': 'Test de batterie BTW200',
    'btw200-record-st': 'Test de démarrage / chargement BTW200',
    'btw200-record-ivt': 'Test du BTW200 embarqué',
    'btw200-record-vm': 'Moniteur de charge intelligent BTW200',
  },
  //#endregion

  //#region nav bar
  nav: {
    free: 'Essai gratuit',
    logo: 'Tableau de bord',
    plan: 'Abonnement de données',
    deposit: 'Dépôt',
    Language: 'Langue',
    PrivacyPolicy: 'Politique de confidentialité',
    SignOut: 'Déconnexion',
    version:
      process.env.VUE_APP_NODE_ENV !== 'PROD'
        ? APP_VERSION.DEV_VERSION
        : APP_VERSION.PROD_VERSION,
    edit: 'Edit', // new
    point: 'point', // new
    id: 'ID', // new
    techNumber: 'Tech number', // new
    techNumberLimit: 'Tech number limit', // new
    people: 'people', //new
  },
  //#endregion

  //#region modal
  modal: {
    // title
    error: 'Erreur',
    notice: 'Remarque',
    success: 'Succès',
    warning: 'Avertissement',
    invite: 'Inviter un utilisateur',
    addadevice: 'Ajouter un périphérique',
    subscribeDetail: "Détails de l'abonnement",
    changeHead: 'Change your personal headshot', //new
    // content
    signIn: {
      forgotPassword: {
        error: {
          text1: 'Adresse e-mail invalide.',
          text2: 'Veuillez entrer une adresse valide.',
        },
        success: {
          text1:
            'Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe.',
          text2: 'Veuillez vérifier votre boîte de réception.',
        },
      },
      resetPassword: {
        text1:
          'La première fois que vous vous connectez, vous devez changer votre mot de passe.',
        text2: 'Accédez à Modifier le mot de passe.',
      },
      verified: {
        text1: "Ce compte n'a pas été vérifié.",
        text2:
          'Veuillez vérifier la boîte de réception pour vérifier votre compte.',
        text3:
          "Si vous ne recevez pas d'e-mails, veuillez vérifier votre dossier spam.",
      },
      sendEmail: {
        text1: "L'e-mail de vérification est envoyé à votre e-mail:",
        text2:
          'Veuillez vérifier la boîte de réception pour vérifier votre compte.',
        text3:
          "Si vous ne recevez pas d'e-mails, veuillez vérifier votre dossier spam.",
      },
      pwExpired: 'Votre mot de passe a expiré et doit être modifié.',
    },
    changePassword: {
      success: {
        text1: 'Le mot de passe a été changé avec succès.',
        text2:
          'Veuillez utiliser ce mot de passe pour vous connecter la prochaine fois.',
      },
      error: {
        text1:
          'Identique à votre ancien mot de passe, veuillez le saisir à nouveau',
      },
    },
    createShop: {
      success: {
        text1: 'Création réussie, veuillez patienter ...',
      },
      error: {
        text1: 'Vous avez déjà une boutique.',
      },
    },
    registerUser: {
      success: {
        text1: 'Un lien de vérification a été envoyé à votre adresse e-mail.',
        text2:
          'Veuillez vérifier la boîte de réception pour vérifier votre e-mail.',
        text3:
          "Si vous ne recevez pas d'e-mails, veuillez vérifier votre dossier spam.",
      },
      error: {
        text1: 'Désolé.',
        text2: "L'adresse e-mail est déjà enregistrée.",
        text3: 'Veuillez utiliser une adresse e-mail différente.',
      },
    },
    deviceManagement: {
      deviceOnly: {
        text1: 'Cette page concerne uniquement BT2200, BT2300 Plus.',
        text2: 'Ne plus afficher',
      },
      noSelect: "Veuillez d'abord sélectionner un appareil.",
      onlySelect: 'Un seul appareil peut être supprimé à la fois.',
      remove: {
        success: {
          text1: 'sera supprimé.',
          text2: 'Voulez-vous continuer ?',
        },
        error: "n'existe pas.",
      },
      addDevice: {
        text1:
          "Veuillez sélectionner une méthode pour terminer l'enregistrement de l'appareil.",
        text2: 'Scanner le code-barres 2D (code QR)',
        text3: 'Scanner le code-barres 1D (code 39)',
        text4: "Entrez manuellement le code d'enregistrement",
        text5: 'Please scan the barcode to complete the device registration.', // new
        text6: 'Ce code expirera après 20 minutes.',
        text7:
          "Veuillez saisir manuellement le code d'enregistrement pour terminer l'enregistrement de l'appareil.",
      },
    },
    shopMemberList: {
      inviteLable: 'Email', // new
      invite: "Veuillez saisir l'adresse e-mail du compte pour inviter.",
      success: "Un e-mail d'invitation a été envoyé.",
      error: {
        text1: 'Erreur de format du courrier.',
        text2: "Le compte spécifié n'existe pas.",
        text3: 'La boutique est pleine.',
        text4: 'Ce compte appartient déjà à une boutique.',
      },
      noSelect: "Veuillez d'abord sélectionner un compte.",
      onlySelect: 'Un seul compte peut être sélectionné à la fois.',
      delete: {
        success: {
          text1: 'sera supprimé.',
          text2: 'Voulez-vous continuer ?',
        },
        error: "n'existe pas.",
      },
      detach: {
        success: {
          text1: 'sera supprimé de la boutique.',
          text2: 'Voulez-vous continuer ?',
        },
        error: 'Vous ne pouvez pas vous retirer de la boutique.',
      },
      disband: {
        text1: 'Êtes-vous sûr de vouloir démonter la boutique?',
        text2:
          "Votre boutique n'existera plus une fois que vous aurez démonté votre boutique.",
        text3:
          'Vous ne pourrez PAS consulter les données de test de votre technicien.',
        text4:
          'Si vous acceptez, veuillez saisir "confirmer" ci-dessous pour démonter la boutique.',
        text5: 'Êtes-vous sûr ?',
        success: 'Démontage réussi, veuillez patienter ...',
      },
      // edit頁面暫時移除
      editAccount: {
        text1: 'Will jump to edit page.',
        text2: 'Would you like to continue?',
      },
      haveDevice: {
        text1: 'Il y a encore des appareils enregistrés sous ce compte.',
        text2:
          'Veuillez désenregistrer les appareils avant de supprimer le compte.',
      },
      handover: {
        text1: 'Êtes-vous sûr de vouloir passer le relais à',
        success1: 'La demande ne sera valable que pendant 24 heures.',
        success2: " Veuillez informer l'utilisateur",
        success3:
          'de terminer le processus en cliquant sur le lien hypertexte dans son e-mail de notification.',
        error:
          "Le compte spécifié doit d'abord s'abonner à l'abonnement Pro User.",
      },
    },
    subscribe: {
      planTitle: 'Abonnement de données',
      planSubTitle: 'Utilisateur',
      memberTitle: 'Limite de nombre de technicien',
      memberSubTitle: 'Population',
      priceTitle: 'Points encouru',
      priceSubTitle: 'Point / par mois',
      free: 'Gratuit / par mois',
      info: {
        basic: {
          text1: 'Revenir à un abonnement d’utilisateur de base.',
          text2: 'Votre boutique sera fermée après le déclassement',
          text3: " Les données de test stockées n'existeront plus.",
        },
        pro: {
          text1:
            "Abonnez-vous à un abonnement Pro User et vous profiterez de votre essai gratuit de Shop Manager jusqu'à la fin du mois",
          text2:
            'Le premier jour calendaire du mois prochain, votre compte sera facturé pour votre abonnement de données.',
        },
        important: {
          text1:
            "* Le système enverra un avis lorsque les points de votre dépôt sont inférieurs à l'exigence de votre abonnement de données mensuel.",
          text2:
            "Rappel !!! Votre boutique sera fermée après le passage à un abonnement utilisateur de base. Les données de test stockées n'existeront plus",
          text3: 'Veuillez contacter votre agent pour acheter les points.',
        },
      },
      check: 'Êtes-vous sûr de vouloir vous abonner ?',
      success: {
        text1: "Vous vous êtes abonné avec succès à l'abonnement utilisateur",
        text2: 'User Plan.',
        text3: 'Limite du nombre de Techniciens :',
        text4: 'personnes.',
        text5: 'Le premier jour calendaire du mois prochain,',
        text6: 'votre compte sera facturé pour votre forfait de données.',
      },
    },
    createPoint: {
      success: {
        text1: 'Exportez votre code de point',
        text2: 'Point:',
        text3: 'Définir le numéro:',
        text4: "S'il vous plaît, attendez …",
      },
    },
    storedPoint: {
      success: 'Succès du dépôt de point.',
    },
    headshot: {
      // new
      upload: 'Upload',
      text: 'Please use JPG or JPEG photo type',
      success: 'Upload Success',
    },
    tokenError:
      "Désolé. Le signal n'est pas autorisé ou a expiré. Veuillez vous reconnecter.",
    somethingWrong: 'Désolé. Un problème est survenu. Veuillez réessayer.',
    unauthorized: "Le compte n'est pas autorisé.",
    accountNotExist:
      "Le compte spécifié n'existe pas, veuillez vérifier à nouveau.",
  },
  //#endregion

  //#region button
  button: {
    ok: "D'accord",
    close: 'Fermer',
    yes: 'Oui',
    no: 'Non',
    resentMail: "Renvoyer l'e-mail de vérification",
    submit: 'Soumettre',
    create: 'Créer',
    addDevice: 'Ajouter un appareil',
    removeDevice: 'Supprimer un appareil',
    refresh: 'Rafraîchir',
    columns: 'Colonnes',
    export: 'Exportation',
    edit: 'Éditer',
    handOver: 'Remettre',
    deleteAccount: 'Supprimer',
    invite: 'Inviter un utilisateur',
    sendInvitation: 'Envoyer une invitation',
    disband: 'Démanteler',
    detach: "Supprimer l'utilisateur",
    batteryTest: 'Test de batterie',
    systemTest: 'Test du système',
    back: 'Revenir',
    subscribe: 'Souscrire',
    stored: 'Valeur stockée',
    save: 'Save', //new
    download: 'Download', //new
  },
  //#endregion

  //#region Table - filter
  filter: {
    addFilter: 'Ajouter un filtre',
    choose: 'Choisir',
    search: 'Chercher',
  },
  //#endregion

  //#region Page - signIn
  signIn: {
    signIn: 'Se connecter',
    forgotPassword: {
      title: 'Mot de passe oublié',
      text1: 'Veuillez saisir votre adresse e-mail.',
      text2:
        'Nous vous enverrons un e-mail pour réinitialiser votre mot de passe.',
    },
    register: "S'inscrire",
    text1Placeholder: 'Adresse e-mail',
    text2Placeholder: 'Mot de passe',
  },
  //#endregion

  //#region Page - change password
  changePassword: {
    title: 'Changer le mot de passe',
    subtitle:
      'Veuillez remplir le formulaire ci-dessous pour changer votre mot de passe.',
    inputText1: 'Mot de passe',
    inputText2: 'Confirmez le mot de passe',
  },
  //#endregion

  //#region Page - create shop
  createShop: {
    title: 'Construire une nouvelle boutique',
    subtitle:
      'Veuillez remplir le formulaire ci-dessous pour créer une nouvelle boutique.',
    inputText1: 'Identifiant de la boutique',
    inputText1Placeholder: 'Veuillez saisir votre identifiant de boutique',
  },
  //#endregion

  //#region Page - register
  register: {
    title: 'Enregistrer un nouvel utilisateur',
    subtitle:
      'Veuillez remplir le formulaire ci-dessous pour enregistrer un nouvel utilisateur.',
    inputText1: 'Adresse e-mail',
    check1: "J'ai lu et j'accepte la",
    notice:
      "Si vous utilisez BTW200, veuillez vous assurer que le compte que vous remplissez est cohérent avec le compte que vous avez créé sur l'application.",
  },
  //#endregion

  //#region Page - device management
  deviceManagement: {
    errorListTitle: "Liste des codes d'erreur d'enregistrement de l'appareil:",
    list1:
      '01: Le serveur est occupé à lire des données. Veuillez réessayer plus tard.',
    list2:
      '03: Le serveur est occupé à écrire des données. Veuillez réessayer plus tard.',
    list3:
      "06: Le compte n'existe pas. Veuillez d'abord vérifier votre compte.",
    list4: "07: L'appareil a déjà été enregistré avec succès.",
    list5:
      "08: L'appareil a été enregistré par quelqu'un d'autre. Veuillez d'abord vous désinscrire.",
    list6:
      "09: Le code d'enregistrement n'existe pas ou a déjà expiré. Veuillez réessayer.",
  },
  //#endregion

  //#region Page - subscribe
  subscribePlan: {
    title: 'Souscrivez votre abonnement de données',
    subtitle: "Choisissez l'abonnement de données souhaité et abonnez-vous.",
    subscribed: 'Abonné',
    basic: {
      title: 'Utilisateur de base',
      price: 'Gratuit',
      text1: 'Stockez les données de test téléchargées pendant 6 mois.',
      text2: 'Vérifiez les données téléchargées.',
      text3: "Gérez jusqu'à 3 appareils.",
    },
    pro: {
      title: 'Utilisateur Pro',
      price: 'point',
      priceRule: {
        chargedDay: '/ par mois',
        default: 'Nombre maximum de technicien (par défaut): 20 personnes',
        customize:
          'Personnaliser la limite de nombre de technicien (+2 points / personne):',
        text1: 'Stockez les données de test téléchargées pendant 12 mois.',
        text2: 'Vérifiez les données téléchargées.',
        text3: "Gérez jusqu'à 3 appareils",
        text4: 'Exportez les données de test stockées.',
        text5:
          "Créez une boutique en gérant jusqu'à 20 données de test de techniciens.",
        text6:
          "Activez un tableau de bord de support avec des informations statistiques, aidant la gestion d'une boutique / d'une flotte. Mais vous devez d'abord créer votre boutique et inviter les utilisateurs à rejoindre votre boutique.",
      },
    },
    enterPrice: {
      title: "Utilisateur d'entreprise",
      other: 'En développement…',
    },
    icon: {
      text1: 'Exportation',
      text2: 'Tableau de données',
      text3: 'Graphique de données',
      text4: 'Tableau de bord',
      text5: 'Groupe',
      text6: 'Gestion',
      text7: 'Grande base de données',
    },
  },
  //#endregion

  //#region Page - editAccount(頁面內容尚未確定，暫時移除此畫面)
  editAccount: {
    title: 'Edit user profile',
    subtitle: 'Please complete the form below to update user profile.',
    inputText1: 'Email address',
  },
  //#endregion

  //#region Page - create point
  createPoint: {
    title: 'Créer un code de point',
    subtitle:
      'Veuillez remplir le formulaire ci-dessous pour créer un code de point.',
    inputText1: 'Point',
    inputText1Placeholder: "Quelle est la valeur d'un code de point",
    inputText2: 'Déterminer les numéros',
    inputText2Placeholder: "Combien d'ensembles de code de point",
  },
  //#endregion

  //#region Page - download excel     // new
  download: {
    title: 'Your monthly report is downloading',
    subtitle: 'If download is not processing.please click the button below.',
  },
  //#endregion

  //#region Page - stored point
  storedPoint: {
    title: 'Point mémorisé',
    subtitle:
      'Veuillez remplir le formulaire ci-dessous pour le point enregistré.',
    inputText1: 'Code de point',
    inputText1Placeholder: 'Veuillez entrer votre code de point',
  },
  //#endregion

  //#region invalid
  invalidError: {
    changePassword: {
      title: 'Le mot de passe doit respecter les règles suivantes:',
      list1: '* Au moins 8 caractères.',
      list2: '* Au moins 1 caractère numérique.',
      list3: '* Au moins 1 caractère minuscule.',
      list4: '* Au moins 1 caractère majuscule.',
      list5: '* Au moins 1 caractère spécial.',
      list6: '* Ne contient pas de courrier électronique.',
      list7: '* Différent de votre mot de passe précédent.',
      confirmPasswordError:
        'La confirmation du mot de passe ne correspond pas.',
    },
    createShop: {
      title: "L'ID de boutique doit respecter les règles suivantes:",
      list1: '* Alphabet français.',
      list2: '* Nombre.',
      list3: '* Au moins 3 caractères de longueur.',
      list4: "* Jusqu'à 20 caractères de longueur.",
      list5: '* Ne contient pas de caractère spécial sauf - ou _',
      existID:
        'Cet identifiant de boutique existe déjà, veuillez le saisir à nouveau',
    },
    registerUser: {
      mailError:
        'Veuillez saisir votre adresse e-mail au format vous@exemple.com.',
    },
    signIn: {
      mailPwError: "L'e-mail ou le mot de passe est incorrect.",
    },
    subscribe: {
      pointNotEnough: {
        text1:
          "Votre demande de surclassement a été refusée en raison d'un dépôt insuffisant.",
        text2: 'Dépôt actuel:',
        text3: 'point',
        text4:
          'Veuillez contacter votre agent pour acheter et créditer votre abonnement.',
      },
      stillShopManager: {
        text1:
          'Veuillez dissocier les techniciens de votre boutique avant de rétrograder votre abonnement de données.',
        text2:
          "Avertissement !!! Votre boutique n'existera plus une fois que vous aurez rétrogradé votre abonnement de données à l’abonnement d’utilisateur de base. Vous ne pourrez PAS consulter les données de test de votre technicien.",
      },
      samePlanBasic: 'Vous êtes déjà un utilisateur de base.',
      samePlanPro:
        'La limite de membres est la même que celle du plan précédent, veuillez revérifier.',
      memberlimit: {
        text1:
          "Il s'agit d'un ajustement non valide de la liste des technologies de votre boutique en raison d'une incompatibilité avec la limite du nombre de technologies de la boutique.",
        text2: 'Numéro technique de la boutique actuelle :',
        text3: 'personnes',
      },
    },
    createPoint: {
      onlyNumber: 'Ne remplissez que des chiffres',
      lessThan: 'Veuillez saisir moins de 10000',
    },
    stroedPoint: {
      invalid: 'Code de point non valide.',
      used: 'Ce code de point est déjà utilisé.',
    },
  },
  //#endregion

  //#region table  //new
  tableColumns: {
    deviceManagement: {
      sn: 'Device S/N',
      model: 'Model',
      account: 'Account',
    },
    accountList: {
      account: 'Account',
      verification: 'Verification status',
      accountLevel: 'Account level',
      shopID: 'Shop ID',
      dataPlan: 'Data Plan',
    },
    bt2200BT: {
      sn: 'S/N',
      testType: 'Test Type',
      judgement: 'Judgement',
      batteryType: 'Battery Type',
      voltage: 'Voltage',
      rated: 'Rated',
      measuredCca: 'Measured',
      temperature: 'Temperature',
      soc: 'State of Charge',
      soh: 'State of Health',
      code: 'Code',
      vin: 'VIN Number',
      testTime: 'Test Date',
      account: 'Account',
      shopId: 'Shop ID',
      uploadTime: 'Upload Time',
    },
    bt2300plusBT: {
      sn: 'S/N',
      testType: 'Test Type',
      roNum: 'RO Number',
      vin: 'VIN',
      batteryType: 'Battery Type',
      testTime: 'Date/Time',
      nextChargeDate: 'Next Maintenance Charge Date',
      judgement: 'Battery Condition',
      rated: 'Rated CCA',
      measuredCca: 'Tested CCA',
      startVol: 'Starting Volts',
      ocvAfterLoad: 'OCV After Initial Load',
      endVol: 'Ending Volts',
      chargeTime: 'Charge Time',
      capacityAh: 'Capacity (AH)',
      temperature: 'Temperature',
      soh: 'SOH',
      ir: 'IR',
      cellShort: 'Cell Short',
      surfaceCharge: 'Surface Charge',
      testCode: 'Warranty Code',
      fwVersion: 'Software Version',
      account: 'Account',
      shopId: 'Shop ID',
      uploadTime: 'Upload Time',
    },
    bt2300plusST: {
      sn: 'S/N',
      testType: 'Test Type',
      roNum: 'RO Number',
      vin: 'VIN',
      testTime: 'Date/Time',
      crankingResult: 'Cranking Result',
      crankingVoltage: 'Cranking Voltage',
      altIdleResult: 'Alt Idle Result',
      altIdleVoltage: 'Alt Idle Voltage',
      rippleResult: 'Ripple Result',
      rippleVoltage: 'Ripple Voltage',
      altLoadResult: 'Alt Load Result',
      altLoadVoltage: 'Alt Load Voltage',
      temperature: 'Temperature',
      fwVersion: 'Software Version',
      account: 'Account',
      shopId: 'Shop ID',
      uploadTime: 'Upload Time',
    },
    btw200BT: {
      sn: 'S/N',
      deviceName: 'Device Name',
      batterySn: 'Battery SN',
      systemVoltage: 'System voltage',
      testTime: 'Test Time',
      testType: 'Test Type',
      vin: 'VIN',
      judgement: 'Judgement',
      batteryType: 'Battery Type',
      setCapacity: 'Set Capacity',
      measuredCca: 'Measured CCA',
      rating: 'Rating',
      soh: 'SOH',
      soc: 'SOC',
      voltage: 'Voltage',
      isBatteryCharged: 'Is Battery Charged',
      account: 'Account',
      shopId: 'Shop ID',
      appVersion: 'APP Version',
      fwVersion: 'FW Version',
      uploadTime: 'Upload Time',
    },
    btw200IVT: {
      sn: 'S/N',
      deviceName: 'Device Name',
      batterySn: 'Battery SN',
      systemVoltage: 'System voltage',
      testTime: 'Test Time',
      testType: 'Test Type',
      vin: 'VIN',
      judgement: 'Judgement',
      batteryType: 'Battery Type',
      setCapacity: 'Set Capacity',
      measuredCca: 'Measured CCA',
      rating: 'Rating',
      soh: 'SOH',
      soc: 'SOC',
      voltage: 'Voltage',
      isBatteryCharged: 'Is Battery Charged',
      crankingJudgement: 'Cranking Judgement',
      crankingVoltage: 'Cranking Voltage',
      crankingMinVoltage: 'Cranking min voltage',
      crankingTime: 'Cranking time',
      altIdleJudgement: 'Alt Idle Judgement',
      altIdleVoltage: 'Alt Idle Voltage',
      rippleJudgement: 'Ripple Judgement',
      rippleVoltage: 'Ripple Voltage',
      altLoadJudgement: 'Alt Load Judgement',
      altLoadVoltage: 'Alt Load Voltage',
      systemTestTime: 'System test time',
      smartChargingControl: 'Smart Charging Control',
      voltageMonitorLowest: 'Voltage Monitor Lowest',
      voltageMonitorHighest: 'Voltage Monitor Highest',
      rippleLowestJudgement: 'Ripple Lowest Judgement',
      rippleLowest: 'Ripple Lowest',
      rippleHighestJudgement: 'Ripple Highest Judgement',
      rippleHighest: 'Ripple Highest',
      monitorTime: 'Monitor Time',
      monitorTestTime: 'Monitor Test Time',
      account: 'Account',
      shopId: 'Shop ID',
      appVersion: 'APP Version',
      fwVersion: 'FW Version',
      uploadTime: 'Upload Time',
    },
    btw200ST: {
      sn: 'S/N',
      deviceName: 'Device Name',
      testTime: 'Test Time',
      testType: 'Test Type',
      crankingJudgement: 'Cranking Judgement',
      crankingVoltage: 'Cranking Voltage',
      crankingMinVoltage: 'Cranking min voltage',
      crankingTime: 'Cranking time',
      altIdleJudgement: 'Alt Idle Judgement',
      altIdleVoltage: 'Alt Idle Voltage',
      rippleJudgement: 'Ripple Judgement',
      rippleVoltage: 'Ripple Voltage',
      altLoadJudgement: 'Alt Load Judgement',
      altLoadVoltage: 'Alt Load Voltage',
      smartChargingControl: 'Smart Charging Control',
      voltageMonitorLowest: 'Voltage Monitor Lowest',
      voltageMonitorHighest: 'Voltage Monitor Highest',
      rippleLowestJudgement: 'Ripple Lowest Judgement',
      rippleLowest: 'Ripple Lowest',
      rippleHighestJudgement: 'Ripple Highest Judgement',
      rippleHighest: 'Ripple Highest',
      monitorTime: 'Monitor Time',
      account: 'Account',
      shopId: 'Shop ID',
      appVersion: 'APP Version',
      fwVersion: 'FW Version',
      uploadTime: 'Upload Time',
    },
    btw200VM: {
      sn: 'S/N',
      deviceName: 'Device Name',
      testTime: 'Test Time',
      voltageMonitorLowest: 'Voltage Monitor Lowest',
      voltageMonitorHighest: 'Voltage Monitor Highest',
      monitorTime: 'Monitor Time',
      account: 'Account',
      shopId: 'Shop ID',
      appVersion: 'APP Version',
      fwVersion: 'FW Version',
      uploadTime: 'Upload Time',
    },
    btw300BT: {
      sn: 'S/N',
      testTime: 'Test Time',
      testType: 'Test Type',
      vin: 'VIN',
      hybrid: 'Hybird',
      judgement: 'Judgement',
      batteryType: 'Battery Type',
      setCapacity: 'Set Capacity',
      measure: 'Measured CCA',
      rating: 'Rating',
      soh: 'SOH',
      soc: 'SOC',
      voltage: 'Voltage',
      isBatteryCharged: 'Is Battery Charged',
      account: 'Account',
      shopId: 'Shop ID',
      appVersion: 'APP Version',
      fwVersion: 'FW Version',
      uploadTime: 'Upload Time',
    },
    btw300ST: {
      sn: 'S/N',
      testTime: 'Test Time',
      testType: 'Test Type',
      vin: 'VIN',
      crankingResult: 'Cranking Judgement',
      crankingVoltage: 'Cranking Voltage',
      altIdleResult: 'Alt Idle Judgement',
      altIdleVoltage: 'Alt Idle Voltage',
      rippleResult: 'Ripple Judgement',
      rippleVoltage: 'Ripple Voltage',
      altLoadResult: 'Alt Load Judagement',
      altLoadVoltage: 'Alt Load Voltage',
      account: 'Account',
      shopId: 'Shop ID',
      appVersion: 'APP Version',
      fwVersion: 'FW Version',
      uploadTime: 'Upload Time',
    },
    bt2400BT: {
      psn: 'S/N',
      testType: 'Test Type',
      vin: 'VIN',
      systemVolCh: 'System Voltage Channel',
      batteryType: 'Battery Type',
      rating: 'Rating',
      capacity: 'Capacity',
      batInVehicle: 'Battery In Vehicle',
      batCharged: 'Battery Charged',
      voltage: 'Voltage',
      soh: 'State of Health',
      soc: 'State of Charge',
      ir: 'IR',
      measuredCca: 'Measured CCA',
      temperature: 'Temperature',
      judgement: 'Judgement',
      testTime: 'Test Time',
      fwVersion: 'Firmware Version',
      account: 'Account',
      companyId: 'Company ID',
      nationalId: 'National ID',
      regionalId: 'Regional ID',
      shopId: 'Shop ID',
      uploadTime: 'Upload Time',
    },
    bt2400ST: {
      psn: 'S/N',
      testType: 'Test Type',
      vin: 'VIN',
      systemVolCh: 'System Voltage Channel',
      crankingResult: 'Cranking Result',
      crankingVoltage: 'Cranking Voltage',
      crankingTime: 'Cranking Time',
      altIdleResult: 'Alt Idle Result',
      altIdleVoltage: 'Alt Idle Voltage',
      rippleResult: 'Ripple Result',
      rippleVoltage: 'Ripple Voltage',
      altLoadResult: 'Alt Load Result',
      altLoadVoltage: 'Alt Load Voltage',
      temperature: 'Temperature',
      dieselCheck: 'Diesel Check',
      testTime: 'Test Time',
      fwVersion: 'Firmware Version',
      account: 'Account',
      companyId: 'Company ID',
      nationalId: 'National ID',
      regionalId: 'Regional ID',
      shopId: 'Shop ID',
      uploadTime: 'Upload Time',
    },
    bt2400IVT: {
      psn: 'S/N',
      testType: 'Test Type',
      vin: 'VIN',
      systemVolCh: 'System Voltage Channel',
      batteryType: 'Battery Type',
      rating: 'Rating',
      capacity: 'Capacity',
      batInVehicle: 'Battery In Vehicle',
      batCharged: 'Battery Charged',
      voltage: 'Voltage',
      soh: 'State of Health',
      soc: 'State of Charge',
      ir: 'IR',
      measuredCca: 'Measured CCA',
      judgement: 'Judgement',
      batTestTime: 'Battery Test Time',
      crankingResult: 'Cranking Result',
      crankingVoltage: 'Cranking Voltage',
      crankingTime: 'Cranking Time',
      altIdleResult: 'Alt Idle Result',
      altIdleVoltage: 'Alt Idle Voltage',
      rippleResult: 'Ripple Result',
      rippleVoltage: 'Ripple Voltage',
      altLoadResult: 'Alt Load Result',
      altLoadVoltage: 'Alt Load Voltage',
      temperature: 'Temperature',
      dieselCheck: 'Diesel Check',
      sysTestTime: 'System Test Time',
      fwVersion: 'Firmware Version',
      account: 'Account',
      companyId: 'Company ID',
      nationalId: 'National ID',
      regionalId: 'Regional ID',
      shopId: 'Shop ID',
      uploadTime: 'Upload Time',
    },
    noMatch: 'No matching records found',
    next: 'Next',
  },
  filterOption: {
    bt2200BT: {
      testType: {
        1: 'Battery Test',
        2: 'Start-Stop Test',
      },
      judgement: {
        1: 'GOOD & PASS',
        2: 'BAD & REPLACE',
        3: 'GOOD & RECHARGE',
        4: 'RECHARGE & RETEST',
        5: 'BAD CELL REPLACE',
        6: 'CAUTION',
      },
      batteryType: {
        1: 'FLOODED',
        2: 'AGM FLAT PLATE',
        3: 'AGM SPIRAL',
        4: 'VRLA/GEL',
        5: 'EFB',
      },
    },
    bt2300plusBT: {
      testType: {
        1: 'Battery Test',
        2: 'Start-Stop Test',
      },
      judgement: {
        0: 'GOOD & PASS',
        1: 'GOOD & RECHARGE',
        2: 'BAD & REPLACE',
        3: 'CAUTION',
        4: 'RECHARGE & RETEST',
        5: 'BAD CELL REPLACE',
      },
      batteryType: {
        0: 'FLOODED',
        1: 'AGM FLAT PLATE',
        2: 'AGM SPIRAL',
        3: 'VRLA/GEL',
        4: 'SS-AGM',
        5: 'SS-EFB',
      },
      cellShort: {
        0: 'NO',
        1: 'YES',
      },
      surfaceCharge: {
        0: 'NO',
        1: 'YES',
      },
    },
    bt2300plusST: {
      testType: {
        1: 'System Test',
      },
      crankingResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      altIdleResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      rippleResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      altLoadResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
    },
    btw300BT: {
      testType: {
        1: 'Battery Test',
        2: 'Start-Stop Test',
      },
      judgement: {
        0: 'GOOD & PASS',
        1: 'GOOD & RECHARGE',
        2: 'BAD & REPLACE',
        3: 'CAUTION',
        4: 'RECHARGE & RETEST',
        5: 'BAD CELL REPLACE',
      },
      batteryType: {
        1: 'FLOODED',
        2: 'AGM FLAT PLATE',
        3: 'AGM SPIRAL',
        4: 'VRLA/GEL',
        5: 'PS FLOODED',
        6: 'PS AGM',
      },
      rating: {
        0: 'CCA/SAE',
        1: 'EN',
        2: 'IEC',
        3: 'JIS',
        4: 'DIN',
        5: 'CA/MCA',
      },
      isBatteryCharged: {
        0: 'N/A',
        1: 'NO',
        2: 'YES',
      },
    },
    btw300ST: {
      testType: {
        1: 'System Test',
      },
      crankingResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      altIdleResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      rippleResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      altLoadResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
    },
    btw200BT: {
      systemVoltage: {
        0: '12V',
      },
      testType: {
        1: 'Battery Test',
        2: 'Start-Stop Test',
      },
      judgement: {
        0: 'GOOD & PASS',
        1: 'GOOD & RECHARGE',
        2: 'BAD & REPLACE',
        3: 'CAUTION',
        4: 'RECHARGE & RETEST',
        5: 'BAD CELL REPLACE',
      },
      batteryType: {
        1: 'FLOODED',
        2: 'AGM FLAT PLATE',
        3: 'AGM SPIRAL',
        4: 'VRLA/GEL',
        5: 'PS FLOOEDE',
        6: 'PS AGM',
        7: 'EFB',
      },
      rating: {
        0: 'CCA/SAE',
        1: 'EN',
        2: 'IEC',
        3: 'JIS',
        4: 'DIN',
        5: 'CA/MCA',
      },
      isBatteryCharged: {
        0: 'N/A',
        1: 'NO',
        2: 'YES',
      },
    },
    btw200ST: {
      testType: {
        1: 'Cranking Test',
        2: 'Charging Test',
      },
      crankingJudgement: {
        0: 'N/A',
        1: 'LOW',
        2: 'PASS',
      },
      altIdleJudgement: {
        0: 'PASS',
        1: 'FAIL',
      },
      rippleJudgement: {
        0: 'N/A',
        1: 'PASS',
        2: 'HIGH',
      },
      altLoadJudgement: {
        0: 'PASS',
        1: 'FAIL',
      },
      smartChargingControl: {
        0: 'YES',
        1: 'NO',
      },
      rippleLowestJudgement: {
        0: 'N/A',
        1: 'PASS',
        2: 'HIGH',
      },
      rippleHighestJudgement: {
        0: 'N/A',
        1: 'PASS',
        2: 'HIGH',
      },
    },
    btw200IVT: {
      systemVoltage: {
        0: '12V',
      },
      testType: {
        1: 'In Vehicle Test',
      },
      judgement: {
        0: 'GOOD & PASS',
        1: 'GOOD & RECHARGE',
        2: 'BAD & REPLACE',
        3: 'CAUTION',
        4: 'RECHARGE & RETEST',
        5: 'BAD CELL REPLACE',
      },
      batteryType: {
        1: 'FLOODED',
        2: 'AGM FLAT PLATE',
        3: 'AGM SPIRAL',
        4: 'VRLA/GEL',
        5: 'PS FLOOEDE',
        6: 'PS AGM',
        7: 'EFB',
      },
      rating: {
        0: 'CCA/SAE',
        1: 'EN',
        2: 'IEC',
        3: 'JIS',
        4: 'DIN',
        5: 'CA/MCA',
      },
      isBatteryCharged: {
        0: 'N/A',
        1: 'NO',
        2: 'YES',
      },
      crankingJudgement: {
        0: 'N/A',
        1: 'LOW',
        2: 'PASS',
      },
      altIdleJudgement: {
        0: 'PASS',
        1: 'FAIL',
      },
      rippleJudgement: {
        0: 'N/A',
        1: 'PASS',
        2: 'HIGH',
      },
      altLoadJudgement: {
        0: 'PASS',
        1: 'FAIL',
      },
      smartChargingControl: {
        0: 'YES',
        1: 'NO',
      },
      rippleLowestJudgement: {
        0: 'N/A',
        1: 'PASS',
        2: 'HIGH',
      },
      rippleHighestJudgement: {
        0: 'N/A',
        1: 'PASS',
        2: 'HIGH',
      },
    },
    bt2400BT: {
      testType: {
        1: 'Battery Test',
        2: 'Start-Stop Test',
      },
      systemVolCh: {
        1: '6V',
        2: '12V',
        3: '24V',
      },
      batteryType: {
        0: 'FLOODED',
        1: 'AGM FLAT PLATE',
        2: 'AGM SPIRAL',
        3: 'VRLA/GEL',
        4: 'SS-AGM',
        5: 'SS-EFB',
      },
      rating: {
        0: 'CCA',
        1: 'SAE',
        2: 'EN',
        3: 'IEC',
        4: 'JIS',
        5: 'DIN',
        6: 'CA',
      },
      batInVehicle: {
        1: 'Yes',
        2: 'No',
      },
      batCharged: {
        1: 'Yes',
        2: 'No',
      },
      judgement: {
        0: 'GOOD & PASS',
        1: 'GOOD & RECHARGE',
        2: 'BAD & REPLACE',
        3: 'CAUTION',
        4: 'RECHARGE & RETEST',
        5: 'BAD CELL REPLACE',
      },
    },
    bt2400ST: {
      testType: {
        3: 'System Test',
      },
      systemVolCh: {
        1: '6V',
        2: '12V',
        3: '24V',
      },
      crankingResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      altIdleResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      rippleResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      altLoadResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      dieselCheck: {
        1: 'Yes',
        2: 'No',
      },
    },
    bt2400IVT: {
      testType: {
        4: 'In Vehicle Test',
      },
      systemVolCh: {
        1: '6V',
        2: '12V',
        3: '24V',
      },
      batteryType: {
        0: 'FLOODED',
        1: 'AGM FLAT PLATE',
        2: 'AGM SPIRAL',
        3: 'VRLA/GEL',
        4: 'SS-AGM',
        5: 'SS-EFB',
      },
      rating: {
        0: 'CCA',
        1: 'SAE',
        2: 'EN',
        3: 'IEC',
        4: 'JIS',
        5: 'DIN',
        6: 'CA',
      },
      batInVehicle: {
        1: 'Yes',
        2: 'No',
      },
      batCharged: {
        1: 'Yes',
        2: 'No',
      },
      judgement: {
        0: 'GOOD & PASS',
        1: 'GOOD & RECHARGE',
        2: 'BAD & REPLACE',
        3: 'CAUTION',
        4: 'RECHARGE & RETEST',
        5: 'BAD CELL REPLACE',
      },
      crankingResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      altIdleResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      rippleResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      altLoadResult: {
        0: 'NO DETECT',
        1: 'LOW',
        2: 'NORMAL',
        3: 'HIGH',
      },
      dieselCheck: {
        1: 'Yes',
        2: 'No',
      },
    },
  },
  //#endregion

  //#region chart  //new
  chart: {
    title: {
      testTypeCounter: 'Test Type Counter',
      judgmentResultCounter: 'Judgment Result Counter',
      crankingResultCounter: 'Cranking Result Counter',
      rippleResultCounter: 'Ripple Result Counter',
      altIdleResultCounter: 'Alt Idle Result Counter',
      altLoadResultCounter: 'Alt Load Result Counter',
      month: 'Month',
      result: 'Result',
      total: 'Total Result',
      monthly: 'Monthly Report',
      firmwareVersion: 'Firmware Version',
      hourlyDay: 'Hourly Day',
      top4: 'TOP4 Account Usage',
      testType: 'Test Type Result',
      batteryTypeMonth: 'Battery Type Month Result',
      batteryTypeYear: 'Battery Type Result',
      new: 'New User',
      active: 'Active User',
      inactive: 'Inactive User',
    },
    label: {
      testedTimes: 'Tested Times',
      batteryTest: 'BATTERY TEST',
      startStopTest: 'START-STOP TEST',
      gp: 'GOOD & PASS',
      gr: 'GOOD & RECHARGE',
      rr: 'RECHARGE & RETEST',
      ca: 'CAUTION',
      br: 'BAD & REPLACE',
      bcr: 'BAD CELL REPLACE',
      noDetect: 'NO DETECT',
      low: 'LOW',
      normal: 'NORMAL',
      high: 'HIGH',
      na: 'N/A',
      pass: 'PASS',
      fail: 'FAIL',
      ivtTest: 'IN VEHICLE TEST',
      total: 'TOTAL',
      replace: 'REPLACE',
      gd: 'GOOD',
      bd: 'BAD',
      oth: 'OTHER',
      month: 'Month',
      quarter: 'Quarter',
      records: 'Records',
      time: 'Time',
      account: 'Account',
      batteryType: 'Battery Type',
      timePeriod: 'Time Period',
    },
    info: {
      new: 'User number count for who just join the shop in the last 24 hours.',
      active:
        'User number count for who has uploaded at least single test data in the last 24 hours.',
      inactive:
        'User number count for who did NOT upload any test data in the last 24 hours.',
    },
    testType: {
      'Battery Test': 'Battery Test',
      'Start-Stop Test': 'Start-Stop Test',
      'System Test': 'System Test',
      'Cranking Test': 'Cranking Test',
      'Charging Test': 'Charging Test',
      'In Vehicle Test': 'In Vehicle Test',
      'Smart Charging Monitor': 'Smart Charging Monitor',
    },
    batteryType: {
      FLOODED: 'FLOODED',
      'AGM FLAT PLATE': 'AGM FLAT PLATE',
      'AGM SPIRAL': 'AGM SPIRAL',
      'VRLA/GEL': 'VRLA/GEL',
      'SS-AGM': 'SS-AGM',
      'SS-EFB': 'SS-EFB',
      'PS FLOODED': 'PS FLOODED',
      'PS AGM': 'PS AGM',
      EFB: 'EFB',
    },
    month: {
      full: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
      short: {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      },
    },
  },
  //#endregion

  //#region Page - home  //new
  home: {
    title: 'My Battery Expert',
    updated: 'Last Updated Time',
    hour: 'hours',
    minute: 'minutes ago',
    nodata: 'No data uploaded',
    leaderboard: {
      title: 'Weekly LeaderBoard',
      subtitle:
        'The counter will be reset at 00:00 every Sunday. (Timezone: UTC+0)',
      total: 'Total',
    },
  },
  //#endregion
};
