<template>
  <div>
    <formFormat
      :page="page"
      :formInput="formInput"
      :loading="loading"
      :error.sync="errorMessage"
      @submit="showEmitValue"
      data-test="formFormat"
    ></formFormat>
    <!-------------- modal area --------------->
    <!-- 修改密碼成功 -->
    <b-modal
      id="changePasswordSuccess"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$t('modal.success')"
    >
      <template v-slot:modal-header>
        <h5>{{ $t('modal.success') }}</h5>
        <button @click="fnChangePasswordSuccess">X</button>
      </template>
      <h6>{{ $t('modal.changePassword.success.text1') }}</h6>
      <h6>{{ $t('modal.changePassword.success.text2') }}</h6>
      <template v-slot:modal-footer>
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="fnChangePasswordSuccess"
        >
          {{ $t('button.ok') }}
          <b-spinner
            v-if="loading"
            small
            label="Loading..."
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
      </template>
    </b-modal>
    <!-- 修改密碼失敗(相同密碼) -->
    <b-modal id="changePasswordError" centered :title="$t('modal.error')">
      <h6>{{ apiMessage }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <!------------------------------------------->
  </div>
</template>

<script>
  import formFormat from '@/components/FormFormat.vue';
  import { APIchangePassword } from '../api';

  export default {
    components: {
      formFormat,
    },
    data() {
      return {
        page: 'changePassword',
        loading: false,
        errorMessage: {
          RegPassword: false,
          confirmPassword: false,
        },
        apiMessage: null,
        formInput: [
          {
            id: 'currentPassword',
            title: 'changePassword.currentPasswordText',
            placeholder: 'changePassword.password',
            type: 'password',
            check: '',
          },
          {
            id: 'password',
            title: 'changePassword.newPasswordText',
            placeholder: 'changePassword.password',
            type: 'password',
            check: 'password',
          },
          {
            id: 'confirmPassword',
            title: 'changePassword.confirmNewPasswordText',
            placeholder: 'changePassword.password',
            type: 'password',
            check: 'confirm',
          },
        ],
      };
    },
    methods: {
      async showEmitValue(inputValue) {
        try {
          this.loading = true;
          const requestData = {
            account: this.vxAccount,
          };
          this.formInput.map((el, i) => {
            if (el.id === 'password' || el.id === 'currentPassword') {
              requestData[el.id] = inputValue.input[i];
            }
          });
          const { data: APIdata } = await APIchangePassword(requestData);
          switch (APIdata.result) {
            case 1:
              this.$bvModal.show('changePasswordSuccess');
              break;
            case 2:
              this.formInput.map((el, i) => {
                inputValue[i] = '';
              });
              this.apiMessage = APIdata.message;
              this.$bvModal.show('changePasswordError');
              break;
            default:
              this.regCheck = true;
          }
          this.loading = false;
        } catch (error) {
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            this.$bvModal.show('setsomethingWrong');
          }
        }
      },
      fnChangePasswordSuccess() {
        this.loading = true;
        this.$store.dispatch('setSignInToggle', false);
        this.$store.dispatch('setAccount', '');
        this.$store.dispatch('setAccountInformation', {
          accountLevel: 'Technician',
        });
        this.$router.push('/');
      },
    },
    computed: {
      vxAccount() {
        return this.$store.state.account;
      },
    },
  };
</script>
