<template>
  <div>
    <b-overlay
      spinner-variant="info"
      :show="overlay"
      rounded="sm"
      variant="light"
      :opacity="0.6"
      blur="none"
    >
      <vue-good-table
        ref="myCustomTable"
        mode="remote"
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        v-on="{ 'on-row-click': clickToggle ? onRowClick : () => {} }"
        :columns="columns"
        :rows="dataTableRows"
        :row-style-class="fnRowStyleClass"
        :pagination-options="{
          enabled: true,
          nextLabel: this.$t('tableColumns.next'),
          prevLabel: this.$t('tableColumns.prev'),
          mode: 'records',
          perPage: 10,
          perPageDropdown: [10],
          dropdownAllowAll: false,
          ofLabel: this.$t('tableColumns.of'),
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'testTime', type: 'desc' },
        }"
        :totalRows="totalRecords"
        :search-options="{
          enabled: true,
          placeholder: 'Search this table',
          externalQuery: serverParams.searchTerm,
          skipDiacritics: true,
        }"
        :select-options="{
          enabled: checkboxToggle,
          disableSelectInfo: true,
          selectionInfoClass: 'selected',
        }"
        styleClass="vgt-table striped"
        :class="[clickToggle ? 'rowHoverEvent' : '']"
      >
        <div slot="table-actions" class="tableBar">
          <div class="button-group">
            <m-button
              data-test="button-REFRESH"
              @click="fnDataTableRefresh"
              class="tableButton"
              raised
              dense
              ><i class="fas fa-sync-alt"></i
            ></m-button>

            <b-dropdown
              class="columns"
              v-if="
                !(
                  device === 'deviceManager' ||
                  device === 'blacklist' ||
                  device === 'signinLogs' ||
                  device === 'actionLogs'
                )
              "
            >
              <template v-slot:button-content>
                <m-button class="dropDownButton" raised dense>{{
                  $t("button.columns")
                }}</m-button>
              </template>
              <b-dropdown-item v-for="(column, index) of columns" :key="index">
                <label>
                  <a
                    href="#"
                    tabIndex="-1"
                    @click.stop.prevent="fnToggleColumn(index, $event)"
                  >
                    <input :checked="!column.hidden" type="checkbox" />
                    <span class="checkmark"></span>
                    &nbsp;{{ column.label }}
                  </a>
                </label>
              </b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              class="export"
              v-if="
                vxAccountLevel !== vxConstantsAccountLevel.basic &&
                device !== 'deviceManager' &&
                device !== 'blacklist' &&
                device !== 'signinLogs' &&
                device !== 'actionLogs'
              "
            >
              <template v-slot:button-content>
                <m-button class="dropDownButton" raised dense>{{
                  $t("button.export")
                }}</m-button>
              </template>
              <b-dropdown-item @click.prevent="fnExport('xlsx')">{{
                $t("button.exportExcel")
              }}</b-dropdown-item>
              <b-dropdown-item @click.prevent="fnExport('csv')">{{
                $t("button.exportCSV")
              }}</b-dropdown-item>
            </b-dropdown>

            <m-button
              v-if="
                (vxAccountLevel === vxConstantsAccountLevel.shop ||
                  vxAccountLevel === vxConstantsAccountLevel.business) &&
                device === 'manageMemberList'
              "
              class="tableButton"
              raised
              dense
              @click="fnChangeManagerCheckSelected"
              >{{ $t("button.handOver") }}</m-button
            >
            <m-button
              v-if="
                vxAccountLevel === vxConstantsAccountLevel.admin &&
                device === 'manageMemberList'
              "
              class="tableButton"
              raised
              dense
              @click="fnUnlock"
              >{{ $t("button.unlock") }}</m-button
            >
            <!-- device Management -->
            <m-button
              v-if="device === 'deviceManager'"
              class="tableButton"
              raised
              dense
              @click="fnAddDevice"
              >{{ $t("button.addDevice") }}</m-button
            >
            <m-button
              v-if="device === 'deviceManager'"
              class="tableButton"
              raised
              dense
              @click="fnRemoveDeviceCheckSelected"
              >{{ $t("button.removeDevice") }}</m-button
            >

            <!-- blacklist -->
            <m-button
              v-if="device === 'blacklist'"
              data-test="button-REFRESH"
              class="tableButton"
              raised
              dense
              @click="showDeleteBlackListAccountModal"
            >
              {{ $t("button.delete") }}
            </m-button>
          </div>
          <div class="filterBox">
            <div class="filterButton" @click="fnAddFilterCondition">
              <i class="far fa-times-circle"></i>
              <p>{{ $t("filter.addFilter") }}</p>
            </div>
            <div class="filterSearch">
              <ul>
                <li
                  v-for="(list, index) of filter.array"
                  :key="list"
                  class="filter-group"
                >
                  <select
                    @change="fnFilterConditionChange(index)"
                    v-model="filter.selected[index]"
                    class="custom-select"
                  >
                    <option value="" selected disabled>
                      {{ $t("filter.choose") }}
                    </option>
                    <option
                      v-for="(label, columnIndex) of columns"
                      :key="columnIndex"
                      :value="label"
                      v-show="!label.disable"
                      :disabled="label.disable"
                    >
                      {{ label.label }}
                    </option>
                  </select>

                  <!-- String -->
                  <div
                    class="filterSearchBar"
                    v-if="filter.selected[index].type === 'String'"
                  >
                    <select
                      class="custom-select"
                      @change="fnFilterConditionChange(index)"
                      v-model="filter.stringType[index]"
                    >
                      <option
                        v-for="type in filter.typeOptions[index]"
                        :key="type"
                        :value="type"
                      >
                        {{ $t("filter." + type) }}
                      </option>
                    </select>
                    <input
                      @keyup="fnFilterKeyboardEvent"
                      @blur="fnFilterBlurEvent(index)"
                      :data-id="index"
                      type="text"
                      class="filterInput"
                      :placeholder="$t('filter.search')"
                      v-model.trim="filter.stringCondition[index][0]"
                      :class="[
                        filter.error && filter.stringCondition[index][0] === ''
                          ? 'error'
                          : '',
                      ]"
                    />
                  </div>

                  <!-- Number -->
                  <div
                    class="filterSearchBar"
                    v-if="filter.selected[index].type === 'Number'"
                  >
                    <select
                      @change="fnFilterNumberTypeChange(index)"
                      v-model="filter.numberType[index]"
                      class="custom-select"
                    >
                      <option
                        v-for="type in filter.typeOptions[index]"
                        :key="type"
                        :value="type"
                      >
                        {{ $t("filter." + type) }}
                      </option>
                    </select>
                    <input
                      @keyup="fnFilterKeyboardEvent"
                      @blur="fnFilterBlurEvent(index)"
                      :data-id="index"
                      v-if="filter.numberType[index] !== 'between'"
                      type="number"
                      class="filterInput"
                      :placeholder="$t('filter.value')"
                      v-model.trim.number="filter.numberCondition[index][0]"
                      :class="[
                        filter.error && filter.numberCondition[index][0] === ''
                          ? 'error'
                          : '',
                      ]"
                    />
                    <div class="filterBetweenBox" v-else>
                      <input
                        @keyup="fnFilterKeyboardEvent"
                        @blur="fnFilterBlurEvent(index)"
                        type="number"
                        class="filterInput"
                        :data-id="index"
                        :placeholder="$t('filter.min')"
                        v-model.trim.number="filter.numberCondition[index][0]"
                        :class="[
                          filter.error &&
                          filter.numberCondition[index][0] === ''
                            ? 'error'
                            : '',
                        ]"
                      />
                      {{ $t("filter.to") }}
                      <input
                        @keyup="fnFilterKeyboardEvent"
                        @blur="fnFilterBlurEvent(index)"
                        :data-id="index"
                        type="number"
                        class="filterInput"
                        :placeholder="$t('filter.max')"
                        v-model.trim.number="filter.numberCondition[index][1]"
                        :class="[
                          filter.error &&
                          filter.numberCondition[index][1] === ''
                            ? 'error'
                            : '',
                        ]"
                      />
                    </div>
                  </div>

                  <!-- Option -->
                  <div
                    class="filterSearchBar"
                    v-if="filter.selected[index].type === 'Option'"
                  >
                    <select
                      @change="fnFilterOptionConditionChange(index)"
                      v-model="filter.optionType[index]"
                      class="custom-select"
                    >
                      <option value="" selected disabled>
                        {{ $t("filter.optionSelect") }}
                      </option>
                      <option
                        v-for="option in filter.selected[index].options"
                        :key="option"
                        :value="option"
                      >
                        {{
                          $t(
                            "filterOption." +
                              device +
                              "." +
                              filter.selected[index].field +
                              "." +
                              option
                          )
                        }}
                      </option>
                    </select>
                  </div>

                  <!-- Time -->
                  <div
                    class="filterSearchBar"
                    v-if="filter.selected[index].type === 'Time'"
                  >
                    <date-picker
                      ref="datePicker"
                      v-model="filter.timeCondition[index]"
                      type="datetime"
                      placeholder="select time range"
                      range
                      confirm
                      confirm-text="apply"
                      :editable="false"
                      :clearable="false"
                      :disabled-date="disableFeatureTime"
                      :show-time-panel="filter.showTimeRangePanel[index]"
                      :show-second="false"
                      :minute-step="1"
                      :shortcuts="filter.shortcuts"
                      @close="handleRangeClose(index)"
                      @confirm="fnFilterAddTimeCondition(index)"
                      @change="fnFilterAddTimeCondition(index)"
                      format="YYYY/MM/DD HH:mm a"
                    >
                      <!-- :time-picker-options="{ start: '00:00', step: '00:30', end: '23:30'}" -->
                      <template v-slot:icon-calendar>
                        <div></div>
                      </template>
                      <template v-slot:footer>
                        <div class="timeRangeType">
                          <label>
                            <input
                              type="radio"
                              name="timeType"
                              value="local"
                              v-model="filter.timeType[index]"
                            />
                            {{ $t("filter.timeTypeLocal") }}
                          </label>
                          <label>
                            <input
                              type="radio"
                              name="timeType"
                              value="utc"
                              v-model="filter.timeType[index]"
                            />
                            {{ $t("filter.timeTypeUTC") }}
                          </label>
                        </div>
                        <button
                          class="mx-btn mx-btn-text"
                          @click="toggleTimeRangePanel(index)"
                        >
                          {{
                            filter.showTimeRangePanel[index]
                              ? "select date"
                              : "select time"
                          }}
                        </button>
                      </template>
                    </date-picker>
                  </div>

                  <!-- Mix -->
                  <div
                    class="filterSearchBar"
                    v-if="filter.selected[index].type === 'Mix'"
                  >
                    <select
                      @change="fnFilterConditionChange(index)"
                      v-model="filter.mixOption[index]"
                      class="custom-select"
                    >
                      <option value="number" selected>
                        {{ $t("filter.filterTypeNumber") }}
                      </option>
                      <option value="string">
                        {{ $t("filter.filterTypeString") }}
                      </option>
                    </select>

                    <!-- Mix-string -->
                    <div v-if="filterMixColumns[index] === 'string'">
                      <select
                        @change="fnFilterConditionChange(index)"
                        v-model="filter.mixStringType[index]"
                        class="custom-select"
                      >
                        <option
                          v-for="type in filter.typeOptions[index]"
                          :key="type"
                          :value="type"
                        >
                          {{ $t("filter." + type) }}
                        </option>
                      </select>
                      <input
                        @keyup="fnFilterKeyboardEvent"
                        @blur="fnFilterBlurEvent(index)"
                        class="filterInput"
                        :data-id="index"
                        :placeholder="$t('filter.search')"
                        type="text"
                        v-model.trim="filter.mixStringCondition[index][0]"
                        :class="[
                          filter.error &&
                          filter.mixStringCondition[index][0] === ''
                            ? 'error'
                            : '',
                        ]"
                      />
                    </div>

                    <!-- Mix-number -->
                    <div
                      class="mix-number"
                      v-if="filterMixColumns[index] === 'number'"
                    >
                      <select
                        @change="fnFilterMixNumberTypeChange(index)"
                        v-model="filter.mixNumberType[index]"
                        class="custom-select"
                      >
                        <option
                          v-for="type in filter.typeOptions[index]"
                          :key="type"
                          :value="type"
                        >
                          {{ $t("filter." + type) }}
                        </option>
                      </select>
                      <input
                        @keyup="fnFilterKeyboardEvent"
                        @blur="fnFilterBlurEvent(index)"
                        v-if="mixType[index] !== 'between'"
                        class="filterInput"
                        :data-id="index"
                        :placeholder="$t('filter.value')"
                        type="number"
                        v-model.trim.number="
                          filter.mixNumberCondition[index][0]
                        "
                        :class="[
                          filter.error &&
                          filter.mixNumberCondition[index][0] === ''
                            ? 'error'
                            : '',
                        ]"
                      />
                      <div class="filterBetweenBox" v-else>
                        <input
                          @keyup="fnFilterKeyboardEvent"
                          @blur="fnFilterBlurEvent(index)"
                          type="number"
                          class="filterInput"
                          :data-id="index"
                          :placeholder="$t('filter.min')"
                          v-model.trim.number="
                            filter.mixNumberCondition[index][0]
                          "
                          :class="[
                            filter.error &&
                            filter.mixNumberCondition[index][0] === ''
                              ? 'error'
                              : '',
                          ]"
                        />
                        {{ $t("filter.to") }}
                        <input
                          @keyup="fnFilterKeyboardEvent"
                          @blur="fnFilterBlurEvent(index)"
                          type="number"
                          class="filterInput"
                          :data-id="index"
                          :placeholder="$t('filter.max')"
                          v-model.trim.number="
                            filter.mixNumberCondition[index][1]
                          "
                          :class="[
                            filter.error &&
                            filter.mixNumberCondition[index][1] === ''
                              ? 'error'
                              : '',
                          ]"
                        />
                      </div>
                    </div>

                    <!-- Mix-option -->
                    <div v-if="filterMixColumns[index] === 'option'">
                      <select
                        @change="fnFilterMixOptionConditionChange(index)"
                        v-model="filter.mixOptionCondition[index]"
                        class="custom-select"
                      >
                        <option value="" selected disabled>
                          {{ $t("filter.optionSelect") }}
                        </option>
                        <option
                          v-for="option in filter.selected[index].options"
                          :key="option"
                          :value="option"
                        >
                          {{
                            $t(
                              "filterOption." +
                                device +
                                "." +
                                filter.selected[index].field +
                                "['" +
                                option +
                                "']"
                            )
                          }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <i
                    @click="fnDelelteFilterCondition(index)"
                    class="far fa-times-circle"
                  ></i>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          v-if="
            device === 'manageMemberList' &&
            vxAccountLevel !== vxConstantsAccountLevel.admin
          "
          slot="table-actions-bottom"
          class="tableBar"
        >
          <div class="button-group">
            <m-button
              class="tableButton"
              raised
              dense
              @click="$bvModal.show('inviteGroup')"
              >{{ $t(inviteButtonTitle) }}
            </m-button>
            <m-button
              class="tableButton"
              raised
              dense
              @click="fnKickAccountCheckSelected"
              >{{ $t(removeButtonTitle) }}</m-button
            >
            <m-button
              v-if="buttonGroupBoolean"
              class="tableButton"
              raised
              dense
              @click="$bvModal.show('disbandGroup')"
              >{{ $t("button.disband") }}</m-button
            >
          </div>
        </div>

        <div slot="emptystate">
          <p>{{ $t("tableColumns.noMatch") }}</p>
        </div>
      </vue-good-table>
    </b-overlay>
    <!-- modal -->
    <!-- 沒選擇任何account -->
    <b-modal id="noSelectAccountToggle" centered :title="$t('modal.notice')">
      <h6>{{ $t("modal.shopMemberList.noSelect") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 一次只能選擇一個account -->
    <b-modal id="selectMultipleAccount" centered :title="$t('modal.notice')">
      <h6>{{ $t("modal.shopMemberList.onlySelect") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 沒選擇任何device -->
    <b-modal id="noSelectDeviceToggle" centered :title="$t('modal.notice')">
      <h6>{{ $t("modal.deviceManagement.noSelect") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 一次只能刪除一個device -->
    <b-modal
      id="selectMultipleDeviceToggle"
      centered
      :title="$t('modal.notice')"
    >
      <h6>{{ $t("modal.deviceManagement.onlySelect") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 交接 -->
    <b-modal id="changeGroupManager" centered :title="$t('modal.warning')">
      <h6>
        {{ $t("modal.shopMemberList.handover.text1") }}
        {{ selectedUser }} ?
      </h6>
      <h6>{{ $t("modal.shopMemberList.handover.text2") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.no") }}
        </m-button>
        <m-button
          class="tableButton"
          raised
          dense
          :disabled="loading"
          :outlined="loading"
          @click="fnchangeGroupManager"
        >
          {{ $t("button.yes") }}
          <b-spinner
            v-if="loading"
            small
            label="Loading..."
            :variant="'info'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
      </template>
    </b-modal>
    <!-- 交接信寄出 -->
    <b-modal
      id="changeShopManagerMailSend"
      centered
      :title="$t('modal.success')"
    >
      <h6>{{ $t("modal.shopMemberList.handover.success1") }}</h6>
      <h6>
        {{ $t("modal.shopMemberList.handover.success2") }}
        {{ handoverAccount }}
        {{ $t("modal.shopMemberList.handover.success3") }}
      </h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 此帳號尚有綁定裝置 -->
    <b-modal id="haveDeviceToggle" centered :title="$t('modal.error')">
      <h6>{{ $t("modal.shopMemberList.haveDevice.text1") }}</h6>
      <h6>{{ $t("modal.shopMemberList.haveDevice.text2") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- kicked out the group -->
    <b-modal id="kickAccountToggle" centered :title="$t('modal.warning')">
      <h6 v-if="vxAccountLevel === vxConstantsAccountLevel.shop">
        {{ selectedUser }}
        {{ $t("modal.shopMemberList.detach.success.text1") }}
      </h6>
      <h6 v-else>
        {{ $t("modal.shopMemberList.detach.companySuccess.text1") }} :
        {{ selectedShop }}
        {{ $t("modal.shopMemberList.detach.companySuccess.text2") }}
      </h6>
      <h6>{{ $t("modal.shopMemberList.detach.success.text3") }}</h6>
      <h6>{{ $t("modal.shopMemberList.detach.success.text2") }}</h6>
      <template v-slot:modal-footer="{ close, ok }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.no") }}
        </m-button>
        <m-button class="tableButton" raised dense @click="ok(fnKickAccount())">
          {{ $t("button.yes") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 踢出群組、delete account error不存在之帳號-->
    <b-modal id="kickAccountErrorToggle" centered :title="$t('modal.error')">
      <h6>{{ selectedUser }} {{ $t("modal.shopMemberList.delete.error") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="close(fnDataTableRefresh())"
        >
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 踢出群組error同階層帳戶(當有兩個shop level才會發生) -->
    <b-modal id="kickSameLevelErrorToggle" centered :title="$t('modal.error')">
      <h6>{{ $t("modal.shopMemberList.detach.error") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 踢出群組error Company Manager只能夠踢Shop Manager -->
    <b-modal
      id="canOnlyKickShopErrorToggle"
      centered
      :title="$t('modal.error')"
    >
      <h6>{{ $t("modal.shopMemberList.detach.errorOnlyKickShop") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 踢出群組後資料轉移 -->
    <b-modal id="testDataTransform" centered :title="$t('modal.notice')">
      <h6>{{ $t("modal.shopMemberList.detach.createTask") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="close(blankPage('serverTasks'))"
        >
          {{ $t("button.tasklist") }}
        </m-button>
      </template>
    </b-modal>
    <!-- add device -->
    <b-modal id="addDeviceToggle" centered :title="$t('modal.addadevice')">
      <template v-slot:modal-header>
        <h5>{{ $t("modal.addadevice") }}</h5>
        <button @click="modalClose">X</button>
      </template>
      <div class="button-group" v-if="addDevice.buttonToggle">
        <h6>{{ $t("modal.deviceManagement.addDevice.text1") }}</h6>
        <m-button @click="fnShowQrcode" class="tableButton cancel" raised>
          {{ $t("modal.deviceManagement.addDevice.text2") }}
        </m-button>
        <m-button @click="fnShowBarcode" class="tableButton cancel" raised>
          {{ $t("modal.deviceManagement.addDevice.text3") }}
        </m-button>
        <m-button
          @click="fnShowRegistrationCode"
          class="tableButton cancel"
          raised
        >
          {{ $t("modal.deviceManagement.addDevice.text4") }}
        </m-button>
        <br />
      </div>
      <div class="qrcode-group" v-if="addDevice.qrcodeToggle">
        <b-spinner
          v-if="loading"
          label="Loading..."
          :variant="'info'"
          class="codeLoading"
        ></b-spinner>
        <qrcode-vue
          v-else
          :value="addDevice.DATAqrcode"
          :size="256"
          level="H"
        ></qrcode-vue>
        <h6>
          {{ $t("modal.deviceManagement.addDevice.text5") }}
        </h6>
      </div>
      <div class="barcode-group" v-if="addDevice.barcodeToggle">
        <b-spinner
          v-if="loading"
          label="Loading..."
          :variant="'info'"
          class="codeLoading"
        ></b-spinner>
        <VueBarcode
          :value="addDevice.DATAregistrationCode"
          :display-value="false"
          v-else
          format="CODE39"
        ></VueBarcode>
        <h6>
          {{ $t("modal.deviceManagement.addDevice.text5") }}
        </h6>
        <h6>{{ $t("modal.deviceManagement.addDevice.text6") }}</h6>
      </div>
      <div
        class="registrationCode-group"
        v-if="addDevice.registrationCodeToggle"
      >
        <b-spinner
          v-if="loading"
          label="Loading..."
          :variant="'info'"
          class="codeLoading"
        ></b-spinner>
        <h1 v-else>{{ addDevice.DATAregistrationCode }}</h1>
        <h6>
          {{ $t("modal.deviceManagement.addDevice.text7") }}
        </h6>
        <h6>{{ $t("modal.deviceManagement.addDevice.text6") }}</h6>
      </div>
      <template v-slot:modal-footer="{}">
        <p></p>
      </template>
    </b-modal>
    <!-- deleted device -->
    <b-modal id="deleteDeviceToggle" centered :title="$t('modal.warning')">
      <h6>
        {{ selectedDevice }}
        {{ $t("modal.deviceManagement.remove.success.text1") }}
      </h6>
      <h6>{{ $t("modal.deviceManagement.remove.success.text2") }}</h6>
      <template v-slot:modal-footer="{ ok, close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.no") }}
        </m-button>
        <m-button
          class="tableButton"
          raised
          dense
          @click="ok(fnRemoveDevice())"
        >
          {{ $t("button.yes") }}
        </m-button>
      </template>
    </b-modal>
    <!-- deleted device error-->
    <b-modal id="deleteDeviceErrorToggle" centered :title="$t('modal.notice')">
      <h6>
        {{ selectedDevice }}
        {{ $t("modal.deviceManagement.remove.error") }}
      </h6>
      <template v-slot:modal-footer="{ close }">
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="close(fnDataTableRefresh())"
        >
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- invite group -->
    <b-modal
      id="inviteGroup"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$t(modalTitle)"
    >
      <div
        class="businessInviteTop"
        v-if="vxAccountLevel === vxConstantsAccountLevel.business"
      >
        <a
          class="inviteLabel"
          :class="inviteMethod === 'id' ? 'active' : ''"
          href="javascript;"
          @click.prevent="clickInviteMethodAndHideError('id')"
          >{{ $t("modal.shopMemberList.inviteLab.id") }}</a
        >
        <a
          class="inviteLabel"
          :class="inviteMethod === 'account' ? 'active' : ''"
          href="javascript;"
          @click.prevent="clickInviteMethodAndHideError('account')"
          >{{ $t("modal.shopMemberList.inviteLab.account") }}</a
        >
      </div>
      <h6 v-if="vxAccountLevel === vxConstantsAccountLevel.shop">
        {{ $t("modal.shopMemberList.invite") }}
      </h6>
      <h6 v-else>
        <div v-if="inviteMethod === 'id'">
          {{ $t("modal.shopMemberList.inviteShopID") }}
        </div>
        <div v-else>
          {{ $t("modal.shopMemberList.inviteShopAccount") }}
        </div>
      </h6>
      <!-- 本來想做多人邀請所以才用v-for -->
      <ul>
        <li
          v-for="mail in invite.inviteGroupMail"
          :key="mail.id"
          class="inviteMail-Group"
        >
          <label v-if="inviteMethod === 'id'" for="inviteInputText">
            {{ $t(`modal.shopMemberList.inviteLable.${subscribeLevel}`) }}:
          </label>
          <label v-if="inviteMethod === 'account'" for="inviteInputText">
            {{ $t(`modal.shopMemberList.inviteLable.companyAccount`) }}:
          </label>
          <input
            id="inviteInputText"
            v-model="mail.mail"
            :class="mail.check ? '' : 'error'"
            type="text"
            @keyup="
              invite.mailErrorToggle = false;
              mail.check = true;
              invite.accountNotExistToggle = false;
              invite.cantInviteTechToggle = false;
            "
            :placeholder="[
              vxAccountLevel === vxConstantsAccountLevel.shop
                ? $t('modal.shopMemberList.inviteLable.shopPlaceholder')
                : inviteMethod === 'id'
                ? $t('modal.shopMemberList.inviteLable.companyPlaceholder')
                : $t(
                    'modal.shopMemberList.inviteLable.companyAccountPlaceholder'
                  ),
            ]"
          />
        </li>
      </ul>
      <template v-slot:modal-footer>
        <div v-show="invite.mailErrorToggle" style="color: red">
          {{ $t("modal.shopMemberList.error.text1") }}
        </div>
        <div v-show="invite.accountNotExistToggle" style="color: red">
          {{ $t("modal.shopMemberList.error.text2") }}
        </div>
        <div v-show="invite.cantInviteTechToggle" style="color: red">
          {{ $t("modal.shopMemberList.error.text7") }}
        </div>
        <m-button
          class="tableButton cancel"
          raised
          dense
          :disabled="loading || inviteError"
          :outlined="loading || inviteError"
          @click="fnEmailCheckAndSend"
        >
          {{ $t("button.sendInvitation") }}
          <b-spinner
            v-show="loading"
            label="Loading..."
            small
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
      </template>
    </b-modal>
    <!-- 已送出邀請 -->
    <b-modal id="inviteGroupSuccess" centered :title="$t('modal.success')">
      <h6>{{ $t("modal.shopMemberList.success") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 已有群組 -->
    <b-modal id="shopIDNotNullToggle" centered :title="$t('modal.error')">
      <h6>{{ $t("modal.shopMemberList.error.text4") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- invite group error group is full-->
    <b-modal id="groupFullErrorToggle" centered :title="$t('modal.error')">
      <h6>{{ $t("modal.shopMemberList.error.text3") }}</h6>
      <h6>{{ $t("modal.shopMemberList.error.text5") }}</h6>
      <h6>{{ $t("modal.shopMemberList.error.text6") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- disband group -->
    <b-modal
      id="disbandGroup"
      centered
      no-close-on-backdrop
      no-close-on-esc
      :title="$t('modal.warning')"
    >
      <div v-if="vxAccountLevel === vxConstantsAccountLevel.shop">
        <h6>{{ $t("modal.shopMemberList.disband.text1") }}</h6>
        <h6>{{ $t("modal.shopMemberList.disband.text2") }}</h6>
        <h6>{{ $t("modal.shopMemberList.disband.text3") }}</h6>
        <br />
        <h6>{{ $t("modal.shopMemberList.disband.text4") }}</h6>
      </div>
      <div v-else>
        <h6>{{ $t("modal.shopMemberList.disband.text6") }}</h6>
        <h6>{{ $t("modal.shopMemberList.disband.text7") }}</h6>
        <h6>{{ $t("modal.shopMemberList.disband.text8") }}</h6>
        <br />
        <h6>{{ $t("modal.shopMemberList.disband.text9") }}</h6>
      </div>
      <template v-slot:modal-footer>
        <input
          type="text"
          v-model="disbandGroupCheck"
          :placeholder="$t('modal.shopMemberList.disband.text5')"
        />
        <m-button
          class="tableButton"
          :class="disbandGroupCheck !== 'confirm' ? '' : 'cancel'"
          raised
          dense
          :outlined="loading"
          :disabled="disbandGroupCheck !== 'confirm' || loading"
          @click="fnDisbandGroup"
        >
          {{ $t("button.disband") }}
          <b-spinner
            v-if="loading"
            small
            label="Loading..."
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
      </template>
    </b-modal>
    <!-- 解散群組成功 -->
    <b-modal
      id="disbandGroupSuccess"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$t('modal.success')"
    >
      <h6>{{ $t("modal.shopMemberList.disband.success") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- sub datatable -->
    <b-modal id="subDatatable" centered size="xl" hide-footer>
      <vue-good-table
        mode="remote"
        :columns="subColumns"
        :rows="dataTableSubRows"
        :row-style-class="fnRowStyleClass"
        styleClass="vgt-table striped"
      >
        <div slot="table-actions" class="tableBar">
          <div class="button-group">
            <b-dropdown class="columns" v-if="device !== 'deviceManager'">
              <template v-slot:button-content>
                <m-button class="dropDownButton" :raised="true" :dense="true">{{
                  $t("button.columns")
                }}</m-button>
              </template>
              <b-dropdown-item
                v-for="(column, index) of subColumns"
                :key="index"
              >
                <label>
                  <a
                    href="#"
                    tabIndex="-1"
                    @click.stop.prevent="fnToggleSubColumn(index, $event)"
                  >
                    <input :checked="!column.hidden" type="checkbox" />
                    <span class="checkmark"></span>
                    &nbsp;{{ column.label }}
                  </a>
                </label>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>

        <div slot="emptystate">
          <p>{{ $t("tableColumns.noMatch") }}</p>
        </div>
      </vue-good-table>
    </b-modal>
    <!-- delete blacklist account -->
    <b-modal
      id="deleteBlackListAccountToggle"
      centered
      :title="$t('modal.warning')"
    >
      <h6>
        {{ $t("modal.blacklist.text1") }}
      </h6>
      <h6>
        {{ $t("modal.blacklist.text2") }}
      </h6>
      <template v-slot:modal-footer="{ close, ok }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.no") }}
        </m-button>
        <m-button
          class="tableButton"
          raised
          dense
          @click="ok(fnDeleteBlacklistAccount())"
        >
          {{ $t("button.yes") }}
        </m-button>
      </template>
    </b-modal>
    <!-- Unlock User -->
    <b-modal id="unlockUserToggle" centered :title="$t('modal.warning')">
      <h6>
        {{
          $t("modal.unlockUser.text1") +
          selectedUser +
          $t("modal.unlockUser.text2")
        }}
      </h6>
      <h6>{{ $t("modal.unlockUser.comment") }}</h6>
      <b-input id="unlockComment" v-model="unlockComment" type="textarea" />
      <template v-slot:modal-footer="{ close, ok }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.no") }}
        </m-button>
        <m-button class="tableButton" raised dense @click="ok(fnUnlockUser())">
          {{ $t("button.yes") }}
        </m-button>
      </template>
    </b-modal>
    <b-modal id="unlockUserErrorToggle" centered :title="$t('modal.error')">
      <h6>{{ unlockUserErrorMessage }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import QrcodeVue from "qrcode.vue";
import VueBarcode from "vue-barcode";
import DatePicker from "vue2-datepicker";

import {
  apiURL,
  APIgetAccountInformation,
  APIaccountList,
  APIdisbandGroup,
  APIcheckVaildAndRefresh,
  APIinviteGroup,
  APIkickGroup,
  APIHandoverGroup,
  APIdeviceList,
  APIqrcode,
  APIregistrationCode,
  APIdeviceDelete,
  APIList,
  APIListCell,
  APIgetBlackList,
  APIdeleteBlackListAccount,
  APIexportAccount,
  APIexportRecord,
  APIsigninLogList,
  APIactionLogList,
  APIUnlockUser,
} from "../api";

export default {
  components: {
    VueGoodTable,
    QrcodeVue,
    VueBarcode,
    DatePicker,
  },
  props: {
    columns: Array,
    device: String,
    subColumns: Array,
    unlockUserErrorMessage: String,
  },
  data() {
    return {
      testToggle: true,
      overlay: false,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "testTime",
            type: "desc",
          },
        ],
        searchTerm: "",
        page: 1,
        perPage: 10,
      },
      rows: [],
      // filter
      filter: {
        error: false,
        array: [1], // filter數量
        showTimeRangePanel: [false],
        selected: [""], // filter選擇欄位
        typeOptions: [[""]],
        mixOption: ["number"],
        stringCondition: [[""]],
        numberCondition: [[""]],
        optionCondition: [[]],
        mixStringCondition: [[""]],
        mixNumberCondition: [[""]],
        mixOptionCondition: [""],
        stringType: ["cont"],
        numberType: ["lte"],
        optionType: [""],
        mixStringType: ["cont"],
        mixNumberType: ["lte"],
        timeType: ["local"],
        timeCondition: [[null, null]],
        shortcuts: [
          {
            text: "Today",
            onClick() {
              const date = new Date();
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              let start = new Date(date);
              date.setHours(23);
              date.setMinutes(59);
              date.setSeconds(59);
              let end = new Date(date);
              // return a Date
              return [start, end];
            },
          },
        ],
        AllCondition: [],
      },
      //--
      sortType: "desc",
      totalRecords: 0,
      // 選擇row時用到的變數
      rowSelected: {
        selectedItem: [],
      },
      handoverAccount: "no select",
      addDevice: {
        buttonToggle: true,
        qrcodeToggle: false,
        barcodeToggle: false,
        registrationCodeToggle: false,
        DATAqrcode: "",
        DATAregistrationCode: "",
      },
      invite: {
        inviteGroupMail: [
          {
            id: 0,
            mail: "",
            check: true,
          },
        ],
        mailErrorToggle: false,
        accountNotExistToggle: false,
        cantInviteTechToggle: false,
      },
      inviteMethod: "id",
      disbandGroupCheck: "",
      loading: false,
      subServerParams: {
        columnFilters: {},
        sort: [
          {
            field: "testTime",
            type: "asc",
          },
        ],
        searchTerm: "",
        page: 1,
        perPage: 10,
      },
      subRows: [],
      unlockComment: "",
    };
  },
  created() {
    // 進入後判斷並在head加入
    if (this.vxAccountLevel === this.vxConstantsAccountLevel.business) {
      let cssLink = document.createElement("style");
      cssLink.setAttribute("id", "businessInvite");
      cssLink.setAttribute("type", "text/css");
      cssLink.setAttribute("herf", "../style.css");
      cssLink.innerHTML = `
        #inviteGroup #inviteGroup___BV_modal_body_{
            padding-top: 50px;
        }

        .businessInviteTop{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
        }

        .businessInviteTop a {
            width: 50%;
            text-align: center;
            padding: 8px;
            color: #aaa;
            background: #ddd;
        }

        .businessInviteTop a.active {
            color: #000;
            background: #fff;
            font-weight: 600;
        }

        .inviteLabel:hover{
          color: #999;
          background: rgb(226,226,226);
        }
      `;
      document.head.appendChild(cssLink);
    }
  },
  destroyed() {
    // 離開本頁移除
    let removeStyle = document.getElementById("businessInvite");
    if (removeStyle) removeStyle.parentNode.removeChild(removeStyle);
  },
  methods: {
    blankPage(link) {
      let routeUrl = this.$router.resolve(link);
      window.open(routeUrl.href, "_blank");
    },

    clickInviteMethodAndHideError(choose) {
      this.invite.mailErrorToggle = false;
      this.invite.accountNotExistToggle = false;
      this.invite.cantInviteTechToggle = false;
      this.invite.inviteGroupMail[0].check = true;
      if (choose === "id") this.inviteMethod = "id";
      if (choose === "account") this.inviteMethod = "account";
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.rowSelected.selectedItem = [];
      this.updateParams({ page: params.currentPage });
      this.loadItems(this.device);
    },

    onSortChange(params) {
      this.sortType = params[0].type;
      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      // 使用sort後回歸第一頁
      this.serverParams.page = 1;
      this.$refs["myCustomTable"].resetTable();
      this.loadItems(this.device);
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems(this.device);
      this.filter.error = false;
    },

    //columns
    fnToggleColumn(index) {
      this.$set(this.columns[index], "hidden", !this.columns[index].hidden);
    },
    fnToggleSubColumn(index) {
      this.$set(
        this.subColumns[index],
        "hidden",
        !this.subColumns[index].hidden
      );
    },

    //export
    fnExport(type) {
      switch (this.device) {
        case "manageMemberList":
          APIexportAccount(type, {
            form: this.columnsForm,
            selected: this.rowSelected.selectedItem,
          }).then((res) => {
            let fileName = `Account.${type}`; // 預設檔名
            // 創建一個 Blob 物件
            const blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });

            // 創建一個連結以下載 Blob
            const a = document.createElement("a");
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            // 釋放資源
            window.URL.revokeObjectURL(url);
          });
          break;
        default:
          APIexportRecord(this.device, type, {
            form: this.columnsForm,
          }).then((res) => {
            let exportFileName = this.$t(`export.fileName.${this.device}`);
            let fileName = `${exportFileName}.${type}`; // 預設檔名
            // 創建一個 Blob 物件
            const blob = new Blob([res.data], {
              type: res.headers["content-type"],
            });

            // 創建一個連結以下載 Blob
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            // 釋放資源
            window.URL.revokeObjectURL(url);
          });
          break;
      }
    },
    // #region - hand over
    fnChangeManagerCheckSelected() {
      if (this.rowSelected.selectedItem.length === 0) {
        this.$bvModal.show("noSelectAccountToggle");
      } else if (this.rowSelected.selectedItem.length > 1) {
        this.$bvModal.show("selectMultipleAccount");
      } else {
        this.$bvModal.show("changeGroupManager");
      }
    },
    fnchangeGroupManager() {
      this.loading = true;
      let data = {
        targetAccount: this.rowSelected.selectedItem[0].account,
      };
      APIHandoverGroup(data)
        .then((res) => {
          if (res.data.result === 1) {
            this.handoverAccount = this.rowSelected.selectedItem[0].account;
            this.$bvModal.show("changeShopManagerMailSend");
          }
        })
        .catch((err) => {
          this.$bvModal.hide("changeGroupManager");
          if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show("setCheckToken");
          } else {
            switch (err.response.data.error) {
              case "targetAccount does not exist":
              case "targetAccount is not in the group":
                this.$bvModal.show("kickAccountErrorToggle");
                break;
              case "unauthorized":
              default:
                this.$bvModal.show("setsomethingWrong");
                break;
            }
          }
        })
        .then(() => {
          this.loading = false;
          this.$bvModal.hide("changeGroupManager");
        });
    },
    // #endregion

    // #region - remove device
    fnRemoveDeviceCheckSelected() {
      if (this.rowSelected.selectedItem.length === 0) {
        this.$bvModal.show("noSelectDeviceToggle");
      } else if (this.rowSelected.selectedItem.length > 1) {
        this.$bvModal.show("selectMultipleDeviceToggle");
      } else {
        this.$bvModal.show("deleteDeviceToggle");
      }
    },
    fnRemoveDevice() {
      let data = {
        devicePsn: this.rowSelected.selectedItem[0].psn,
      };
      APIdeviceDelete(data)
        .then((res) => {
          if (res.data.result === 1) {
            this.modalClose();
            let name = this.rows[this.rowSelected.selectedItem[0].id].psn;
            this.rows[this.rowSelected.selectedItem[0].id].psn =
              name + "(Delete...)";
            this.rowSelected.selectedItem = [];
          }
        })
        .catch((err) => {
          if (err.response.data.error === "not exist") {
            this.$bvModal.show("deleteDeviceErrorToggle");
          } else if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show("setCheckToken");
          } else {
            this.$bvModal.show("setsomethingWrong");
          }
        });
    },
    // #endregion

    // #region - register device
    fnAddDevice() {
      this.modalClose();
      this.$bvModal.show("addDeviceToggle");
    },
    fnShowQrcode() {
      this.loading = true;
      APIqrcode()
        .then((res) => {
          this.addDevice.DATAqrcode = JSON.stringify({
            data: res.data.data,
          });
        })
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show("setCheckToken");
          } else {
            this.$bvModal.show("setsomethingWrong");
          }
        });
      this.addDevice.buttonToggle = false;
      this.addDevice.qrcodeToggle = true;
    },
    fnShowBarcode() {
      this.loading = true;
      APIregistrationCode()
        .then((res) => {
          this.addDevice.DATAregistrationCode = res.data.registrationCode;
        })
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show("setCheckToken");
          } else {
            this.$bvModal.show("setsomethingWrong");
          }
        });
      this.addDevice.buttonToggle = false;
      this.addDevice.barcodeToggle = true;
    },
    fnShowRegistrationCode() {
      this.loading = true;
      APIregistrationCode()
        .then((res) => {
          this.addDevice.DATAregistrationCode = res.data.registrationCode;
        })
        .then(() => {
          this.loading = false;
        })
        .catch((err) => {
          if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show("setCheckToken");
          } else {
            this.$bvModal.show("setsomethingWrong");
          }
        });
      this.addDevice.buttonToggle = false;
      this.addDevice.registrationCodeToggle = true;
    },
    // #endregion
    fnUnlock() {
      if (this.rowSelected.selectedItem.length === 0) {
        this.$bvModal.show("noSelectAccountToggle");
      } else if (this.rowSelected.selectedItem.length > 1) {
        this.$bvModal.show("selectMultipleAccount");
      } else {
        if (
          this.rowSelected.selectedItem[0].isLocked.toLowerCase() === "unlocked"
        ) {
          this.unlockUserErrorMessage = this.$t(
            "modal.unlockUser.isAlreadyLocked"
          );
          this.$bvModal.show("unlockUserErrorToggle");
        } else {
          this.$bvModal.show("unlockUserToggle");
        }
      }
    },
    fnUnlockUser() {
      this.loading = true;
      this.overlay = true;
      let data = {
        account: this.rowSelected.selectedItem[0].account,
        comment: this.unlockComment,
      };
      APIUnlockUser(data)
        .then((res) => {
          if (res.data.result === 1) {
            this.$bvModal.show("unlockUserSuccessToggle");
            this.fnDataTableRefresh();
          }
        })
        .catch((err) => {
          if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show("setCheckToken");
          } else if (err.response.status === 400) {
            this.unlockUserErrorMessage = err.response.data.error;
            this.$bvModal.show("unlockUserErrorToggle");
          } else {
            this.$bvModal.show("setsomethingWrong");
          }
        })
        .then(() => {
          this.overlay = false;
          this.$bvModal.hide("unlockUserToggle");
        });
    },
    modalClose() {
      this.$bvModal.hide("addDeviceToggle");
      this.addDevice.buttonToggle = true;
      this.addDevice.qrcodeToggle = false;
      this.addDevice.barcodeToggle = false;
      this.addDevice.registrationCodeToggle = false;
    },

    // #region - filter new
    fnAddFilterCondition() {
      if (this.filter.array.length === 3) {
        return;
      }
      let id = 1;
      if (this.filter.array.length != 0) {
        id = this.filter.array[this.filter.array.length - 1];
        id = id + 1;
      }
      this.filter.array.push(id);
      this.filter.selected.push("");
      this.filter.typeOptions.push([""]);
      this.filter.stringType.push("cont");
      this.filter.stringCondition.push([""]);
      this.filter.numberType.push("lte");
      this.filter.numberCondition.push([""]);
      this.filter.optionType.push("");

      this.filter.mixOption.push("number");
      this.filter.mixStringCondition.push([""]);
      this.filter.mixNumberCondition.push([""]);
      this.filter.mixOptionCondition.push("");
      this.filter.mixStringType.push("cont");
      this.filter.mixNumberType.push("lte");

      this.filter.showTimeRangePanel.push(false);
      this.filter.timeType.push("local");
      this.filter.timeCondition.push([null, null]);
    },
    fnDelelteFilterCondition(selectIndex) {
      this.filter.array.splice(selectIndex, 1);
      this.filter.selected.splice(selectIndex, 1);
      this.filter.typeOptions.splice(selectIndex, 1);
      this.filter.stringType.splice(selectIndex, 1);
      this.filter.stringCondition.splice(selectIndex, 1);
      this.filter.numberType.splice(selectIndex, 1);
      this.filter.numberCondition.splice(selectIndex, 1);
      this.filter.optionType.splice(selectIndex, 1);

      this.filter.mixOption.splice(selectIndex, 1);
      this.filter.mixStringCondition.splice(selectIndex, 1);
      this.filter.mixNumberCondition.splice(selectIndex, 1);
      this.filter.mixOptionCondition.splice(selectIndex, 1);
      this.filter.mixStringType.splice(selectIndex, 1);
      this.filter.mixNumberType.splice(selectIndex, 1);

      this.filter.showTimeRangePanel.splice(selectIndex, 1);
      this.filter.timeType.splice(selectIndex, 1);
      this.filter.timeCondition.splice(selectIndex, 1);

      this.filter.AllCondition.splice(selectIndex, 1); // 暫時留
      this.fnFilterSelectDisabledCheck();
      this.filter.error = false;
    },
    fnFilterConditionChange(selectIndex) {
      let type = "";
      let condition = "";
      let value = [];
      this.fnFilterSelectDisabledCheck();

      switch (this.filter.selected[selectIndex].type) {
        case "String":
          type = "String";
          condition = this.filter.stringType[selectIndex];
          value = JSON.parse(
            JSON.stringify(this.filter.stringCondition[selectIndex])
          );
          break;
        case "Number":
          type = "Number";
          condition = this.filter.numberType[selectIndex];
          value = JSON.parse(
            JSON.stringify(this.filter.numberCondition[selectIndex])
          );
          break;
        case "Option":
          type = "Option";
          this.filter.optionType[selectIndex] = "";
          break;
        case "Mix":
          if (this.filterMixColumns[selectIndex] !== "option") {
            type = "Mix";
            condition = this.mixType[selectIndex];
            value = JSON.parse(JSON.stringify(this.mixCondition[selectIndex]));
          } else {
            type = "Mix";
            condition = "eql";
            this.filter.mixOptionCondition[selectIndex] = "";
          }
          break;
        case "Time":
          type = "Time";
          this.filter.timeCondition[selectIndex][0] = null;
          this.filter.timeCondition[selectIndex][1] = null;
          if (
            this.$refs.datePicker &&
            this.$refs.datePicker.length === this.filter.timeCondition.length
          ) {
            this.$refs.datePicker[selectIndex].clear(); // 當值為空時，畫面不會變成空值，故執行date picker的clear function
          }
          break;
      }
      let obj = {
        id: this.filter.selected[selectIndex].field,
        condition,
        type,
        value,
      };
      // 檢查有無空值
      this.fnFilterConditionEmptyCheck();
      // 檢查filter條件是否相同
      let numberOfSameCondition = this.fnFilterConditionSameAsPast(
        selectIndex,
        obj
      );
      // num>=4就等於全部條件相等
      if (numberOfSameCondition >= 4) {
        return;
      } else {
        // 清除原有位置 塞進新條件
        this.filter.AllCondition.splice(selectIndex, 1, obj);
      }
    },
    fnFilterNumberTypeChange(selectIndex) {
      if (this.filter.numberType[selectIndex] === "between") {
        this.filter.numberCondition[selectIndex].push("");
      } else if (this.filter.numberCondition[selectIndex].length === 2) {
        this.filter.numberCondition[selectIndex].pop();
      }
      this.fnFilterConditionChange(selectIndex);
    },
    fnFilterMixNumberTypeChange(selectIndex) {
      if (this.mixType[selectIndex] === "between") {
        this.filter.mixNumberCondition[selectIndex].push("");
      } else if (this.filter.mixNumberCondition[selectIndex].length === 2) {
        this.filter.mixNumberCondition[selectIndex].pop();
      }
      this.fnFilterConditionChange(selectIndex);
    },
    fnFilterOptionConditionChange(selectIndex) {
      this.fnFilterConditionEmptyCheck();
      let condition = "eql";
      let type = "";
      let value = [];
      switch (this.filter.selected[selectIndex].type) {
        case "Option":
          type = "Option";
          value = this.filter.optionType[selectIndex];
          break;
      }
      let obj = {
        id: this.filter.selected[selectIndex].field,
        condition,
        type,
        value: value,
      };
      // 清除原有位置 塞進新條件
      this.filter.AllCondition.splice(selectIndex, 1, obj);
    },
    fnFilterMixOptionConditionChange(selectIndex) {
      this.fnFilterConditionEmptyCheck();
      let condition = "eql";
      let type = "";
      let value = [];
      switch (this.filter.selected[selectIndex].type) {
        case "Mix":
          type = "Mix";
          value = [this.filter.mixOptionCondition[selectIndex]];
          break;
      }
      let obj = {
        id: this.filter.selected[selectIndex].field,
        condition,
        type,
        value,
      };
      // 清除原有位置 塞進新條件
      this.filter.AllCondition.splice(selectIndex, 1, obj);
    },
    disableFeatureTime(date) {
      const today = new Date();
      if (date > today) {
        return true;
      } else {
        return false;
      }
    },
    toggleTimeRangePanel(selectIndex) {
      this.filter.showTimeRangePanel.splice(
        selectIndex,
        1,
        !this.filter.showTimeRangePanel[selectIndex]
      );
    },
    handleRangeClose(selectIndex) {
      this.filter.showTimeRangePanel.splice(selectIndex, 1, false);
    },
    fnFilterAddTimeCondition(selectIndex) {
      this.fnFilterConditionEmptyCheck();
      // console.log("有值");
      let gte = new Date(this.filter.timeCondition[selectIndex][0]);
      let lte = new Date(this.filter.timeCondition[selectIndex][1]);
      if (this.filter.timeType[selectIndex] === "utc") {
        let timeZone = new Date().getTimezoneOffset() / 60; // 0-原時區
        gte.setHours(gte.getHours() - timeZone);
        lte.setHours(lte.getHours() - timeZone);
        gte = gte.toISOString();
        lte = lte.toISOString();
      } else {
        gte = gte.toISOString();
        lte = lte.toISOString();
      }

      let condition = "between";
      let type = "";
      let value = [];
      switch (this.filter.selected[selectIndex].type) {
        case "Time":
          type = "Time";
          value = [gte, lte];
          break;
      }
      let obj = {
        id: this.filter.selected[selectIndex].field,
        condition,
        type,
        value: value,
      };
      // 清除原有位置 塞進新條件
      this.filter.AllCondition.splice(selectIndex, 1, obj);
    },
    fnFilterKeyboardEvent(e) {
      const selectIndex = Number(e.target.dataset.id); // 抓到id為字串，須轉型為數字
      if (e.key === "Enter") {
        this.fnFilterConditionChange(selectIndex);
      }
      if (e.key === "Backspace") {
        switch (this.filter.selected[selectIndex].type) {
          case "String":
            if (this.filter.stringCondition[selectIndex][0] === "") {
              this.fnFilterConditionChange(selectIndex);
            }
            break;
          case "Number":
            if (
              this.filter.selected[selectIndex].condition !== "between" &&
              this.filter.numberCondition[selectIndex][0] === ""
            ) {
              this.fnFilterConditionChange(selectIndex);
            } else if (
              this.filter.numberCondition[selectIndex][0] === "" ||
              this.filter.numberCondition[selectIndex][1] === ""
            ) {
              this.fnFilterConditionChange(selectIndex);
            }
            break;
        }
      }
    },
    fnFilterBlurEvent(selectIndex) {
      this.fnFilterConditionChange(selectIndex);
    },
    fnFilterConditionEmptyCheck() {
      let emptyToggle = false;
      this.filter.selected.map((el, index) => {
        switch (el.type) {
          case "String":
            if (this.filter.stringCondition[index][0] === "") {
              emptyToggle = true;
            }
            break;
          case "Number":
            if (this.filter.numberType[index] === "between") {
              if (
                this.filter.numberCondition[index][0] === "" ||
                this.filter.numberCondition[index][1] === ""
              ) {
                emptyToggle = true;
              }
            } else {
              if (this.filter.numberCondition[index][0] === "") {
                emptyToggle = true;
              }
            }
            break;
          case "Option":
            if (this.filter.optionType[index] === "") {
              emptyToggle = true;
            }
            break;
          case "Mix":
            if (
              this.filterMixColumns[index] === "number" &&
              this.mixType[index] === "between"
            ) {
              if (
                this.filter.mixNumberCondition[index][0] === "" ||
                this.filter.mixNumberCondition[index][1] === ""
              ) {
                emptyToggle = true;
              }
            } else if (this.filterMixColumns[index] === "number") {
              if (this.filter.mixNumberCondition[index][0] === "") {
                emptyToggle = true;
              }
            } else if (this.filterMixColumns[index] === "string") {
              if (this.filter.mixStringCondition[index][0] === "") {
                emptyToggle = true;
              }
            } else {
              if (this.filter.mixOptionCondition[index] === "") {
                emptyToggle = true;
              }
            }
            break;
          case "Time":
            if (
              this.filter.timeCondition[index][0] === null ||
              this.filter.timeCondition[index][1] === null
            ) {
              emptyToggle = true;
            }
            break;
          default:
            emptyToggle = true;
        }
        this.filter.error = emptyToggle;
      });
    },
    fnFilterConditionSameAsPast(selectIndex, obj) {
      let numberOfSameCondition = 0;
      if (
        this.filter.AllCondition.length > 0 &&
        this.filter.AllCondition[selectIndex]
      ) {
        // let oldObj = JSON.parse(JSON.stringify(this.filter.AllCondition[selectIndex]));  // 本來想轉成物件直接對物件做比較，但後來直接遍歷物件做比較
        let oldObj = this.filter.AllCondition[selectIndex];
        numberOfSameCondition = 0;
        Object.keys(oldObj).forEach((el) => {
          if (obj[el] === oldObj[el] && el !== "value") {
            numberOfSameCondition++;
          } else {
            if (obj[el].length === oldObj[el].length) {
              if (JSON.stringify(obj[el]) === JSON.stringify(oldObj[el])) {
                // 轉成字串展開比較
                numberOfSameCondition++;
              }
            }
          }
        });
      }
      return numberOfSameCondition;
    },
    fnFilterSelectDisabledCheck() {
      this.columns.map((el) => {
        el.disable = false;
        this.filter.selected.map((e) => {
          if (el.field === e.field) {
            el.disable = true;
          }
        });
      });
      if (this.rowSelected.selectedItem.length > 0) {
        this.rowSelected.selectedItem = [];
      }
    },
    fnDataTableRefresh() {
      function fnClick() {
        const date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        let start = new Date(date);
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        let end = new Date(date);
        // return a Date
        return [start, end];
      }
      this.filter = {
        error: false,
        array: [1],
        showTimeRangePanel: [false],
        selected: [""],
        typeOptions: [[""]],
        mixOption: ["number"],
        stringCondition: [[""]],
        numberCondition: [[""]],
        optionCondition: [[]],
        mixStringCondition: [[""]],
        mixNumberCondition: [[""]],
        mixOptionCondition: [""],
        stringType: ["cont"],
        numberType: ["lte"],
        optionType: [""],
        mixStringType: ["cont"],
        mixNumberType: ["lte"],
        timeType: ["local"],
        timeCondition: [[null, null]],
        shortcuts: [
          {
            text: "Today",
            onClick: fnClick,
          },
        ],
        AllCondition: [],
      };
      this.fnFilterSelectDisabledCheck();
      this.rowSelected.selectedItem = [];
      this.rows = [];
      this.serverParams = {
        columnFilters: {},
        sort: [
          {
            field: "testTime",
            type: this.sortType,
          },
        ],
        searchTerm: "",
        page: 1,
        perPage: 10,
      };
      this.totalRecords = 0;
      // reset & paint
      this.$refs["myCustomTable"].resetTable();
      this.modalClose();
    },
    // #endregion

    // #region - invite
    fnEmailCheckAndSend() {
      let data;
      let mailArray = this.invite.inviteGroupMail.map((el) => {
        return el.mail;
      });
      if (this.vxAccountLevel === this.vxConstantsAccountLevel.shop) {
        let mailCheck = mailArray.map((el) => {
                    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
          if (reg.test(el)) {
            return true;
          } else {
            return false;
          }
        });
        mailCheck.forEach((el, index) => {
          this.invite.inviteGroupMail[index].check = el;
          if (el === false) {
            this.invite.mailErrorToggle = true;
          } else {
            this.invite.mailErrorToggle = false;
          }
        });

        if (mailCheck.indexOf(false) < 0) {
          this.loading = true;
          data = {
            account: mailArray[0],
          };
        }
      } else {
        this.loading = true;
        if (this.inviteMethod === "id") {
          data = {
            shopId: mailArray[0],
          };
        } else {
          data = {
            account: mailArray[0],
          };
        }
      }
      if (data) {
        APIinviteGroup(data)
          .then((res) => {
            if (res.data.result === 1) {
              this.$bvModal.hide("inviteGroup");
              this.$bvModal.show("inviteGroupSuccess");
              this.invite.inviteGroupMail = [
                {
                  id: 0,
                  mail: "",
                  check: true,
                },
              ];
            }
          })
          .catch((err) => {
            if (err.response != undefined && err.response.status === 401) {
              this.$bvModal.show("setCheckToken");
            } else {
              switch (err.response.data.error) {
                case "company can only invite shop":
                  this.invite.cantInviteTechToggle = true;
                  this.invite.inviteGroupMail[0].check = false;
                  break;
                case "target does not exist":
                  this.invite.accountNotExistToggle = true;
                  this.invite.inviteGroupMail[0].check = false;
                  break;
                case "belongs to a group":
                  this.$bvModal.show("shopIDNotNullToggle");
                  this.invite.inviteGroupMail[0].mail = "";
                  break;
                case "group is full":
                  this.$bvModal.hide("inviteGroup");
                  this.$bvModal.show("groupFullErrorToggle");
                  this.invite.inviteGroupMail[0].mail = "";
                  this.fnDataTableRefresh();
                  break;
                case "unauthorized":
                  this.$bvModal.hide("inviteGroup");
                  this.$bvModal.show("unauthorizedErrorToggle");
                  this.invite.inviteGroupMail[0].mail = "";
                  break;
                default:
                  this.invite.inviteGroupMail[0].mail = "";
                  this.$bvModal.show("setsomethingWrong");
              }
            }
          })
          .then(() => {
            this.loading = false;
          });
      }
    },
    // #endregion

    // kick out
    fnKickAccountCheckSelected() {
      if (this.rowSelected.selectedItem.length === 0) {
        this.$bvModal.show("noSelectAccountToggle");
      } else if (this.rowSelected.selectedItem.length > 1) {
        this.$bvModal.show("selectMultipleAccount");
      } else {
        this.$bvModal.show("kickAccountToggle");
      }
    },
    fnKickAccount() {
      this.overlay = true;
      let data = {
        account: this.rowSelected.selectedItem[0].account,
      };
      APIkickGroup(data)
        .then((res) => {
          if (res.data.result === 1) {
            let name = this.rows[this.rowSelected.selectedItem[0].id].account;
            this.rows[this.rowSelected.selectedItem[0].id].account =
              name + "(Delete...)";
            this.rowSelected.selectedItem = [];
            // server tasks
            this.$bvModal.show("testDataTransform");
          }
        })
        .catch((err) => {
          if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show("setCheckToken");
          } else {
            switch (err.response.data.error) {
              case "account does not exist":
                this.$bvModal.show("kickAccountErrorToggle");
                break;
              case "unauthorized":
              case "different group id":
                this.$bvModal.show("unauthorizedErrorToggle");
                break;
              case "cannot kick yourself":
                this.$bvModal.show("kickSameLevelErrorToggle");
                break;
              case "company can only kick shop":
                this.$bvModal.show("canOnlyKickShopErrorToggle");
                break;
              default:
                this.$bvModal.show("setsomethingWrong");
            }
          }
        })
        .then(() => {
          this.overlay = false;
        });
    },

    //disband shop
    fnDisbandGroup() {
      this.loading = true;
      APIdisbandGroup()
        .then((res) => {
          if (res.data.result === 1) {
            APIcheckVaildAndRefresh()
              .then((res) => {
                if (res.data.result === 1) {
                  window.localStorage.setItem(
                    "token",
                    "bearer " + res.data.token
                  );
                  this.$store.dispatch("setAccount", res.data.account);
                  this.$store.dispatch("setAccountInformation", {
                    account: res.data.account,
                    accountLevel: res.data.accountLevel,
                    companyId: res.data.companyId,
                    nationalId: res.data.nationalId,
                    regionalId: res.data.regionalId,
                    shopId: res.data.shopId,
                    pricingPlan: res.data.pricingPlan,
                  });
                }
                this.$bvModal.show("disbandGroupSuccess");
                APIgetAccountInformation()
                  .then((res) => {
                    this.$store.dispatch("setAccountInformation", res.data);
                  })
                  .catch((err) => {
                    this.loading = false;
                    if (
                      err.response != undefined &&
                      err.response.status === 401
                    ) {
                      this.$bvModal.show("setCheckToken");
                    } else {
                      this.$bvModal.show("setsomethingWrong");
                    }
                  });
              })
              .then(() => {
                setTimeout(() => {
                  this.$router.push("/subscribePlan");
                }, 2000);
              })
              .catch((err) => {
                if (err.response != undefined && err.response.status === 401) {
                  this.$bvModal.show("setCheckToken");
                } else {
                  switch (err.response.data.error) {
                    case "token missing":
                    case "account does not exist":
                      this.$bvModal.show("setCheckToken");
                      break;
                    default:
                      this.$bvModal.show("setsomethingWrong");
                      break;
                  }
                }
              });
          }
        })
        .catch((err) => {
          if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show("setCheckToken");
          } else {
            switch (err.response.data.error) {
              case "unauthorized":
                this.$bvModal.hide("disbandGroup");
                this.$bvModal.show("unauthorizedErrorToggle");
                break;
              default:
                this.$bvModal.hide("disbandGroup");
                this.$bvModal.show("setsomethingWrong");
            }
          }
          this.loading = false;
          this.disbandGroupCheck = "";
        });
    },
    loadItems(device) {
      this.overlay = true;
      let dataTableAPI = null;
      const nonSortPages = [
        "deviceManager",
        "manageMemberList",
        "blacklist",
        "signinLogs",
        "actionLogs",
      ];

      if (nonSortPages.indexOf(device) > -1) {
        this.serverParams.sort = [
          {
            field: "",
            type: "",
          },
        ];
      }
      switch (device) {
        case "deviceManager":
          dataTableAPI = APIdeviceList(this.serverParams);
          break;
        case "manageMemberList":
          dataTableAPI = APIaccountList(this.serverParams);
          break;
        case "blacklist":
          dataTableAPI = APIgetBlackList();
          break;
        case "signinLogs":
          dataTableAPI = APIsigninLogList(this.serverParams);
          break;
        case "actionLogs":
          dataTableAPI = APIactionLogList(this.serverParams);
          break;
        default:
          dataTableAPI = APIList(device, this.serverParams);
          break;
      }
      dataTableAPI
        .then((res) => {
          this.totalRecords = res.data.totalRecords;

          if (device === "btw350BT" || device === "btw350IVT") {
            this.rows = res.data.rows.map((e) => ({
              ...e,
              ir: (() => {
                if (e.ir * 100 > 99999) return ">999.99";
                if (e.ir * 10 < 15) return "<1.5";
                return e.ir;
              })(),
            }));
          } else if (device === "blacklist") {
            this.rows = res.data.result;
          } else {
            this.rows = res.data.rows;
          }
        })
        .catch((err) => {
          if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show("setCheckToken");
          } else {
            this.$bvModal.show("setsomethingWrong");
          }
        })
        .then(() => {
          this.overlay = false;
        });
    },
    loadSubItems(device, psn, testTime) {
      // erase all
      this.subRows = [];

      // fill in psn + test time to search the reocrd
      this.subServerParams.columnFilters.psn = {
        eql: psn,
      };
      this.subServerParams.columnFilters.testTime = {
        lte: testTime,
        gte: testTime,
      };

      let dataTableAPI = APIListCell(device, this.subServerParams);
      dataTableAPI
        .then((res) => {
          this.subRows = res.data.rows;
        })
        .catch((err) => {
          if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show("setCheckToken");
          } else {
            this.$bvModal.show("setsomethingWrong");
          }
        })
        .then(() => {
          this.$bvModal.show("subDatatable");
        });
    },

    onRowClick(params) {
      // 必須為primary key || unique key
      // arr.findIndex(function) 尋找陣列中符合function的第一筆資料並返回index，找不到返回-1
      switch (this.device) {
        case "manageMemberList": {
          let checkIndex = this.rowSelected.selectedItem.findIndex(
            (obj) => obj.account === params.row.account
          );
          let object = {
            account: params.row.account,
            id: params.row.originalIndex,
            pricingPlan: params.row.pricingPlan,
            shopId: params.row.shopId,
            companyId: params.row.companyId,
            status: params.row.status,
            isLocked: params.row.isLocked,
            lockedAt: params.row.lockedAt,
          };
          if (checkIndex < 0) {
            this.rowSelected.selectedItem.push(object);
          } else {
            this.rowSelected.selectedItem.splice(checkIndex, 1);
          }
          break;
        }
        case "deviceManager": {
          let checkIndex = this.rowSelected.selectedItem.findIndex(
            (obj) => obj.psn === params.row.psn
          );
          let object = {
            psn: params.row.psn,
            id: params.row.originalIndex,
          };
          if (checkIndex < 0) {
            this.rowSelected.selectedItem.push(object);
          } else {
            this.rowSelected.selectedItem.splice(checkIndex, 1);
          }
          break;
        }
        case "blacklist": {
          let checkIndex = this.rowSelected.selectedItem.findIndex(
            (obj) => obj.recipient === params.row.recipient
          );
          let object = {
            recipient: params.row.recipient,
            id: params.row.originalIndex,
          };
          if (checkIndex < 0) {
            this.rowSelected.selectedItem.push(object);
          } else {
            this.rowSelected.selectedItem.splice(checkIndex, 1);
          }
          break;
        }
        case "bt2400TG31":
        case "bt2400PK24":
          this.loadSubItems(this.device, params.row.psn, params.row.testTime);
          break;
      }
    },

    // row Click
    fnRowStyleClass(row) {
      return row.vgtSelected ? "VGT-row _dblclick" : "VGT-row";
    },

    // #region - fnDeleteBlackListAccount
    showDeleteBlackListAccountModal() {
      if (this.rowSelected.selectedItem.length === 0) {
        this.$bvModal.show("noSelectAccountToggle");
      } else if (this.rowSelected.selectedItem.length > 1) {
        this.$bvModal.show("selectMultipleAccount");
      } else {
        this.$bvModal.show("deleteBlackListAccountToggle");
      }
    },

    async fnDeleteBlacklistAccount() {
      try {
        const data = await APIdeleteBlackListAccount(
          this.rowSelected.selectedItem[0].recipient
        );

        if (data.status === 204) {
          this.fnDataTableRefresh();
        }
      } catch (err) {
        if (err.response != undefined && err.response.status === 401) {
          this.$bvModal.show("setCheckToken");
        } else {
          this.$bvModal.show("setsomethingWrong");
        }
      }
    },
    // #endregion
  },
  computed: {
    vxAccountLevel() {
      return this.$store.state.accountInformation.accountLevel;
    },
    vxAccountCompanyId() {
      return this.$store.state.accountInformation.companyId;
    },
    vxLanguage() {
      return this.$store.state.language;
    },
    vxConstantsAccountLevel() {
      return this.$store.state.constants.csAccountLevel;
    },
    // for modal text
    subscribeLevel() {
      if (this.vxAccountLevel === this.vxConstantsAccountLevel.business) {
        return "company";
      } else {
        return "shop";
      }
    },
    // for invite title
    modalTitle() {
      if (this.vxAccountLevel === this.vxConstantsAccountLevel.business) {
        return "modal.inviteShop";
      } else {
        return "modal.inviteUser";
      }
    },
    // for invite button
    inviteButtonTitle() {
      if (this.vxAccountLevel === this.vxConstantsAccountLevel.business) {
        return "button.inviteShop";
      } else {
        return "button.inviteUser";
      }
    },
    // for remove button
    removeButtonTitle() {
      if (this.vxAccountLevel === this.vxConstantsAccountLevel.business) {
        return "button.remove";
      } else {
        return "button.detach";
      }
    },
    dataTableRows() {
      this.rows.map((el) => {
        switch (this.device) {
          case "btw200BT":
            switch (el.systemVoltage) {
              case "2":
                el.systemVoltage = this.$t(
                  "filterOption.btw200BT.systemVoltage.2"
                );
                break;
            }
            switch (el.testType) {
              case "1":
                el.testType = this.$t("filterOption.btw200BT.testType.1");
                break;
              case "2":
                el.testType = this.$t("filterOption.btw200BT.testType.2");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.btw200BT.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.btw200BT.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.btw200BT.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.btw200BT.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.btw200BT.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.btw200BT.judgement.5");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t("filterOption.btw200BT.batteryType.0");
                break;
              case "1":
                el.batteryType = this.$t("filterOption.btw200BT.batteryType.1");
                break;
              case "2":
                el.batteryType = this.$t("filterOption.btw200BT.batteryType.2");
                break;
              case "3":
                el.batteryType = this.$t("filterOption.btw200BT.batteryType.3");
                break;
              case "5":
                el.batteryType = this.$t("filterOption.btw200BT.batteryType.5");
                break;
              case "6":
                el.batteryType = this.$t("filterOption.btw200BT.batteryType.6");
                break;
              case "7":
                el.batteryType = this.$t("filterOption.btw200BT.batteryType.7");
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.btw200BT.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.btw200BT.rating.1");
                break;
              case "3":
                el.rating = this.$t("filterOption.btw200BT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.btw200BT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.btw200BT.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.btw200BT.rating.6");
                break;
            }
            switch (el.isBatteryCharged) {
              case "1":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw200BT.isBatteryCharged.1"
                );
                break;
              case "2":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw200BT.isBatteryCharged.2"
                );
                break;
              case "3":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw200BT.isBatteryCharged.3"
                );
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.btw200BT.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.btw200BT.batInVehicle.2"
                );
                break;
              case "3":
                el.batInVehicle = this.$t(
                  "filterOption.btw200BT.batInVehicle.3"
                );
                break;
            }
            break;
          case "btw200ST":
            switch (el.testType) {
              case "5":
                el.testType = this.$t("filterOption.btw200ST.testType.5");
                break;
              case "6":
                el.testType = this.$t("filterOption.btw200ST.testType.6");
                break;
            }
            switch (el.crankingJudgement) {
              case "3":
                el.crankingJudgement = this.$t(
                  "filterOption.btw200ST.crankingJudgement.3"
                );
                break;
              case "5":
                el.crankingJudgement = this.$t(
                  "filterOption.btw200ST.crankingJudgement.5"
                );
                break;
              case "8":
                el.crankingJudgement = this.$t(
                  "filterOption.btw200ST.crankingJudgement.8"
                );
                break;
            }
            switch (el.altIdleJudgement) {
              case "8":
                el.altIdleJudgement = this.$t(
                  "filterOption.btw200ST.altIdleJudgement.8"
                );
                break;
              case "9":
                el.altIdleJudgement = this.$t(
                  "filterOption.btw200ST.altIdleJudgement.9"
                );
                break;
            }
            switch (el.rippleJudgement) {
              case "3":
                el.rippleJudgement = this.$t(
                  "filterOption.btw200ST.rippleJudgement.3"
                );
                break;
              case "7":
                el.rippleJudgement = this.$t(
                  "filterOption.btw200ST.rippleJudgement.7"
                );
                break;
              case "8":
                el.rippleJudgement = this.$t(
                  "filterOption.btw200ST.rippleJudgement.8"
                );
                break;
            }
            switch (el.altLoadJudgement) {
              case "8":
                el.altLoadJudgement = this.$t(
                  "filterOption.btw200ST.altLoadJudgement.8"
                );
                break;
              case "9":
                el.altLoadJudgement = this.$t(
                  "filterOption.btw200ST.altLoadJudgement.9"
                );
                break;
            }
            switch (el.smartChargingControl) {
              case "1":
                el.smartChargingControl = this.$t(
                  "filterOption.btw200ST.smartChargingControl.1"
                );
                break;
              case "2":
                el.smartChargingControl = this.$t(
                  "filterOption.btw200ST.smartChargingControl.2"
                );
                break;
            }
            switch (el.rippleLowestJudgement) {
              case "3":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw200ST.rippleLowestJudgement.3"
                );
                break;
              case "7":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw200ST.rippleLowestJudgement.7"
                );
                break;
              case "8":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw200ST.rippleLowestJudgement.8"
                );
                break;
            }
            switch (el.rippleHighestJudgement) {
              case "3":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw200ST.rippleHighestJudgement.3"
                );
                break;
              case "7":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw200ST.rippleHighestJudgement.7"
                );
                break;
              case "8":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw200ST.rippleHighestJudgement.8"
                );
                break;
            }
            break;
          case "btw200IVT":
            switch (el.systemVoltage) {
              case "2":
                el.systemVoltage = this.$t(
                  "filterOption.btw200IVT.systemVoltage.2"
                );
                break;
            }
            switch (el.testType) {
              case "4":
                el.testType = this.$t("filterOption.btw200IVT.testType.4");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.btw200IVT.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.btw200IVT.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.btw200IVT.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.btw200IVT.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.btw200IVT.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.btw200IVT.judgement.5");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.btw200IVT.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.btw200IVT.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.btw200IVT.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.btw200IVT.batteryType.3"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.btw200IVT.batteryType.5"
                );
                break;
              case "6":
                el.batteryType = this.$t(
                  "filterOption.btw200IVT.batteryType.6"
                );
                break;
              case "7":
                el.batteryType = this.$t(
                  "filterOption.btw200IVT.batteryType.7"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.btw200IVT.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.btw200IVT.rating.1");
                break;
              case "3":
                el.rating = this.$t("filterOption.btw200IVT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.btw200IVT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.btw200IVT.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.btw200IVT.rating.6");
                break;
            }
            switch (el.isBatteryCharged) {
              case "1":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw200IVT.isBatteryCharged.1"
                );
                break;
              case "2":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw200IVT.isBatteryCharged.2"
                );
                break;
              case "3":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw200IVT.isBatteryCharged.3"
                );
                break;
            }
            switch (el.crankingJudgement) {
              case "3":
                el.crankingJudgement = this.$t(
                  "filterOption.btw200IVT.crankingJudgement.3"
                );
                break;
              case "5":
                el.crankingJudgement = this.$t(
                  "filterOption.btw200IVT.crankingJudgement.5"
                );
                break;
              case "8":
                el.crankingJudgement = this.$t(
                  "filterOption.btw200IVT.crankingJudgement.8"
                );
                break;
            }
            switch (el.altIdleJudgement) {
              case "8":
                el.altIdleJudgement = this.$t(
                  "filterOption.btw200IVT.altIdleJudgement.8"
                );
                break;
              case "9":
                el.altIdleJudgement = this.$t(
                  "filterOption.btw200IVT.altIdleJudgement.9"
                );
                break;
            }
            switch (el.rippleJudgement) {
              case "3":
                el.rippleJudgement = this.$t(
                  "filterOption.btw200IVT.rippleJudgement.3"
                );
                break;
              case "7":
                el.rippleJudgement = this.$t(
                  "filterOption.btw200IVT.rippleJudgement.7"
                );
                break;
              case "8":
                el.rippleJudgement = this.$t(
                  "filterOption.btw200IVT.rippleJudgement.8"
                );
                break;
            }
            switch (el.altLoadJudgement) {
              case "8":
                el.altLoadJudgement = this.$t(
                  "filterOption.btw200IVT.altLoadJudgement.8"
                );
                break;
              case "9":
                el.altLoadJudgement = this.$t(
                  "filterOption.btw200IVT.altLoadJudgement.9"
                );
                break;
            }
            switch (el.smartChargingControl) {
              case "1":
                el.smartChargingControl = this.$t(
                  "filterOption.btw200IVT.smartChargingControl.1"
                );
                break;
              case "2":
                el.smartChargingControl = this.$t(
                  "filterOption.btw200IVT.smartChargingControl.2"
                );
                break;
            }
            switch (el.rippleLowestJudgement) {
              case "3":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw200IVT.rippleLowestJudgement.3"
                );
                break;
              case "7":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw200IVT.rippleLowestJudgement.7"
                );
                break;
              case "8":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw200IVT.rippleLowestJudgement.8"
                );
                break;
            }
            switch (el.rippleHighestJudgement) {
              case "3":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw200IVT.rippleHighestJudgement.3"
                );
                break;
              case "7":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw200IVT.rippleHighestJudgement.7"
                );
                break;
              case "8":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw200IVT.rippleHighestJudgement.8"
                );
                break;
            }
            break;
          case "bt2300plusBT":
            switch (el.testType) {
              case "1":
                el.testType = this.$t("filterOption.bt2300plusBT.testType.1");
                break;
              case "2":
                el.testType = this.$t("filterOption.bt2300plusBT.testType.2");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.bt2300plusBT.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.bt2300plusBT.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.bt2300plusBT.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.bt2300plusBT.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.bt2300plusBT.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.bt2300plusBT.judgement.5");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.bt2300plusBT.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.bt2300plusBT.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.bt2300plusBT.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.bt2300plusBT.batteryType.3"
                );
                break;
              case "4":
                el.batteryType = this.$t(
                  "filterOption.bt2300plusBT.batteryType.4"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.bt2300plusBT.batteryType.5"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.bt2300plusBT.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.bt2300plusBT.rating.1");
                break;
              case "3":
                el.rating = this.$t("filterOption.bt2300plusBT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.bt2300plusBT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.bt2300plusBT.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.bt2300plusBT.rating.6");
                break;
              case "8":
                el.rating = this.$t("filterOption.bt2300plusBT.rating.8");
                break;
            }
            switch (el.cellShort) {
              case "1":
                el.cellShort = this.$t("filterOption.bt2300plusBT.cellShort.1");
                break;
              case "2":
                el.cellShort = this.$t("filterOption.bt2300plusBT.cellShort.2");
                break;
            }
            switch (el.surfaceCharge) {
              case "1":
                el.surfaceCharge = this.$t(
                  "filterOption.bt2300plusBT.surfaceCharge.1"
                );
                break;
              case "2":
                el.surfaceCharge = this.$t(
                  "filterOption.bt2300plusBT.surfaceCharge.2"
                );
                break;
            }
            switch (el.batCharged) {
              case "1":
                el.batCharged = this.$t(
                  "filterOption.bt2300plusBT.batCharged.1"
                );
                break;
              case "2":
                el.batCharged = this.$t(
                  "filterOption.bt2300plusBT.batCharged.2"
                );
                break;
              case "3":
                el.batCharged = this.$t(
                  "filterOption.bt2300plusBT.batCharged.3"
                );
                break;
            }
            break;
          case "bt2300plusST":
            switch (el.testType) {
              case "3":
                el.testType = this.$t("filterOption.bt2300plusST.testType.3");
                break;
            }
            switch (el.crankingResult) {
              case "4":
                el.crankingResult = this.$t(
                  "filterOption.bt2300plusST.crankingResult.4"
                );
                break;
              case "5":
                el.crankingResult = this.$t(
                  "filterOption.bt2300plusST.crankingResult.5"
                );
                break;
              case "6":
                el.crankingResult = this.$t(
                  "filterOption.bt2300plusST.crankingResult.6"
                );
                break;
              case "7":
                el.crankingResult = this.$t(
                  "filterOption.bt2300plusST.crankingResult.7"
                );
                break;
            }
            switch (el.altIdleResult) {
              case "4":
                el.altIdleResult = this.$t(
                  "filterOption.bt2300plusST.altIdleResult.4"
                );
                break;
              case "5":
                el.altIdleResult = this.$t(
                  "filterOption.bt2300plusST.altIdleResult.5"
                );
                break;
              case "6":
                el.altIdleResult = this.$t(
                  "filterOption.bt2300plusST.altIdleResult.6"
                );
                break;
              case "7":
                el.altIdleResult = this.$t(
                  "filterOption.bt2300plusST.altIdleResult.7"
                );
                break;
            }
            switch (el.rippleResult) {
              case "4":
                el.rippleResult = this.$t(
                  "filterOption.bt2300plusST.rippleResult.4"
                );
                break;
              case "5":
                el.rippleResult = this.$t(
                  "filterOption.bt2300plusST.rippleResult.5"
                );
                break;
              case "6":
                el.rippleResult = this.$t(
                  "filterOption.bt2300plusST.rippleResult.6"
                );
                break;
              case "7":
                el.rippleResult = this.$t(
                  "filterOption.bt2300plusST.rippleResult.7"
                );
                break;
            }
            switch (el.altLoadResult) {
              case "4":
                el.altLoadResult = this.$t(
                  "filterOption.bt2300plusST.altLoadResult.4"
                );
                break;
              case "5":
                el.altLoadResult = this.$t(
                  "filterOption.bt2300plusST.altLoadResult.5"
                );
                break;
              case "6":
                el.altLoadResult = this.$t(
                  "filterOption.bt2300plusST.altLoadResult.6"
                );
                break;
              case "7":
                el.altLoadResult = this.$t(
                  "filterOption.bt2300plusST.altLoadResult.7"
                );
                break;
            }
            break;
          case "bt2200BT":
            switch (el.testType) {
              case "1":
                el.testType = this.$t("filterOption.bt2200BT.testType.1");
                break;
              case "2":
                el.testType = this.$t("filterOption.bt2200BT.testType.2");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.bt2200BT.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.bt2200BT.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.bt2200BT.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.bt2200BT.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.bt2200BT.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.bt2200BT.judgement.5");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t("filterOption.bt2200BT.batteryType.0");
                break;
              case "1":
                el.batteryType = this.$t("filterOption.bt2200BT.batteryType.1");
                break;
              case "2":
                el.batteryType = this.$t("filterOption.bt2200BT.batteryType.2");
                break;
              case "3":
                el.batteryType = this.$t("filterOption.bt2200BT.batteryType.3");
                break;
              case "5":
                el.batteryType = this.$t("filterOption.bt2200BT.batteryType.5");
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.bt2200BT.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.bt2200BT.rating.1");
                break;
              case "2":
                el.rating = this.$t("filterOption.bt2200BT.rating.2");
                break;
              case "3":
                el.rating = this.$t("filterOption.bt2200BT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.bt2200BT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.bt2200BT.rating.5");
                break;
              case "7":
                el.rating = this.$t("filterOption.bt2200BT.rating.7");
                break;
            }
            switch (el.batteryCharged) {
              case "1":
                el.batteryCharged = this.$t(
                  "filterOption.bt2200BT.batteryCharged.1"
                );
                break;
              case "2":
                el.batteryCharged = this.$t(
                  "filterOption.bt2200BT.batteryCharged.2"
                );
                break;
            }
            break;
          case "bt2200ST":
            switch (el.testType) {
              case "3":
                el.testType = this.$t("filterOption.bt2200ST.testType.3");
                break;
            }
            switch (el.crankingResult) {
              case "4":
                el.crankingResult = this.$t(
                  "filterOption.bt2200ST.crankingResult.4"
                );
                break;
              case "5":
                el.crankingResult = this.$t(
                  "filterOption.bt2200ST.crankingResult.5"
                );
                break;
              case "6":
                el.crankingResult = this.$t(
                  "filterOption.bt2200ST.crankingResult.6"
                );
                break;
              case "7":
                el.crankingResult = this.$t(
                  "filterOption.bt2200ST.crankingResult.7"
                );
                break;
            }
            switch (el.altIdleResult) {
              case "5":
                el.altIdleResult = this.$t(
                  "filterOption.bt2200ST.altIdleResult.5"
                );
                break;
              case "6":
                el.altIdleResult = this.$t(
                  "filterOption.bt2200ST.altIdleResult.6"
                );
                break;
              case "7":
                el.altIdleResult = this.$t(
                  "filterOption.bt2200ST.altIdleResult.7"
                );
                break;
            }
            switch (el.rippleResult) {
              case "4":
                el.rippleResult = this.$t(
                  "filterOption.bt2200ST.rippleResult.4"
                );
                break;
              case "5":
                el.rippleResult = this.$t(
                  "filterOption.bt2200ST.rippleResult.5"
                );
                break;
              case "6":
                el.rippleResult = this.$t(
                  "filterOption.bt2200ST.rippleResult.6"
                );
                break;
              case "7":
                el.rippleResult = this.$t(
                  "filterOption.bt2200ST.rippleResult.7"
                );
                break;
            }
            switch (el.altLoadResult) {
              case "5":
                el.altLoadResult = this.$t(
                  "filterOption.bt2200ST.altLoadResult.5"
                );
                break;
              case "6":
                el.altLoadResult = this.$t(
                  "filterOption.bt2200ST.altLoadResult.6"
                );
                break;
              case "7":
                el.altLoadResult = this.$t(
                  "filterOption.bt2200ST.altLoadResult.7"
                );
                break;
            }
            break;
          case "bt2400BT":
            switch (el.testType) {
              case "1":
                el.testType = this.$t("filterOption.bt2400BT.testType.1");
                break;
            }
            switch (el.systemVolCh) {
              case "1":
                el.systemVolCh = this.$t("filterOption.bt2400BT.systemVolCh.1");
                break;
              case "2":
                el.systemVolCh = this.$t("filterOption.bt2400BT.systemVolCh.2");
                break;
              case "3":
                el.systemVolCh = this.$t("filterOption.bt2400BT.systemVolCh.3");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t("filterOption.bt2400BT.batteryType.0");
                break;
              case "1":
                el.batteryType = this.$t("filterOption.bt2400BT.batteryType.1");
                break;
              case "2":
                el.batteryType = this.$t("filterOption.bt2400BT.batteryType.2");
                break;
              case "3":
                el.batteryType = this.$t("filterOption.bt2400BT.batteryType.3");
                break;
              case "4":
                el.batteryType = this.$t("filterOption.bt2400BT.batteryType.4");
                break;
              case "5":
                el.batteryType = this.$t("filterOption.bt2400BT.batteryType.5");
                break;
              case "8":
                el.batteryType = this.$t("filterOption.bt2400BT.batteryType.8");
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.bt2400BT.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.bt2400BT.rating.1");
                break;
              case "2":
                el.rating = this.$t("filterOption.bt2400BT.rating.2");
                break;
              case "3":
                el.rating = this.$t("filterOption.bt2400BT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.bt2400BT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.bt2400BT.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.bt2400BT.rating.6");
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400BT.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400BT.batInVehicle.2"
                );
                break;
              case "3":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400BT.batInVehicle.3"
                );
                break;
            }
            switch (el.batCharged) {
              case "1":
                el.batCharged = this.$t("filterOption.bt2400BT.batCharged.1");
                break;
              case "2":
                el.batCharged = this.$t("filterOption.bt2400BT.batCharged.2");
                break;
              case "3":
                el.batCharged = this.$t("filterOption.bt2400BT.batCharged.3");
                break;
            }
            switch (el.ir) {
              case "5":
                el.ir = this.$t("filterOption.bt2400BT.ir.5");
                break;
              case "7":
                el.ir = this.$t("filterOption.bt2400BT.ir.7");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.bt2400BT.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.bt2400BT.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.bt2400BT.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.bt2400BT.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.bt2400BT.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.bt2400BT.judgement.5");
                break;
            }
            break;
          case "bt2400ST":
            switch (el.testType) {
              case "3":
                el.testType = this.$t("filterOption.bt2400ST.testType.3");
                break;
            }
            switch (el.systemVolCh) {
              case "1":
                el.systemVolCh = this.$t("filterOption.bt2400ST.systemVolCh.1");
                break;
              case "2":
                el.systemVolCh = this.$t("filterOption.bt2400ST.systemVolCh.2");
                break;
              case "3":
                el.systemVolCh = this.$t("filterOption.bt2400ST.systemVolCh.3");
                break;
            }
            switch (el.crankingResult) {
              case "4":
                el.crankingResult = this.$t(
                  "filterOption.bt2400ST.crankingResult.4"
                );
                break;
              case "5":
                el.crankingResult = this.$t(
                  "filterOption.bt2400ST.crankingResult.5"
                );
                break;
              case "6":
                el.crankingResult = this.$t(
                  "filterOption.bt2400ST.crankingResult.6"
                );
                break;
              case "7":
                el.crankingResult = this.$t(
                  "filterOption.bt2400ST.crankingResult.7"
                );
                break;
            }
            switch (el.altIdleResult) {
              case "4":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400ST.altIdleResult.4"
                );
                break;
              case "5":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400ST.altIdleResult.5"
                );
                break;
              case "6":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400ST.altIdleResult.6"
                );
                break;
              case "7":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400ST.altIdleResult.7"
                );
                break;
            }
            switch (el.rippleResult) {
              case "4":
                el.rippleResult = this.$t(
                  "filterOption.bt2400ST.rippleResult.4"
                );
                break;
              case "5":
                el.rippleResult = this.$t(
                  "filterOption.bt2400ST.rippleResult.5"
                );
                break;
              case "6":
                el.rippleResult = this.$t(
                  "filterOption.bt2400ST.rippleResult.6"
                );
                break;
              case "7":
                el.rippleResult = this.$t(
                  "filterOption.bt2400ST.rippleResult.7"
                );
                break;
            }
            switch (el.altLoadResult) {
              case "4":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400ST.altLoadResult.4"
                );
                break;
              case "5":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400ST.altLoadResult.5"
                );
                break;
              case "6":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400ST.altLoadResult.6"
                );
                break;
              case "7":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400ST.altLoadResult.7"
                );
                break;
            }
            switch (el.dieselCheck) {
              case "1":
                el.dieselCheck = this.$t("filterOption.bt2400ST.dieselCheck.1");
                break;
              case "2":
                el.dieselCheck = this.$t("filterOption.bt2400ST.dieselCheck.2");
                break;
            }
            break;
          case "bt2400IVT":
            switch (el.testType) {
              case "4":
                el.testType = this.$t("filterOption.bt2400IVT.testType.4");
                break;
            }
            switch (el.systemVolCh) {
              case "1":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400IVT.systemVolCh.1"
                );
                break;
              case "2":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400IVT.systemVolCh.2"
                );
                break;
              case "3":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400IVT.systemVolCh.3"
                );
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.bt2400IVT.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.bt2400IVT.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.bt2400IVT.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.bt2400IVT.batteryType.3"
                );
                break;
              case "4":
                el.batteryType = this.$t(
                  "filterOption.bt2400IVT.batteryType.4"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.bt2400IVT.batteryType.5"
                );
                break;
              case "8":
                el.batteryType = this.$t(
                  "filterOption.bt2400IVT.batteryType.8"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.bt2400IVT.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.bt2400IVT.rating.1");
                break;
              case "2":
                el.rating = this.$t("filterOption.bt2400IVT.rating.2");
                break;
              case "3":
                el.rating = this.$t("filterOption.bt2400IVT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.bt2400IVT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.bt2400IVT.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.bt2400IVT.rating.6");
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400IVT.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400IVT.batInVehicle.2"
                );
                break;
              case "3":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400IVT.batInVehicle.3"
                );
                break;
            }
            switch (el.batCharged) {
              case "1":
                el.batCharged = this.$t("filterOption.bt2400IVT.batCharged.1");
                break;
              case "2":
                el.batCharged = this.$t("filterOption.bt2400IVT.batCharged.2");
                break;
              case "3":
                el.batCharged = this.$t("filterOption.bt2400IVT.batCharged.3");
                break;
            }
            switch (el.ir) {
              case "5":
                el.ir = this.$t("filterOption.bt2400BT.ir.5");
                break;
              case "7":
                el.ir = this.$t("filterOption.bt2400BT.ir.7");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.bt2400IVT.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.bt2400IVT.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.bt2400IVT.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.bt2400IVT.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.bt2400IVT.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.bt2400IVT.judgement.5");
                break;
            }
            switch (el.crankingResult) {
              case "4":
                el.crankingResult = this.$t(
                  "filterOption.bt2400IVT.crankingResult.4"
                );
                break;
              case "5":
                el.crankingResult = this.$t(
                  "filterOption.bt2400IVT.crankingResult.5"
                );
                break;
              case "6":
                el.crankingResult = this.$t(
                  "filterOption.bt2400IVT.crankingResult.6"
                );
                break;
              case "7":
                el.crankingResult = this.$t(
                  "filterOption.bt2400IVT.crankingResult.7"
                );
                break;
            }
            switch (el.altIdleResult) {
              case "4":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400IVT.altIdleResult.4"
                );
                break;
              case "5":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400IVT.altIdleResult.5"
                );
                break;
              case "6":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400IVT.altIdleResult.6"
                );
                break;
              case "7":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400IVT.altIdleResult.7"
                );
                break;
            }
            switch (el.rippleResult) {
              case "4":
                el.rippleResult = this.$t(
                  "filterOption.bt2400IVT.rippleResult.4"
                );
                break;
              case "5":
                el.rippleResult = this.$t(
                  "filterOption.bt2400IVT.rippleResult.5"
                );
                break;
              case "6":
                el.rippleResult = this.$t(
                  "filterOption.bt2400IVT.rippleResult.6"
                );
                break;
              case "7":
                el.rippleResult = this.$t(
                  "filterOption.bt2400IVT.rippleResult.7"
                );
                break;
            }
            switch (el.altLoadResult) {
              case "4":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400IVT.altLoadResult.4"
                );
                break;
              case "5":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400IVT.altLoadResult.5"
                );
                break;
              case "6":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400IVT.altLoadResult.6"
                );
                break;
              case "7":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400IVT.altLoadResult.7"
                );
                break;
            }
            switch (el.dieselCheck) {
              case "1":
                el.dieselCheck = this.$t(
                  "filterOption.bt2400IVT.dieselCheck.1"
                );
                break;
              case "2":
                el.dieselCheck = this.$t(
                  "filterOption.bt2400IVT.dieselCheck.2"
                );
                break;
            }
            break;
          case "bt2400TG31":
            switch (el.testType) {
              case "7":
                el.testType = this.$t("filterOption.bt2400TG31.testType.7");
                break;
            }
            switch (el.systemVolCh) {
              case "1":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400TG31.systemVolCh.1"
                );
                break;
              case "2":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400TG31.systemVolCh.2"
                );
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400TG31.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400TG31.batInVehicle.2"
                );
                break;
              case "3":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400TG31.batInVehicle.3"
                );
                break;
            }
            switch (el.batCharged) {
              case "1":
                el.batCharged = this.$t("filterOption.bt2400TG31.batCharged.1");
                break;
              case "2":
                el.batCharged = this.$t("filterOption.bt2400TG31.batCharged.2");
                break;
              case "3":
                el.batCharged = this.$t("filterOption.bt2400TG31.batCharged.3");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.3"
                );
                break;
              case "4":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.4"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.5"
                );
                break;
              case "8":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.8"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.bt2400TG31.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.bt2400TG31.rating.1");
                break;
              case "2":
                el.rating = this.$t("filterOption.bt2400TG31.rating.2");
                break;
              case "3":
                el.rating = this.$t("filterOption.bt2400TG31.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.bt2400TG31.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.bt2400TG31.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.bt2400TG31.rating.6");
                break;
            }
            switch (el.ir) {
              case "5":
                el.ir = this.$t("filterOption.bt2400TG31.ir.5");
                break;
              case "7":
                el.ir = this.$t("filterOption.bt2400TG31.ir.7");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.5");
                break;
              case "6":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.6");
                break;
              case "7":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.7");
                break;
            }
            break;
          case "bt2400PK24":
            switch (el.testType) {
              case "8":
                el.testType = this.$t("filterOption.bt2400PK24.testType.8");
                break;
            }
            switch (el.systemVolCh) {
              case "2":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400PK24.systemVolCh.2"
                );
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400PK24.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400PK24.batInVehicle.2"
                );
                break;
              case "3":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400PK24.batInVehicle.3"
                );
                break;
            }
            switch (el.batCharged) {
              case "1":
                el.batCharged = this.$t("filterOption.bt2400PK24.batCharged.1");
                break;
              case "2":
                el.batCharged = this.$t("filterOption.bt2400PK24.batCharged.2");
                break;
              case "3":
                el.batCharged = this.$t("filterOption.bt2400PK24.batCharged.3");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.3"
                );
                break;
              case "4":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.4"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.5"
                );
                break;
              case "8":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.8"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.bt2400PK24.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.bt2400PK24.rating.1");
                break;
              case "2":
                el.rating = this.$t("filterOption.bt2400PK24.rating.2");
                break;
              case "3":
                el.rating = this.$t("filterOption.bt2400PK24.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.bt2400PK24.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.bt2400PK24.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.bt2400PK24.rating.6");
                break;
            }
            switch (el.ir) {
              case "5":
                el.ir = this.$t("filterOption.bt2400PK24.ir.5");
                break;
              case "7":
                el.ir = this.$t("filterOption.bt2400PK24.ir.7");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.5");
                break;
            }
            break;
          case "bt2400NapaBT":
            switch (el.testType) {
              case "1":
                el.testType = this.$t("filterOption.bt2400NapaBT.testType.1");
                break;
            }
            switch (el.systemVolCh) {
              case "1":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400NapaBT.systemVolCh.1"
                );
                break;
              case "2":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400NapaBT.systemVolCh.2"
                );
                break;
              case "3":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400NapaBT.systemVolCh.3"
                );
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaBT.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaBT.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaBT.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaBT.batteryType.3"
                );
                break;
              case "4":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaBT.batteryType.4"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaBT.batteryType.5"
                );
                break;
              case "8":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaBT.batteryType.8"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.bt2400NapaBT.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.bt2400NapaBT.rating.1");
                break;
              case "2":
                el.rating = this.$t("filterOption.bt2400NapaBT.rating.2");
                break;
              case "3":
                el.rating = this.$t("filterOption.bt2400NapaBT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.bt2400NapaBT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.bt2400NapaBT.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.bt2400NapaBT.rating.6");
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400NapaBT.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400NapaBT.batInVehicle.2"
                );
                break;
              case "3":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400NapaBT.batInVehicle.3"
                );
                break;
            }
            switch (el.batCharged) {
              case "1":
                el.batCharged = this.$t(
                  "filterOption.bt2400NapaBT.batCharged.1"
                );
                break;
              case "2":
                el.batCharged = this.$t(
                  "filterOption.bt2400NapaBT.batCharged.2"
                );
                break;
              case "3":
                el.batCharged = this.$t(
                  "filterOption.bt2400NapaBT.batCharged.3"
                );
                break;
            }
            switch (el.ir) {
              case "5":
                el.ir = this.$t("filterOption.bt2400NapaBT.ir.5");
                break;
              case "7":
                el.ir = this.$t("filterOption.bt2400NapaBT.ir.7");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.bt2400NapaBT.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.bt2400NapaBT.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.bt2400NapaBT.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.bt2400NapaBT.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.bt2400NapaBT.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.bt2400NapaBT.judgement.5");
                break;
            }
            switch (el.upcStatus) {
              case "1":
                el.upcStatus = this.$t("filterOption.bt2400NapaBT.upcStatus.1");
                break;
              case "2":
                el.upcStatus = this.$t("filterOption.bt2400NapaBT.upcStatus.2");
                break;
              case "3":
                el.upcStatus = this.$t("filterOption.bt2400NapaBT.upcStatus.3");
                break;
            }
            break;
          case "bt2400NapaST":
            switch (el.testType) {
              case "3":
                el.testType = this.$t("filterOption.bt2400NapaST.testType.3");
                break;
            }
            switch (el.systemVolCh) {
              case "1":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400NapaST.systemVolCh.1"
                );
                break;
              case "2":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400NapaST.systemVolCh.2"
                );
                break;
              case "3":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400NapaST.systemVolCh.3"
                );
                break;
            }
            switch (el.crankingResult) {
              case "4":
                el.crankingResult = this.$t(
                  "filterOption.bt2400NapaST.crankingResult.4"
                );
                break;
              case "5":
                el.crankingResult = this.$t(
                  "filterOption.bt2400NapaST.crankingResult.5"
                );
                break;
              case "6":
                el.crankingResult = this.$t(
                  "filterOption.bt2400NapaST.crankingResult.6"
                );
                break;
              case "7":
                el.crankingResult = this.$t(
                  "filterOption.bt2400NapaST.crankingResult.7"
                );
                break;
            }
            switch (el.altIdleResult) {
              case "4":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400NapaST.altIdleResult.4"
                );
                break;
              case "5":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400NapaST.altIdleResult.5"
                );
                break;
              case "6":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400NapaST.altIdleResult.6"
                );
                break;
              case "7":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400NapaST.altIdleResult.7"
                );
                break;
            }
            switch (el.rippleResult) {
              case "4":
                el.rippleResult = this.$t(
                  "filterOption.bt2400NapaST.rippleResult.4"
                );
                break;
              case "5":
                el.rippleResult = this.$t(
                  "filterOption.bt2400NapaST.rippleResult.5"
                );
                break;
              case "6":
                el.rippleResult = this.$t(
                  "filterOption.bt2400NapaST.rippleResult.6"
                );
                break;
              case "7":
                el.rippleResult = this.$t(
                  "filterOption.bt2400NapaST.rippleResult.7"
                );
                break;
            }
            switch (el.altLoadResult) {
              case "4":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400NapaST.altLoadResult.4"
                );
                break;
              case "5":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400NapaST.altLoadResult.5"
                );
                break;
              case "6":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400NapaST.altLoadResult.6"
                );
                break;
              case "7":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400NapaST.altLoadResult.7"
                );
                break;
            }
            switch (el.dieselCheck) {
              case "1":
                el.dieselCheck = this.$t(
                  "filterOption.bt2400NapaST.dieselCheck.1"
                );
                break;
              case "2":
                el.dieselCheck = this.$t(
                  "filterOption.bt2400NapaST.dieselCheck.2"
                );
                break;
            }
            switch (el.upcStatus) {
              case "1":
                el.upcStatus = this.$t("filterOption.bt2400NapaBT.upcStatus.1");
                break;
              case "2":
                el.upcStatus = this.$t("filterOption.bt2400NapaBT.upcStatus.2");
                break;
              case "3":
                el.upcStatus = this.$t("filterOption.bt2400NapaBT.upcStatus.3");
                break;
            }
            break;
          case "bt2400NapaIVT":
            switch (el.testType) {
              case "4":
                el.testType = this.$t("filterOption.bt2400NapaIVT.testType.4");
                break;
            }
            switch (el.systemVolCh) {
              case "1":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400NapaIVT.systemVolCh.1"
                );
                break;
              case "2":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400NapaIVT.systemVolCh.2"
                );
                break;
              case "3":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400NapaIVT.systemVolCh.3"
                );
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaIVT.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaIVT.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaIVT.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaIVT.batteryType.3"
                );
                break;
              case "4":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaIVT.batteryType.4"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaIVT.batteryType.5"
                );
                break;
              case "8":
                el.batteryType = this.$t(
                  "filterOption.bt2400NapaIVT.batteryType.8"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.bt2400NapaIVT.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.bt2400NapaIVT.rating.1");
                break;
              case "2":
                el.rating = this.$t("filterOption.bt2400NapaIVT.rating.2");
                break;
              case "3":
                el.rating = this.$t("filterOption.bt2400NapaIVT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.bt2400NapaIVT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.bt2400NapaIVT.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.bt2400NapaIVT.rating.6");
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400NapaIVT.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400NapaIVT.batInVehicle.2"
                );
                break;
              case "3":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400NapaIVT.batInVehicle.3"
                );
                break;
            }
            switch (el.batCharged) {
              case "1":
                el.batCharged = this.$t(
                  "filterOption.bt2400NapaIVT.batCharged.1"
                );
                break;
              case "2":
                el.batCharged = this.$t(
                  "filterOption.bt2400NapaIVT.batCharged.2"
                );
                break;
              case "3":
                el.batCharged = this.$t(
                  "filterOption.bt2400NapaIVT.batCharged.3"
                );
                break;
            }
            switch (el.ir) {
              case "5":
                el.ir = this.$t("filterOption.bt2400NapaBT.ir.5");
                break;
              case "7":
                el.ir = this.$t("filterOption.bt2400NapaBT.ir.7");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t(
                  "filterOption.bt2400NapaIVT.judgement.0"
                );
                break;
              case "1":
                el.judgement = this.$t(
                  "filterOption.bt2400NapaIVT.judgement.1"
                );
                break;
              case "2":
                el.judgement = this.$t(
                  "filterOption.bt2400NapaIVT.judgement.2"
                );
                break;
              case "3":
                el.judgement = this.$t(
                  "filterOption.bt2400NapaIVT.judgement.3"
                );
                break;
              case "4":
                el.judgement = this.$t(
                  "filterOption.bt2400NapaIVT.judgement.4"
                );
                break;
              case "5":
                el.judgement = this.$t(
                  "filterOption.bt2400NapaIVT.judgement.5"
                );
                break;
            }
            switch (el.crankingResult) {
              case "4":
                el.crankingResult = this.$t(
                  "filterOption.bt2400NapaIVT.crankingResult.4"
                );
                break;
              case "5":
                el.crankingResult = this.$t(
                  "filterOption.bt2400NapaIVT.crankingResult.5"
                );
                break;
              case "6":
                el.crankingResult = this.$t(
                  "filterOption.bt2400NapaIVT.crankingResult.6"
                );
                break;
              case "7":
                el.crankingResult = this.$t(
                  "filterOption.bt2400NapaIVT.crankingResult.7"
                );
                break;
            }
            switch (el.altIdleResult) {
              case "4":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400NapaIVT.altIdleResult.4"
                );
                break;
              case "5":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400NapaIVT.altIdleResult.5"
                );
                break;
              case "6":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400NapaIVT.altIdleResult.6"
                );
                break;
              case "7":
                el.altIdleResult = this.$t(
                  "filterOption.bt2400NapaIVT.altIdleResult.7"
                );
                break;
            }
            switch (el.rippleResult) {
              case "4":
                el.rippleResult = this.$t(
                  "filterOption.bt2400NapaIVT.rippleResult.4"
                );
                break;
              case "5":
                el.rippleResult = this.$t(
                  "filterOption.bt2400NapaIVT.rippleResult.5"
                );
                break;
              case "6":
                el.rippleResult = this.$t(
                  "filterOption.bt2400NapaIVT.rippleResult.6"
                );
                break;
              case "7":
                el.rippleResult = this.$t(
                  "filterOption.bt2400NapaIVT.rippleResult.7"
                );
                break;
            }
            switch (el.altLoadResult) {
              case "4":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400NapaIVT.altLoadResult.4"
                );
                break;
              case "5":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400NapaIVT.altLoadResult.5"
                );
                break;
              case "6":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400NapaIVT.altLoadResult.6"
                );
                break;
              case "7":
                el.altLoadResult = this.$t(
                  "filterOption.bt2400NapaIVT.altLoadResult.7"
                );
                break;
            }
            switch (el.dieselCheck) {
              case "1":
                el.dieselCheck = this.$t(
                  "filterOption.bt2400NapaIVT.dieselCheck.1"
                );
                break;
              case "2":
                el.dieselCheck = this.$t(
                  "filterOption.bt2400NapaIVT.dieselCheck.2"
                );
                break;
            }
            switch (el.upcStatus) {
              case "1":
                el.upcStatus = this.$t("filterOption.bt2400NapaBT.upcStatus.1");
                break;
              case "2":
                el.upcStatus = this.$t("filterOption.bt2400NapaBT.upcStatus.2");
                break;
              case "3":
                el.upcStatus = this.$t("filterOption.bt2400NapaBT.upcStatus.3");
                break;
            }
            break;
          case "ba427plusBT":
            switch (el.testType) {
              case "1":
                el.testType = this.$t("filterOption.ba427plusBT.testType.1");
                break;
              case "2":
                el.testType = this.$t("filterOption.ba427plusBT.testType.2");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.ba427plusBT.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.ba427plusBT.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.ba427plusBT.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.ba427plusBT.batteryType.3"
                );
                break;
              case "4":
                el.batteryType = this.$t(
                  "filterOption.ba427plusBT.batteryType.4"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.ba427plusBT.batteryType.5"
                );
                break;
              case "8":
                el.batteryType = this.$t(
                  "filterOption.ba427plusBT.batteryType.8"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.ba427plusBT.rating.0");
                break;
              case "2":
                el.rating = this.$t("filterOption.ba427plusBT.rating.2");
                break;
              case "3":
                el.rating = this.$t("filterOption.ba427plusBT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.ba427plusBT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.ba427plusBT.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.ba427plusBT.rating.6");
                break;
              case "7":
                el.rating = this.$t("filterOption.ba427plusBT.rating.7");
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.ba427plusBT.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.ba427plusBT.batInVehicle.2"
                );
                break;
              case "3":
                el.batInVehicle = this.$t(
                  "filterOption.ba427plusBT.batInVehicle.3"
                );
                break;
            }
            switch (el.batCharged) {
              case "1":
                el.batCharged = this.$t(
                  "filterOption.ba427plusBT.batCharged.1"
                );
                break;
              case "2":
                el.batCharged = this.$t(
                  "filterOption.ba427plusBT.batCharged.2"
                );
                break;
              case "3":
                el.batCharged = this.$t(
                  "filterOption.ba427plusBT.batCharged.3"
                );
                break;
            }
            switch (el.temperature) {
              case "0":
                el.temperature = this.$t(
                  "filterOption.ba427plusBT.temperature.0"
                );
                break;
              case "1":
                el.temperature = this.$t(
                  "filterOption.ba427plusBT.temperature.1"
                );
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.ba427plusBT.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.ba427plusBT.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.ba427plusBT.judgement.2");
                break;
              case "4":
                el.judgement = this.$t("filterOption.ba427plusBT.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.ba427plusBT.judgement.5");
                break;
            }
            break;
          case "ba427plusST":
            switch (el.testType) {
              case "3":
                el.testType = this.$t("filterOption.ba427plusST.testType.3");
                break;
            }
            switch (el.crankingResult) {
              case "4":
                el.crankingResult = this.$t(
                  "filterOption.ba427plusST.crankingResult.4"
                );
                break;
              case "5":
                el.crankingResult = this.$t(
                  "filterOption.ba427plusST.crankingResult.5"
                );
                break;
              case "6":
                el.crankingResult = this.$t(
                  "filterOption.ba427plusST.crankingResult.6"
                );
                break;
              case "7":
                el.crankingResult = this.$t(
                  "filterOption.ba427plusST.crankingResult.7"
                );
                break;
            }
            switch (el.altIdleResult) {
              case "4":
                el.altIdleResult = this.$t(
                  "filterOption.ba427plusST.altIdleResult.4"
                );
                break;
              case "5":
                el.altIdleResult = this.$t(
                  "filterOption.ba427plusST.altIdleResult.5"
                );
                break;
              case "6":
                el.altIdleResult = this.$t(
                  "filterOption.ba427plusST.altIdleResult.6"
                );
                break;
              case "7":
                el.altIdleResult = this.$t(
                  "filterOption.ba427plusST.altIdleResult.7"
                );
                break;
            }
            switch (el.rippleResult) {
              case "4":
                el.rippleResult = this.$t(
                  "filterOption.ba427plusST.rippleResult.4"
                );
                break;
              case "5":
                el.rippleResult = this.$t(
                  "filterOption.ba427plusST.rippleResult.5"
                );
                break;
              case "6":
                el.rippleResult = this.$t(
                  "filterOption.ba427plusST.rippleResult.6"
                );
                break;
              case "7":
                el.rippleResult = this.$t(
                  "filterOption.ba427plusST.rippleResult.7"
                );
                break;
            }
            switch (el.altLoadResult) {
              case "4":
                el.altLoadResult = this.$t(
                  "filterOption.ba427plusST.altLoadResult.4"
                );
                break;
              case "5":
                el.altLoadResult = this.$t(
                  "filterOption.ba427plusST.altLoadResult.5"
                );
                break;
              case "6":
                el.altLoadResult = this.$t(
                  "filterOption.ba427plusST.altLoadResult.6"
                );
                break;
              case "7":
                el.altLoadResult = this.$t(
                  "filterOption.ba427plusST.altLoadResult.7"
                );
                break;
            }
            break;
          case "manageMemberList":
            switch (el.verification) {
              case "0":
                el.verification = this.$t(
                  "filterOption.manageMemberList.verification.0"
                );
                break;
              case "1":
                el.verification = this.$t(
                  "filterOption.manageMemberList.verification.1"
                );
                break;
            }
            switch (el.pricingPlan) {
              case "0":
                el.pricingPlan = this.$t(
                  "filterOption.manageMemberList.pricingPlan.0"
                );
                break;
              case "1":
                el.pricingPlan = this.$t(
                  "filterOption.manageMemberList.pricingPlan.1"
                );
                break;
              case "2":
                el.pricingPlan = this.$t(
                  "filterOption.manageMemberList.pricingPlan.2"
                );
                break;
            }
            switch (el.status) {
              case 1:
                el.status = this.$t("filterOption.manageMemberList.status.1");
                break;
              case 2:
                el.status = this.$t("filterOption.manageMemberList.status.2");
                break;
            }
            switch (el.isLocked) {
              case 0:
                el.isLocked = this.$t(
                  "filterOption.manageMemberList.isLocked.0"
                );
                break;
              case 1:
                el.isLocked = this.$t(
                  "filterOption.manageMemberList.isLocked.1"
                );
                break;
            }
            break;
          case "deviceManager":
            switch (el.model) {
              case "0":
                el.model = this.$t("filterOption.deviceManager.model.0");
                break;
              case "1":
                el.model = this.$t("filterOption.deviceManager.model.1");
                break;
              case "2":
                el.model = this.$t("filterOption.deviceManager.model.2");
                break;
              case "3":
                el.model = this.$t("filterOption.deviceManager.model.3");
                break;
              case "4":
                el.model = this.$t("filterOption.deviceManager.model.4");
                break;
            }
            break;
          case "btw350BT":
            switch (el.systemVoltage) {
              case "2":
                el.systemVoltage = this.$t(
                  "filterOption.btw350BT.systemVoltage.2"
                );
                break;
            }
            switch (el.testType) {
              case "1":
                el.testType = this.$t("filterOption.btw350BT.testType.1");
                break;
              case "2":
                el.testType = this.$t("filterOption.btw350BT.testType.2");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.btw350BT.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.btw350BT.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.btw350BT.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.btw350BT.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.btw350BT.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.btw350BT.judgement.5");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t("filterOption.btw350BT.batteryType.0");
                break;
              case "1":
                el.batteryType = this.$t("filterOption.btw350BT.batteryType.1");
                break;
              case "2":
                el.batteryType = this.$t("filterOption.btw350BT.batteryType.2");
                break;
              case "3":
                el.batteryType = this.$t("filterOption.btw350BT.batteryType.3");
                break;
              case "5":
                el.batteryType = this.$t("filterOption.btw350BT.batteryType.5");
                break;
              case "6":
                el.batteryType = this.$t("filterOption.btw350BT.batteryType.6");
                break;
              case "7":
                el.batteryType = this.$t("filterOption.btw350BT.batteryType.7");
                break;
              case "8":
                el.batteryType = this.$t("filterOption.btw350BT.batteryType.8");
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.btw350BT.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.btw350BT.rating.1");
                break;
              case "3":
                el.rating = this.$t("filterOption.btw350BT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.btw350BT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.btw350BT.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.btw350BT.rating.6");
                break;
            }
            switch (el.isBatteryCharged) {
              case "1":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw350BT.isBatteryCharged.1"
                );
                break;
              case "2":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw350BT.isBatteryCharged.2"
                );
                break;
              case "3":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw350BT.isBatteryCharged.3"
                );
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.btw350BT.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.btw350BT.batInVehicle.2"
                );
                break;
              case "3":
                el.batInVehicle = this.$t(
                  "filterOption.btw350BT.batInVehicle.3"
                );
                break;
            }
            break;
          case "btw350ST":
            switch (el.testType) {
              case "5":
                el.testType = this.$t("filterOption.btw350ST.testType.5");
                break;
              case "6":
                el.testType = this.$t("filterOption.btw350ST.testType.6");
                break;
            }
            switch (el.crankingJudgement) {
              case "3":
                el.crankingJudgement = this.$t(
                  "filterOption.btw350ST.crankingJudgement.3"
                );
                break;
              case "5":
                el.crankingJudgement = this.$t(
                  "filterOption.btw350ST.crankingJudgement.5"
                );
                break;
              case "8":
                el.crankingJudgement = this.$t(
                  "filterOption.btw350ST.crankingJudgement.8"
                );
                break;
            }
            switch (el.altIdleJudgement) {
              case "8":
                el.altIdleJudgement = this.$t(
                  "filterOption.btw350ST.altIdleJudgement.8"
                );
                break;
              case "9":
                el.altIdleJudgement = this.$t(
                  "filterOption.btw350ST.altIdleJudgement.9"
                );
                break;
            }
            switch (el.rippleJudgement) {
              case "3":
                el.rippleJudgement = this.$t(
                  "filterOption.btw350ST.rippleJudgement.3"
                );
                break;
              case "7":
                el.rippleJudgement = this.$t(
                  "filterOption.btw350ST.rippleJudgement.7"
                );
                break;
              case "8":
                el.rippleJudgement = this.$t(
                  "filterOption.btw350ST.rippleJudgement.8"
                );
                break;
            }
            switch (el.altLoadJudgement) {
              case "8":
                el.altLoadJudgement = this.$t(
                  "filterOption.btw350ST.altLoadJudgement.8"
                );
                break;
              case "9":
                el.altLoadJudgement = this.$t(
                  "filterOption.btw350ST.altLoadJudgement.9"
                );
                break;
            }
            switch (el.smartChargingControl) {
              case "1":
                el.smartChargingControl = this.$t(
                  "filterOption.btw350ST.smartChargingControl.1"
                );
                break;
              case "2":
                el.smartChargingControl = this.$t(
                  "filterOption.btw350ST.smartChargingControl.2"
                );
                break;
            }
            switch (el.rippleLowestJudgement) {
              case "3":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw350ST.rippleLowestJudgement.3"
                );
                break;
              case "7":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw350ST.rippleLowestJudgement.7"
                );
                break;
              case "8":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw350ST.rippleLowestJudgement.8"
                );
                break;
            }
            switch (el.rippleHighestJudgement) {
              case "3":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw350ST.rippleHighestJudgement.3"
                );
                break;
              case "7":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw350ST.rippleHighestJudgement.7"
                );
                break;
              case "8":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw350ST.rippleHighestJudgement.8"
                );
                break;
            }
            break;
          case "btw350IVT":
            switch (el.systemVoltage) {
              case "2":
                el.systemVoltage = this.$t(
                  "filterOption.btw350IVT.systemVoltage.2"
                );
                break;
            }
            switch (el.testType) {
              case "4":
                el.testType = this.$t("filterOption.btw350IVT.testType.4");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.btw350IVT.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.btw350IVT.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.btw350IVT.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.btw350IVT.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.btw350IVT.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.btw350IVT.judgement.5");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.btw350IVT.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.btw350IVT.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.btw350IVT.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.btw350IVT.batteryType.3"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.btw350IVT.batteryType.5"
                );
                break;
              case "6":
                el.batteryType = this.$t(
                  "filterOption.btw350IVT.batteryType.6"
                );
                break;
              case "7":
                el.batteryType = this.$t(
                  "filterOption.btw350IVT.batteryType.7"
                );
                break;
              case "8":
                el.batteryType = this.$t(
                  "filterOption.btw350IVT.batteryType.8"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.btw350IVT.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.btw350IVT.rating.1");
                break;
              case "3":
                el.rating = this.$t("filterOption.btw350IVT.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.btw350IVT.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.btw350IVT.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.btw350IVT.rating.6");
                break;
            }
            switch (el.isBatteryCharged) {
              case "1":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw350IVT.isBatteryCharged.1"
                );
                break;
              case "2":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw350IVT.isBatteryCharged.2"
                );
                break;
              case "3":
                el.isBatteryCharged = this.$t(
                  "filterOption.btw350IVT.isBatteryCharged.3"
                );
                break;
            }
            switch (el.crankingJudgement) {
              case "3":
                el.crankingJudgement = this.$t(
                  "filterOption.btw350IVT.crankingJudgement.3"
                );
                break;
              case "5":
                el.crankingJudgement = this.$t(
                  "filterOption.btw350IVT.crankingJudgement.5"
                );
                break;
              case "8":
                el.crankingJudgement = this.$t(
                  "filterOption.btw350IVT.crankingJudgement.8"
                );
                break;
            }
            switch (el.altIdleJudgement) {
              case "8":
                el.altIdleJudgement = this.$t(
                  "filterOption.btw350IVT.altIdleJudgement.8"
                );
                break;
              case "9":
                el.altIdleJudgement = this.$t(
                  "filterOption.btw350IVT.altIdleJudgement.9"
                );
                break;
            }
            switch (el.rippleJudgement) {
              case "3":
                el.rippleJudgement = this.$t(
                  "filterOption.btw350IVT.rippleJudgement.3"
                );
                break;
              case "7":
                el.rippleJudgement = this.$t(
                  "filterOption.btw350IVT.rippleJudgement.7"
                );
                break;
              case "8":
                el.rippleJudgement = this.$t(
                  "filterOption.btw350IVT.rippleJudgement.8"
                );
                break;
            }
            switch (el.altLoadJudgement) {
              case "8":
                el.altLoadJudgement = this.$t(
                  "filterOption.btw350IVT.altLoadJudgement.8"
                );
                break;
              case "9":
                el.altLoadJudgement = this.$t(
                  "filterOption.btw350IVT.altLoadJudgement.9"
                );
                break;
            }
            switch (el.smartChargingControl) {
              case "1":
                el.smartChargingControl = this.$t(
                  "filterOption.btw350IVT.smartChargingControl.1"
                );
                break;
              case "2":
                el.smartChargingControl = this.$t(
                  "filterOption.btw350IVT.smartChargingControl.2"
                );
                break;
            }
            switch (el.rippleLowestJudgement) {
              case "3":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw350IVT.rippleLowestJudgement.3"
                );
                break;
              case "7":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw350IVT.rippleLowestJudgement.7"
                );
                break;
              case "8":
                el.rippleLowestJudgement = this.$t(
                  "filterOption.btw350IVT.rippleLowestJudgement.8"
                );
                break;
            }
            switch (el.rippleHighestJudgement) {
              case "3":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw350IVT.rippleHighestJudgement.3"
                );
                break;
              case "7":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw350IVT.rippleHighestJudgement.7"
                );
                break;
              case "8":
                el.rippleHighestJudgement = this.$t(
                  "filterOption.btw350IVT.rippleHighestJudgement.8"
                );
                break;
            }
            break;
          case "btw350CC":
            switch (el.positive) {
              case "3":
                el.positive = this.$t("filterOption.btw350CC.positive.3");
                break;
              case "8":
                el.positive = this.$t("filterOption.btw350CC.positive.8");
                break;
              case "9":
                el.positive = this.$t("filterOption.btw350CC.positive.9");
                break;
            }
            switch (el.negative) {
              case "3":
                el.negative = this.$t("filterOption.btw350CC.negative.3");
                break;
              case "8":
                el.negative = this.$t("filterOption.btw350CC.negative.8");
                break;
              case "9":
                el.negative = this.$t("filterOption.btw350CC.negative.9");
                break;
            }
            break;
          case "signinLogs":
            switch (el.successful) {
              case 0:
                el.successful = this.$t("filterOption.signinLogs.successful.0");
                break;
              case 1:
                el.successful = this.$t("filterOption.signinLogs.successful.1");
                break;
            }
            break;
        }
      });
      return this.rows;
    },
    dataTableSubRows() {
      this.subRows.map((el) => {
        switch (this.device) {
          case "bt2400TG31":
            switch (el.testType) {
              case "7":
                el.testType = this.$t("filterOption.bt2400TG31.testType.7");
                break;
            }
            switch (el.systemVolCh) {
              case "1":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400TG31.systemVolCh.1"
                );
                break;
              case "2":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400TG31.systemVolCh.2"
                );
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400TG31.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400TG31.batInVehicle.2"
                );
                break;
            }
            switch (el.batCharged) {
              case "1":
                el.batCharged = this.$t("filterOption.bt2400TG31.batCharged.1");
                break;
              case "2":
                el.batCharged = this.$t("filterOption.bt2400TG31.batCharged.2");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.3"
                );
                break;
              case "4":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.4"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.bt2400TG31.batteryType.5"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.bt2400TG31.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.bt2400TG31.rating.1");
                break;
              case "2":
                el.rating = this.$t("filterOption.bt2400TG31.rating.2");
                break;
              case "3":
                el.rating = this.$t("filterOption.bt2400TG31.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.bt2400TG31.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.bt2400TG31.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.bt2400TG31.rating.6");
                break;
            }
            switch (el.ir) {
              case "5":
                el.ir = this.$t("filterOption.bt2400TG31.ir.5");
                break;
              case "7":
                el.ir = this.$t("filterOption.bt2400TG31.ir.7");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.5");
                break;
              case "6":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.6");
                break;
              case "7":
                el.judgement = this.$t("filterOption.bt2400TG31.judgement.7");
                break;
            }
            break;
          case "bt2400PK24":
            switch (el.testType) {
              case "8":
                el.testType = this.$t("filterOption.bt2400PK24.testType.8");
                break;
            }
            switch (el.systemVolCh) {
              case "2":
                el.systemVolCh = this.$t(
                  "filterOption.bt2400PK24.systemVolCh.2"
                );
                break;
            }
            switch (el.batInVehicle) {
              case "1":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400PK24.batInVehicle.1"
                );
                break;
              case "2":
                el.batInVehicle = this.$t(
                  "filterOption.bt2400PK24.batInVehicle.2"
                );
                break;
            }
            switch (el.batCharged) {
              case "1":
                el.batCharged = this.$t("filterOption.bt2400PK24.batCharged.1");
                break;
              case "2":
                el.batCharged = this.$t("filterOption.bt2400PK24.batCharged.2");
                break;
            }
            switch (el.batteryType) {
              case "0":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.0"
                );
                break;
              case "1":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.1"
                );
                break;
              case "2":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.2"
                );
                break;
              case "3":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.3"
                );
                break;
              case "4":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.4"
                );
                break;
              case "5":
                el.batteryType = this.$t(
                  "filterOption.bt2400PK24.batteryType.5"
                );
                break;
            }
            switch (el.rating) {
              case "0":
                el.rating = this.$t("filterOption.bt2400PK24.rating.0");
                break;
              case "1":
                el.rating = this.$t("filterOption.bt2400PK24.rating.1");
                break;
              case "2":
                el.rating = this.$t("filterOption.bt2400PK24.rating.2");
                break;
              case "3":
                el.rating = this.$t("filterOption.bt2400PK24.rating.3");
                break;
              case "4":
                el.rating = this.$t("filterOption.bt2400PK24.rating.4");
                break;
              case "5":
                el.rating = this.$t("filterOption.bt2400PK24.rating.5");
                break;
              case "6":
                el.rating = this.$t("filterOption.bt2400PK24.rating.6");
                break;
            }
            switch (el.ir) {
              case "5":
                el.ir = this.$t("filterOption.bt2400PK24.ir.5");
                break;
              case "7":
                el.ir = this.$t("filterOption.bt2400PK24.ir.7");
                break;
            }
            switch (el.judgement) {
              case "0":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.0");
                break;
              case "1":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.1");
                break;
              case "2":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.2");
                break;
              case "3":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.3");
                break;
              case "4":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.4");
                break;
              case "5":
                el.judgement = this.$t("filterOption.bt2400PK24.judgement.5");
                break;
            }
            break;
        }
      });
      return this.subRows;
    },
    columnsVisible() {
      let array = [];
      this.columns.forEach((el) => {
        array.push(!el.hidden);
      });
      return array;
    },
    columnsForm() {
      let token = window.localStorage.token;
      let object = JSON.stringify({
        lang: this.vxLanguage,
        serverParams: this.serverParams,
        visible: this.columnsVisible,
        token,
      });
      return object;
    },
    filterObject() {
      let columnFilters = {};
      let obj = {
        columnFilters,
        page: 1,
      };
      this.filter.AllCondition.map((el) => {
        if (el.type === "Option") {
          columnFilters[el.id] = {
            [el.condition]: el.value,
          };
        } else {
          if (el.condition !== "between") {
            columnFilters[el.id] = {
              [el.condition]: el.value[0],
            };
          } else {
            columnFilters[el.id] = {
              gte: el.value[0],
              lte: el.value[1],
            };
          }
        }
      });
      return obj;
    },
    filterMixColumns() {
      let type = [];
      switch (this.device) {
        case "bt2400BT":
        case "bt2400IVT":
        case "bt2400TG31":
        case "bt2400NapaBT":
        case "bt2400NapaIVT":
        case "bt2400PK24":
          this.filter.selected.map((el, index) => {
            if (el.field === "ir") {
              if (this.filter.mixOption[index] === "string") {
                type.splice(index, 1, "option");
              } else {
                type.splice(index, 1, "number");
              }
            } else if (el.field === "capacity") {
              if (this.filter.mixOption[index] === "string") {
                type.splice(index, 1, "string");
              } else {
                type.splice(index, 1, "number");
              }
            } else {
              type.splice(index, 1, "");
            }
          });
          break;
        case "ba427plusBT":
          this.filter.selected.map((el, index) => {
            if (el.field === "ir") {
              if (this.filter.mixOption[index] === "string") {
                type.splice(index, 1, "option");
              } else {
                type.splice(index, 1, "number");
              }
            } else if (el.field === "capacity") {
              if (this.filter.mixOption[index] === "string") {
                type.splice(index, 1, "string");
              } else {
                type.splice(index, 1, "number");
              }
            } else if (el.field === "temperature") {
              if (this.filter.mixOption[index] === "string") {
                type.splice(index, 1, "option");
              } else {
                type.splice(index, 1, "number");
              }
            } else {
              type.splice(index, 1, "");
            }
          });
          break;
        case "bt2400ST":
        case "bt2400NapaST":
        case "ba427plusST":
        case "bt2200ST":
          this.filter.selected.map((el, index) => {
            if (
              el.field === "crankingVoltage" ||
              el.field === "rippleVoltage"
            ) {
              if (this.filter.mixOption[index] === "string") {
                type.splice(index, 1, "option");
              } else {
                type.splice(index, 1, "number");
              }
            } else {
              type.splice(index, 1, "");
            }
          });
          break;
        case "bt2300plusBT":
        case "bt2200BT":
        case "btw300BT":
        case "btw200BT":
        case "btw350BT":
          this.filter.selected.map((el, index) => {
            if (el.field === "setCapacity") {
              if (this.filter.mixOption[index] === "string") {
                type.splice(index, 1, "string");
              } else {
                type.splice(index, 1, "number");
              }
            } else {
              type.splice(index, 1, "");
            }
          });
          break;
        case "btw200ST":
        case "btw350ST":
          this.filter.selected.map((el, index) => {
            if (
              el.field === "crankingVoltage" ||
              el.field === "crankingTime" ||
              el.field === "rippleVoltage" ||
              el.field === "rippleLowest" ||
              el.field === "rippleHighest"
            ) {
              if (this.filter.mixOption[index] === "string") {
                type.splice(index, 1, "option");
              } else {
                type.splice(index, 1, "number");
              }
            } else {
              type.splice(index, 1, "");
            }
          });
          break;
        case "btw200IVT":
        case "btw350IVT":
          this.filter.selected.map((el, index) => {
            if (
              el.field === "crankingVoltage" ||
              el.field === "crankingTime" ||
              el.field === "rippleVoltage" ||
              el.field === "rippleLowest" ||
              el.field === "rippleHighest"
            ) {
              if (this.filter.mixOption[index] === "string") {
                type.splice(index, 1, "option");
              } else {
                type.splice(index, 1, "number");
              }
            } else if (el.field === "setCapacity") {
              if (this.filter.mixOption[index] === "string") {
                type.splice(index, 1, "string");
              } else {
                type.splice(index, 1, "number");
              }
            } else {
              type.splice(index, 1, "");
            }
          });
          break;
      }
      return type;
    },
    mixType() {
      let array = [];
      this.filterMixColumns.map((el, index) => {
        switch (el) {
          case "string":
            array.push(this.filter.mixStringType[index]);
            break;
          case "number":
            array.push(this.filter.mixNumberType[index]);
            break;
          case "option":
            array.push("eql");
            break;
          default:
            array.push("");
        }
      });
      return array;
    },
    mixCondition() {
      let array = [];
      this.filterMixColumns.map((el, index) => {
        switch (el) {
          case "number":
            if (this.mixType[index] === "between") {
              let item = [
                this.filter.mixNumberCondition[index][0],
                this.filter.mixNumberCondition[index][1],
              ];
              array.push(item);
            } else {
              let item = [this.filter.mixNumberCondition[index][0]];
              array.push(item);
            }
            break;
          case "string":
            array.push([this.filter.mixStringCondition[index][0]]);
            break;
          case "option":
            array.push([this.filter.mixOptionCondition[index]]);
            break;
          default:
            array.push([]);
            break;
        }
      });
      return array;
    },
    selectedUser() {
      if (this.rowSelected.selectedItem.length === 0) {
        return "no selected";
      } else {
        return this.rowSelected.selectedItem[0].account;
      }
    },
    selectedShop() {
      if (this.rowSelected.selectedItem.length === 0) {
        return "no selected";
      } else {
        return this.rowSelected.selectedItem[0].shopId;
      }
    },
    inviteError() {
      let toggle = false;
      if (
        this.invite.accountNotExistToggle ||
        this.invite.cantInviteTechToggle
      ) {
        toggle = true;
      }
      return toggle;
    },
    selectedDevice() {
      if (this.rowSelected.selectedItem.length === 0) {
        return "no selected";
      } else {
        return this.rowSelected.selectedItem[0].psn;
      }
    },
    clickToggle() {
      let toggle = null;
      switch (this.device) {
        case "deviceManager":
        case "manageMemberList":
        case "bt2400TG31":
        case "bt2400PK24":
        case "blacklist":
          toggle = true;
          break;
        default:
          toggle = false;
          break;
      }
      return toggle;
    },
    checkboxToggle() {
      let toggle = null;
      switch (this.device) {
        case "deviceManager":
        case "manageMemberList":
        case "blacklist":
          toggle = true;
          break;
        default:
          toggle = false;
          break;
      }
      return toggle;
    },
    exportExcelAPI() {
      let api = "";
      switch (this.device) {
        case "manageMemberList":
          api = apiURL + "/account/export/xlsx";
          break;
        default:
          api = apiURL + `/record/${this.device}/export/xlsx`;
          break;
      }
      return api;
    },
    exportCsvAPI() {
      let api = "";
      switch (this.device) {
        case "manageMemberList":
          api = apiURL + "/account/export/csv";
          break;
        default:
          api = apiURL + `/record/${this.device}/export/csv`;
          break;
      }
      return api;
    },
    buttonGroupBoolean() {
      switch (this.vxAccountLevel) {
        case this.vxConstantsAccountLevel.shop:
          return true;
        case this.vxConstantsAccountLevel.business:
          return true;
        default:
          return false;
      }
    },
  },
  watch: {
    // filter selected改變時的filter.type
    "filter.selected": function () {
      this.filter.selected.map((el, index) => {
        switch (this.filter.selected[index].type) {
          case "String":
            this.filter.typeOptions[index] = ["cont", "ncont", "eql"];
            break;
          case "Number":
            this.filter.typeOptions[index] = ["lte", "gte", "between", "eql"];
            break;
          case "Option":
            this.filter.typeOptions[index] = ["eql"];
            break;
          case "Mix":
            if (this.filter.mixOption[index] === "number") {
              this.filter.typeOptions[index] = ["lte", "gte", "between", "eql"];
            } else {
              this.filter.typeOptions[index] = ["cont", "ncont", "eql"];
            }
            break;
        }
      });
    },
    // filter mix改變時的filter.type，mix多一個型別選擇
    "filter.mixOption": function () {
      this.filter.selected.map((el, index) => {
        switch (this.filter.selected[index].type) {
          case "String":
            this.filter.typeOptions[index] = ["cont", "ncont", "eql"];
            break;
          case "Number":
            this.filter.typeOptions[index] = ["lte", "gte", "between", "eql"];
            break;
          case "Option":
            this.filter.typeOptions[index] = ["eql"];
            break;
          case "Mix":
            if (this.filter.mixOption[index] === "number") {
              this.filter.typeOptions[index] = ["lte", "gte", "between", "eql"];
            } else {
              this.filter.typeOptions[index] = ["cont", "ncont", "eql"];
            }
            break;
        }
      });
    },
    filterObject: function () {
      if (!this.filter.error) {
        this.$refs["myCustomTable"].resetTable();
        this.onColumnFilter(this.filterObject);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~vue2-datepicker/scss/index.scss";
.vgt-wrap__footer {
  .footer__navigation__page-btn,
  .footer__navigation {
    display: flex;
    align-items: center;
  }
}

.vgt-table thead tr th {
  background: #024f83;
  color: white;
}
.vgt-table thead th.vgt-checkbox-col {
  background: #024f83;
}

.vgt-table thead th.sorting-asc:after {
  border-bottom: 5px solid #ffba66;
}
.vgt-table thead th.sorting-desc:before {
  border-top: 5px solid #ffba66;
}

#VueGoodTable {
  width: 95%;
  margin: 1rem auto 0;
}

.qrcode-group > div {
  margin-bottom: 15px;
}

.codeLoading {
  margin-bottom: 15px;
}

.footer__row-count {
  display: none;
}
table.vgt-table {
  max-width: none;
  width: 100%;
  min-width: 700px;
  font-family: $font-family;
  thead > tr > th > input {
    display: none;
  }
}
.vgt-global-search__input {
  flex-grow: 0;
}
.vgt-global-search__actions {
  width: 100%;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-grow: 1;
  }
  .globalSearch {
    margin-right: 40px;
    border-radius: 0 5px 5px 0;
    border: transparent;
    padding: 6.5px;
  }
  .filterInput {
    border-radius: 5px;
    border: transparent;
    padding: 5.5px;
  }
  .filterValueBox {
    display: flex;
  }
  .filterBetweenBox {
    color: white;
    input {
      max-width: 85px;
    }
  }
}
.searchLabel {
  display: flex;
  margin: 5px 0;
  color: $sub_white_background;
  font-family: $font-family;
  align-items: unset;
  input {
    font-size: 13px;
  }
  i {
    background: white;
    line-height: 17px;
    border-radius: 5px 0 0 5px;
    padding: 6.5px;
    box-sizing: border-box;
    color: black;
    margin-left: 10px;
  }
}

.vgt-table th.sortable:before,
.vgt-table th.sortable:after {
  right: 50px;
}

.filterButton {
  color: white;
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 18px;
  margin: 5px 0 5px 35px;
  line-height: 30px;
  user-select: none;
  cursor: pointer;
  i {
    transition: 0.5s;
    transform: rotate(45deg);
  }
  p {
    margin-left: 5px;
    font-size: 16px;
    transition: 0.5s;
  }
  &:hover i {
    transform: rotate(135deg);
  }
  &:hover p {
    color: #ddd;
  }
}

.filterSearch {
  display: flex;
  align-items: center;
  i {
    cursor: pointer;
    color: white;
    font-size: 16px;
    transition: 0.3s;
    &:hover {
      color: $bad_replace;
    }
  }
  .filter-group {
    margin: 6px 0px 6px 10px;
    display: flex;
    align-items: center;
    font-size: 13.333px;
    select,
    input,
    i {
      margin: 0 3px;
    }
    select {
      border: transparent;
      padding: 4.5px 2em 4.5px 4.5px;
      border-radius: 5px;
      width: auto;
      height: auto;
      font-size: 13.333px;
    }
  }
  .filter-group .error {
    border: solid 2px red;
  }
  .filterSearchBar {
    display: flex;
    align-items: center;
    .mx-datepicker-range {
      width: auto;
    }
    .mx-input {
      width: 312px;
      padding: 4.5px;
      height: auto;
      border: transparent;
      box-shadow: none;
    }
    .mix-number {
      display: flex;
    }
  }
}

.vgt-global-search__actions .tableBar {
  // flex-direction: row-reverse;
  font-family: $font-family;
  .filterBox {
    margin-right: 10px;
    display: flex;
    align-items: flex-start;
  }
}

.button-group {
  display: flex;
  align-items: flex-start;
  font-family: $font-family;
}

.button-group .tableButton {
  padding: 5px 10px;
  margin: 5px;
  width: auto;
  height: 30px;
  background: $sub_white_background;
  cursor: pointer;
}

.registrationCode-group h1 {
  font-weight: 700;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  font-family: $font-family;
  line-height: 1.2;
}

.button-group .mdc-button--outlined {
  background: transparent;
}

.button-group .dropDownButton {
  padding: 5px 10px;
  width: auto;
  letter-spacing: 0.2px;
  line-height: 13px;
  height: 30px;
  background: $sub_white_background;
  cursor: pointer;
  font-family: $font-family;
}

.dropdown-toggle.btn-secondary {
  border-color: transparent;
  background: transparent;
  padding: 0;
  height: 30px;
  margin: 5px;
  border: none;
  box-sizing: border-box;
}

.columns,
.export {
  .dropdown-toggle.btn-secondary {
    &::after {
      border: none;
      margin-left: 0;
    }
  }
}

.mdc-button--raised.tableButton {
  font-family: $font-family;
  letter-spacing: 0.2px;
}

.tableButton.cancel {
  background: #007bff;
  color: white;
}
.tableButton.cancel:disabled {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.37);
}
.cancel.mdc-button--raised::before {
  background-color: darkblue;
}
.cancel.mdc-button--raised {
  &:hover::before {
    opacity: 0.08;
  }
}

.tableButtonUpdate.cancel {
  background: #007bff;
  color: white;
}
.tableButtonUpdate.cancel:disabled {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.37);
}

hr {
  border: 0;
  border-top: 2px solid rgba(236, 236, 236, 0.8);
}
.cutLine {
  width: 90%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.deviceErrorCode {
  color: $sub_white_background;
  text-align: left;
  margin: auto;
  width: 90%;
  font-family: $font-family;
  h5 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
  }
  h6 {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1rem;
  }
}

.vgt-wrap__actions-footer .tableBar {
  background: $sub_background_color;
  .button-group {
    margin-left: 50px;
  }
}

.vgt-responsive .vgt-table tbody tr:nth-child(2n + 2) {
  background: #4880a5;
  td {
    color: $title_color;
    font-size: 15.5px;
  }
}

.vgt-responsive .vgt-table tbody tr:nth-child(2n + 1) {
  background: $title_color;
  td {
    color: $nav_background;
    font-size: 15.5px;
  }
}

table.vgt-table tr.clickable {
  cursor: default;
}

.vgt-global-search,
.rowHoverEvent .vgt-responsive table.vgt-table {
  background: $dark_background;
  tr.VGT-row {
    cursor: pointer;
  }
  tr.VGT-row:hover {
    background-color: rgb(155, 177, 197);
    // background-color: #D7E9F4;
  }
  tr.VGT-row:hover td {
    color: $dark_background;
  }
  tr._dblclick {
    td {
      background-color: rgb(155, 177, 197);
      // background-color: #D7E9F4;
      color: $dark_background;
    }
  }
}

@media screen and (max-width: 992px) {
  .vgt-global-search__input {
    padding: 0;
  }
  .vgt-global-search__actions .tableBar {
    flex-wrap: wrap;
    .button-group,
    .filterBox {
      width: 100%;
      justify-content: center;
    }
  }
}

@media only screen and (max-width: 750px) {
  .vgt-wrap__footer .footer__navigation__info {
    display: inline-block;
  }
  .vgt-wrap__footer .footer__navigation__page-btn {
    margin-left: 0px;
  }
  .vgt-wrap__footer {
    padding: 1rem 0;
  }
}

@media screen and (max-width: 650px) {
  .vgt-wrap__actions-footer .tableBar .button-group {
    justify-content: center;
    margin: 0;
    flex-wrap: wrap;
  }
  .vgt-global-search__actions .tableBar {
    .button-group {
      flex-wrap: wrap;
    }
    .filterBox {
      width: 100%;
      justify-content: center;
      margin-right: 0px;
      flex-direction: column;
      .filterButton {
        margin: 5px 0 5px 15px;
      }
    }
  }
  .vgt-global-search__actions {
    margin-left: 0px;
    .searchLabel i {
      margin-left: 0px;
    }
    .filterSearch {
      width: 100%;
      ul {
        width: 100%;
      }
      .filterSearchBar {
        margin: 5px 0 0;
        width: 90%;
        i {
          width: 10%;
        }
      }
    }
  }
  .filterSearch .filter-group {
    margin: 0 0 10px;
    flex-direction: column;
    select {
      width: 90%;
    }
    .filterInput {
      width: 85%;
    }
  }
}

#createGroupSuccess,
#sendEmailSuccess,
#changePasswordSuccess,
#addDeviceToggle,
#setCheckToken,
#uploadImageToggle {
  .modal-header {
    button {
      background: transparent;
      margin: -1rem -1rem -1rem auto;
      padding: 1rem;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5;
      opacity: 0.5;
      &:hover {
        opacity: 0.75;
      }
    }
  }
}

#addDeviceToggle {
  .button-group {
    display: flex;
    flex-direction: column;
    margin: 10px;
    display: inline-block;
  }
  .modal-footer {
    p {
      padding: 0;
    }
  }
}

.qrcode-group,
.barcode-group,
.registrationCode-group {
  text-align: center;
}
</style>
