var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "frame" },
    [
      _c(
        "div",
        { staticClass: "testReportBottom" },
        [
          _c("dataTable", {
            attrs: {
              columns: _vm.columns,
              device: "pointsLogs",
              "data-test": "dataTable",
            },
            on: { "on-row-click": _vm.handleRowClick },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "detailsModal",
            title: "Detail",
            size: "lg",
            "dialog-class": "custom-modal-width",
            centered: "",
            "hide-footer": "",
          },
        },
        [
          _c("b-table", {
            attrs: {
              sstriped: "",
              hover: "",
              bordered: "",
              items: _vm.tableItems,
              fields: _vm.tableFields,
              small: "",
            },
          }),
          _c("hr"),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between flex-row-reverse align-items-center my-3",
            },
            [
              _c("div", { staticClass: "text-end" }, [
                _c("strong", [
                  _vm._v("Total Points: " + _vm._s(_vm.totalPoints)),
                ]),
              ]),
            ]
          ),
          _c(
            "b-alert",
            { attrs: { variant: "success", show: "" } },
            [
              [
                _c("strong", [
                  _vm._v("Business Account Plan : 20 points / month"),
                  _c("br"),
                ]),
                _vm._v(" • default to manage 5 Shop accounts for free. "),
                _c("br"),
                _vm._v(" • + 5 points for each additional shop account. "),
              ],
            ],
            2
          ),
          _c("b-alert", { attrs: { variant: "info", show: "" } }, [
            _c("strong", [
              _vm._v("Shop Account Plan: 5 point / month"),
              _c("br"),
            ]),
            _vm._v(" • default to manage 3 basic accounts for free. "),
            _c("br"),
            _vm._v(" • + 2 points for each additional basic account "),
          ]),
          _c("b-alert", { attrs: { variant: "warning", show: "" } }, [
            _c("strong", [_vm._v("NOTE:"), _c("br")]),
            _vm._v(
              " 1. All payment in a system will be made at the highest tier. "
            ),
            _c("br"),
            _vm._v(
              " 2. The Shop Account will have to pay for the shop tier fee and any additional basic account fee under it. "
            ),
            _c("br"),
            _vm._v(
              " 3. The Business Account will have to pay for the business tier fee and any additional shop/basic account fee under it. "
            ),
          ]),
          _c(
            "b-button",
            {
              attrs: { variant: "secondary" },
              on: {
                click: function ($event) {
                  return _vm.closeDetailsModal()
                },
              },
            },
            [_vm._v("Close")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }