// 管理API作法

import axios from 'axios';

// 依照關聯性分類
// 透過變數命名新創造的實體並分類
// dev => "https://dev.api.mybatteryexpert.com/api";
// pro => "https://api.mybatteryexpert.com/api";
let envBaseUrl = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : 'https://api.mybatteryexpert.com/api';
// export const apiURL = "https://dev.api.mybatteryexpert.com/api";
export const apiURL = envBaseUrl;
// export const apiURL = "http://localhost:3000/api";
// export const apiURL = "http://192.168.1.60:3000/api";  // local for everybody use
// export const apiURL = "http://192.168.1.216:3000/api"; // Derek
// export const apiURL = "http://192.168.1.45:3000/api"; // ian

// ---test----------------------------------------
// export function testAPI() {
//   let testURL = "https://run.mocky.io/v3/1f3b7bad-ec09-49ed-ac42-9441bb1662c7";
//   return axios
//     .create({
//       baseURL: testURL
//     })
//     .post();
// }

// -----------------------------------------------
// get tenant info-------------------------------------------

const generalDomain = ['localhost', 'mybatteryexpert'];
const hostname = window.location.hostname;
const subdomain = getSubdomain(hostname);

function getSubdomain(hostname) {
  const subdomainResult = hostname.split('.')[0];
  if (generalDomain.includes(subdomainResult)) {
    return undefined;
  } else {
    return subdomainResult;
  }
}
// -----------------------------------------------
// account(without token)
const account = axios.create({
  baseURL: apiURL + '/account',
  headers: {
    'X-Tenant-Id': subdomain,
  },
});

function createGetAPI(url) {
  let token = window.localStorage.token;
  return axios
    .create({
      baseURL: apiURL,
      headers: { Authorization: token, 'X-Tenant-Id': subdomain },
    })
    .get(url);
}

function createPostAPI(url, data) {
  let token = window.localStorage.token;
  return axios
    .create({
      baseURL: apiURL,
      headers: { Authorization: token, 'X-Tenant-Id': subdomain },
    })
    .post(url, data);
}

function createExportAPI(url, data) {
  let token = window.localStorage.token;
  return axios
    .create({
      baseURL: apiURL,
      headers: { Authorization: token, 'X-Tenant-Id': subdomain },
      responseType: 'blob',
    })
    .post(url, data);
}

function createUploadAPI(url, data, onUploadProgress) {
  let token = window.localStorage.token;
  return axios
    .create({
      baseURL: apiURL,
      headers: {
        'X-Tenant-Id': subdomain,
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
    .post(url, data);
}

// -----------------------------------------------

// account(without token)-------------------------------------------
//signIn
export const APIsignIn = data => account.post('/signIn', data);
//reset password
export const APIresetPassword = data => account.post('/resetPassword', data);
//resent verification Email
export const APIresentVerificationEmail = data =>
  account.post('/resentVerificationEmail', data);
//create
export const APIcreate = data => account.post('/create', data);

// account(need token)-------------------------------------------
//get account information
export const APIgetAccountInformation = () =>
  createPostAPI('/account/getAccountInformation');
//change password
export const APIchangePassword = data =>
  createPostAPI('/account/changePassword', data);
//search
export const APISearch = data => createPostAPI('/account/search', data);
//delete account
export const APIdeleteAccount = data => createPostAPI('/account/delete', data);
//create group
export const APICreateGroup = data =>
  createPostAPI('/account/group/create', data);
//invite group
export const APIinviteGroup = data =>
  createPostAPI('/account/group/invite', data);
//kick group
export const APIkickGroup = data => createPostAPI('/account/group/kick', data);
//disband group
export const APIdisbandGroup = () => createPostAPI('/account/group/disband');
//handover group
export const APIHandoverGroup = data =>
  createPostAPI('/account/group/handover', data);
//update group timezone
export const APIupdateGroupTimezone = data =>
  createPostAPI('/account/group/update/timezone', data);
//check vaild & refresh
export const APIcheckVaildAndRefresh = () =>
  createPostAPI('/account/checkValidAndRefresh');
//change subscription plan
export const APIchangeSubscriptionPlan = data =>
  createPostAPI('/account/changePlan', data);
//restore group
export const APIRestore = () => createPostAPI('/account/restore');
//check bill
export const APIcheckBill = data => createPostAPI('/account/checkBill', data);
//personal profile
export const APIgetPersonalProfile = () => createPostAPI('/account/getProfile');
//user headshot
export const APIgetUserHeadshot = () => createPostAPI('/account/userHeadshot');
//leader board
export const APIgetLeaderBoard = () => createPostAPI('/account/leaderBoard');
//upload headshot
export const APIuploadHeadshot = data =>
  createPostAPI('/account/uploadPhoto', data);
//upload batch excel
export const APIuploadBatchExcel = (data, onUploadProgress) =>
  createUploadAPI('/account/batchCreate', data, onUploadProgress);
//get the shop list of Business account
export const APIgetShopList = () => createPostAPI('/account/getShopList');
//get server tasks list
export const APIgetServerTasksList = () =>
  createPostAPI('/account/getServerTaskList');
//get black list
export const APIgetBlackList = () => createPostAPI('/account/blackList');
//delete black list account
export const APIdeleteBlackListAccount = account =>
  createPostAPI(`/account/deleteBlackList?email=${account}`);
export const APIUnlockUser = data => createPostAPI('/account/unlock', data);
// point code(need token)-------------------------------------------
//deposit point
export const APIdepositPoint = data =>
  createPostAPI('/pointCode/deposit', data);

// new bigdata-------------------------------------------
//active user
export const APIActiveUser = (device, data) =>
  createPostAPI(`/bigdata/${device}/activeUserPerDay`, data);
//fw version
export const APIFwVersionPerDay = (device, data) =>
  createPostAPI(`/bigdata/${device}/fwVersionPerDay`, data);
//test result per month
export const APITestResultPerMonth = (device, data) =>
  createPostAPI(`/bigdata/${device}/testResultPerMonth`, data);
//test result n month // 尚未加入
export const APITestResultPerNMonth = (device, data) =>
  createPostAPI(`/bigdata/${device}/testResultPerNMonth`, data);
//top 4 account
export const APITop4AccountPerDay = (device, data) =>
  createPostAPI(`/bigdata/${device}/top4AccountPerDay`, data);
//total test result
export const APITotalTestResult = (device, data) =>
  createPostAPI(`/bigdata/${device}/totalTestResult`, data);
//usage per day
export const APIUsagePerDay = (device, data) =>
  createPostAPI(`/bigdata/${device}/usagePerDay`, data);
//test type per quarter
export const APITestTypePerQuarter = (device, data) =>
  createPostAPI(`/bigdata/${device}/testTypePerQuarter`, data);
//battery type month
export const APIBatteryTypeMonth = (device, data) =>
  createPostAPI(`/bigdata/${device}/batteryTypeMonth`, data);
//battery type year
export const APIBatteryTypeYear = (device, data) =>
  createPostAPI(`/bigdata/${device}/batteryTypeYear`, data);

// other
//newest update time
export const APInewestUpdateTime = data =>
  createPostAPI('/bigdata/newestUpdateTime', data);

// test report (need token)-------------------------------------------
// record chart
// BT
export const APIChartTestType = device =>
  createPostAPI(`/record/${device}/chart/testType`);
export const APIChartJudgement = device =>
  createPostAPI(`/record/${device}/chart/judgement`);
// ST
export const APIChartCranking = device =>
  createPostAPI(`/record/${device}/chart/crankingResult`);
export const APIChartRipple = device =>
  createPostAPI(`/record/${device}/chart/rippleResult`);
export const APIChartAltIdle = device =>
  createPostAPI(`/record/${device}/chart/altIdleResult`);
export const APIChartAltLoad = device =>
  createPostAPI(`/record/${device}/chart/altLoadResult`);

// record list
export const APIList = (device, data) =>
  createPostAPI(`/record/${device}/list`, data);
// record list for cell
export const APIListCell = (device, data) =>
  createPostAPI(`/record/${device}/list/cell`, data);

// sidebar record check(need token)-------------------------------------
export const APIcheck = data => createPostAPI('/record/check', data);

// device (need token)-------------------------------------------
//device list
export const APIdeviceList = data => createPostAPI('/device/list', data);
//qrcode
export const APIqrcode = () => createPostAPI('/device/qrcode');
//registration code
export const APIregistrationCode = () =>
  createPostAPI('/device/registrationCode');
//delete
export const APIdeviceDelete = data => createPostAPI('/device/delete', data);

// account (need token)-------------------------------------------
//account list
export const APIaccountList = data => createPostAPI('/account/list', data);
// export account
export const APIexportAccount = (type, data) =>
  createExportAPI(`/account/export/${type}`, data);
// export record
export const APIexportRecord = (device, type, data) =>
  createExportAPI(`/record/${device}/export/${type}`, data);
//export package
export const APIexportPackage = data =>
  createExportAPI(`/record/exportPackage`, data);
// https://medium.com/i-am-mike/%E4%BD%BF%E7%94%A8axios%E6%99%82%E4%BD%A0%E7%9A%84api%E9%83%BD%E6%80%8E%E9%BA%BC%E7%AE%A1%E7%90%86-557d88365619

// log (need admin token)-------------------------------------------

// signinLogs
export const APIsigninLogList = data => createPostAPI('/log/signinLogs', data);
// actionLogs
export const APIactionLogList = data => createPostAPI('/log/actionLogs', data);

// pointsLogs list (need user token)
export const APIpointsLogsList = data =>
  createPostAPI('/pointsLogs/list', data);

// pointsLogs get detail (need user token)
export const APIpointsLogsDetail = id =>
  createGetAPI(`/pointsLogs/detail/${id}`);
