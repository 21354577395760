<template>
  <div class="container signinBox">
    <img
      v-if="subdomain === 'napa'"
      id="logo"
      src="@/assets/logo-napa.png"
      alt="logo-napa"
    />
    <svg
      v-else
      id="logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 511.936 511.936"
      enable-background="new 0 0 511.936 511.936"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.082,147.554c3.669-8.934,7.734-17.528,15.403-23.985
        c8.551-7.199,18.167-11.924,29.348-11.934c140.789-0.116,281.577-0.227,422.365,0.081c21.907,0.048,39.341,16.239,44.723,37.063
        c0.404,1.564,0.095,3.318,0.095,4.984c0.002,91.471,0.044,182.942-0.044,274.413c-0.013,13.594-6.913,23.895-16.996,32.34
        c-10.2,8.545-22.207,11.057-35.211,11.049c-137.124-0.082-274.246,0.029-411.37-0.137c-20.891-0.025-36.041-10.184-45.279-28.949
        c-1.249-2.537-2.035-5.303-3.034-7.961C0.082,338.863,0.082,243.209,0.082,147.554z M255.881,133.556
        c-68.486,0-136.972-0.038-205.457,0.097c-3.987,0.008-8.172,0.866-11.919,2.258c-12.954,4.813-17.325,15.673-17.309,28.148
        c0.114,87.414,0.524,174.828,0.938,262.242c0.031,6.641,3.938,11.74,8.54,16.016c6.381,5.928,14.619,7.131,22.837,7.137
        c132.806,0.09,265.612,0.074,398.417,0.02c5.429-0.002,10.907-0.281,16.273-1.057c6.633-0.957,11.826-4.328,16.363-9.602
        c5.705-6.629,6.298-14.459,6.318-22.146c0.22-83.814,0.158-167.632,0.074-251.447c-0.004-3.895-0.521-7.898-1.517-11.662
        c-3.443-13.012-14.229-19.905-27.104-19.932C393.519,133.477,324.699,133.556,255.881,133.556z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M185.059,102.315c-7.413,0-14.343,0-21.997,0c0-8.41-0.218-16.836,0.048-25.248
        c0.454-14.325-1.243-15.887-15.64-15.573c-14.649,0.318-29.311,0.067-43.967,0.071c-8.149,0.002-10.431,2.337-10.433,10.665
        c-0.002,9.947,0,19.894,0,30.082c-7.407,0-14.337,0-21.998,0c0-10.234-0.179-20.488,0.089-30.73
        c0.105-4.023,0.967-8.077,1.909-12.016c2.445-10.236,14.997-18.867,24.304-19.013c18.418-0.291,36.841-1.088,55.252-0.848
        c14.97,0.196,26.797,7.229,30.577,20.493c0.972,3.413,1.694,7.013,1.772,10.544C185.207,81.184,185.059,91.632,185.059,102.315z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M349.038,102.444c-7.63,0-14.441,0-21.997,0c0-10.345-0.179-20.598,0.089-30.839
        c0.105-4.022,0.972-8.075,1.903-12.016c2.431-10.262,15.021-18.888,24.285-19.036c18.419-0.294,36.844-1.129,55.251-0.84
        c15.63,0.246,26.723,7.391,30.883,20.861c0.999,3.234,1.443,6.747,1.505,10.143c0.189,10.445,0.069,20.895,0.069,31.589
        c-7.396,0-14.326,0-21.997,0c0-2.884,0-5.806,0-8.728c0-7.994,0.069-15.989-0.027-23.982c-0.058-4.751-2.97-7.959-7.544-7.984
        c-18.32-0.104-36.641-0.11-54.961,0.005c-4.552,0.029-7.391,3.279-7.435,8.092c-0.082,9.159-0.024,18.32-0.024,27.48
        C349.038,98.81,349.038,100.433,349.038,102.444z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M327.284,307.713c0-11.164,0-21.426,0-32.057c13.5,0,26.765,0,40.572,0
        c0-13.738,0-27.125,0-40.809c10.924,0,21.305,0,32.056,0c0,13.439,0,26.703,0,40.51c13.741,0,27.126,0,40.81,0
        c0,10.926,0,21.307,0,32.057c-13.438,0-26.704,0-40.511,0c0,13.74,0,27.127,0,40.811c-10.924,0-21.305,0-32.057,0
        c0-13.439,0-26.705,0-40.512C354.416,307.713,341.028,307.713,327.284,307.713z"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M184.838,275.893c0,10.59,0,20.85,0,31.375c-37.793,0-75.367,0-113.354,0
        c0-10.254,0-20.635,0-31.375C109.028,275.893,146.72,275.893,184.838,275.893z"
      />
    </svg>
    <!-- signin -->
    <p v-if="subdomain === 'napa'" id="napa-logo"></p>
    <p v-else id="freeLogo">
      {{ $t('nav.free') }}
    </p>
    <transition name="hide">
      <form
        @submit.prevent="fnAPISignIn"
        v-if="!forgotPasswordPageToggle"
        class="form-signin"
        data-test="form-signIn"
      >
        <input
          v-model="account"
          id="inputEmail"
          type="email"
          :placeholder="$t('signIn.text1Placeholder')"
          data-test="input-account-TYPE"
          required
          autofocus
        />
        <div class="input-group">
          <input
            v-model="password"
            id="inputPassword"
            :type="eyeToggle ? 'password' : 'text'"
            :placeholder="$t('signIn.text2Placeholder')"
            data-test="input-password-TYPE"
            required
          />
          <div @click="eyeToggle = !eyeToggle" id="passwordToggle">
            <span>
              <i
                :class="eyeToggle ? 'fa-eye-slash' : ''"
                id="eye"
                class="fas fa-eye"
                aria-hidden="true"
              ></i>
            </span>
          </div>
        </div>

        <m-button
          id="signInBtn"
          :disabled="loading"
          raised
          :outlined="loading"
          data-test="button-signin-CLICK"
          >{{ $t('signIn.signIn') }}</m-button
        >
        <div class="other">
          <a id="forgotPassword" @click.prevent="clickForgotPassword">{{
            $t('signIn.forgotPassword.title')
          }}</a>
          <router-link to="/register">{{ $t('signIn.register') }}</router-link>
        </div>
      </form>
    </transition>

    <!-- forgot password -->
    <transition name="hide">
      <form
        @submit.prevent="fnResetPassword"
        v-if="forgotPasswordPageToggle"
        class="form-forgotPassword"
      >
        <h3 id="forgotPasswordTitle">
          {{ $t('signIn.forgotPassword.title') }}
        </h3>
        <h6 class="forgotPasswordP">{{ $t('signIn.forgotPassword.text1') }}</h6>
        <h6 class="forgotPasswordP">
          {{ $t('signIn.forgotPassword.text2') }}
        </h6>
        <input
          v-model="account"
          id="inputEmail"
          type="email"
          :placeholder="$t('signIn.text1Placeholder')"
          required
          autofocus
        />
        <m-button :disabled="loading" :outlined="loading" raised>{{
          $t('button.submit')
        }}</m-button>
      </form>
    </transition>

    <!-- modal -->
    <!-- 登入帳密錯誤 -->
    <div
      v-if="MailPwError"
      class="invalidInput"
      data-test="invalid-mailpwError"
    >
      {{ pwErrorMessage }}
      <!-- {{ $t("invalidError.signIn.mailPwError") }} -->
    </div>
    <!-- 忘記密碼信箱錯誤 -->
    <b-modal id="forgotPasswordError" centered :title="$t('modal.error')">
      <h6>{{ $t('modal.signIn.forgotPassword.error.text1') }}</h6>
      <h6>{{ $t('modal.signIn.forgotPassword.error.text2') }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <!-- 忘記密碼信箱成功 -->
    <b-modal id="forgotPasswordSuccess" centered :title="$t('modal.success')">
      <h6>{{ $t('modal.signIn.forgotPassword.success.text1') }}</h6>
      <h6>{{ $t('modal.signIn.forgotPassword.success.text2') }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="close((forgotPasswordPageToggle = false))"
        >
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <!-- 第一次登入，準備修改密碼 -->
    <b-modal
      id="resetPassword"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$t('modal.notice')"
    >
      <h6>{{ $t('modal.signIn.resetPassword.text1') }}</h6>
      <h6>{{ $t('modal.signIn.resetPassword.text2') }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="close($router.push('/changePassword'))"
        >
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <!-- 信箱尚未認證 -->
    <b-modal id="verified" centered :title="$t('modal.notice')">
      <h6>{{ $t('modal.signIn.verified.text1') }}</h6>
      <h6>{{ $t('modal.signIn.verified.text2') }}</h6>
      <h6>{{ $t('modal.signIn.verified.text3') }}</h6>
      <template v-slot:modal-footer="{ ok, close }">
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="ok(fnResentMail())"
        >
          {{ $t('button.resentMail') }}
        </m-button>
        <m-button class="tableButton" raised dense @click="close()">
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <!-- 重送信箱成功 -->
    <b-modal id="afterSendEmail" centered :title="$t('modal.notice')">
      <h6>{{ $t('modal.signIn.sendEmail.text1') }}</h6>
      <h6>{{ account }}</h6>
      <h6>{{ $t('modal.signIn.sendEmail.text2') }}</h6>
      <h6>
        {{ $t('modal.signIn.sendEmail.text3') }}
      </h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <!-- 密碼過期 -->
    <b-modal id="pwExpired" centered :title="$t('modal.warning')">
      <h6>{{ $t('modal.signIn.pwExpired') }}</h6>
      <template v-slot:modal-footer="{ ok }">
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="ok($router.push('/changePassword'))"
        >
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>

    <!-- loading -->
    <div v-if="!MailPwError" class="loader" :class="loading ? 'active' : ''">
      <b-spinner label="Loading..." :variant="'info'"></b-spinner>
    </div>

    <!-- forgot password back button -->
    <m-button
      class="tableButton"
      raised
      dense
      @click="clickForgotPassword"
      v-if="forgotPasswordPageToggle"
    >
      {{ $t('button.back') }}
    </m-button>
  </div>
</template>

<script>
  import {
    APIsignIn,
    APIresetPassword,
    APIresentVerificationEmail,
    APIgetAccountInformation,
    APIcheck,
  } from '../api';

  export default {
    props: {
      pwErrorMessage: String,
    },
    data() {
      return {
        // input box----
        account: '', // 存帳號丟API
        password: '',
        // --------------

        // 變數判斷-------
        MailPwError: false, // 判斷帳號密碼有無正確，彈出視窗用
        eyeToggle: true, // change password input type
        loading: false, // 判斷ajax loading顯示
        forgotPasswordPageToggle: false, // true為forgot password頁面
        // --------------
      };
    },
    methods: {
      async fnAPISignIn() {
        this.MailPwError = false;
        this.loading = true;
        const requestData = {
          account: this.account,
          password: this.password,
        };
        try {
          const { data: APIsignInResponse } = await APIsignIn(requestData);
          this.fnCheckSignInCase(APIsignInResponse);
        } catch {
          this.loading = false;
          this.$bvModal.show('setsomethingWrong');
        }
      },

      // #region - sign in state
      fnCheckSignInCase(APIsignInResponse) {
        switch (APIsignInResponse.result) {
          // 帳號密碼錯誤
          case 0:
            if (APIsignInResponse.errorCode === 223) {
              if (APIsignInResponse.failedAttempts) {
                const remainAttempts =
                  APIsignInResponse.signinAttemptsTimes -
                  APIsignInResponse.failedAttempts;
                if (remainAttempts === 0) {
                  this.pwErrorMessage = `${this.$t(
                    'invalidError.signIn.lockedMessage1'
                  )} ${this.$t('invalidError.signIn.lockedMessage2')} ${
                    APIsignInResponse.unlockCooldownMinutes
                  } ${this.$t('invalidError.signIn.lockMessage3')}`;
                } else {
                  this.pwErrorMessage = `${this.$t(
                    'invalidError.signIn.lockMessage1'
                  )} ${remainAttempts} ${this.$t(
                    'invalidError.signIn.lockMessage2'
                  )} ${APIsignInResponse.unlockCooldownMinutes} ${this.$t(
                    'invalidError.signIn.lockMessage3'
                  )}`;
                }
              }
            } else if (
              APIsignInResponse.errorCode === 224 &&
              APIsignInResponse.isLocked === true
            ) {
              this.pwErrorMessage = `${this.$t(
                'invalidError.signIn.lockedMessage1'
              )} ${this.$t(
                'invalidError.signIn.lockedMessage2'
              )} ${this.fnMinuteDifferece(
                APIsignInResponse.lockedUntil
              )} ${this.$t('invalidError.signIn.lockMessage3')}`;
            } else {
              this.pwErrorMessage = this.$t('invalidError.signIn.mailPwError');
            }
            this.fnAccountOrPasswordError();
            break;
          // 登入成功，轉跳到home頁面，存token
          case 1:
            this.successSignIn(APIsignInResponse);
            break;
          // 需認證，重送驗證信，彈出視窗
          case 3:
            this.needVerifyAndPopupResendMail();
            break;
          // 密碼過期需更改，彈出視窗，轉跳到未登入時修改密碼頁面，須帶入信箱帳號
          case 4:
            this.passwordExpireAndPopupChangePassword(APIsignInResponse);
            break;
          // 訂閱shop account後未創建shop
          // 訂閱business account後未創建business
          case 5:
          case 6:
            this.needToBuildGroup(APIsignInResponse);
            break;
          // 發生錯誤
          default:
            this.unexpected();
        }
      },

      // case 0
      fnAccountOrPasswordError() {
        this.loading = false;
        this.MailPwError = true;
      },
      // case 1
      async successSignIn(APIsignInResponse) {
        try {
          this.MailPwError = false;
          this.$store.dispatch('setAccount', this.account);
          window.localStorage.setItem(
            'token',
            'bearer ' + APIsignInResponse.token
          );
          const { data: APIgetAccountInformationResponse } =
            await APIgetAccountInformation();
          this.$store.dispatch(
            'setAccountInformation',
            APIgetAccountInformationResponse
          );
          if (this.vxAccountLevel === this.vxConstantsAccountLevel.basic) {
            // 如果是technician會判斷有沒有測試資料，有的話直接導向test report，沒有的話導向home
            const { data: APIcheckResponse } = await APIcheck();
            let pathname = APIcheckResponse.list[0];
            this.$store.dispatch('setSignInToggle', true);
            this.loading = false;
            if (pathname) {
              this.$router.push({ name: pathname });
            } else {
              this.$router.push('/home');
            }
          } else {
            this.$store.dispatch('setSignInToggle', true);
            this.loading = false;
            this.$router.push('/home');
          }
        } catch (error) {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            this.$bvModal.show('setsomethingWrong');
          }
        }
      },
      // case 2 have been remove
      // case 3
      needVerifyAndPopupResendMail() {
        this.MailPwError = false;
        this.loading = false;
        this.$bvModal.show('verified');
      },
      // case 4
      passwordExpireAndPopupChangePassword(APIsignInResponse) {
        this.MailPwError = false;
        this.loading = false;
        this.$bvModal.show('pwExpired');
        window.localStorage.setItem(
          'token',
          'bearer ' + APIsignInResponse.token
        );
        this.$store.dispatch('setAccount', this.account);
      },
      // case 5 // case 6
      async needToBuildGroup(APIsignInResponse) {
        try {
          this.MailPwError = false;
          this.$store.dispatch('setAccount', this.account);
          window.localStorage.setItem(
            'token',
            'bearer ' + APIsignInResponse.token
          );
          const { data: APIgetAccountInformationResponse } =
            await APIgetAccountInformation();
          this.$store.dispatch(
            'setAccountInformation',
            APIgetAccountInformationResponse
          );
          let userPlan;
          switch (this.$store.state.accountInformation.pricingPlan) {
            case '0':
              userPlan = 'Basic';
              break;
            case '1':
              userPlan = 'Pro';
              break;
            case '2':
              userPlan = 'Enterprise';
              break;
          }
          let url;
          if (userPlan === this.$store.state.constants.csDataPlan.old.shop) {
            url = '/buildYourShop';
          } else {
            url = '/buildYourCompany';
          }
          this.$store.dispatch('setSignInToggle', false);
          this.loading = false;
          this.$router.push(url);
        } catch (error) {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            this.$bvModal.show('setsomethingWrong');
          }
        }
      },
      // other
      unexpected() {
        this.MailPwError = false;
        this.loading = false;
      },
      // #endregion

      // #region - reset password
      async fnResetPassword() {
        try {
          this.loading = true;
          const requestData = {
            account: this.account,
          };
          const { data: APIresetPasswordResponse } = await APIresetPassword(
            requestData
          );
          if (APIresetPasswordResponse.error) {
            // error
            this.loading = false;
            this.$bvModal.show('forgotPasswordError');
            this.account = '';
          } else {
            // success
            this.loading = false;
            this.$bvModal.show('forgotPasswordSuccess');
          }
        } catch {
          this.$bvModal.show('setsomethingWrong');
        }
      },
      // #endregion

      // #region - resent mail
      async fnResentMail() {
        const requestData = {
          account: this.account,
        };
        const { data: APIresentVerificationEmailResponse } =
          await APIresentVerificationEmail(requestData);
        if (APIresentVerificationEmailResponse.result === 1) {
          this.$bvModal.show('afterSendEmail');
          this.password = '';
        }
      },
      // #endregion

      clickForgotPassword() {
        this.MailPwError = false;
        this.forgotPasswordPageToggle = !this.forgotPasswordPageToggle;
      },
      fnMinuteDifferece(time) {
        const now = new Date();
        const givenTime = new Date(time + ' UTC');
        const diff = givenTime.getTime() - now.getTime();
        return Math.floor(diff / (60 * 1000));
      },
    },
    computed: {
      vxAccountLevel() {
        return this.$store.state.accountInformation.accountLevel;
      },
      vxConstantsAccountLevel() {
        return this.$store.state.constants.csAccountLevel;
      },
      subdomain() {
        return this.$root.subdomain;
      },
    },
  };
</script>

<style lang="scss" scoped>
  html {
    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(#024f83, #017ab9, #024f83);
  }

  #logo {
    margin-top: 3rem;
    width: 150px;
    height: 150px;
    fill: #003f69;
  }

  #freeLogo {
    font-weight: 800;
    font-size: 20px;
    color: rgb(0, 63, 105);
    border: solid 4px rgb(0, 63, 105);
    border-radius: 2rem;
    background: #bfdeed;
    padding: 3px 7px;
    position: absolute;
    // for IE--
    -ms-transform: translate(-30px, -30px);
    transform: translate(-30px, -30px);
    // for other browser--
    -moz-transform: translate(50px, 165px);
    -webkit-transform: translate(50px, 165px);
  }

  .container {
    align-items: center;
  }

  .form-signin,
  .form-forgotPassword {
    max-width: 330px;
    width: 90%;
    height: 100%;
    padding: 15px 0;
    font-weight: 400;
    box-sizing: border-box;
    font-family: $font-family;

    input {
      box-sizing: border-box;
      padding: 10px;
      font-size: 16px;
      width: 100%;
      border: 2px solid transparent;
      line-height: 1.5;

      &:focus {
        border-bottom: solid 2px $sub_background_color;
        outline: transparent;
      }
    }

    button {
      letter-spacing: $button_letter_space;
      font-weight: $button_font_weight;
      width: 100%;
      padding: 0.5rem 1rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: 0.3rem;
      height: 46px;
      cursor: pointer;
      background: $sub_background_color;
      color: $sub_button_color;
      transition: 0.3s;
      font-family: $font-family;

      &:hover::before {
        opacity: 0.08;
      }

      &:disabled {
        color: $sub_background_color;
        background: transparent;
        border: solid 1px $sub_background_color;
      }
    }

    #inputEmail {
      border-radius: 0.25rem;
      margin: 10px 0 20px;
      background: rgba(277, 277, 277, 0.7);
      color: $input_color;

      &::placeholder {
        color: $input_placeholder_color;
      }
    }

    #inputPassword {
      border-radius: 0.25rem;
      background: rgba(277, 277, 277, 0.7);
      color: $input_color;

      &::placeholder {
        color: $input_placeholder_color;
      }
    }
  }

  .loader {
    text-align: center;
    display: inline-block;
    vertical-align: top;
    opacity: 0;
    margin: 0.8rem 0;
    padding: 0.15rem 0;

    svg path,
    svg rect {
      fill: $sub_background_color;
    }
  }

  .loader.active {
    opacity: 1;
  }

  .form-forgotPassword {
    input {
      margin-bottom: 15px;
      border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
    }

    #forgotPasswordTitle {
      font-size: 1.75rem;
      font-weight: 700;
      margin-bottom: 1rem;
      line-height: 1.2;
      color: $main_background_color;
    }

    .forgotPasswordP {
      color: $main_background_color;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 0.5rem;
      text-align: left;
    }
  }

  .input-group {
    margin-bottom: 1.5rem;
  }

  .other {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-weight: 500;

    a {
      cursor: pointer;
      color: $sub_background_color;
      transition: 0.3s;

      &:hover {
        color: $link_hover_color;
        text-decoration: underline;
      }
    }
  }

  .signinBox {
    background: rgba(277, 277, 277, 0.75);
    padding-bottom: 25px;
    width: 480px;
    max-width: 90%;
  }

  .invalidInput {
    width: 90%;
    max-width: 330px;
  }
</style>

<style lang="scss">
  // modal
  .subscribeInput {
    margin-top: 0.5rem;
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
    padding: 0.75rem 0;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    letter-spacing: 0.3px;
    font-weight: 400;
    line-height: 1.5;
    font-family: $font-family;
  }

  // error
  .invalidInput {
    margin-top: 0.5rem;
    color: $error_massage_color;
    background-color: $error_massage_background_color;
    padding: 0.75rem 0;
    margin-bottom: 1rem;
    border-color: $error_massage_border_color;
    border-radius: 0.25rem;
    letter-spacing: 0.3px;
    font-weight: 400;
    line-height: 1.5;
    font-family: $font-family;
  }

  .input-group {
    display: flex;
    flex-wrap: nowrap;

    #passwordToggle {
      display: flex;
      align-items: center;
      line-height: 1.5;
      padding: 0.375rem 0.75rem;
      border-radius: 0 0.25rem 0.25rem 0;
      color: $sub_background_color;
      background: rgba(277, 277, 277, 0.7);
      cursor: pointer;
      position: absolute;
      right: 0;
      height: 100%;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 90%;
    font-family: $font-family;

    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }
  }

  // vue-transsition
  .hide-enter {
    opacity: 0;
  }

  .hide-enter-active {
    transition: all 0.3s;
  }

  .hide-enter-to {
    opacity: 1;
  }

  .hide-leave {
    opacity: 1;
    display: none;
  }

  .hide-leave-active {
    transition: all 0.3s;
    display: none;
  }

  .hide-leave-to {
    opacity: 0;
    display: none;
  }

  // 以下為彈出視窗樣式修改
  // 加上陰影
  .modal-content {
    font-family: $font-family;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  }

  // 調淡背景色
  .modal-backdrop {
    background-color: rgba(0, 0, 0, 0.32);
    opacity: 1;
  }

  // 修改標題樣式
  .modal-title,
  .modal-header h5 {
    font-size: 1.25rem;
    line-height: 1.5;
    font-weight: 500;
  }

  // 修改內文樣式
  .modal-body {
    h6 {
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      font-size: 1rem;
      text-align: center;
    }
  }
</style>

<style lang="scss">
  // ie/edge 預設eye
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
</style>
