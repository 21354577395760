import Vue from "vue";
import VueRouter from "vue-router";

import SignIn from "../views/SignIn.vue";
import Home from "../views/Home.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import DeviceManagement from "../views/DeviceManagement.vue";
import ChangePassword from "../views/ChangePassword.vue";
import SubscribePlan from "../views/SubscribePlan.vue";
import StoredPoints from "../views/StoredPoints.vue";
import PointsCalculation from "../views/PointsCalculation.vue";
import CreatePoints from "../views/CreatePoints.vue";
import BatchCreate from "../views/BatchCreate.vue";
import ListAllUsers from "../views/ListAllUsers.vue";
import Register from "../views/Register.vue";
import Download from "../views/Download.vue";
import BuildYourShop from "../views/BuildYourShop.vue";
import BuildYourCompany from "../views/BuildYourCompany.vue";
import SetTimezone from "../views/SetTimezone.vue";
import ServerTasks from "../views/ServerTasks.vue";
import Blacklist from "../views/Blacklist.vue";
import SigninLogs from "../views/SigninLogs.vue";
import ActionLogs from "../views/ActionLogs.vue";

import Nav from "../components/Nav.vue";
import Language from "../components/Language.vue";
import Sidebar from "../components/Sidebar.vue";

import Icon from "../views/Home/Icon.vue";
import LeaderBoard from "../views/Home/LeaderBoard.vue";
import ShopDashboard from "../views/Home/ShopDashboard.vue";
import BusinessDashboard from "../views/Home/BusinessDashboard.vue";

// import test from "../views/test.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "SignIn",
    components: {
      default: SignIn,
      language: Language,
    },
  },
  {
    path: "/home",
    components: {
      default: Home,
      nav: Nav,
      sidebar: Sidebar,
    },
    children: [
      {
        path: "/",
        component: Icon,
      },
      {
        path: "leaderboard",
        component: LeaderBoard,
      },
      {
        path: "shopDashboard",
        component: ShopDashboard,
      },
      {
        path: "businessDashboard",
        component: BusinessDashboard,
      },
    ],
  },
  {
    path: "/privacyPolicy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/deviceManagement",
    name: "DeviceManagement",
    components: {
      default: DeviceManagement,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/changePassword",
    name: "ChangePassword",
    components: {
      default: ChangePassword,
      nav: Nav,
      language: Language,
      sidebar: Sidebar,
    },
  },
  {
    path: "/storedPoints",
    name: "StoredPoints",
    components: {
      default: StoredPoints,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/pointsCalculation",
    name: "PointsCalculation",
    components: {
      default: PointsCalculation,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/createPoints",
    name: "CreatePoints",
    components: {
      default: CreatePoints,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/batchCreate",
    name: "BatchCreate",
    components: {
      default: BatchCreate,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/subscribePlan",
    name: "SubscribePlan",
    components: {
      default: SubscribePlan,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/listAllUsers",
    name: "ListAllUsers",
    components: {
      default: ListAllUsers,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/blacklist",
    name: "Blacklist",
    components: {
      default: Blacklist,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/buildYourShop",
    name: "BuildYourShop",
    components: {
      default: BuildYourShop,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/buildYourCompany",
    name: "BuildYourCompany",
    components: {
      default: BuildYourCompany,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/setTimezone",
    name: "SetTimezone",
    components: {
      default: SetTimezone,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/serverTasks",
    name: "ServerTasks",
    components: {
      default: ServerTasks,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/register",
    name: "Register",
    components: {
      default: Register,
      nav: Nav,
      language: Language,
      sidebar: Sidebar,
    },
  },
  {
    path: "/download",
    name: "Download",
    components: {
      default: Download,
    },
  },
  {
    path: "/reportBt2200BT",
    name: "bt2200-record-bt",
    components: {
      default: () => import("../views/ReportBt2200Bt.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2200ST",
    name: "bt2200-record-st",
    components: {
      default: () => import("../views/ReportBt2200St.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2300plusBT",
    name: "bt2300plus-record-bt",
    components: {
      default: () => import("../views/ReportBt2300plusBt.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2300plusST",
    name: "bt2300plus-record-st",
    components: {
      default: () => import("../views/ReportBt2300plusSt.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw300BT",
    name: "btw300-record-bt",
    components: {
      default: () => import("../views/ReportBtw300Bt.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw300ST",
    name: "btw300-record-st",
    components: {
      default: () => import("../views/ReportBtw300St.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw200BT",
    name: "btw200-record-bt",
    components: {
      default: () => import("../views/ReportBtw200Bt.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw200ST",
    name: "btw200-record-st",
    components: {
      default: () => import("../views/ReportBtw200St.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw200IVT",
    name: "btw200-record-ivt",
    components: {
      default: () => import("../views/ReportBtw200IVt.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw200VM",
    name: "btw200-record-vm",
    components: {
      default: () => import("../views/ReportBtw200Vm.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2400BT",
    name: "bt2400-record-bt",
    components: {
      default: () => import("../views/ReportBt2400Bt.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2400ST",
    name: "bt2400-record-st",
    components: {
      default: () => import("../views/ReportBt2400St.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2400IVT",
    name: "bt2400-record-ivt",
    components: {
      default: () => import("../views/ReportBt2400IVt.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2400TG31",
    name: "bt2400-record-tg31",
    components: {
      default: () => import("../views/ReportBt2400TG31.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2400PK24",
    name: "bt2400-record-pk24",
    components: {
      default: () => import("../views/ReportBt2400PK24.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBa427plusBT",
    name: "ba427plus-record-bt",
    components: {
      default: () => import("../views/ReportBa427plusBT.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBa427plusST",
    name: "ba427plus-record-st",
    components: {
      default: () => import("../views/ReportBa427plusST.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw350BT",
    name: "btw350-record-bt",
    components: {
      default: () => import("../views/ReportBtw350Bt.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw350ST",
    name: "btw350-record-st",
    components: {
      default: () => import("../views/ReportBtw350St.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw350IVT",
    name: "btw350-record-ivt",
    components: {
      default: () => import("../views/ReportBtw350IVt.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw350VM",
    name: "btw350-record-vm",
    components: {
      default: () => import("../views/ReportBtw350Vm.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBtw350CC",
    name: "btw350-record-cc",
    components: {
      default: () => import("../views/ReportBtw350Cc.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2400NapaBT",
    name: "bt2400-napa-record-bt",
    components: {
      default: () => import("../views/ReportBt2400NapaBT.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2400NapaST",
    name: "bt2400-napa-record-st",
    components: {
      default: () => import("../views/ReportBt2400NapaST.vue"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/reportBt2400NapaIVT",
    name: "bt2400-napa-record-ivt",
    components: {
      default: () => import("../views/ReportBt2400NapaIVT"),
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  // {
  //   path: "/test",
  //   components: {
  //     default: test,
  //     nav: Nav,
  //     sidebar: Sidebar
  //   }
  // },
  {
    path: "/signinLogs",
    name: "SigninLogs",
    components: {
      default: SigninLogs,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "/actionLogs",
    name: "ActionLogs",
    components: {
      default: ActionLogs,
      nav: Nav,
      sidebar: Sidebar,
    },
  },
  {
    path: "*",
    // 要記得檢查token
    redirect: "/home",
    // redirect: "/"
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
