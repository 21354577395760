var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: {
            "spinner-variant": "info",
            show: _vm.overlay,
            rounded: "sm",
            variant: "light",
            opacity: 0.6,
            blur: "none",
          },
        },
        [
          _c(
            "vue-good-table",
            _vm._g(
              {
                ref: "myCustomTable",
                class: [_vm.clickToggle ? "rowHoverEvent" : ""],
                attrs: {
                  mode: "remote",
                  columns: _vm.columns,
                  rows: _vm.dataTableRows,
                  "row-style-class": _vm.fnRowStyleClass,
                  "pagination-options": {
                    enabled: true,
                    nextLabel: this.$t("tableColumns.next"),
                    prevLabel: this.$t("tableColumns.prev"),
                    mode: "records",
                    perPage: 10,
                    perPageDropdown: [10],
                    dropdownAllowAll: false,
                    ofLabel: this.$t("tableColumns.of"),
                  },
                  "sort-options": {
                    enabled: true,
                    initialSortBy: { field: "testTime", type: "desc" },
                  },
                  totalRows: _vm.totalRecords,
                  "search-options": {
                    enabled: true,
                    placeholder: "Search this table",
                    externalQuery: _vm.serverParams.searchTerm,
                    skipDiacritics: true,
                  },
                  "select-options": {
                    enabled: _vm.checkboxToggle,
                    disableSelectInfo: true,
                    selectionInfoClass: "selected",
                  },
                  styleClass: "vgt-table striped",
                },
                on: {
                  "on-page-change": _vm.onPageChange,
                  "on-sort-change": _vm.onSortChange,
                  "on-column-filter": _vm.onColumnFilter,
                },
              },
              { "on-row-click": _vm.clickToggle ? _vm.onRowClick : () => {} }
            ),
            [
              _c(
                "div",
                {
                  staticClass: "tableBar",
                  attrs: { slot: "table-actions" },
                  slot: "table-actions",
                },
                [
                  _c(
                    "div",
                    { staticClass: "button-group" },
                    [
                      _c(
                        "m-button",
                        {
                          staticClass: "tableButton",
                          attrs: {
                            "data-test": "button-REFRESH",
                            raised: "",
                            dense: "",
                          },
                          on: { click: _vm.fnDataTableRefresh },
                        },
                        [_c("i", { staticClass: "fas fa-sync-alt" })]
                      ),
                      !(
                        _vm.device === "deviceManager" ||
                        _vm.device === "blacklist" ||
                        _vm.device === "signinLogs" ||
                        _vm.device === "actionLogs"
                      )
                        ? _c(
                            "b-dropdown",
                            {
                              staticClass: "columns",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "m-button",
                                          {
                                            staticClass: "dropDownButton",
                                            attrs: { raised: "", dense: "" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("button.columns"))
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3856404969
                              ),
                            },
                            _vm._l(_vm.columns, function (column, index) {
                              return _c("b-dropdown-item", { key: index }, [
                                _c("label", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#", tabIndex: "-1" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.fnToggleColumn(
                                            index,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: { type: "checkbox" },
                                        domProps: { checked: !column.hidden },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                      _vm._v("  " + _vm._s(column.label) + " "),
                                    ]
                                  ),
                                ]),
                              ])
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm.vxAccountLevel !==
                        _vm.vxConstantsAccountLevel.basic &&
                      _vm.device !== "deviceManager" &&
                      _vm.device !== "blacklist" &&
                      _vm.device !== "signinLogs" &&
                      _vm.device !== "actionLogs" &&
                      _vm.device !== "pointsLogs"
                        ? _c(
                            "b-dropdown",
                            {
                              staticClass: "export",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "m-button",
                                          {
                                            staticClass: "dropDownButton",
                                            attrs: { raised: "", dense: "" },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("button.export"))
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3993086888
                              ),
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.fnExport("xlsx")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.exportExcel")))]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.fnExport("csv")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.exportCSV")))]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm.vxAccountLevel ===
                        _vm.vxConstantsAccountLevel.shop ||
                        _vm.vxAccountLevel ===
                          _vm.vxConstantsAccountLevel.business) &&
                      _vm.device === "manageMemberList"
                        ? _c(
                            "m-button",
                            {
                              staticClass: "tableButton",
                              attrs: { raised: "", dense: "" },
                              on: { click: _vm.fnChangeManagerCheckSelected },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.handOver")))]
                          )
                        : _vm._e(),
                      _vm.vxAccountLevel ===
                        _vm.vxConstantsAccountLevel.admin &&
                      _vm.device === "manageMemberList"
                        ? _c(
                            "m-button",
                            {
                              staticClass: "tableButton",
                              attrs: { raised: "", dense: "" },
                              on: { click: _vm.fnUnlock },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.unlock")))]
                          )
                        : _vm._e(),
                      _vm.device === "deviceManager"
                        ? _c(
                            "m-button",
                            {
                              staticClass: "tableButton",
                              attrs: { raised: "", dense: "" },
                              on: { click: _vm.fnAddDevice },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.addDevice")))]
                          )
                        : _vm._e(),
                      _vm.device === "deviceManager"
                        ? _c(
                            "m-button",
                            {
                              staticClass: "tableButton",
                              attrs: { raised: "", dense: "" },
                              on: { click: _vm.fnRemoveDeviceCheckSelected },
                            },
                            [_vm._v(_vm._s(_vm.$t("button.removeDevice")))]
                          )
                        : _vm._e(),
                      _vm.device === "blacklist"
                        ? _c(
                            "m-button",
                            {
                              staticClass: "tableButton",
                              attrs: {
                                "data-test": "button-REFRESH",
                                raised: "",
                                dense: "",
                              },
                              on: {
                                click: _vm.showDeleteBlackListAccountModal,
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("button.delete")) + " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.device !== "pointsLogs"
                    ? _c("div", { staticClass: "filterBox" }, [
                        _c(
                          "div",
                          {
                            staticClass: "filterButton",
                            on: { click: _vm.fnAddFilterCondition },
                          },
                          [
                            _c("i", { staticClass: "far fa-times-circle" }),
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("filter.addFilter"))),
                            ]),
                          ]
                        ),
                        _c("div", { staticClass: "filterSearch" }, [
                          _c(
                            "ul",
                            _vm._l(_vm.filter.array, function (list, index) {
                              return _c(
                                "li",
                                { key: list, staticClass: "filter-group" },
                                [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.filter.selected[index],
                                          expression: "filter.selected[index]",
                                        },
                                      ],
                                      staticClass: "custom-select",
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.filter.selected,
                                              index,
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                          function ($event) {
                                            return _vm.fnFilterConditionChange(
                                              index
                                            )
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "option",
                                        {
                                          attrs: {
                                            value: "",
                                            selected: "",
                                            disabled: "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("filter.choose")) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm._l(
                                        _vm.columns,
                                        function (label, columnIndex) {
                                          return _c(
                                            "option",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !label.disable,
                                                  expression: "!label.disable",
                                                },
                                              ],
                                              key: columnIndex,
                                              attrs: {
                                                disabled: label.disable,
                                              },
                                              domProps: { value: label },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(label.label) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm.filter.selected[index].type === "String"
                                    ? _c(
                                        "div",
                                        { staticClass: "filterSearchBar" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.filter.stringType[
                                                      index
                                                    ],
                                                  expression:
                                                    "filter.stringType[index]",
                                                },
                                              ],
                                              staticClass: "custom-select",
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.filter.stringType,
                                                      index,
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.fnFilterConditionChange(
                                                      index
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            _vm._l(
                                              _vm.filter.typeOptions[index],
                                              function (type) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: type,
                                                    domProps: { value: type },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "filter." + type
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value:
                                                  _vm.filter.stringCondition[
                                                    index
                                                  ][0],
                                                expression:
                                                  "filter.stringCondition[index][0]",
                                                modifiers: { trim: true },
                                              },
                                            ],
                                            staticClass: "filterInput",
                                            class: [
                                              _vm.filter.error &&
                                              _vm.filter.stringCondition[
                                                index
                                              ][0] === ""
                                                ? "error"
                                                : "",
                                            ],
                                            attrs: {
                                              "data-id": index,
                                              type: "text",
                                              placeholder:
                                                _vm.$t("filter.search"),
                                            },
                                            domProps: {
                                              value:
                                                _vm.filter.stringCondition[
                                                  index
                                                ][0],
                                            },
                                            on: {
                                              keyup: _vm.fnFilterKeyboardEvent,
                                              blur: [
                                                function ($event) {
                                                  return _vm.fnFilterBlurEvent(
                                                    index
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.$forceUpdate()
                                                },
                                              ],
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.filter.stringCondition[
                                                    index
                                                  ],
                                                  0,
                                                  $event.target.value.trim()
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.filter.selected[index].type === "Number"
                                    ? _c(
                                        "div",
                                        { staticClass: "filterSearchBar" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.filter.numberType[
                                                      index
                                                    ],
                                                  expression:
                                                    "filter.numberType[index]",
                                                },
                                              ],
                                              staticClass: "custom-select",
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.filter.numberType,
                                                      index,
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.fnFilterNumberTypeChange(
                                                      index
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            _vm._l(
                                              _vm.filter.typeOptions[index],
                                              function (type) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: type,
                                                    domProps: { value: type },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "filter." + type
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                          _vm.filter.numberType[index] !==
                                          "between"
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName:
                                                      "v-model.trim.number",
                                                    value:
                                                      _vm.filter
                                                        .numberCondition[
                                                        index
                                                      ][0],
                                                    expression:
                                                      "filter.numberCondition[index][0]",
                                                    modifiers: {
                                                      trim: true,
                                                      number: true,
                                                    },
                                                  },
                                                ],
                                                staticClass: "filterInput",
                                                class: [
                                                  _vm.filter.error &&
                                                  _vm.filter.numberCondition[
                                                    index
                                                  ][0] === ""
                                                    ? "error"
                                                    : "",
                                                ],
                                                attrs: {
                                                  "data-id": index,
                                                  type: "number",
                                                  placeholder:
                                                    _vm.$t("filter.value"),
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.filter.numberCondition[
                                                      index
                                                    ][0],
                                                },
                                                on: {
                                                  keyup:
                                                    _vm.fnFilterKeyboardEvent,
                                                  blur: [
                                                    function ($event) {
                                                      return _vm.fnFilterBlurEvent(
                                                        index
                                                      )
                                                    },
                                                    function ($event) {
                                                      return _vm.$forceUpdate()
                                                    },
                                                  ],
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.filter
                                                        .numberCondition[index],
                                                      0,
                                                      _vm._n(
                                                        $event.target.value.trim()
                                                      )
                                                    )
                                                  },
                                                },
                                              })
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "filterBetweenBox",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName:
                                                          "v-model.trim.number",
                                                        value:
                                                          _vm.filter
                                                            .numberCondition[
                                                            index
                                                          ][0],
                                                        expression:
                                                          "filter.numberCondition[index][0]",
                                                        modifiers: {
                                                          trim: true,
                                                          number: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "filterInput",
                                                    class: [
                                                      _vm.filter.error &&
                                                      _vm.filter
                                                        .numberCondition[
                                                        index
                                                      ][0] === ""
                                                        ? "error"
                                                        : "",
                                                    ],
                                                    attrs: {
                                                      type: "number",
                                                      "data-id": index,
                                                      placeholder:
                                                        _vm.$t("filter.min"),
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.filter
                                                          .numberCondition[
                                                          index
                                                        ][0],
                                                    },
                                                    on: {
                                                      keyup:
                                                        _vm.fnFilterKeyboardEvent,
                                                      blur: [
                                                        function ($event) {
                                                          return _vm.fnFilterBlurEvent(
                                                            index
                                                          )
                                                        },
                                                        function ($event) {
                                                          return _vm.$forceUpdate()
                                                        },
                                                      ],
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.filter
                                                            .numberCondition[
                                                            index
                                                          ],
                                                          0,
                                                          _vm._n(
                                                            $event.target.value.trim()
                                                          )
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("filter.to")
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName:
                                                          "v-model.trim.number",
                                                        value:
                                                          _vm.filter
                                                            .numberCondition[
                                                            index
                                                          ][1],
                                                        expression:
                                                          "filter.numberCondition[index][1]",
                                                        modifiers: {
                                                          trim: true,
                                                          number: true,
                                                        },
                                                      },
                                                    ],
                                                    staticClass: "filterInput",
                                                    class: [
                                                      _vm.filter.error &&
                                                      _vm.filter
                                                        .numberCondition[
                                                        index
                                                      ][1] === ""
                                                        ? "error"
                                                        : "",
                                                    ],
                                                    attrs: {
                                                      "data-id": index,
                                                      type: "number",
                                                      placeholder:
                                                        _vm.$t("filter.max"),
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.filter
                                                          .numberCondition[
                                                          index
                                                        ][1],
                                                    },
                                                    on: {
                                                      keyup:
                                                        _vm.fnFilterKeyboardEvent,
                                                      blur: [
                                                        function ($event) {
                                                          return _vm.fnFilterBlurEvent(
                                                            index
                                                          )
                                                        },
                                                        function ($event) {
                                                          return _vm.$forceUpdate()
                                                        },
                                                      ],
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.filter
                                                            .numberCondition[
                                                            index
                                                          ],
                                                          1,
                                                          _vm._n(
                                                            $event.target.value.trim()
                                                          )
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ]
                                              ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.filter.selected[index].type === "Option"
                                    ? _c(
                                        "div",
                                        { staticClass: "filterSearchBar" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.filter.optionType[
                                                      index
                                                    ],
                                                  expression:
                                                    "filter.optionType[index]",
                                                },
                                              ],
                                              staticClass: "custom-select",
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.filter.optionType,
                                                      index,
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.fnFilterOptionConditionChange(
                                                      index
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    selected: "",
                                                    disabled: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "filter.optionSelect"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.filter.selected[index]
                                                  .options,
                                                function (option) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: option,
                                                      domProps: {
                                                        value: option,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "filterOption." +
                                                                _vm.device +
                                                                "." +
                                                                _vm.filter
                                                                  .selected[
                                                                  index
                                                                ].field +
                                                                "." +
                                                                option
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.filter.selected[index].type === "Time"
                                    ? _c(
                                        "div",
                                        { staticClass: "filterSearchBar" },
                                        [
                                          _c("date-picker", {
                                            ref: "datePicker",
                                            refInFor: true,
                                            attrs: {
                                              type: "datetime",
                                              placeholder: "select time range",
                                              range: "",
                                              confirm: "",
                                              "confirm-text": "apply",
                                              editable: false,
                                              clearable: false,
                                              "disabled-date":
                                                _vm.disableFeatureTime,
                                              "show-time-panel":
                                                _vm.filter.showTimeRangePanel[
                                                  index
                                                ],
                                              "show-second": false,
                                              "minute-step": 1,
                                              shortcuts: _vm.filter.shortcuts,
                                              format: "YYYY/MM/DD HH:mm a",
                                            },
                                            on: {
                                              close: function ($event) {
                                                return _vm.handleRangeClose(
                                                  index
                                                )
                                              },
                                              confirm: function ($event) {
                                                return _vm.fnFilterAddTimeCondition(
                                                  index
                                                )
                                              },
                                              change: function ($event) {
                                                return _vm.fnFilterAddTimeCondition(
                                                  index
                                                )
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "icon-calendar",
                                                  fn: function () {
                                                    return [_c("div")]
                                                  },
                                                  proxy: true,
                                                },
                                                {
                                                  key: "footer",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "timeRangeType",
                                                        },
                                                        [
                                                          _c("label", [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.filter
                                                                      .timeType[
                                                                      index
                                                                    ],
                                                                  expression:
                                                                    "filter.timeType[index]",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "radio",
                                                                name: "timeType",
                                                                value: "local",
                                                              },
                                                              domProps: {
                                                                checked: _vm._q(
                                                                  _vm.filter
                                                                    .timeType[
                                                                    index
                                                                  ],
                                                                  "local"
                                                                ),
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.filter
                                                                        .timeType,
                                                                      index,
                                                                      "local"
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "filter.timeTypeLocal"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                          _c("label", [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.filter
                                                                      .timeType[
                                                                      index
                                                                    ],
                                                                  expression:
                                                                    "filter.timeType[index]",
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "radio",
                                                                name: "timeType",
                                                                value: "utc",
                                                              },
                                                              domProps: {
                                                                checked: _vm._q(
                                                                  _vm.filter
                                                                    .timeType[
                                                                    index
                                                                  ],
                                                                  "utc"
                                                                ),
                                                              },
                                                              on: {
                                                                change:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.$set(
                                                                      _vm.filter
                                                                        .timeType,
                                                                      index,
                                                                      "utc"
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "filter.timeTypeUTC"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "mx-btn mx-btn-text",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.toggleTimeRangePanel(
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.filter
                                                                  .showTimeRangePanel[
                                                                  index
                                                                ]
                                                                  ? "select date"
                                                                  : "select time"
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value:
                                                _vm.filter.timeCondition[index],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.filter.timeCondition,
                                                  index,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filter.timeCondition[index]",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.filter.selected[index].type === "Mix"
                                    ? _c(
                                        "div",
                                        { staticClass: "filterSearchBar" },
                                        [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.filter.mixOption[index],
                                                  expression:
                                                    "filter.mixOption[index]",
                                                },
                                              ],
                                              staticClass: "custom-select",
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.$set(
                                                      _vm.filter.mixOption,
                                                      index,
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function ($event) {
                                                    return _vm.fnFilterConditionChange(
                                                      index
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "number",
                                                    selected: "",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "filter.filterTypeNumber"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "option",
                                                { attrs: { value: "string" } },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "filter.filterTypeString"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm.filterMixColumns[index] ===
                                          "string"
                                            ? _c("div", [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.filter
                                                            .mixStringType[
                                                            index
                                                          ],
                                                        expression:
                                                          "filter.mixStringType[index]",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "custom-select",
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            _vm.filter
                                                              .mixStringType,
                                                            index,
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                        function ($event) {
                                                          return _vm.fnFilterConditionChange(
                                                            index
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  _vm._l(
                                                    _vm.filter.typeOptions[
                                                      index
                                                    ],
                                                    function (type) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: type,
                                                          domProps: {
                                                            value: type,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "filter." +
                                                                    type
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.trim",
                                                      value:
                                                        _vm.filter
                                                          .mixStringCondition[
                                                          index
                                                        ][0],
                                                      expression:
                                                        "filter.mixStringCondition[index][0]",
                                                      modifiers: { trim: true },
                                                    },
                                                  ],
                                                  staticClass: "filterInput",
                                                  class: [
                                                    _vm.filter.error &&
                                                    _vm.filter
                                                      .mixStringCondition[
                                                      index
                                                    ][0] === ""
                                                      ? "error"
                                                      : "",
                                                  ],
                                                  attrs: {
                                                    "data-id": index,
                                                    placeholder:
                                                      _vm.$t("filter.search"),
                                                    type: "text",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.filter
                                                        .mixStringCondition[
                                                        index
                                                      ][0],
                                                  },
                                                  on: {
                                                    keyup:
                                                      _vm.fnFilterKeyboardEvent,
                                                    blur: [
                                                      function ($event) {
                                                        return _vm.fnFilterBlurEvent(
                                                          index
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.$forceUpdate()
                                                      },
                                                    ],
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.filter
                                                          .mixStringCondition[
                                                          index
                                                        ],
                                                        0,
                                                        $event.target.value.trim()
                                                      )
                                                    },
                                                  },
                                                }),
                                              ])
                                            : _vm._e(),
                                          _vm.filterMixColumns[index] ===
                                          "number"
                                            ? _c(
                                                "div",
                                                { staticClass: "mix-number" },
                                                [
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.filter
                                                              .mixNumberType[
                                                              index
                                                            ],
                                                          expression:
                                                            "filter.mixNumberType[index]",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "custom-select",
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$selectedVal =
                                                              Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function (o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function (
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                            _vm.$set(
                                                              _vm.filter
                                                                .mixNumberType,
                                                              index,
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          },
                                                          function ($event) {
                                                            return _vm.fnFilterMixNumberTypeChange(
                                                              index
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.filter.typeOptions[
                                                        index
                                                      ],
                                                      function (type) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: type,
                                                            domProps: {
                                                              value: type,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "filter." +
                                                                      type
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                  _vm.mixType[index] !==
                                                  "between"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName:
                                                              "v-model.trim.number",
                                                            value:
                                                              _vm.filter
                                                                .mixNumberCondition[
                                                                index
                                                              ][0],
                                                            expression:
                                                              "\n                        filter.mixNumberCondition[index][0]\n                      ",
                                                            modifiers: {
                                                              trim: true,
                                                              number: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass:
                                                          "filterInput",
                                                        class: [
                                                          _vm.filter.error &&
                                                          _vm.filter
                                                            .mixNumberCondition[
                                                            index
                                                          ][0] === ""
                                                            ? "error"
                                                            : "",
                                                        ],
                                                        attrs: {
                                                          "data-id": index,
                                                          placeholder:
                                                            _vm.$t(
                                                              "filter.value"
                                                            ),
                                                          type: "number",
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.filter
                                                              .mixNumberCondition[
                                                              index
                                                            ][0],
                                                        },
                                                        on: {
                                                          keyup:
                                                            _vm.fnFilterKeyboardEvent,
                                                          blur: [
                                                            function ($event) {
                                                              return _vm.fnFilterBlurEvent(
                                                                index
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.$forceUpdate()
                                                            },
                                                          ],
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.$set(
                                                              _vm.filter
                                                                .mixNumberCondition[
                                                                index
                                                              ],
                                                              0,
                                                              _vm._n(
                                                                $event.target.value.trim()
                                                              )
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "filterBetweenBox",
                                                        },
                                                        [
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model.trim.number",
                                                                value:
                                                                  _vm.filter
                                                                    .mixNumberCondition[
                                                                    index
                                                                  ][0],
                                                                expression:
                                                                  "\n                          filter.mixNumberCondition[index][0]\n                        ",
                                                                modifiers: {
                                                                  trim: true,
                                                                  number: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "filterInput",
                                                            class: [
                                                              _vm.filter
                                                                .error &&
                                                              _vm.filter
                                                                .mixNumberCondition[
                                                                index
                                                              ][0] === ""
                                                                ? "error"
                                                                : "",
                                                            ],
                                                            attrs: {
                                                              type: "number",
                                                              "data-id": index,
                                                              placeholder:
                                                                _vm.$t(
                                                                  "filter.min"
                                                                ),
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.filter
                                                                  .mixNumberCondition[
                                                                  index
                                                                ][0],
                                                            },
                                                            on: {
                                                              keyup:
                                                                _vm.fnFilterKeyboardEvent,
                                                              blur: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.fnFilterBlurEvent(
                                                                    index
                                                                  )
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$forceUpdate()
                                                                },
                                                              ],
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm.filter
                                                                    .mixNumberCondition[
                                                                    index
                                                                  ],
                                                                  0,
                                                                  _vm._n(
                                                                    $event.target.value.trim()
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "filter.to"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model.trim.number",
                                                                value:
                                                                  _vm.filter
                                                                    .mixNumberCondition[
                                                                    index
                                                                  ][1],
                                                                expression:
                                                                  "\n                          filter.mixNumberCondition[index][1]\n                        ",
                                                                modifiers: {
                                                                  trim: true,
                                                                  number: true,
                                                                },
                                                              },
                                                            ],
                                                            staticClass:
                                                              "filterInput",
                                                            class: [
                                                              _vm.filter
                                                                .error &&
                                                              _vm.filter
                                                                .mixNumberCondition[
                                                                index
                                                              ][1] === ""
                                                                ? "error"
                                                                : "",
                                                            ],
                                                            attrs: {
                                                              type: "number",
                                                              "data-id": index,
                                                              placeholder:
                                                                _vm.$t(
                                                                  "filter.max"
                                                                ),
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.filter
                                                                  .mixNumberCondition[
                                                                  index
                                                                ][1],
                                                            },
                                                            on: {
                                                              keyup:
                                                                _vm.fnFilterKeyboardEvent,
                                                              blur: [
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.fnFilterBlurEvent(
                                                                    index
                                                                  )
                                                                },
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$forceUpdate()
                                                                },
                                                              ],
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                )
                                                                  return
                                                                _vm.$set(
                                                                  _vm.filter
                                                                    .mixNumberCondition[
                                                                    index
                                                                  ],
                                                                  1,
                                                                  _vm._n(
                                                                    $event.target.value.trim()
                                                                  )
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm.filterMixColumns[index] ===
                                          "option"
                                            ? _c("div", [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.filter
                                                            .mixOptionCondition[
                                                            index
                                                          ],
                                                        expression:
                                                          "filter.mixOptionCondition[index]",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "custom-select",
                                                    on: {
                                                      change: [
                                                        function ($event) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            _vm.filter
                                                              .mixOptionCondition,
                                                            index,
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                        function ($event) {
                                                          return _vm.fnFilterMixOptionConditionChange(
                                                            index
                                                          )
                                                        },
                                                      ],
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "",
                                                          selected: "",
                                                          disabled: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "filter.optionSelect"
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.filter.selected[index]
                                                        .options,
                                                      function (option) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: option,
                                                            domProps: {
                                                              value: option,
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "filterOption." +
                                                                      _vm.device +
                                                                      "." +
                                                                      _vm.filter
                                                                        .selected[
                                                                        index
                                                                      ].field +
                                                                      "['" +
                                                                      option +
                                                                      "']"
                                                                  )
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                  _c("i", {
                                    staticClass: "far fa-times-circle",
                                    on: {
                                      click: function ($event) {
                                        return _vm.fnDelelteFilterCondition(
                                          index
                                        )
                                      },
                                    },
                                  }),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm.device === "manageMemberList" &&
              _vm.vxAccountLevel !== _vm.vxConstantsAccountLevel.admin
                ? _c(
                    "div",
                    {
                      staticClass: "tableBar",
                      attrs: { slot: "table-actions-bottom" },
                      slot: "table-actions-bottom",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "button-group" },
                        [
                          _c(
                            "m-button",
                            {
                              staticClass: "tableButton",
                              attrs: { raised: "", dense: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.show("inviteGroup")
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t(_vm.inviteButtonTitle)) + " "
                              ),
                            ]
                          ),
                          _c(
                            "m-button",
                            {
                              staticClass: "tableButton",
                              attrs: { raised: "", dense: "" },
                              on: { click: _vm.fnKickAccountCheckSelected },
                            },
                            [_vm._v(_vm._s(_vm.$t(_vm.removeButtonTitle)))]
                          ),
                          _vm.buttonGroupBoolean
                            ? _c(
                                "m-button",
                                {
                                  staticClass: "tableButton",
                                  attrs: { raised: "", dense: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$bvModal.show("disbandGroup")
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("button.disband")))]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c("div", { attrs: { slot: "emptystate" }, slot: "emptystate" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("tableColumns.noMatch")))]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "noSelectAccountToggle",
            centered: "",
            title: _vm.$t("modal.notice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.$t("modal.shopMemberList.noSelect")))])]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "selectMultipleAccount",
            centered: "",
            title: _vm.$t("modal.notice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.$t("modal.shopMemberList.onlySelect")))])]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "noSelectDeviceToggle",
            centered: "",
            title: _vm.$t("modal.notice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.$t("modal.deviceManagement.noSelect")))])]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "selectMultipleDeviceToggle",
            centered: "",
            title: _vm.$t("modal.notice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.deviceManagement.onlySelect"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "changeGroupManager",
            centered: "",
            title: _vm.$t("modal.warning"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.no")) + " ")]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton",
                      attrs: {
                        raised: "",
                        dense: "",
                        disabled: _vm.loading,
                        outlined: _vm.loading,
                      },
                      on: { click: _vm.fnchangeGroupManager },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("button.yes")) + " "),
                      _vm.loading
                        ? _c("b-spinner", {
                            staticClass: "buttonLoad",
                            attrs: {
                              small: "",
                              label: "Loading...",
                              variant: "info",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("modal.shopMemberList.handover.text1")) +
                " " +
                _vm._s(_vm.selectedUser) +
                " ? "
            ),
          ]),
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.handover.text2"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "changeShopManagerMailSend",
            centered: "",
            title: _vm.$t("modal.success"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.handover.success1"))),
          ]),
          _c("h6", [
            _vm._v(
              " " +
                _vm._s(_vm.$t("modal.shopMemberList.handover.success2")) +
                " " +
                _vm._s(_vm.handoverAccount) +
                " " +
                _vm._s(_vm.$t("modal.shopMemberList.handover.success3")) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "haveDeviceToggle",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.haveDevice.text1"))),
          ]),
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.haveDevice.text2"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "kickAccountToggle",
            centered: "",
            title: _vm.$t("modal.warning"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close, ok }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.no")) + " ")]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          ok(_vm.fnKickAccount())
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.yes")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.shop
            ? _c("h6", [
                _vm._v(
                  " " +
                    _vm._s(_vm.selectedUser) +
                    " " +
                    _vm._s(
                      _vm.$t("modal.shopMemberList.detach.success.text1")
                    ) +
                    " "
                ),
              ])
            : _c("h6", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("modal.shopMemberList.detach.companySuccess.text1")
                    ) +
                    " : " +
                    _vm._s(_vm.selectedShop) +
                    " " +
                    _vm._s(
                      _vm.$t("modal.shopMemberList.detach.companySuccess.text2")
                    ) +
                    " "
                ),
              ]),
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.detach.success.text3"))),
          ]),
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.detach.success.text2"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "kickAccountErrorToggle",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          close(_vm.fnDataTableRefresh())
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(
              _vm._s(_vm.selectedUser) +
                " " +
                _vm._s(_vm.$t("modal.shopMemberList.delete.error"))
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "kickSameLevelErrorToggle",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.detach.error"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "canOnlyKickShopErrorToggle",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(
              _vm._s(_vm.$t("modal.shopMemberList.detach.errorOnlyKickShop"))
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "testDataTransform",
            centered: "",
            title: _vm.$t("modal.notice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          close(_vm.blankPage("serverTasks"))
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.tasklist")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.detach.createTask"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "addDeviceToggle",
            centered: "",
            title: _vm.$t("modal.addadevice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function () {
                return [
                  _c("h5", [_vm._v(_vm._s(_vm.$t("modal.addadevice")))]),
                  _c("button", { on: { click: _vm.modalClose } }, [
                    _vm._v("X"),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "modal-footer",
              fn: function ({}) {
                return [_c("p")]
              },
            },
          ]),
        },
        [
          _vm.addDevice.buttonToggle
            ? _c(
                "div",
                { staticClass: "button-group" },
                [
                  _c("h6", [
                    _vm._v(
                      _vm._s(_vm.$t("modal.deviceManagement.addDevice.text1"))
                    ),
                  ]),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "" },
                      on: { click: _vm.fnShowQrcode },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("modal.deviceManagement.addDevice.text2")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "" },
                      on: { click: _vm.fnShowBarcode },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("modal.deviceManagement.addDevice.text3")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "" },
                      on: { click: _vm.fnShowRegistrationCode },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("modal.deviceManagement.addDevice.text4")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("br"),
                ],
                1
              )
            : _vm._e(),
          _vm.addDevice.qrcodeToggle
            ? _c(
                "div",
                { staticClass: "qrcode-group" },
                [
                  _vm.loading
                    ? _c("b-spinner", {
                        staticClass: "codeLoading",
                        attrs: { label: "Loading...", variant: "info" },
                      })
                    : _c("qrcode-vue", {
                        attrs: {
                          value: _vm.addDevice.DATAqrcode,
                          size: 256,
                          level: "H",
                        },
                      }),
                  _c("h6", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("modal.deviceManagement.addDevice.text5")
                        ) +
                        " "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.addDevice.barcodeToggle
            ? _c(
                "div",
                { staticClass: "barcode-group" },
                [
                  _vm.loading
                    ? _c("b-spinner", {
                        staticClass: "codeLoading",
                        attrs: { label: "Loading...", variant: "info" },
                      })
                    : _c("VueBarcode", {
                        attrs: {
                          value: _vm.addDevice.DATAregistrationCode,
                          "display-value": false,
                          format: "CODE39",
                        },
                      }),
                  _c("h6", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("modal.deviceManagement.addDevice.text5")
                        ) +
                        " "
                    ),
                  ]),
                  _c("h6", [
                    _vm._v(
                      _vm._s(_vm.$t("modal.deviceManagement.addDevice.text6"))
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.addDevice.registrationCodeToggle
            ? _c(
                "div",
                { staticClass: "registrationCode-group" },
                [
                  _vm.loading
                    ? _c("b-spinner", {
                        staticClass: "codeLoading",
                        attrs: { label: "Loading...", variant: "info" },
                      })
                    : _c("h1", [
                        _vm._v(_vm._s(_vm.addDevice.DATAregistrationCode)),
                      ]),
                  _c("h6", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("modal.deviceManagement.addDevice.text7")
                        ) +
                        " "
                    ),
                  ]),
                  _c("h6", [
                    _vm._v(
                      _vm._s(_vm.$t("modal.deviceManagement.addDevice.text6"))
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "deleteDeviceToggle",
            centered: "",
            title: _vm.$t("modal.warning"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.no")) + " ")]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          ok(_vm.fnRemoveDevice())
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.yes")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(
              " " +
                _vm._s(_vm.selectedDevice) +
                " " +
                _vm._s(_vm.$t("modal.deviceManagement.remove.success.text1")) +
                " "
            ),
          ]),
          _c("h6", [
            _vm._v(
              _vm._s(_vm.$t("modal.deviceManagement.remove.success.text2"))
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "deleteDeviceErrorToggle",
            centered: "",
            title: _vm.$t("modal.notice"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          close(_vm.fnDataTableRefresh())
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(
              " " +
                _vm._s(_vm.selectedDevice) +
                " " +
                _vm._s(_vm.$t("modal.deviceManagement.remove.error")) +
                " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "inviteGroup",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            title: _vm.$t(_vm.modalTitle),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.invite.mailErrorToggle,
                          expression: "invite.mailErrorToggle",
                        },
                      ],
                      staticStyle: { color: "red" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("modal.shopMemberList.error.text1")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.invite.accountNotExistToggle,
                          expression: "invite.accountNotExistToggle",
                        },
                      ],
                      staticStyle: { color: "red" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("modal.shopMemberList.error.text2")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.invite.cantInviteTechToggle,
                          expression: "invite.cantInviteTechToggle",
                        },
                      ],
                      staticStyle: { color: "red" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("modal.shopMemberList.error.text7")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: {
                        raised: "",
                        dense: "",
                        disabled: _vm.loading || _vm.inviteError,
                        outlined: _vm.loading || _vm.inviteError,
                      },
                      on: { click: _vm.fnEmailCheckAndSend },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("button.sendInvitation")) + " "
                      ),
                      _c("b-spinner", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        staticClass: "buttonLoad",
                        attrs: {
                          label: "Loading...",
                          small: "",
                          variant: "light",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.business
            ? _c("div", { staticClass: "businessInviteTop" }, [
                _c(
                  "a",
                  {
                    staticClass: "inviteLabel",
                    class: _vm.inviteMethod === "id" ? "active" : "",
                    attrs: { href: "javascript;" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.clickInviteMethodAndHideError("id")
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("modal.shopMemberList.inviteLab.id")))]
                ),
                _c(
                  "a",
                  {
                    staticClass: "inviteLabel",
                    class: _vm.inviteMethod === "account" ? "active" : "",
                    attrs: { href: "javascript;" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.clickInviteMethodAndHideError("account")
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("modal.shopMemberList.inviteLab.account"))
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.shop
            ? _c("h6", [
                _vm._v(
                  " " + _vm._s(_vm.$t("modal.shopMemberList.invite")) + " "
                ),
              ])
            : _c("h6", [
                _vm.inviteMethod === "id"
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("modal.shopMemberList.inviteShopID")) +
                          " "
                      ),
                    ])
                  : _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("modal.shopMemberList.inviteShopAccount")
                          ) +
                          " "
                      ),
                    ]),
              ]),
          _c(
            "ul",
            _vm._l(_vm.invite.inviteGroupMail, function (mail) {
              return _c(
                "li",
                { key: mail.id, staticClass: "inviteMail-Group" },
                [
                  _vm.inviteMethod === "id"
                    ? _c("label", { attrs: { for: "inviteInputText" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                `modal.shopMemberList.inviteLable.${_vm.subscribeLevel}`
                              )
                            ) +
                            ": "
                        ),
                      ])
                    : _vm._e(),
                  _vm.inviteMethod === "account"
                    ? _c("label", { attrs: { for: "inviteInputText" } }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                `modal.shopMemberList.inviteLable.companyAccount`
                              )
                            ) +
                            ": "
                        ),
                      ])
                    : _vm._e(),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: mail.mail,
                        expression: "mail.mail",
                      },
                    ],
                    class: mail.check ? "" : "error",
                    attrs: {
                      id: "inviteInputText",
                      type: "text",
                      placeholder: [
                        _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.shop
                          ? _vm.$t(
                              "modal.shopMemberList.inviteLable.shopPlaceholder"
                            )
                          : _vm.inviteMethod === "id"
                          ? _vm.$t(
                              "modal.shopMemberList.inviteLable.companyPlaceholder"
                            )
                          : _vm.$t(
                              "modal.shopMemberList.inviteLable.companyAccountPlaceholder"
                            ),
                      ],
                    },
                    domProps: { value: mail.mail },
                    on: {
                      keyup: function ($event) {
                        _vm.invite.mailErrorToggle = false
                        mail.check = true
                        _vm.invite.accountNotExistToggle = false
                        _vm.invite.cantInviteTechToggle = false
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(mail, "mail", $event.target.value)
                      },
                    },
                  }),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "inviteGroupSuccess",
            centered: "",
            title: _vm.$t("modal.success"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.$t("modal.shopMemberList.success")))])]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "shopIDNotNullToggle",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.$t("modal.shopMemberList.error.text4")))])]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "groupFullErrorToggle",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.error.text3"))),
          ]),
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.error.text5"))),
          ]),
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.error.text6"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "disbandGroup",
            centered: "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            title: _vm.$t("modal.warning"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function () {
                return [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.disbandGroupCheck,
                        expression: "disbandGroupCheck",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("modal.shopMemberList.disband.text5"),
                    },
                    domProps: { value: _vm.disbandGroupCheck },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.disbandGroupCheck = $event.target.value
                      },
                    },
                  }),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton",
                      class:
                        _vm.disbandGroupCheck !== "confirm" ? "" : "cancel",
                      attrs: {
                        raised: "",
                        dense: "",
                        outlined: _vm.loading,
                        disabled:
                          _vm.disbandGroupCheck !== "confirm" || _vm.loading,
                      },
                      on: { click: _vm.fnDisbandGroup },
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$t("button.disband")) + " "),
                      _vm.loading
                        ? _c("b-spinner", {
                            staticClass: "buttonLoad",
                            attrs: {
                              small: "",
                              label: "Loading...",
                              variant: "light",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.shop
            ? _c("div", [
                _c("h6", [
                  _vm._v(_vm._s(_vm.$t("modal.shopMemberList.disband.text1"))),
                ]),
                _c("h6", [
                  _vm._v(_vm._s(_vm.$t("modal.shopMemberList.disband.text2"))),
                ]),
                _c("h6", [
                  _vm._v(_vm._s(_vm.$t("modal.shopMemberList.disband.text3"))),
                ]),
                _c("br"),
                _c("h6", [
                  _vm._v(_vm._s(_vm.$t("modal.shopMemberList.disband.text4"))),
                ]),
              ])
            : _c("div", [
                _c("h6", [
                  _vm._v(_vm._s(_vm.$t("modal.shopMemberList.disband.text6"))),
                ]),
                _c("h6", [
                  _vm._v(_vm._s(_vm.$t("modal.shopMemberList.disband.text7"))),
                ]),
                _c("h6", [
                  _vm._v(_vm._s(_vm.$t("modal.shopMemberList.disband.text8"))),
                ]),
                _c("br"),
                _c("h6", [
                  _vm._v(_vm._s(_vm.$t("modal.shopMemberList.disband.text9"))),
                ]),
              ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "disbandGroupSuccess",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
            centered: "",
            title: _vm.$t("modal.success"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(_vm._s(_vm.$t("modal.shopMemberList.disband.success"))),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "subDatatable",
            centered: "",
            size: "xl",
            "hide-footer": "",
          },
        },
        [
          _c(
            "vue-good-table",
            {
              attrs: {
                mode: "remote",
                columns: _vm.subColumns,
                rows: _vm.dataTableSubRows,
                "row-style-class": _vm.fnRowStyleClass,
                styleClass: "vgt-table striped",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "tableBar",
                  attrs: { slot: "table-actions" },
                  slot: "table-actions",
                },
                [
                  _c(
                    "div",
                    { staticClass: "button-group" },
                    [
                      _vm.device !== "deviceManager"
                        ? _c(
                            "b-dropdown",
                            {
                              staticClass: "columns",
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c(
                                          "m-button",
                                          {
                                            staticClass: "dropDownButton",
                                            attrs: {
                                              raised: true,
                                              dense: true,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("button.columns"))
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                73853161
                              ),
                            },
                            _vm._l(_vm.subColumns, function (column, index) {
                              return _c("b-dropdown-item", { key: index }, [
                                _c("label", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#", tabIndex: "-1" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.fnToggleSubColumn(
                                            index,
                                            $event
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: { type: "checkbox" },
                                        domProps: { checked: !column.hidden },
                                      }),
                                      _c("span", { staticClass: "checkmark" }),
                                      _vm._v("  " + _vm._s(column.label) + " "),
                                    ]
                                  ),
                                ]),
                              ])
                            }),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c("div", { attrs: { slot: "emptystate" }, slot: "emptystate" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("tableColumns.noMatch")))]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "deleteBlackListAccountToggle",
            centered: "",
            title: _vm.$t("modal.warning"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close, ok }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.no")) + " ")]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          ok(_vm.fnDeleteBlacklistAccount())
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.yes")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(" " + _vm._s(_vm.$t("modal.blacklist.text1")) + " "),
          ]),
          _c("h6", [
            _vm._v(" " + _vm._s(_vm.$t("modal.blacklist.text2")) + " "),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "unlockUserToggle",
            centered: "",
            title: _vm.$t("modal.warning"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close, ok }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.no")) + " ")]
                  ),
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          ok(_vm.fnUnlockUser())
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.yes")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c("h6", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("modal.unlockUser.text1") +
                    _vm.selectedUser +
                    _vm.$t("modal.unlockUser.text2")
                ) +
                " "
            ),
          ]),
          _c("h6", [_vm._v(_vm._s(_vm.$t("modal.unlockUser.comment")))]),
          _c("b-input", {
            attrs: { id: "unlockComment", type: "textarea" },
            model: {
              value: _vm.unlockComment,
              callback: function ($$v) {
                _vm.unlockComment = $$v
              },
              expression: "unlockComment",
            },
          }),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            id: "unlockUserErrorToggle",
            centered: "",
            title: _vm.$t("modal.error"),
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ close }) {
                return [
                  _c(
                    "m-button",
                    {
                      staticClass: "tableButton cancel",
                      attrs: { raised: "", dense: "" },
                      on: {
                        click: function ($event) {
                          return close()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.ok")) + " ")]
                  ),
                ]
              },
            },
          ]),
        },
        [_c("h6", [_vm._v(_vm._s(_vm.unlockUserErrorMessage))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }