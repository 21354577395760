<template>
  <div>
    <div class="dashboardTop">
      <div class="dashboardTitle">
        <h1>{{ dashboardTitle }}</h1>
        <h1>
          {{ dashboardTimezone
          }}<span
            v-if="
              dashboardTimezone !== '' &&
              shopList[0].active &&
              vxTimezone !== DATAnewestUpdateTime.timezone
            "
            style="font-size: 16px"
            >({{ $t('formFormat.willUpdate') }})</span
          >
        </h1>
      </div>
      <div class="ShopListForArrow">
        <i
          v-show="shopListShow != 0"
          class="shopListArrow prevPage fas fa-angle-left"
          @click="fnShopListPrev"
        ></i>
        <div id="groupListBox" class="groupListBox">
          <ul id="groupList" class="groupList">
            <li
              @click="
                !list.active || !list.loading ? fnBusinessChooseView(index) : ''
              "
              :class="list.active ? 'active' : ''"
              :disabled="loading || list.active"
              class="groupItem"
              v-for="(list, index) in shopList"
              :key="list.shopId"
            >
              <div class="loading" :class="list.loading ? 'active' : ''">
                <b-spinner
                  small
                  label="Loading..."
                  :variant="'info'"
                ></b-spinner>
              </div>
              <p :class="list.loading ? 'active' : ''">{{ list.shopId }}</p>
            </li>
          </ul>
        </div>
        <i
          v-show="shopListTotalWidth > shopListShowWidth"
          class="shopListArrow nextPage fas fa-angle-right"
          @click="fnShopListNext"
        ></i>
      </div>
    </div>
    <div class="frameContainer">
      <!-- 機種切換按鈕，API load完後才能點擊 -->
      <b-row class="dashboardButtons">
        <b-col lg="6" md="12" class="button-group fixFlex">
          <m-button
            v-for="(button, index) of buttonArray"
            :key="button.name"
            @click="fnDeviceChange(button.name, index)"
            :disabled="button.disabled || loading || button.action"
            :raised="button.action"
            :outlined="!button.action"
            dense
            class="tableButton"
          >
            <div class="loading" v-if="button.loading">
              <b-spinner small label="Loading..." :variant="'info'"></b-spinner>
            </div>
            <p v-else>{{ getButtonModelName(button.name) }}</p>
          </m-button>
        </b-col>

        <b-col lg="6" md="12" class="fixFlex">
          <!-- no data時為false -->
          <div v-if="DataTimeToggle" class="lastUpdateTime">
            {{ newestUpdateTime }}
          </div>
          <div v-else class="lastUpdateTime"></div>
        </b-col>
      </b-row>

      <b-row class="chartBox">
        <b-col
          :sm="userBoxRWD.user.new.sm"
          :md="userBoxRWD.user.new.md"
          :xl="userBoxRWD.user.new.xl"
          class="p0"
        >
          <UserBox
            class="chartUser chartArea"
            :data_userChart="DATAactiveUser.newUser"
            :data_date="DATAactiveUser.date"
            :title="$t('chart.title.new')"
            :haveData="NoDataUserToggle"
            :isSmallBox="userBoxRWD.user.new.xl === '2'"
          >
            <i
              slot="text"
              v-b-tooltip.bottom.hover
              :title="$t('chart.info.new')"
              class="fas fa-info-circle info"
            ></i>
          </UserBox>
        </b-col>

        <b-col
          :sm="userBoxRWD.user.active.sm"
          :md="userBoxRWD.user.active.md"
          :xl="userBoxRWD.user.active.xl"
          class="p0"
        >
          <UserBox
            class="chartUser chartArea"
            :data_userChart="DATAactiveUser.activeUser"
            :data_date="DATAactiveUser.date"
            :title="$t('chart.title.active')"
            :haveData="NoDataUserToggle"
            :isSmallBox="userBoxRWD.user.active.xl === '2'"
          >
            <i
              slot="text"
              v-b-tooltip.bottom.hover
              :title="$t('chart.info.active')"
              class="fas fa-info-circle info"
            ></i>
          </UserBox>
        </b-col>

        <b-col
          :sm="userBoxRWD.user.total.sm"
          :md="userBoxRWD.user.total.md"
          :xl="userBoxRWD.user.total.xl"
          class="p0"
        >
          <UserBox
            class="chartUser chartArea"
            :data_userChart="DATAactiveUser.totalUser"
            :data_date="DATAactiveUser.date"
            :title="$t('chart.title.totalUesr')"
            :haveData="NoDataUserToggle"
            :isSmallBox="userBoxRWD.user.total.xl === '2'"
          >
            <i
              slot="text"
              v-b-tooltip.bottom.hover
              :title="$t('chart.info.total')"
              class="fas fa-info-circle info"
            ></i>
          </UserBox>
        </b-col>

        <b-col
          :sm="userBoxRWD.shop.new.sm"
          :md="userBoxRWD.shop.new.md"
          :xl="userBoxRWD.shop.new.xl"
          class="p0"
          v-show="userBoxRWD.shop.show"
        >
          <UserBox
            class="chartUser chartArea"
            :data_userChart="DATAactiveUser.newShop"
            :data_date="DATAactiveUser.date"
            :title="$t('chart.title.newShop')"
            :haveData="NoDataUserToggle"
            :isSmallBox="userBoxRWD.shop.new.xl === '2'"
          >
            <i
              slot="text"
              v-b-tooltip.bottom.hover
              :title="$t('chart.info.newShop')"
              class="fas fa-info-circle info"
            ></i>
          </UserBox>
        </b-col>

        <b-col
          :sm="userBoxRWD.shop.active.sm"
          :md="userBoxRWD.shop.active.md"
          :xl="userBoxRWD.shop.active.xl"
          class="p0"
          v-show="userBoxRWD.shop.show"
        >
          <UserBox
            class="chartUser chartArea"
            :data_userChart="DATAactiveUser.activeShop"
            :data_date="DATAactiveUser.date"
            :title="$t('chart.title.activeShop')"
            :haveData="NoDataUserToggle"
            :isSmallBox="userBoxRWD.shop.active.xl === '2'"
          >
            <i
              slot="text"
              v-b-tooltip.bottom.hover
              :title="$t('chart.info.activeShop')"
              class="fas fa-info-circle info"
            ></i>
          </UserBox>
        </b-col>

        <b-col
          :sm="userBoxRWD.shop.total.sm"
          :md="userBoxRWD.shop.total.md"
          :xl="userBoxRWD.shop.total.xl"
          class="p0"
          v-show="userBoxRWD.shop.show"
        >
          <UserBox
            class="chartUser chartArea"
            :data_userChart="DATAactiveUser.totalShop"
            :data_date="DATAactiveUser.date"
            :title="$t('chart.title.totalShop')"
            :haveData="NoDataUserToggle"
            :isSmallBox="userBoxRWD.shop.total.xl === '2'"
          >
            <i
              slot="text"
              v-b-tooltip.bottom.hover
              :title="$t('chart.info.totalShop')"
              class="fas fa-info-circle info"
            ></i>
          </UserBox>
        </b-col>
      </b-row>
      <b-row class="chartBox">
        <!-- firmware version v-if nodata = false -->

        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDataFwToggle" class="chartArea chartBarFirmware">
            <HorizontalBarChart
              :chartData="DATAversionByDay"
              :chartTitle="[
                $t('chart.title.firmwareVersion'),
                APIversionByDay.date ? APIversionByDay.date : '',
              ]"
              :chart="'firmware'"
              class="chart"
            ></HorizontalBarChart>
            <ul class="labelsBox labelFirmware">
              <li
                class="label"
                v-for="(label, index) of firmwareLabel"
                :key="index"
              >
                <span
                  class="labelColor"
                  :style="{ background: label.color }"
                ></span>
                <div class="labelText">
                  <p>{{ label.name }}</p>
                  <p>{{ label.num }} ({{ label.percen }}%)</p>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="chartBarFirmware chartArea noDataChart">
            <h3>{{ $t('chart.title.firmwareVersion') }}</h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>
        <!-- Hourly By Day v-if nodata = false -->

        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDataPerDayToggle" class="chartArea chartBarHourlyByDay">
            <BarChart
              :chartData="DATAusageByDay"
              :chartTitle="
                $t('chart.title.hourlyDay') +
                ' ' +
                (this.APIusageByDay.date ? this.APIusageByDay.date : '')
              "
              :chartLabel="[$t('chart.label.time'), $t('chart.label.records')]"
              :avg="usageByDay_Avg"
              :chart="'hourlyDay'"
              class="chart"
            ></BarChart>
          </div>
          <div v-else class="chartBarHourlyByDay chartArea noDataChart">
            <h3>{{ $t('chart.title.hourlyDay') }}</h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>

        <!-- account By Day v-if nodata = false -->

        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDataTop4Toggle" class="chartArea chartBarAccount">
            <HorizontalBarChart
              :chartData="DATAaccountByDay"
              :chartTitle="[
                accountByDay_title,
                APIaccountByDay.date ? APIaccountByDay.date : '',
              ]"
              :chartLabel="[$t('chart.label.records'), accountByDay_yAxes]"
              :chart="'userRank'"
              class="chart"
            ></HorizontalBarChart>
          </div>
          <div v-else class="chartBarAccount chartArea noDataChart">
            <h3>{{ accountByDay_title }}</h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>

        <!-- Monthly report -->
        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDataNMonthToggle" class="chartArea chartLineMonths">
            <LineChart
              :chartData="DATAtestResultByXMonth"
              :chartTitle="$t('chart.title.monthly')"
              :chartLabel="[$t('chart.label.month'), $t('chart.label.records')]"
              :chartPercen="toolTipsArrayPercen"
              class="chart"
            ></LineChart>
            <div class="chooseMonthlyBox">
              <div class="chooseTimeType">
                <label class="timeType"
                  ><input
                    @change="fnChangeTimeType"
                    type="radio"
                    value="month"
                    v-model="selectTimeType"
                    :disabled="loading"
                  />
                  {{ $t('chart.label.month') }}</label
                >
                <label class="timeType"
                  ><input
                    @change="fnChangeTimeType"
                    type="radio"
                    value="quarter"
                    v-model="selectTimeType"
                    :disabled="loading"
                  />
                  {{ $t('chart.label.quarter') }}</label
                >
              </div>
              <b-spinner
                v-show="drawing"
                small
                label="Loading..."
                :variant="'light'"
                class="buttonLoad drawingLoad"
              ></b-spinner>
              <div class="chooseTimePeriod">
                <span class="timePeriod"
                  >{{ $t('chart.label.timePeriod') }}:</span
                >
                <select
                  @change="reDrawMonthlyChart"
                  class="timePeriod"
                  v-model="selectTimePeriod"
                  :disabled="loading"
                >
                  <option
                    :selected="index === 0"
                    :value="period"
                    v-for="(period, index) in timePeriod"
                    :key="period"
                  >
                    {{ period }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            lg="4"
            md="6"
            v-else
            class="chartLineMonths chartArea noDataChart"
          >
            <h3>{{ $t('chart.title.monthly') }}</h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>

        <!-- month result and total result -->
        <b-col lg="12" xl="8" class="p0">
          <div class="chartDoughnut-group">
            <!-- Month report v-if nodata = false -->
            <b-col md="12" lg="6" class="p0">
              <div v-if="NoDataPerMonthToggle" class="chartDoughnutMonth">
                <DoughnutChart
                  :chartData="DATAtestResultByMonth"
                  :chartTitle="
                    testResultByMonth_Title + ' ' + $t('chart.title.result')
                  "
                  :labelData="[
                    testResultByMonth_Total,
                    testResultByMonthPercen,
                  ]"
                  month
                  class="chart"
                ></DoughnutChart>
              </div>
              <div v-else class="chartDoughnutMonth noDataChart">
                <h3>
                  {{ testResultByMonth_ErrorTitle }}
                  {{ $t('chart.title.result') }}
                </h3>
                <p>{{ $t('home.nodata') }}</p>
              </div>
            </b-col>
            <!-- Total report v-if nodata = false -->
            <b-col md="12" lg="6" class="p0">
              <div v-if="NoDataTotalToggle" class="chartDoughnutTotal">
                <DoughnutChart
                  :chartData="DATAtotalTestResult"
                  :chartTitle="$t('chart.title.total')"
                  :labelData="[totalTestResult_Total]"
                  total
                  class="chart"
                ></DoughnutChart>
              </div>
              <div v-else class="chartDoughnutTotal noDataChart">
                <h3>{{ $t('chart.title.total') }}</h3>
                <p>{{ $t('home.nodata') }}</p>
              </div>
            </b-col>

            <!-- 與Total report共用狀態 -->
            <ul v-if="NoDataTotalToggle" class="labelsBox totalChartLabel">
              <li
                class="label"
                v-for="(label, index) of labelResult"
                :key="index"
              >
                <span
                  class="labelColor"
                  :style="{ background: label.color }"
                ></span>
                <p>{{ label.label }}</p>
              </li>
            </ul>
          </div>
        </b-col>

        <!-- test type(Quarter) -->
        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDataTestResultPerQuarter" class="chartArea chartPieType">
            <DoughnutChart
              :chartData="DATAtestType"
              :chartTitle="testResultPerQuarter_Title"
              pie
              class="chart"
            ></DoughnutChart>
            <ul class="pieLabelsBox">
              <li
                class="label"
                v-for="(label, index) of testResultPerQuarter_Label"
                :key="index"
              >
                <span
                  class="labelColor"
                  :style="{ background: label.color }"
                ></span>
                <p>{{ label.label }}</p>
              </li>
            </ul>
          </div>
          <div v-else class="chartPieType chartArea noDataChart">
            <h3>
              {{ APIchartPerQuarterErrorTitle }}
              {{ $t('chart.title.testType') }}
            </h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>

        <!-- battery type(month) -->
        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDatabatteryTypeMonth" class="chartArea chartBarType">
            <BarChart
              :chartData="DATAbatteryTypeMonth"
              :chartTitle="$t('chart.title.batteryTypeMonth')"
              :chartLabel="[
                $t('chart.label.batteryType'),
                $t('chart.label.records'),
              ]"
              :chart="'batteryType'"
              class="chart"
            ></BarChart>
          </div>
          <div v-else class="chartBarType chartArea noDataChart">
            <h3>{{ $t('chart.title.batteryTypeMonth') }}</h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>

        <!-- battery type(year) -->
        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDatabatteryTypeYear" class="chartArea chartPieType">
            <DoughnutChart
              :chart-data="DATAbatteryTypeYear"
              :chartTitle="
                (this.batteryTypeYear_Title ? this.batteryTypeYear_Title : '') +
                ' ' +
                $t('chart.title.batteryTypeYear')
              "
              pie
              class="chart"
            ></DoughnutChart>
            <ul class="pieLabelsBox">
              <li
                class="label"
                v-for="(label, index) of batteryTypeYear_Label"
                :key="index"
              >
                <span
                  class="labelColor"
                  :style="{ background: label.color }"
                ></span>
                <p>{{ label.label }}</p>
              </li>
            </ul>
          </div>
          <div v-else class="chartPieType chartArea noDataChart">
            <h3>
              {{ batteryTypeYear_Title }}
              {{ $t('chart.title.batteryTypeYear') }}
            </h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import DoughnutChart from '@/components/chart/DoughnutChart.vue';
  import BarChart from '@/components/chart/BarChart.vue';
  import HorizontalBarChart from '@/components/chart/HorizontalBarChart.vue';
  import LineChart from '@/components/chart/LineChart.vue';
  import UserBox from '@/components/chart/UserBox.vue';

  import {
    APITop4AccountPerDay,
    APIActiveUser,
    APITestResultPerMonth,
    APITestResultPerNMonth,
    APITotalTestResult,
    APIUsagePerDay,
    APIFwVersionPerDay,
    APITestTypePerQuarter,
    APIBatteryTypeMonth,
    APIBatteryTypeYear,
    APInewestUpdateTime,
    APIcheck,
    APIgetShopList,
  } from '../../api';

  export default {
    name: 'Home',
    components: {
      UserBox,
      DoughnutChart,
      BarChart,
      HorizontalBarChart,
      LineChart,
    },
    created() {
      // get shop list
      APIgetShopList()
        .then(res => {
          this.shopList = this.shopList.concat(
            res.data.shopList.map(e => {
              return { shopId: e, timezone: '', active: false, loading: false };
            })
          );
        })
        .then(() => {
          this.shopListTotalWidth =
            document.getElementById('groupList').offsetWidth;
          this.shopListShowWidth =
            document.getElementById('groupListBox').offsetWidth;
          // 如果total>show則代表要秀出箭頭讓使用者切換
          this.fnLoadDeviceAndDrawDashboard();
        })
        .catch(error => {
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            switch (error.response.data.error) {
              case 'unauthorized':
                this.$bvModal.show('unauthorizedErrorToggle');
                break;
              default:
                this.$bvModal.show('setsomethingWrong');
            }
          }
        });
    },
    data() {
      return {
        shopList: [
          { shopId: 'All', timezone: '', active: true, loading: true },
        ],
        shopListTotalWidth: null,
        shopListShowWidth: null,
        shopListShow: 0,
        loading: true,
        drawing: false,
        buttonArray: [],
        getFullMonth: [
          this.$t('chart.month.full.1'),
          this.$t('chart.month.full.2'),
          this.$t('chart.month.full.3'),
          this.$t('chart.month.full.4'),
          this.$t('chart.month.full.5'),
          this.$t('chart.month.full.6'),
          this.$t('chart.month.full.7'),
          this.$t('chart.month.full.8'),
          this.$t('chart.month.full.9'),
          this.$t('chart.month.full.10'),
          this.$t('chart.month.full.11'),
          this.$t('chart.month.full.12'),
        ],
        getShoutMonth: [
          this.$t('chart.month.short.1'),
          this.$t('chart.month.short.2'),
          this.$t('chart.month.short.3'),
          this.$t('chart.month.short.4'),
          this.$t('chart.month.short.5'),
          this.$t('chart.month.short.6'),
          this.$t('chart.month.short.7'),
          this.$t('chart.month.short.8'),
          this.$t('chart.month.short.9'),
          this.$t('chart.month.short.10'),
          this.$t('chart.month.short.11'),
          this.$t('chart.month.short.12'),
        ],
        getQuarter: ['Q1', 'Q2', 'Q3', 'Q4'],
        labelColor: [
          'rgba(46,139,87,1)',
          'rgba(255,167,63,1)',
          'rgba(255, 186, 102,1)',
          'rgba(255,214,0,1)',
          'rgba(235, 77, 71,1)',
          'rgba(217,30,24,1)',
        ],
        // ----
        userBoxRWD: {
          user: {
            new: {
              sm: '6',
              md: '4',
              xl: '2',
            },
            active: {
              sm: '6',
              md: '4',
              xl: '2',
            },
            total: {
              sm: '12',
              md: '4',
              xl: '2',
            },
          },
          shop: {
            new: {
              sm: '6',
              md: '4',
              xl: '2',
            },
            active: {
              sm: '6',
              md: '4',
              xl: '2',
            },
            total: {
              sm: '12',
              md: '4',
              xl: '2',
            },
            show: true,
          },
        },
        // ----
        chartPerMonthTitle: '',
        DATAtestResultByMonth: {}, // 1
        APItestResultByMonth: {},
        APIchartPerMonthErrorTitle: '',
        // ----
        DATAtotalTestResult: {}, // 2
        APItotalTestResult: {},
        // ----
        DATAtestResultByXMonth: {}, // 3
        APItestResultByXMonth: [],
        testResultByXMonth_Month: [],
        testResultByXMonth_Year: [],
        selectTimeType: 'month',
        selectTimePeriod: 6,
        // ----
        DATAversionByDay: {}, // 4
        APIversionByDay: {},
        versionByDay_Color: [
          'rgba(1,122,185,.8)',
          'rgba(102,175,213,.8)',
          'rgba(178,215,234,.8)',
        ],
        // ----
        DATAusageByDay: {}, // 5
        APIusageByDay: {},
        // ----
        DATAaccountByDay: {}, // 6
        APIaccountByDay: {},
        // ----
        DATAactiveUser: {},
        // ----
        DATAnewestUpdateTime: {},
        // -----
        testResultPerQuarter_Color: [
          'rgba(1,122,185,.8)',
          'rgba(51,148,199,.8)',
          'rgba(102,175,213,.8)',
          'rgba(153,201,227,.8)',
          'rgba(178,215,234,.8)',
          'rgba(204,228,241,.8)',
          'rgba(224,238,246,.55)',
        ],
        APItestResultPerQuarter: {},
        DATAtestType: {},
        APIchartPerQuarterErrorTitle: '',
        // -----
        APIbatteryTypeMonthResult: {},
        DATAbatteryTypeMonth: {},
        // -----
        APIbatteryTypeYearResult: {},
        DATAbatteryTypeYear: {},
        // -----
        NoDataUserToggle: true,
        NoDataPerMonthToggle: true,
        NoDataTotalToggle: true,
        NoDataNMonthToggle: true,
        NoDataFwToggle: true,
        NoDataPerDayToggle: true,
        NoDataTop4Toggle: true,
        DataTimeToggle: true,
        NoDataTestResultPerQuarter: true,
        NoDatabatteryTypeMonth: true,
        NoDatabatteryTypeYear: true,
        device: '',
      };
    },
    methods: {
      getButtonModelName(name) {
        switch (name.toLowerCase()) {
          case 'bt2400-napa':
            return 'st2400pro';
          default:
            return name;
        }
      },
      fnShopListNext() {
        // if(this.shopListTotalWidth<this.shopListShowWidth) return
        // let differ = this.shopListTotalWidth - this.shopListShowWidth;

        // let moveRange = this.shopListShow;
        // if(differ<=this.shopListShowWidth) moveRange-=Math.abs(differ);
        // if(differ>this.shopListShowWidth) moveRange-=Math.abs(this.shopListShowWidth);
        // document.getElementById('groupList').style.transform=`translateX(${moveRange}px)`;
        // this.shopListShow=moveRange;
        // this.shopListTotalWidth-=this.shopListShowWidth;

        if (this.shopListTotalWidth === 0) return;
        if (
          this.shopListTotalWidth - this.shopListShowWidth <
          this.shopListShowWidth
        ) {
          this.shopListShow += this.shopListTotalWidth - this.shopListShowWidth;
          this.shopListTotalWidth -=
            this.shopListTotalWidth - this.shopListShowWidth;
        } else {
          this.shopListShow += this.shopListShowWidth;
          this.shopListTotalWidth -= this.shopListShowWidth;
        }
        document.getElementById(
          'groupList'
        ).style.transform = `translateX(${-Math.abs(this.shopListShow)}px)`;
      },
      fnShopListPrev() {
        if (this.shopListShow === 0) return;

        if (this.shopListShow < this.shopListShowWidth) {
          this.shopListTotalWidth += this.shopListShow;
          this.shopListShow = 0;
        } else {
          this.shopListShow -= this.shopListShowWidth;
          this.shopListTotalWidth += this.shopListShowWidth;
        }

        // if(this.shopListTotalWidth-this.shopListShowWidth<this.shopListShowWidth) {
        //   this.shopListShow+=(this.shopListTotalWidth-this.shopListShowWidth );
        //   this.shopListTotalWidth-=(this.shopListTotalWidth-this.shopListShowWidth );
        // }else{
        //   this.shopListShow+=this.shopListShowWidth;
        //   this.shopListTotalWidth-=this.shopListShowWidth;
        // }
        document.getElementById(
          'groupList'
        ).style.transform = `translateX(${-Math.abs(this.shopListShow)}px)`;
      },
      fnLoadDeviceAndDrawDashboard(shopid, shopIndex) {
        this.loading = true;
        let propObject = {
          isDashboard: true,
        };
        // 如果有帶值，就把shopid放進request data中
        if (shopid) propObject.shopId = shopid.shopId;
        APIcheck(propObject)
          .then(res => {
            // 由於big data dashboard尚未整理出 btw300，所以先挑出來，不show按鈕
            let array = res.data.list.filter(el => {
              return el !== 'btw300-record-bt' && el !== 'btw300-record-st';
            });
            return array;
          })
          .then(res => {
            this.buttonArray = this.checkButtonArray(res);
          })
          .then(() => {
            // if click show tab => do not display show info
            let clickShopTab =
              shopid === undefined ? false : shopid.length !== 0;
            this.userBoxRwdToggle(clickShopTab);

            if (this.buttonArray.length === 0) {
              this.NoDataPerMonthToggle = false;
              this.NoDataTotalToggle = false;
              this.NoDataNMonthToggle = false;
              this.NoDataFwToggle = false;
              this.NoDataPerDayToggle = false;
              this.NoDataTop4Toggle = false;
              this.NoDataUserToggle = false;
              this.DataTimeToggle = false;
              this.NoDataTestResultPerQuarter = false;
              this.NoDatabatteryTypeMonth = false;
              this.NoDatabatteryTypeYear = false;
              this.loading = false;
              this.shopList.map(el => {
                if (shopid === undefined || shopid === '')
                  this.shopList[0].loading = false;
                if (shopid && el.shopId === shopid.shopId) el.loading = false;
              });
              return false;
            }
            this.device = this.buttonArray[0].name;

            // 第一次載入時的機種判斷，以陣列第一筆為第一次載入(尚未新增btw300)
            this.buttonArray[0].action = true;
            this.checkDevice(this.device, 0, shopid, shopIndex);
          })
          .catch(err => {
            if (err.response != undefined && err.response.status === 401) {
              this.$bvModal.show('setCheckToken');
            } else {
              this.$bvModal.show('setsomethingWrong');
            }
          });
      },
      fnBusinessChooseView(chooseIndex) {
        Object.keys(this.shopList).map(el => {
          this.shopList[el].active = false;
        });
        this.shopList[chooseIndex].active = true;
        this.shopList[chooseIndex].loading = true;

        // 當checkAPI request有值時就抓該值的資料
        let selectShopId = {
          shopId: this.shopList[chooseIndex].shopId,
        };
        // 當checkAPI request為空時就抓該token的資料
        if (this.shopList[chooseIndex].shopId === 'All') selectShopId = '';
        this.fnLoadDeviceAndDrawDashboard(selectShopId, chooseIndex);
      },
      checkButtonArray(buttonArray) {
        let array = [];
        const modelNameRegex = /([^]+)-record-[^]+/;
        buttonArray.map((el, index) => {
          let theNext = buttonArray[index + 1];
          const matchElModel = modelNameRegex.exec(el)[1];
          const matchTheNextModel = theNext
            ? modelNameRegex.exec(theNext)[1]
            : null;
          if (
            index === buttonArray.length - 1 ||
            matchElModel !== matchTheNextModel
          ) {
            array.push({
              name: matchElModel,
              loading: false,
              disabled: false,
              action: false,
            });
          }
        });
        return array;
      },
      fnDeviceChange(device, index) {
        this.buttonArray[index].loading = true;
        this.buttonArray.map(el => {
          el.disabled = true;
          el.action = false;
        });
        this.buttonArray[index].action = true;
        this.loading = true;

        let selectShopId = '';
        let selectShopIndex;
        this.shopList.map((el, index) => {
          if (el.active && el.shopId !== 'All') {
            selectShopIndex = index;
            selectShopId = {
              shopId: this.shopList[index].shopId,
            };
          }
        });
        this.checkDevice(device, index, selectShopId, selectShopIndex);
        this.device = device;
      },
      chartOpen() {
        this.NoDataPerMonthToggle = true;
        this.NoDataTotalToggle = true;
        this.NoDataNMonthToggle = true;
        this.NoDataFwToggle = true;
        this.NoDataPerDayToggle = true;
        this.NoDataTop4Toggle = true;
        this.NoDataUserToggle = true;
        this.DataTimeToggle = true;
        this.NoDataTestResultPerQuarter = true;
        this.NoDatabatteryTypeMonth = true;
        this.NoDatabatteryTypeYear = true;
      },

      checkDevice(device, index, shopid, shopIndex) {
        let chartAPI = null;
        let timeRange = {
          unit: this.selectTimeType,
          period: this.selectTimePeriod,
        };
        // 如果shopid有傳值進來再加入
        if (shopid) {
          timeRange.shopId = shopid.shopId;
        }
        chartAPI = Promise.all([
          APITestResultPerMonth(device, shopid), // 0
          APITotalTestResult(device, shopid), // 1
          APITestResultPerNMonth(device, timeRange), // 2
          APIFwVersionPerDay(device, shopid), // 3
          APIUsagePerDay(device, shopid), // 4
          APITop4AccountPerDay(device, shopid), // 5
          APIActiveUser(device, shopid), // 6
          APInewestUpdateTime(shopid), // 7
          APITestTypePerQuarter(device, shopid), // 8
          APIBatteryTypeMonth(device, shopid), // 9
          APIBatteryTypeYear(device, shopid), // 10
        ]);
        this.drawChart(chartAPI, index, shopIndex);
      },

      drawChart(device, index, shopIndex = 0) {
        if (device) {
          device
            .then(res => {
              this.chartOpen();
              if (res[0].data.error) {
                // 當月甜甜圈圖表區塊顯示
                this.APIchartPerMonthErrorTitle = res[0].data.month;
                this.NoDataPerMonthToggle = false;
              } else {
                this.APItestResultByMonth = res[0].data;
                // --------------------
                this.DATAtestResultByMonth = {
                  labels: this.labelItem,
                  datasets: [
                    {
                      backgroundColor: this.labelColor,
                      borderWidth: 0,
                      data: this.testResultByMonth_Array,
                    },
                  ],
                };
              }
              if (res[1].data.error) {
                // Total甜甜圈圖表區塊顯示
                this.NoDataTotalToggle = false;
              } else {
                this.APItotalTestResult = res[1].data;
                // --------------------
                this.DATAtotalTestResult = {
                  labels: this.labelItem,
                  datasets: [
                    {
                      backgroundColor: this.labelColor,
                      borderWidth: 0,
                      data: this.totalTestResult_Array,
                    },
                  ],
                };
              }
              if (res[2].data.error) {
                // 6個月圖表區塊顯示
                this.NoDataNMonthToggle = false;
              } else {
                this.APItestResultByXMonth = res[2].data;

                this.testResultByXMonth_Month = [];
                this.testResultByXMonth_Year = [];

                // XMonth month
                this.APItestResultByXMonth.forEach((el, index) => {
                  if (index === 0) {
                    return;
                  } else {
                    if (this.selectTimeType === 'month') {
                      this.testResultByXMonth_Month.push(el.month);
                    } else if (this.selectTimeType === 'quarter') {
                      this.testResultByXMonth_Month.push(el.quarter);
                    }
                    if (el.year) {
                      this.testResultByXMonth_Year.push(el.year);
                    } else {
                      this.testResultByXMonth_Year.push('');
                    }
                  }
                });

                if (this.selectTimeType === 'month') {
                  this.testResultByXMonth_Month =
                    this.testResultByXMonth_Month.map(el => {
                      return [this.getShoutMonth[el - 1]];
                    });
                } else if (this.selectTimeType === 'quarter') {
                  this.testResultByXMonth_Month =
                    this.testResultByXMonth_Month.map(el => {
                      return [this.getQuarter[el - 1]];
                    });
                }

                this.testResultByXMonth_Month.map((el, index) => {
                  el.push(this.testResultByXMonth_Year[index]);
                });

                // --------------------
                this.DATAtestResultByXMonth = {
                  labels: this.testResultByXMonth_Month,
                  datasets: [
                    {
                      borderColor: 'rgb(0, 173, 57)',
                      label: this.$t('chart.label.gd'),
                      data: this.testResultByXMonth_Good,
                      fill: false,
                    },
                    {
                      borderColor: 'rgb(255, 77, 77)',
                      label: this.$t('chart.label.bd'),
                      data: this.testResultByXMonth_Bad,
                      fill: false,
                    },
                    {
                      borderColor: 'rgb(243, 209, 124)',
                      label: this.$t('chart.label.oth'),
                      data: this.testResultByXMonth_Other,
                      fill: false,
                    },
                    {
                      borderColor: 'white',
                      label: this.$t('chart.label.total'),
                      data: this.testResultByXMonth_Total,
                      fill: false,
                    },
                  ],
                };
              }
              if (res[3].data.error) {
                // 版本圖表區塊顯示
                this.NoDataFwToggle = false;
              } else {
                this.APIversionByDay = res[3].data;
                // firmware
                // --------------------
                this.DATAversionByDay = {
                  datasets: this.versionByDay_List,
                  labels: 'firmware',
                };
              }
              if (res[4].data.error) {
                // 當日時段圖表區塊顯示
                this.NoDataPerDayToggle = false;
              } else {
                this.APIusageByDay = res[4].data;
                // --------------------
                this.DATAusageByDay = {
                  datasets: [
                    {
                      backgroundColor: [
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                      ],
                      data: this.APIusageByDay.usage,
                    },
                  ],
                  labels: [
                    '00-02',
                    '02-04',
                    '04-06',
                    '06-08',
                    '08-10',
                    '10-12',
                    '12-14',
                    '14-16',
                    '16-18',
                    '18-20',
                    '20-22',
                    '22-00',
                  ],
                };
              }
              if (res[5].data.error) {
                // 前4名圖表區塊顯示
                this.NoDataTop4Toggle = false;
              } else {
                // account by day
                this.APIaccountByDay = res[5].data;
                // --------------------
                this.DATAaccountByDay = {
                  labels: this.accountByDay_Array,
                  datasets: [
                    {
                      backgroundColor: [
                        'rgba(238,238,238,.6)',
                        'rgba(238,238,238,.6)',
                        'rgba(238,238,238,.6)',
                        'rgba(238,238,238,.6)',
                        'rgba(238,238,238,.6)',
                      ],
                      data: this.accountByDay_Data,
                      barPercentage: 0.7,
                    },
                  ],
                };
              }
              if (res[6].data.error) {
                // userbox圖表區塊顯示
                this.NoDataUserToggle = false;
              } else {
                this.DATAactiveUser = res[6].data;
              }
              if (res[7].data.error) {
                // time區塊顯示
                this.DataTimeToggle = false;
              } else {
                this.DATAnewestUpdateTime = res[7].data;
                this.shopList[shopIndex].timezone = res[7].data.timezone;
              }
              if (res[8].data.error) {
                this.NoDataTestResultPerQuarter = false;
                this.APIchartPerQuarterErrorTitle =
                  res[8].data.year + ' Q' + res[8].data.quarter;
              } else {
                // test result per quarter
                this.APItestResultPerQuarter = res[8].data;

                this.DATAtestType = {
                  labels: this.testResultPerQuarter_List,
                  datasets: [
                    {
                      backgroundColor: this.testResultPerQuarter_Color,
                      borderWidth: 0,
                      data: this.testResultPerQuarter_Data,
                    },
                  ],
                };
              }
              if (res[9].data.error) {
                this.NoDatabatteryTypeMonth = false;
              } else {
                this.APIbatteryTypeMonthResult = res[9].data;

                // -----new-----
                this.DATAbatteryTypeMonth = {
                  datasets: [
                    {
                      label: this.batteryTypeMonth_Title[0],
                      backgroundColor: 'rgba(153,201,227,.8)',
                      data: this.batteryTypeLastMonth_Data,
                    },
                    {
                      label: this.batteryTypeMonth_Title[1],
                      backgroundColor: 'rgba(1,122,185,.8)',
                      data: this.batteryTypeNowMonth_Data,
                    },
                  ],
                  labels: this.batteryTypeMonth_List,
                };
              }
              if (res[10].data.error) {
                this.NoDatabatteryTypeYear = false;
              } else {
                this.APIbatteryTypeYearResult = res[10].data;
                this.DATAbatteryTypeYear = {
                  labels: this.batteryTypeYear_List,
                  datasets: [
                    {
                      backgroundColor: this.testResultPerQuarter_Color,
                      borderWidth: 0,
                      data: this.batteryTypeYear_Data,
                    },
                  ],
                };
              }
            })
            .then(() => {
              if (index || index === 0) {
                this.buttonArray[index].loading = false;
                this.buttonArray.map(el => {
                  el.disabled = false;
                });
              }
            })
            .catch(err => {
              if (err.response != undefined && err.response.status === 401) {
                this.$bvModal.show('setCheckToken');
              } else {
                this.$bvModal.show('setsomethingWrong');
              }
            })
            .then(() => {
              this.loading = false;
              Object.keys(this.shopList).map(
                el => (this.shopList[el].loading = false)
              );
            });
        } else {
          // chartAPI === false 未綁定任何機台
          this.NoDataPerMonthToggle = false;
          this.NoDataTotalToggle = false;
          this.NoDataNMonthToggle = false;
          this.NoDataFwToggle = false;
          this.NoDataPerDayToggle = false;
          this.NoDataTop4Toggle = false;
          this.NoDataUserToggle = false;
          this.DataTimeToggle = false;
          this.NoDataTestResultPerQuarter = false;
          this.NoDatabatteryTypeMonth = false;
          this.NoDatabatteryTypeYear = false;
          return;
        }
      },

      fnChangeTimeType() {
        if (this.selectTimeType === 'month') {
          this.selectTimePeriod = 6;
        } else {
          this.selectTimePeriod = 4;
        }
        this.reDrawMonthlyChart();
      },

      reDrawMonthlyChart() {
        this.loading = true;
        this.drawing = true;

        let shopId;
        this.shopList.map((el, index) => {
          if (el.active && el.shopId !== 'All') {
            shopId = this.shopList[index].shopId;
          }
        });

        let data = {
          unit: this.selectTimeType,
          period: this.selectTimePeriod,
          shopId,
        };

        let promise = null;
        let device = '';
        this.buttonArray.map(el => {
          if (el.action) {
            device = el.name;
          }
        });
        promise = APITestResultPerNMonth(device, data);
        promise
          .then(res => {
            if (res.data.error) {
              // 6個月圖表區塊顯示
              this.NoDataNMonthToggle = false;
            } else {
              this.APItestResultByXMonth = res.data;

              this.testResultByXMonth_Month = [];
              this.testResultByXMonth_Year = [];

              // XMonth month
              this.APItestResultByXMonth.forEach((el, index) => {
                if (index === 0) {
                  return;
                } else {
                  if (this.selectTimeType === 'month') {
                    this.testResultByXMonth_Month.push(el.month);
                  } else if (this.selectTimeType === 'quarter') {
                    this.testResultByXMonth_Month.push(el.quarter);
                  }
                }

                if (el.year) {
                  this.testResultByXMonth_Year.push(el.year);
                } else {
                  this.testResultByXMonth_Year.push('');
                }
              });
              if (this.selectTimeType === 'month') {
                this.testResultByXMonth_Month =
                  this.testResultByXMonth_Month.map(el => {
                    return [this.getShoutMonth[el - 1]];
                  });
              } else if (this.selectTimeType === 'quarter') {
                this.testResultByXMonth_Month =
                  this.testResultByXMonth_Month.map(el => {
                    return [this.getQuarter[el - 1]];
                  });
              }

              this.testResultByXMonth_Month.map((el, index) => {
                el.push(this.testResultByXMonth_Year[index]);
              });

              // --------------------
              this.DATAtestResultByXMonth = {
                labels: this.testResultByXMonth_Month,
                datasets: [
                  {
                    borderColor: 'rgb(0, 173, 57)',
                    label: this.$t('chart.label.gd'),
                    data: this.testResultByXMonth_Good,
                    fill: false,
                  },
                  {
                    borderColor: 'rgb(255, 77, 77)',
                    label: this.$t('chart.label.bd'),
                    data: this.testResultByXMonth_Bad,
                    fill: false,
                  },
                  {
                    borderColor: 'rgb(243, 209, 124)',
                    label: this.$t('chart.label.oth'),
                    data: this.testResultByXMonth_Other,
                    fill: false,
                  },
                  {
                    borderColor: 'white',
                    label: this.$t('chart.label.total'),
                    data: this.testResultByXMonth_Total,
                    fill: false,
                  },
                ],
              };
            }
          })
          .catch(err => {
            if (err.response != undefined && err.response.status === 401) {
              this.$bvModal.show('setCheckToken');
            } else {
              this.$bvModal.show('setsomethingWrong');
            }
          })
          .then(() => {
            this.loading = false;
            this.drawing = false;
          });
      },

      userBoxRwdToggle(isShop = false) {
        if (isShop) {
          this.userBoxRWD.user.new.sm = '12';
          this.userBoxRWD.user.new.md = '6';
          this.userBoxRWD.user.new.xl = '4';
          this.userBoxRWD.user.active.sm = '12';
          this.userBoxRWD.user.active.md = '6';
          this.userBoxRWD.user.active.xl = '4';
          this.userBoxRWD.user.total.sm = '12';
          this.userBoxRWD.user.total.md = '12';
          this.userBoxRWD.user.total.xl = '4';
          this.userBoxRWD.shop.show = false;
        } else {
          this.userBoxRWD.user.new.sm = '6';
          this.userBoxRWD.user.new.md = '4';
          this.userBoxRWD.user.new.xl = '2';
          this.userBoxRWD.user.active.sm = '6';
          this.userBoxRWD.user.active.md = '4';
          this.userBoxRWD.user.active.xl = '2';
          this.userBoxRWD.user.total.sm = '12';
          this.userBoxRWD.user.total.md = '4';
          this.userBoxRWD.user.total.xl = '2';
          this.userBoxRWD.shop.show = true;
        }
      },
    },
    computed: {
      vxBusinessID() {
        return this.$store.state.accountInformation.companyId;
      },
      vxTimezone() {
        return this.$store.state.accountInformation.timezone;
      },
      dashboardTitle() {
        let string = `${this.vxBusinessID} ` + this.$t('chart.list.business');
        if (this.vxBusinessID === undefined)
          string = this.$t('chart.list.business');
        if (!this.shopList[0].active) {
          this.shopList.map(el => {
            if (el.active) {
              string = `${el.shopId} ` + this.$t('chart.list.shop');
            }
          });
        }
        return string;
      },
      dashboardTimezone() {
        let string = '';
        this.shopList.map(el => {
          if (el.active) {
            string = el.timezone;
          }
        });
        if (string === undefined || string === '') {
          return '';
        }
        return string;
      },
      labelItem() {
        if (this.device === 'ba427plus') {
          return [
            this.$t('chart.label.gp'),
            this.$t('chart.label.gr'),
            this.$t('chart.label.rnr'),
            this.$t('chart.label.ca'),
            this.$t('chart.label.br'),
            this.$t('chart.label.bcr'),
          ];
        } else if (this.device.includes('napa')) {
          return [
            this.$t('chart.label.gp'),
            this.$t('chart.label.gr'),
            this.$t('chart.label.rr'),
            this.$t('chart.label.marginal'),
            this.$t('chart.label.br'),
            this.$t('chart.label.bcr'),
          ];
        } else {
          return [
            this.$t('chart.label.gp'),
            this.$t('chart.label.gr'),
            this.$t('chart.label.rr'),
            this.$t('chart.label.ca'),
            this.$t('chart.label.br'),
            this.$t('chart.label.bcr'),
          ];
        }
      },
      // --------------------
      testResultByMonth_Title() {
        if (this.APItestResultByMonth.month) {
          return this.getFullMonth[this.APItestResultByMonth.month - 1];
        } else {
          return this.$t('chart.title.month');
        }
      },
      testResultByMonth_ErrorTitle() {
        if (this.APIchartPerMonthErrorTitle) {
          return this.getFullMonth[this.APIchartPerMonthErrorTitle - 1];
        } else {
          return this.$t('chart.title.month');
        }
      },
      testResultByMonth_Array() {
        if (this.APItestResultByMonth.testResult === undefined) {
          return;
        }
        return [
          parseInt(this.APItestResultByMonth.testResult.goodAndPass, 10),
          parseInt(this.APItestResultByMonth.testResult.goodAndRecharge, 10),
          parseInt(this.APItestResultByMonth.testResult.rechargeAndRetest, 10),
          parseInt(this.APItestResultByMonth.testResult.caution, 10),
          parseInt(this.APItestResultByMonth.testResult.badAndReplace, 10),
          parseInt(this.APItestResultByMonth.testResult.badCellReplace, 10),
        ];
      },
      testResultByMonth_Total() {
        if (this.testResultByMonth_Array === undefined) {
          return;
        }
        return this.testResultByMonth_Array.reduce((sum, cur) => (sum += cur));
      },
      testResultByMonthPercen() {
        if (this.APItestResultByMonth.testResult === undefined) {
          return;
        }
        let total = 0;
        total =
          ((parseInt(this.APItestResultByMonth.testResult.caution, 10) +
            parseInt(this.APItestResultByMonth.testResult.badAndReplace, 10) +
            parseInt(this.APItestResultByMonth.testResult.badCellReplace, 10)) /
            this.testResultByMonth_Total) *
          100;
        total = total.toFixed(0);
        return total;
      },
      // -------------------
      totalTestResult_Label() {
        let array = [];
        if (this.APItotalTestResult.testResult) {
          Object.keys(this.APItotalTestResult.testResult).forEach(el => {
            if (this.device === 'ba427plus') {
              if (el !== 'caution') {
                array.push(el);
              }
            } else {
              array.push(el);
            }
          });
        }
        return array;
      },
      totalTestResult_Array() {
        if (this.APItotalTestResult.testResult === undefined) {
          return;
        }
        return [
          parseInt(this.APItotalTestResult.testResult.goodAndPass, 10),
          parseInt(this.APItotalTestResult.testResult.goodAndRecharge, 10),
          parseInt(this.APItotalTestResult.testResult.rechargeAndRetest, 10),
          parseInt(this.APItotalTestResult.testResult.caution, 10),
          parseInt(this.APItotalTestResult.testResult.badAndReplace, 10),
          parseInt(this.APItotalTestResult.testResult.badCellReplace, 10),
        ];
      },
      totalTestResult_Total() {
        if (this.totalTestResult_Array === undefined) {
          return;
        }
        let total = this.totalTestResult_Array.reduce(
          (sum, cur) => (sum += cur)
        );
        let unit = ['', 'k', 'M', 'G', 'T'];
        let unitIndex = 0;
        while (total > 1000) {
          unitIndex++;
          total = total / 1000;
        }
        return `${Math.floor(total * 100) / 100}${unit[unitIndex]}`;
      },
      labelResult() {
        if (!this.DATAtotalTestResult.labels) {
          return;
        }
        let array = [];

        this.totalTestResult_Label.map((el, index) => {
          let obj = {};
          if (this.device === 'ba427plus' && index >= 3) {
            obj = {
              label: this.$t('chart.label.' + el),
              color:
                this.DATAtotalTestResult.datasets[0].backgroundColor[index + 1],
            };
          } else if (this.device === 'ba427plus' && index >= 2) {
            obj = {
              label: this.$t('chart.label.rechargeRetest'),
              color:
                this.DATAtotalTestResult.datasets[0].backgroundColor[index],
            };
          } else if (this.device.includes('bt2400-napa')) {
            obj = {
              label:
                el === 'caution'
                  ? this.$t('chart.label.marginal')
                  : this.$t('chart.label.' + el),
              color:
                this.DATAtotalTestResult.datasets[0].backgroundColor[index],
            };
          } else {
            obj = {
              label: this.$t('chart.label.' + el),
              color:
                this.DATAtotalTestResult.datasets[0].backgroundColor[index],
            };
          }
          array.push(obj);
        });
        return array;
      },
      // -------------------
      testResultByXMonth_Good() {
        let array = [];
        this.APItestResultByXMonth.forEach((el, index) => {
          if (index > 0) {
            array.push(el.good);
          }
        });
        return array;
      },
      testResultByXMonth_Bad() {
        let array = [];
        this.APItestResultByXMonth.forEach((el, index) => {
          if (index > 0) {
            array.push(el.bad);
          }
        });
        return array;
      },
      testResultByXMonth_Other() {
        let array = [];
        this.APItestResultByXMonth.forEach((el, index) => {
          if (index > 0) {
            array.push(el.other);
          }
        });
        return array;
      },
      testResultByXMonth_Total() {
        let array = [];
        this.APItestResultByXMonth.forEach((el, index) => {
          if (index > 0) {
            array.push(el.good + el.bad + el.other);
          }
        });
        return array;
      },
      toolTipsArray() {
        let array = [];
        if (this.APItestResultByXMonth.length === 0) return array;
        this.APItestResultByXMonth.map(el => {
          array.push([el.good, el.bad, el.other, el.good + el.bad + el.other]);
        });
        return array;
      },
      toolTipsArrayPercen() {
        if (this.toolTipsArray.length === 0) return [];
        let array = [];
        this.toolTipsArray.map((el, index) => {
          if (index > 0) {
            let innerArray = [];
            for (let i = 0; i < el.length; i++) {
              innerArray.push(
                this.toolTipsArray[index][i] - this.toolTipsArray[index - 1][i]
              );
            }
            array.push(innerArray);
          }
        });
        return array;
      },
      timePeriod() {
        if (this.selectTimeType === 'month') {
          let array = [];
          for (let i = 6; i <= 24; i++) {
            array.push(i);
          }
          return array;
        } else {
          let array = [];
          for (let i = 4; i <= 8; i++) {
            array.push(i);
          }
          return array;
        }
      },
      // -------------------
      versionByDay_List() {
        let array = [];
        this.APIversionByDay.list.map((el, index) => {
          array.push({
            label: el.version,
            data: [el.count],
            backgroundColor: this.versionByDay_Color[index],
            barPercentage: 0.8,
          });
        });
        return array;
      },
      firmwareLabel() {
        if (!this.DATAversionByDay.datasets) {
          return;
        }
        let array = [];
        let total = 0;
        let percen = 0;
        this.DATAversionByDay.datasets.map((el, index) => {
          let obj = {};
          // Firmware版本最多只顯示三個，數量大於三時，以Other顯示
          if (index >= 3) {
            total = total + el.data[0];
            percen = percen + this.firmwarePercen[index] * 1;
            percen = percen.toFixed(1) * 1;
            if (index === this.DATAversionByDay.datasets.length - 1) {
              obj = {
                name: this.$t('chart.label.oth'),
                num: total,
                percen,
                color: 'transparent',
              };
              array.push(obj);
            }
          }
          if (index < 3) {
            obj = {
              name: el.label,
              num: el.data[0],
              percen: this.firmwarePercen[index],
              color: el.backgroundColor,
            };
            array.push(obj);
          }
        });
        return array;
      },
      firmwarePercen() {
        if (!this.DATAversionByDay.datasets) {
          return;
        }
        let array = [];
        let total = 0;
        this.DATAversionByDay.datasets.map(el => {
          total = total + el.data[0];
        });
        this.DATAversionByDay.datasets.map(el => {
          array.push(((el.data[0] / total) * 100).toFixed(1));
        });
        return array;
      },
      // -------------------
      usageByDay_Avg() {
        if (this.APIusageByDay.usage === undefined) return 0;
        let avg = Math.ceil(
          this.APIusageByDay.usage.reduce((sum, current) => (sum += current)) /
            this.APIusageByDay.usage.length
        );
        this.APIusageByDay.usage.map((el, index) => {
          if (el > avg) {
            this.DATAusageByDay.datasets[0].backgroundColor[index] =
              'rgba(238,238,238,.6)';
          }
        });
        return avg;
      },
      // -------------------
      accountByDay_Array() {
        return this.APIaccountByDay.list.map(el => {
          let dataName = el.account;
          // account (email address)
          if (dataName.includes('@')) {
            let dataArray = [];
            let dataMail = dataName.substring(dataName.indexOf('@'));
            let accountName = dataName.replace(
              dataName.substring(dataName.indexOf('@')),
              ''
            );
            dataArray.push(accountName, dataMail);
            return dataArray;
          }
          // shop id
          else {
            return dataName.length < 13
              ? dataName
              : [dataName.substring(0, 10), dataName.substring(10)];
          }
        });
      },
      accountByDay_Data() {
        let array = [];
        this.APIaccountByDay.list.map(el => {
          array.push(el.count);
        });
        return array;
      },
      accountByDay_yAxes() {
        let string = this.$t('chart.label.shop');
        if (this.shopList.length > 0) {
          Object.keys(this.shopList).map(el => {
            if (this.shopList[el].active)
              string = this.$t('chart.label.account');
            if (this.shopList[0].active) string = this.$t('chart.label.shop');
          });
        }
        return string;
      },
      accountByDay_title() {
        let string = this.$t('chart.title.top5Shop');
        if (this.shopList.length > 0) {
          Object.keys(this.shopList).map(el => {
            if (this.shopList[el].active)
              string = this.$t('chart.title.top5Account');
            if (this.shopList[0].active)
              string = this.$t('chart.title.top5Shop');
          });
        }
        return string;
      },
      // -------------------
      testResultPerQuarter_Title() {
        if (this.APItestResultPerQuarter.year === undefined) {
          return this.$t('chart.title.testType');
        }
        return (
          this.APItestResultPerQuarter.year +
          ' Q' +
          this.APItestResultPerQuarter.quarter +
          ' ' +
          this.$t('chart.title.testType')
        );
      },
      testResultPerQuarter_Data() {
        let object = this.APItestResultPerQuarter.testType;
        let array = [];
        for (const key in object) {
          array.push(object[key]);
        }
        return array;
      },
      testResultPerQuarter_Label() {
        let object = this.APItestResultPerQuarter.testType;
        let array = [];
        let index = 0;
        for (const key in object) {
          let obj = {
            label: this.$t('chart.testType.' + key),
            color: this.testResultPerQuarter_Color[index],
          };
          index++;
          array.push(obj);
        }
        return array;
      },
      testResultPerQuarter_List() {
        let object = this.APItestResultPerQuarter.testType;
        let array = [];
        for (const key in object) {
          array.push(this.$t(`chart.testType.${key}`));
        }
        return array;
      },
      // -------------------
      batteryTypeNowMonth_Data() {
        let object = this.APIbatteryTypeMonthResult[1];
        let array = [];
        for (const key in object) {
          if (key !== 'month') {
            array.push(object[key]);
          }
        }
        return array;
      },
      batteryTypeLastMonth_Data() {
        let object = this.APIbatteryTypeMonthResult[0];
        let array = [];
        for (const key in object) {
          if (key !== 'month') {
            array.push(object[key]);
          }
        }
        return array;
      },
      batteryTypeMonth_List() {
        let object = this.APIbatteryTypeMonthResult[0];
        let array = [];
        for (const key in object) {
          if (key !== 'month') {
            array.push(this.$t('chart.batteryType.' + key));
          }
        }
        return array;
      },
      batteryTypeMonth_Title() {
        let object = this.APIbatteryTypeMonthResult[0];
        let array = [];
        for (const key in object) {
          if (key === 'month') {
            array.push(object[key]);
          }
        }
        object = this.APIbatteryTypeMonthResult[1];
        for (const key in object) {
          if (key === 'month') {
            array.push(object[key]);
          }
        }
        // return array;
        let title = array.map(el => {
          return this.getFullMonth[el - 1];
        });
        return title;
      },
      // -------------------
      batteryTypeYear_List() {
        let object = this.APIbatteryTypeYearResult;
        let array = [];
        for (const key in object) {
          if (key !== 'year') {
            array.push(this.$t('chart.batteryType.' + key));
          }
        }
        return array;
      },
      batteryTypeYear_Data() {
        let object = this.APIbatteryTypeYearResult;
        let array = [];
        for (const key in object) {
          if (key !== 'year') {
            array.push(object[key]);
          }
        }
        return array;
      },
      batteryTypeYear_Popular() {
        let max = Math.max.apply(null, this.batteryTypeYear_Data);
        let index = this.batteryTypeYear_Data.indexOf(max);
        return this.batteryTypeYear_List[index];
      },
      batteryTypeYear_Title() {
        return this.APIbatteryTypeYearResult.year;
      },
      batteryTypeYear_Label() {
        let object = this.APIbatteryTypeYearResult;
        let array = [];
        let index = 0;
        for (const key in object) {
          if (key !== 'year') {
            let obj = {
              label: this.$t('chart.batteryType.' + key),
              color: this.testResultPerQuarter_Color[index],
            };
            index++;
            array.push(obj);
          }
        }
        return array;
      },
      // -------------------
      newestUpdateTime() {
        let updateTime = new Date(this.DATAnewestUpdateTime.time);
        updateTime = new Date(updateTime).getTime();

        let nowTime = Date.now();
        let timeAgo = (nowTime - updateTime) / 1000;

        let hour = Math.floor(timeAgo / 3600);
        let minute = Math.floor(timeAgo % 3600) / 60;

        if (!updateTime) {
          return;
        } else {
          return `${this.$t('home.updated')} : ${hour} ${this.$t(
            'home.hour'
          )} ${Math.floor(minute)} ${this.$t('home.minute')}`;
        }
      },
      // -------------------
    },
  };
</script>
