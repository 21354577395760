<template>
  <div>
    <formFormat
      :page="page"
      :formSelect="formSelect"
      :loading="loading"
      :error.sync="errorMessage"
      @submit="showEmitValue"
      data-test="formFormat"
    ></formFormat>
    <!-------------- modal area --------------->
    <!-- 通知使用者會在隔天後更新時區設定 -->
    <b-modal
      id="updateTimezoneSuccess"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$t('modal.success')"
    >
      <h6>{{ $t("modal.setTimezone.success.text1") }}</h6>
      <h6>{{ $t("modal.setTimezone.success.text2") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="close($router.push('/home'))"
        >
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!------------------------------------------->
  </div>
</template>

<script>
import formFormat from "@/components/FormFormat.vue";
import { APIupdateGroupTimezone, APIcheckVaildAndRefresh } from "../api";

export default {
  components: {
    formFormat,
  },
  data() {
    return {
      page: "setTimezone",
      loading: false,
      errorMessage: {
        RegPassword: false,
        confirmPassword: false,
      },
      formSelect: [
        {
          id: "timezone",
          title: "formFormat.selectTimezone",
        },
      ],
    };
  },
  methods: {
    async showEmitValue(inputValue) {
      try {
        this.loading = true;
        let requestData = {};
        this.formSelect.map((el, i) => {
          requestData[el.id] = inputValue.select[i];
        });
        const { data: APIdata } = await APIupdateGroupTimezone(requestData);
        if (APIdata.result === 1) {
          this.loading = false;
          this.$bvModal.show("updateTimezoneSuccess");
          this.updateAccountInformation();
        }
      } catch (error) {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.$bvModal.show("setCheckToken");
        } else {
          switch (error.response.data.error) {
            case "invalid action":
            default:
              this.$bvModal.show("setsomethingWrong");
          }
        }
      }
    },
    async updateAccountInformation() {
      try {
        const { data: APIdata } = await APIcheckVaildAndRefresh();
        if (APIdata.result === 1) {
          window.localStorage.setItem("token", "bearer " + APIdata.token);
          this.$store.dispatch("setAccount", APIdata.account);
          this.$store.dispatch("setAccountInformation", {
            account: APIdata.account,
            accountLevel: APIdata.accountLevel,
            companyId: APIdata.companyId,
            shopId: APIdata.shopId,
            pricingPlan: APIdata.pricingPlan,
            isShopBefore: APIdata.isShopBefore,
            isCompanyBefore: APIdata.isCompanyBefore,
            timezone: APIdata.timezone,
          });
        }
      } catch (error) {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.$bvModal.show("setCheckToken");
        } else {
          switch (error.response.data.error) {
            default:
              this.$bvModal.show("setsomethingWrong");
          }
        }
      }
    },
  },
};
</script>
