<template>
  <div class="frame">
    <div class="alertBox" v-if="showAlertMessage">
      <i slot="text" class="fas fa-info-circle info"></i>
      <p
        v-if="
          (vxAccountLevel === vxConstantsAccountLevel.shop &&
            !vxAccountCompanyId) ||
          vxAccountLevel === vxConstantsAccountLevel.business
        "
      >
        {{ $t('invalidError.subscribe.haveGroup') }}
      </p>
      <p v-else>{{ $t('invalidError.subscribe.inAnyGruop') }}</p>
    </div>
    <div class="formHeader">
      <h1>{{ $t('subscribePlan.title') }}</h1>
      <p>{{ $t('subscribePlan.subtitle') }}</p>
    </div>
    <div class="wrap subscribePage">
      <div v-show="sizeOfPlan !== 0" class="left" @click="fnGoLeft">
        <i class="fas fa-chevron-left"></i>
      </div>
      <div class="planCover">
        <div class="planBody">
          <!-- basic -->
          <b-col class="planBox" id="basicUser">
            <p
              class="submit"
              v-show="vxAccountPlan === vxConstantsDataPlan.basic"
            >
              {{ $t('subscribePlan.subscribed') }}
            </p>
            <div class="planInnerBox">
              <h6>{{ $t('subscribePlan.basic.title') }}</h6>
              <div class="priceBox">
                <div class="basicPriceFixIE price">
                  {{ $t('subscribePlan.basic.price') }}
                </div>
              </div>
              <div
                v-if="
                  (vxShopBefore && !vxAccountCompanyId) ||
                  vxBusinessBefore ||
                  (!vxAccountShopId && !vxAccountCompanyId)
                "
              >
                <m-button
                  @click="showPlanModal('Basic')"
                  :disabled="vxAccountPlan === vxConstantsDataPlan.basic"
                  class="tableButton"
                  :class="
                    vxAccountPlan !== vxConstantsDataPlan.basic ? 'cancel' : ''
                  "
                  raised
                  :outlined="vxAccountPlan === vxConstantsDataPlan.basic"
                >
                  <p>
                    {{ $t('button.subscribe') }}
                  </p>
                </m-button>
              </div>
              <hr />
              <div id="basicUserContent" class="planContent">
                <ul>
                  <li>{{ $t('subscribePlan.basic.text1') }}</li>
                  <li>{{ $t('subscribePlan.basic.text2') }}</li>
                  <li>{{ $t('subscribePlan.basic.text3') }}</li>
                </ul>
              </div>
              <div @click="fnBasicDrop" class="dropBox">
                <i id="basicDropdown" class="fas fa-chevron-right"></i>
              </div>
              <hr />
              <div class="planFooter">
                <ul class="iconGroup">
                  <li class="planIcon">
                    <i class="fas fa-table"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text2') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-chart-pie"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text3') }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <!-- shop -->
          <b-col class="planBox" id="proUser">
            <p
              class="submit"
              v-show="vxAccountPlan === vxConstantsDataPlan.shop"
            >
              {{ $t('subscribePlan.subscribed') }}
            </p>
            <div class="planInnerBox">
              <h6>{{ $t('subscribePlan.pro.title') }}</h6>
              <div class="priceBox">
                <div class="price">
                  {{ proDefaultPrice }}
                  {{ $t('subscribePlan.pro.priceSubTitle') }}
                </div>
                <div class="default mb20">
                  ({{ $t('subscribePlan.pro.defaultManage') }}
                  {{ proGroupProple }} {{ $t('subscribePlan.pro.memberUnit') }})
                </div>
                <div class="bonus">{{ $t('subscribePlan.pro.extra') }}</div>
                <div class="default">
                  {{ $t('subscribePlan.pro.limit') }}
                </div>
              </div>
              <div
                v-if="
                  (vxShopBefore && !vxAccountCompanyId) ||
                  vxBusinessBefore ||
                  (!vxAccountShopId && !vxAccountCompanyId)
                "
              >
                <div v-if="vxShopBefore">
                  <m-button
                    @click="startNewGroupFlow('Pro')"
                    class="tableButton cancel"
                    raised
                  >
                    <p>
                      {{ $t('button.createNew') }}
                    </p>
                  </m-button>
                  <m-button
                    @click="restoreGroupFlow('Pro')"
                    class="tableButton cancel"
                    raised
                    :disabled="loading"
                    :outlined="loading"
                  >
                    <p v-if="loading">
                      <b-spinner
                        small
                        label="Loading..."
                        :variant="'light'"
                        class="buttonLoad"
                      ></b-spinner>
                    </p>
                    <p v-else>
                      {{ $t('button.restore') }}
                    </p>
                  </m-button>
                </div>
                <div v-else>
                  <m-button
                    @click="subscribeFlow('Pro')"
                    class="tableButton cancel"
                    raised
                    :outlined="
                      vxAccountLevel === vxConstantsAccountLevel.shop ||
                      vxBusinessBefore
                    "
                    :disabled="
                      vxAccountLevel === vxConstantsAccountLevel.shop ||
                      vxBusinessBefore
                    "
                  >
                    <p>
                      {{ $t('button.subscribe') }}
                    </p>
                  </m-button>
                </div>
              </div>
              <hr />
              <div id="proUserContent" class="planContent">
                <ul>
                  <li>{{ $t('subscribePlan.pro.priceRule.text1') }}</li>
                  <li>{{ $t('subscribePlan.pro.priceRule.text2') }}</li>
                  <li>{{ $t('subscribePlan.pro.priceRule.text3') }}</li>
                  <li>{{ $t('subscribePlan.pro.priceRule.text4') }}</li>
                  <li>{{ $t('subscribePlan.pro.priceRule.text5') }}</li>
                  <li>{{ $t('subscribePlan.pro.priceRule.text6') }}</li>
                </ul>
              </div>
              <div @click="fnProDrop" class="dropBox">
                <i id="proDropdown" class="fas fa-chevron-right"></i>
              </div>
              <hr />
              <div class="planFooter">
                <ul class="iconGroup">
                  <li class="planIcon">
                    <i class="fas fa-print"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text1') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-table"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text2') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-chart-pie"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text3') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="far fa-chart-bar"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text4') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-user-friends"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text5') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-users-cog"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text6') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-database"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text7') }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <!-- business -->
          <b-col class="planBox" id="enterpriseUser">
            <p
              class="submit"
              v-show="vxAccountPlan === vxConstantsDataPlan.business"
            >
              {{ $t('subscribePlan.subscribed') }}
            </p>

            <div class="planInnerBox">
              <h6>{{ $t('subscribePlan.enterprise.title') }}</h6>
              <div class="priceBox">
                <div class="price">
                  {{ entDefaultPrice }}
                  {{ $t('subscribePlan.enterprise.priceSubTitle') }}
                </div>
                <div class="default mb20">
                  ({{ $t('subscribePlan.enterprise.defaultManage') }}
                  {{ entGroupProple }}
                  {{ $t('subscribePlan.enterprise.memberUnit') }})
                </div>
                <div class="bonus">
                  {{ $t('subscribePlan.enterprise.extra') }}
                </div>
                <div class="default">
                  {{ $t('subscribePlan.enterprise.limit') }}
                </div>
              </div>
              <div
                v-if="
                  (vxShopBefore && !vxAccountCompanyId) ||
                  vxBusinessBefore ||
                  (!vxAccountShopId && !vxAccountCompanyId)
                "
              >
                <!-- 如果不是shopb4 or businessb4 -->
                <div v-if="vxBusinessBefore">
                  <m-button
                    @click="startNewGroupFlow('Enterprise')"
                    class="tableButton cancel"
                    raised
                  >
                    <p>
                      {{ $t('button.createNew') }}
                    </p>
                  </m-button>
                  <m-button
                    @click="restoreGroupFlow('Enterprise')"
                    class="tableButton cancel"
                    raised
                    :disabled="loading"
                    :outlined="loading"
                  >
                    <p v-if="loading">
                      <b-spinner
                        small
                        label="Loading..."
                        :variant="'light'"
                        class="buttonLoad"
                      ></b-spinner>
                    </p>
                    <p v-else>
                      {{ $t('button.restore') }}
                    </p>
                  </m-button>
                </div>
                <div v-else>
                  <m-button
                    @click="subscribeFlow('Enterprise')"
                    class="tableButton cancel"
                    raised
                    :outlined="
                      vxAccountLevel === vxConstantsAccountLevel.business ||
                      vxShopBefore
                    "
                    :disabled="
                      vxAccountLevel === vxConstantsAccountLevel.business ||
                      vxShopBefore
                    "
                  >
                    <p>
                      {{ $t('button.subscribe') }}
                    </p>
                  </m-button>
                </div>
              </div>
              <hr />
              <div id="BusinessUserContent" class="planContent">
                <ul>
                  <li>{{ $t('subscribePlan.enterprise.priceRule.text1') }}</li>
                  <li>{{ $t('subscribePlan.enterprise.priceRule.text2') }}</li>
                  <li>{{ $t('subscribePlan.enterprise.priceRule.text3') }}</li>
                  <li>{{ $t('subscribePlan.enterprise.priceRule.text4') }}</li>
                  <li>{{ $t('subscribePlan.enterprise.priceRule.text5') }}</li>
                </ul>
              </div>
              <div @click="fnBusinessDrop" class="dropBox">
                <i id="BusinessDropdown" class="fas fa-chevron-right"></i>
              </div>
              <hr />
              <div class="planFooter">
                <ul class="iconGroup">
                  <li class="planIcon">
                    <i class="fas fa-print"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text1') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-table"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text2') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-chart-pie"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text3') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="far fa-chart-bar"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text4') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-user-friends"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text5') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-users-cog"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text6') }}</p>
                  </li>
                  <li class="planIcon">
                    <i class="fas fa-database"></i>
                    <p class="iconInfo">{{ $t('subscribePlan.icon.text7') }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
        </div>
      </div>
      <div v-show="sizeOfPlan > -62" class="right" @click="fnGoRight">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
    <!-- modal -->
    <!-- 訂閱明細 -->
    <b-modal
      id="subscribePlan"
      size="lg"
      centered
      :title="$t('modal.subscribeDetail')"
    >
      <div class="subscribeImportant">
        <div
          class="subscribePlan"
          :class="selectPlan === 'Basic' ? 'w100p' : ''"
        >
          <div class="title">{{ $t('modal.subscribe.planTitle') }}</div>
          <div class="select">
            {{ planNameChange }} {{ $t('modal.subscribe.planSubTitle') }}
          </div>
        </div>
        <div class="subscribeMember" v-if="selectPlan === 'Pro'">
          <div class="title">{{ $t('modal.subscribe.memberTitle') }}</div>
          <div class="select">
            {{ proGroupProple }} {{ $t('modal.subscribe.memberSubTitle') }}
          </div>
        </div>
        <div class="subscribeMember" v-if="selectPlan === 'Enterprise'">
          <div class="title">{{ $t('modal.subscribe.shopManagerTitle') }}</div>
          <div class="select">
            {{ entGroupProple }} {{ $t('modal.subscribe.memberSubTitle') }}
          </div>
        </div>
        <div class="subscribeMember extraMember" v-if="restoreGroup">
          <div class="title">{{ $t('modal.subscribe.extraMemberTitle') }}</div>
          <div class="select">
            {{ extraMember }} {{ $t('modal.subscribe.memberSubTitle') }}
          </div>
        </div>
        <div class="subscribePoint">
          <div class="title">{{ $t('modal.subscribe.priceTitle') }}</div>
          <div class="select" v-if="selectPlan === 'Pro'">
            <div
              v-if="
                vxAccountCompanyId &&
                vxAccountLevel === vxConstantsAccountLevel.shop
              "
            >
              <p>0 {{ $t('modal.subscribe.unit') }}</p>
              <span>{{
                $t('modal.subscribe.info.enterprise.shopInComp')
              }}</span>
            </div>
            <p v-else-if="restoreGroup">
              {{ totalCount }} {{ $t('modal.subscribe.unit') }}
            </p>
            <p v-else>{{ proDefaultPrice }} {{ $t('modal.subscribe.unit') }}</p>
          </div>
          <div class="select" v-else-if="selectPlan === 'Enterprise'">
            <p v-if="restoreGroup">
              {{ totalCount }} {{ $t('modal.subscribe.unit') }}
            </p>
            <p v-else>{{ entDefaultPrice }} {{ $t('modal.subscribe.unit') }}</p>
          </div>
          <div class="select" v-else>{{ $t('modal.subscribe.free') }}</div>
        </div>
      </div>

      <!-- 提示可以透過billing caucalation查看收費 -->
      <div
        v-if="
          restoreGroup && (selectPlan === 'Enterprise' || selectPlan === 'Pro')
        "
        class="restoreNotice"
      >
        {{ $t('modal.subscribe.info.restore.text1')
        }}<a @click.prevent="$router.push('/pointsCalculation?recover=true')">{{
          $t('modal.subscribe.info.restore.text2')
        }}</a>
      </div>

      <hr />
      <div class="subscribeInfo">
        <!-- 降級至Basic的呈現 -->
        <ul v-if="selectPlan === 'Basic'">
          <li class="subscribePlanList">
            {{ $t('modal.subscribe.info.basic.text1') }}
          </li>
          <li class="subscribePlanList">
            {{ $t('modal.subscribe.info.basic.text2') }}
          </li>
          <li class="subscribePlanList">
            {{ $t('modal.subscribe.info.basic.text3') }}
          </li>
        </ul>

        <ul v-if="selectPlan === 'Pro'">
          <!-- 升級至Pro的呈現 -->
          <li class="subscribePlanList">
            {{ $t('modal.subscribe.info.pro.text1') }}
          </li>
          <li class="subscribePlanList">
            {{ $t('modal.subscribe.info.pro.text2') }}
          </li>
          <br />
          <li class="subscribePlanList important">
            {{ $t('modal.subscribe.info.important.text1') }}
          </li>
          <li class="subscribePlanList important">
            {{ $t('modal.subscribe.info.important.shop') }}
          </li>
          <li class="subscribePlanList important">
            {{ $t('modal.subscribe.info.important.text3') }}
          </li>
        </ul>

        <ul v-if="selectPlan === 'Enterprise'">
          <!-- 升級至Pro的呈現 -->
          <li class="subscribePlanList">
            {{ $t('modal.subscribe.info.enterprise.text1') }}
          </li>
          <li class="subscribePlanList">
            {{ $t('modal.subscribe.info.enterprise.text2') }}
          </li>
          <br />
          <li class="subscribePlanList important">
            {{ $t('modal.subscribe.info.important.text1') }}
          </li>
          <li class="subscribePlanList important">
            {{ $t('modal.subscribe.info.important.business') }}
          </li>
          <li class="subscribePlanList important">
            {{ $t('modal.subscribe.info.important.text3') }}
          </li>
        </ul>
      </div>
      <br />

      <!-- 點數不夠(Basic -> Pro) -->
      <ul class="invalidInput" v-show="pointNotEnough">
        <li>
          <h6>
            {{ $t('invalidError.subscribe.pointNotEnough.text1') }}
          </h6>
        </li>
        <li>
          <h6>
            {{ $t('invalidError.subscribe.pointNotEnough.text2') }}
            {{ curPoint }}
            {{ $t('invalidError.subscribe.pointNotEnough.text3') }}
          </h6>
        </li>
        <li>
          <h6>
            {{ $t('invalidError.subscribe.pointNotEnough.text4') }}
          </h6>
        </li>
      </ul>

      <!-- 你管理著shop or company，請交接或解散後再嘗試 -->
      <h6 class="invalidInput" v-show="haveAnyGruop">
        {{ $t('invalidError.subscribe.haveGroup') }}
      </h6>

      <!-- 你隸屬別人shop or company，如果需要更換方案請聯繫你的shop or company manager -->
      <h6 class="invalidInput" v-show="inAnyGruop">
        {{ $t('invalidError.subscribe.inAnyGruop') }}
      </h6>

      <!-- 我們注意到你曾經是隸屬Company底下的Shop manager，請聯繫company manager來恢復狀態 -->
      <h6 class="invalidInput" v-show="isShopManagerBefore">
        {{ $t('invalidError.subscribe.shopManagerInCompBefore') }}
      </h6>

      <!-- 我們注意到你曾經是Shop/Company Manager，請在切換方案時先恢復原本方案 -->
      <h6 class="invalidInput" v-show="needToRecover">
        {{ $t('invalidError.subscribe.needToRecover.text1') }}
      </h6>
      <h6 class="invalidInput" v-show="needToRecover">
        {{ $t('invalidError.subscribe.needToRecover.text2') }}
      </h6>

      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton" raised dense @click="close()">
          {{ $t('button.no') }}
        </m-button>
        <m-button
          v-if="startNewGroup"
          @click="$bvModal.show('startNewGroupWarning')"
          class="tableButton cancel"
          :disabled="loading || subscribeError"
          :outlined="loading"
          raised
          dense
        >
          {{ $t('button.createNew') }}
          <b-spinner
            v-if="loading"
            small
            label="Loading..."
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
        <m-button
          v-else-if="restoreGroup"
          @click="startRestoreGorup"
          class="tableButton cancel"
          :disabled="loading || subscribeError"
          :outlined="loading"
          raised
          dense
        >
          {{ $t('button.restore') }}
          <b-spinner
            v-if="loading"
            small
            label="Loading..."
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
        <m-button
          v-else
          class="tableButton cancel"
          :disabled="loading || subscribeError"
          :outlined="loading"
          raised
          dense
          @click="fnSubscribe"
        >
          {{ $t('button.subscribe') }}
          <b-spinner
            v-if="loading"
            small
            label="Loading..."
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
      </template>
    </b-modal>
    <!-- 訂閱成功 -->
    <b-modal
      no-close-on-backdrop
      no-close-on-esc
      id="subscribePlanSuccess"
      centered
      :title="$t('modal.success')"
    >
      <h6>
        {{ $t('modal.subscribe.success.text1') }} {{ planNameChange }}
        {{ $t('modal.subscribe.success.text2') }}
      </h6>
      <div v-show="selectPlan === 'Pro'">
        <h6>
          {{ $t('modal.subscribe.success.pro') }} {{ proGroupProple }}
          {{ $t('modal.subscribe.success.unit') }}
        </h6>
        <h6>
          {{ $t('modal.subscribe.success.proInfo1') }}
        </h6>
        <h6>
          {{ $t('modal.subscribe.success.proInfo2') }}
        </h6>
        <h6>
          {{ $t('modal.subscribe.success.text5') }}
        </h6>
        <h6>
          {{ $t('modal.subscribe.success.text6') }}
        </h6>
      </div>
      <div v-show="selectPlan === 'Enterprise'">
        <h6>
          {{ $t('modal.subscribe.success.company') }} {{ entGroupProple }}
          {{ $t('modal.subscribe.success.unit') }}
        </h6>
        <h6>
          {{ $t('modal.subscribe.success.companyInfo1') }}
        </h6>
        <h6>
          {{ $t('modal.subscribe.success.companyInfo2') }}
        </h6>
        <h6>
          {{ $t('modal.subscribe.success.text5') }}
        </h6>
        <h6>
          {{ $t('modal.subscribe.success.shopInComp') }}
        </h6>
      </div>
      <template v-slot:modal-header>
        <h5>{{ $t('modal.success') }}</h5>
      </template>
      <template v-slot:modal-footer="{ close }">
        <m-button
          v-show="
            selectPlan === 'Basic' || vxAccountShopId || vxAccountCompanyId
          "
          class="tableButton cancel"
          raised
          dense
          :disabled="loading"
          @click="close($bvModal.hide('subscribePlanSuccess'))"
        >
          {{ $t('button.ok') }}
        </m-button>
        <m-button
          v-show="selectPlan === 'Pro' && !vxAccountShopId"
          class="tableButton cancel"
          :disabled="loading"
          :outlined="loading"
          raised
          dense
          @click="$router.push('/buildYourShop')"
        >
          {{ $t('button.buildShop') }}
          <b-spinner
            v-if="loading"
            small
            label="Loading..."
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
        <m-button
          v-show="selectPlan === 'Enterprise' && !vxAccountCompanyId"
          class="tableButton cancel"
          :disabled="loading"
          :outlined="loading"
          raised
          dense
          @click="$router.push('/buildYourCompany')"
        >
          {{ $t('button.buildComp') }}
          <b-spinner
            v-if="loading"
            small
            label="Loading..."
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
      </template>
    </b-modal>
    <!-- start new group警告 -->
    <b-modal id="startNewGroupWarning" centered :title="$t('modal.warning')">
      <h6>{{ $t('modal.subscribe.info.startNewGroup.warning.text1') }}</h6>
      <h6>{{ $t('modal.subscribe.info.startNewGroup.warning.text2') }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t('button.no') }}
        </m-button>
        <m-button
          class="tableButton"
          raised
          dense
          @click="disbandGroupAndCreateNewOne"
        >
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <!-- start new group 流程 -->
    <b-modal
      id="newGroupFlow"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$t('modal.notice')"
    >
      <h6 v-if="startNewGroupVariable.disband.loading" class="newGroupFlowTips">
        {{ $t('modal.subscribe.info.startNewGroup.disbanding') }}
        <b-spinner
          small
          label="Loading..."
          :variant="'primary'"
          class="buttonLoad"
        ></b-spinner>
      </h6>
      <h6 v-if="startNewGroupVariable.disband.success" class="newGroupFlowTips">
        {{ $t('modal.subscribe.info.startNewGroup.disbanded') }}
        <i class="fas fa-check-circle success"></i>
      </h6>
      <h6 v-if="startNewGroupVariable.subscribe.flow" class="newGroupFlowTips">
        {{ $t('modal.subscribe.info.startNewGroup.resubscribe') }}
        <b-spinner
          v-if="startNewGroupVariable.subscribe.loading"
          small
          label="Loading..."
          :variant="'primary'"
          class="buttonLoad"
        ></b-spinner>
        <i
          v-if="startNewGroupVariable.subscribe.error"
          class="fas fa-times-circle error"
        ></i>
      </h6>
      <h6
        v-if="startNewGroupVariable.subscribe.success"
        class="newGroupFlowTips"
      >
        {{ $t('modal.subscribe.info.startNewGroup.subscribed') }}
        <i class="fas fa-check-circle success"></i>
      </h6>
      <template v-slot:modal-footer="{ close }">
        <m-button
          :disabled="loading"
          class="tableButton cancel"
          raised
          dense
          @click="close()"
        >
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
    <br />
  </div>
</template>

<script>
  import {
    APIdisbandGroup,
    APIcheckVaildAndRefresh,
    APIchangeSubscriptionPlan,
    APIcheckBill,
    APIRestore,
  } from '../api';
  export default {
    data() {
      return {
        selectPlan: '',
        proDefaultPrice: 5, // 預設價錢
        entDefaultPrice: 20, // 預設價錢
        proGroupProple: 3,
        entGroupProple: 5,
        loading: false,
        sizeOfPlan: 0,
        checkSubscribe: true,
        startNewGroup: false,
        restoreGroup: false,
        extraMember: 0,
        totalCount: 0,
        startNewGroupVariable: {
          disband: {
            loading: false,
            success: false,
          },
          subscribe: {
            flow: false,
            loading: false,
            success: false,
            error: false,
          },
        },
        // error
        haveAnyGruop: false,
        inAnyGruop: false,
        isShopManagerBefore: false,
        needToRecover: false,
        pointNotEnough: false,
        curPoint: null,
        dropToggle: [false, false, false],
      };
    },
    mounted() {
      window.onresize = () => {
        if (window.innerWidth >= 1440 || window.innerWidth <= 500) {
          document.querySelector(
            '.planBody'
          ).style.transform = `translateX(0%)`;
          this.sizeOfPlan = 0;
        }
      };
    },
    beforeDestroy() {
      window.onresize = null;
    },
    methods: {
      startNewGroupFlow(plan) {
        this.restoreGroup = false;
        this.startNewGroup = true;
        this.showPlanModal(plan);
      },
      async restoreGroupFlow(plan) {
        try {
          this.startNewGroup = false;
          this.loading = true;
          this.restoreGroup = true;
          const { data: APIdata } = await APIcheckBill({ recover: true });
          this.extraMember = APIdata.extraMember;
          this.totalCount = APIdata.total;
          this.showPlanModal(plan);
        } catch (error) {
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            switch (error.response.data.error) {
              case 'unauthorized':
                this.$bvModal.show('unauthorizedErrorToggle');
                break;
              default:
                this.$bvModal.show('setsomethingWrong');
            }
          }
        } finally {
          this.loading = false;
        }
      },
      async startRestoreGorup() {
        try {
          this.loading = true;
          const { data: APIdata } = await APIRestore();
          if (APIdata.result === 1) {
            this.fnRefreshAccountInformation();
            this.$bvModal.hide('subscribePlan');
            this.$bvModal.show('subscribePlanSuccess');
          }
        } catch (error) {
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            switch (error.response.data.error) {
              case 'unauthorized':
                this.$bvModal.show('unauthorizedErrorToggle');
                break;
              // 點數不夠
              case 'not enough points':
                this.pointNotEnough = true;
                this.curPoint = error.response.data.curPoint;
                break;
              default:
                this.$bvModal.show('setsomethingWrong');
            }
          }
        } finally {
          this.loading = false;
        }
      },
      subscribeFlow(plan) {
        this.startNewGroup = false;
        this.restoreGroup = false;
        this.showPlanModal(plan);
      },
      async disbandGroupAndCreateNewOne() {
        try {
          this.$bvModal.hide('startNewGroupWarning');
          this.$bvModal.show('newGroupFlow');
          this.loading = true;
          this.startNewGroupVariable.disband.loading = true;
          const { data: APIdata } = await APIdisbandGroup();
          if (APIdata.result === 1) {
            this.startNewGroupVariable.disband.loading = false;
            this.startNewGroupVariable.disband.success = true;
            this.startNewGroupVariable.subscribe.flow = true;
            this.startNewGroupVariable.subscribe.loading = true;
            let subscribeReturn = await this.fnSubscribe();
            if (subscribeReturn) {
              this.fnRefreshAccountInformation();
            }
          }
        } catch (error) {
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            switch (error.response.data.error) {
              case 'unauthorized':
                this.$bvModal.show('unauthorizedErrorToggle');
                break;
              default:
                this.$bvModal.show('setsomethingWrong');
            }
          }
        }
      },
      showPlanModal(plan) {
        this.checkSubscribe = true;
        this.haveAnyGruop = false;
        this.inAnyGruop = false;
        this.isShopManagerBefore = false;
        this.needToRecover = false;
        this.pointNotEnough = false;
        switch (plan) {
          case 'Enterprise':
            this.selectPlan = 'Enterprise';
            break;
          case 'Pro':
            this.selectPlan = 'Pro';
            break;
          case 'Basic':
            this.selectPlan = 'Basic';
            break;
        }
        this.$bvModal.show('subscribePlan');
      },
      async fnSubscribe() {
        try {
          this.loading = true;
          let requestData = {
            desiredPlan: this.subscribePlanCode,
          };
          const { data: APIdata } = await APIchangeSubscriptionPlan(
            requestData
          );
          if (APIdata.result === 1) {
            this.startNewGroupVariable.subscribe.loading = false;
            this.startNewGroupVariable.subscribe.flow = false;
            this.startNewGroupVariable.subscribe.success = true;
            this.fnRefreshAccountInformation();
            this.$bvModal.hide('subscribePlan');
            this.$bvModal.show('subscribePlanSuccess');
          }
        } catch (error) {
          this.startNewGroupVariable.subscribe.loading = false;
          this.startNewGroupVariable.subscribe.error = true;
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            this.checkSubscribe = false;
            switch (error.response.data.error) {
              // 點數不夠
              case 'not enough points':
                this.pointNotEnough = true;
                this.curPoint = error.response.data.curPoint;
                break;
              case 'invalid action':
              default:
                this.$bvModal.hide('subscribePlan');
                this.$bvModal.show('setsomethingWrong');
                break;
            }
          }
          this.loading = false;
          return true;
        }
      },
      async fnRefreshAccountInformation() {
        try {
          const { data: APIdata } = await APIcheckVaildAndRefresh();
          if (APIdata.result === 1) {
            window.localStorage.setItem('token', 'bearer ' + APIdata.token);
            this.$store.dispatch('setAccount', APIdata.account);
            this.$store.dispatch('setAccountInformation', {
              account: APIdata.account,
              accountLevel: APIdata.accountLevel,
              companyId: APIdata.companyId,
              nationalId: APIdata.nationalId,
              regionalId: APIdata.regionalId,
              shopId: APIdata.shopId,
              pricingPlan: APIdata.pricingPlan,
            });
          }
        } catch (error) {
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            switch (error.response.data.error) {
              case 'token missing':
                this.$bvModal.show('setCheckToken');
                break;
              case 'account does not exist':
                this.$bvModal.show('setCheckToken');
                break;
              default:
                this.$bvModal.show('setsomethingWrong');
                break;
            }
          }
        } finally {
          this.loading = false;
        }
      },
      fnGoLeft() {
        if (this.sizeOfPlan === 0) {
          return;
        } else {
          this.sizeOfPlan = this.sizeOfPlan + 31.5;
          document.querySelector(
            '.planBody'
          ).style.transform = `translateX(${this.sizeOfPlan}%)`;
        }
      },
      fnGoRight() {
        if (this.sizeOfPlan <= -63) {
          return;
        } else {
          this.sizeOfPlan = this.sizeOfPlan - 31.5;
          document.querySelector(
            '.planBody'
          ).style.transform = `translateX(${this.sizeOfPlan}%)`;
        }
      },
      fnBasicDrop() {
        if (this.dropToggle[0]) {
          document
            .getElementById('basicUserContent')
            .classList.remove('showContent');
          document.getElementById('basicDropdown').classList.remove('Up');
          this.dropToggle[0] = !this.dropToggle[0];
        } else {
          document
            .getElementById('basicUserContent')
            .classList.add('showContent');
          document.getElementById('basicDropdown').classList.add('Up');
          this.dropToggle[0] = !this.dropToggle[0];
        }
      },
      fnProDrop() {
        if (this.dropToggle[1]) {
          document
            .getElementById('proUserContent')
            .classList.remove('showContent');
          document.getElementById('proDropdown').classList.remove('Up');
          this.dropToggle[1] = !this.dropToggle[1];
        } else {
          document
            .getElementById('proUserContent')
            .classList.add('showContent');
          document.getElementById('proDropdown').classList.add('Up');
          this.dropToggle[1] = !this.dropToggle[1];
        }
      },
      fnBusinessDrop() {
        if (this.dropToggle[2]) {
          document
            .getElementById('BusinessUserContent')
            .classList.remove('showContent');
          document.getElementById('BusinessDropdown').classList.remove('Up');
          this.dropToggle[2] = !this.dropToggle[2];
        } else {
          document
            .getElementById('BusinessUserContent')
            .classList.add('showContent');
          document.getElementById('BusinessDropdown').classList.add('Up');
          this.dropToggle[2] = !this.dropToggle[2];
        }
      },
    },
    computed: {
      vxAccountPlan() {
        let plan;
        switch (this.$store.state.accountInformation.pricingPlan) {
          case '0':
            plan = 'Basic';
            break;
          case '1':
            plan = 'Pro';
            break;
          case '2':
            plan = 'Enterprise';
            break;
        }
        return plan;
      },
      vxAccountShopId() {
        return this.$store.state.accountInformation.shopId;
      },
      vxAccountCompanyId() {
        return this.$store.state.accountInformation.companyId;
      },
      vxAccountLevel() {
        return this.$store.state.accountInformation.accountLevel;
      },
      vxShopBefore() {
        return this.$store.state.accountInformation.isShopBefore;
      },
      vxBusinessBefore() {
        return this.$store.state.accountInformation.isCompanyBefore;
      },
      vxConstantsAccountLevel() {
        return this.$store.state.constants.csAccountLevel;
      },
      vxConstantsDataPlan() {
        return this.$store.state.constants.csDataPlan.old;
      },
      planNameChange() {
        let plan = this.$t('modal.subscribe.basic');
        if (this.selectPlan === 'Pro') plan = this.$t('modal.subscribe.shop');
        if (this.selectPlan === 'Enterprise')
          plan = this.$t('modal.subscribe.business');
        return plan;
      },
      subscribeError() {
        let toggle = false;
        let array = [
          this.pointNotEnough,
          this.haveAnyGruop,
          this.inAnyGruop,
          this.isShopManagerBefore,
          this.needToRecover,
        ];
        array.map(el => {
          if (el) {
            toggle = true;
          }
        });
        return toggle;
      },
      showAlertMessage() {
        let toggle = true;
        if (
          this.vxAccountLevel === this.vxConstantsAccountLevel.basic &&
          !this.vxAccountShopId &&
          !this.vxAccountCompanyId
        ) {
          toggle = false;
        }
        if (
          this.vxAccountLevel === this.vxConstantsAccountLevel.basic &&
          this.vxBusinessBefore
        ) {
          toggle = false;
        }
        if (
          this.vxAccountLevel === this.vxConstantsAccountLevel.basic &&
          this.vxShopBefore &&
          !this.vxAccountCompanyId
        ) {
          toggle = false;
        }
        return toggle;
      },
      subscribePlanCode() {
        let code;
        switch (this.selectPlan) {
          case 'Basic':
            code = '0';
            break;
          case 'Pro':
            code = '1';
            break;
          case 'Enterprise':
            code = '2';
            break;
        }
        return code;
      },
      subdomain() {
        return this.$root.subdomain;
      },
    },
  };
</script>

<style lang="scss">
  .planBody {
    transition: 0.5s;
    font-family: $font-family;
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    .planBox {
      position: relative;
      button {
        &:disabled {
          color: $sub_background_color;
          background: rgb(181, 219, 238);
        }
      }
      .submit {
        padding: 5px 10px;
        transform: translateY(-70%);
        background: #f2e05f;
        color: #024f83;
        font-weight: 600;
        font-size: 18px;
        position: absolute;
        z-index: 2;
      }
      .planInnerBox {
        min-height: 830px;
        padding: 30px;
        background: #b5dbee;
        box-shadow: 5px 10px 15px #024f83;
      }
      .basicPriceFixIE {
        line-height: 140px;
      }
    }
  }
  @media screen and (min-width: 1440px) {
    .planBox {
      flex: 0 0 30%;
      max-width: 30%;
    }
    .subscribePage {
      .left,
      .right {
        display: none;
      }
    }
  }
  @media screen and (max-width: 1440px) {
    .planBox {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }
  @media screen and (max-width: 1439px) {
    .planBody {
      width: 270%;
    }
    .planCover {
      margin: auto;
      // max-width: 720px;
      max-width: 560px;
      padding-bottom: 20px;
      overflow: hidden;
    }
    .wrap {
      position: relative;
    }
    .subscribePage {
      .left,
      .right {
        display: block;
        position: absolute;
        font-size: 32px;
        z-index: 2;
        color: rgb(2, 79, 131);
        background: rgba(255, 255, 255, 0.8);
        width: 33px;
        border-radius: 10%;
      }
      .left {
        top: 50%;
        left: 5%;
      }
      .right {
        right: 5%;
        top: 50%;
      }
    }
  }

  .planInnerBox {
    position: relative;
    hr {
      border-top: 2px solid rgba(2, 79, 131, 0.8);
    }
    h4 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.6rem;
      color: #024f83;
      font-weight: 600;
    }
    h6 {
      font-size: 1.6rem;
      margin-bottom: 20px;
      font-weight: 600;
      color: rgba(2, 79, 131, 0.8);
    }
    .priceBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 150px;
      margin-bottom: 10px;
      p {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.3px;
        margin: 5px 0;
      }
      div {
        color: #024470;
        margin-bottom: 10px;
      }
      .mb20 {
        margin-bottom: 20px;
      }
      .price {
        font-size: 1.9rem;
        font-weight: 600;
      }
      .bonus {
        font-weight: 600;
        font-size: 1.3rem;
      }
    }
    li {
      padding: 5px 0;
    }
    .planContent {
      min-height: 300px;
      ul {
        list-style: disc;
        width: 80%;
        margin: auto;
      }
      li {
        line-height: 19px;
        text-align: left;
        color: rgb(2, 79, 131);
      }
    }
    .iconGroup {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .planIcon {
      color: rgba(2, 79, 131, 0.8);
      width: 25%;
      margin: 3.5px 0;
      font-size: 42px;
      .iconInfo {
        text-align: center;
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }

  .planInnerBox {
    .countBox {
      display: flex;
      align-items: center;
      justify-content: center;
      input {
        line-height: 26px;
        width: 80%;
        border: transparent;
        font-size: 19px;
        text-align: center;
      }
      .less,
      .plus {
        padding: 6px;
        background: white;
        font-size: 16px;
        cursor: pointer;
        user-select: none;
      }
    }
  }

  .planFooter {
    min-height: 160px;
  }

  .subscribeImportant {
    display: flex;
    flex-wrap: wrap;
    .subscribePlan,
    .subscribeMember,
    .subscribePoint {
      display: flex;
      flex-direction: column;
      padding: 10px;
      .title {
        font-size: 16px;
        margin: 5px 0;
      }
      .select {
        font-size: 24px;
        line-height: 28px;
        font-weight: 600;
        text-align: center;
      }
      span {
        font-size: 16px;
      }
      .shopList div {
        width: 33%;
        display: inline-block;
        text-align: center;
      }
      .shopList.title {
        font-weight: 600;
      }
    }
    .subscribePlan,
    .subscribeMember {
      width: 50%;
    }
    .subscribePoint {
      flex-grow: 1;
    }
    .w100p {
      width: 100%;
    }
  }

  .restoreNotice {
    text-align: center;
    margin: 25px 0;
    a {
      cursor: pointer;
      color: blue !important;
      &:hover {
        color: rgb(61, 61, 245) !important;
      }
    }
  }

  .subscribeInfo {
    padding: 0 10px;
    .subscribePlanList {
      padding: 5px 0;
      font-size: 14px;
      letter-spacing: 0.2px;
      line-height: 18px;
      span {
        font-weight: 600;
        font-size: 18px;
      }
      &.important {
        font-weight: 600;
      }
    }
  }
  #basicDropdown,
  #proDropdown,
  #BusinessDropdown {
    display: none;
  }

  @media screen and (max-width: 500px) {
    .planBody {
      display: block;
      width: 100%;
    }
    .planBox {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 3rem;
    }
    .planBody .planBox .planInnerBox {
      min-height: 240px;
    }
    .planInnerBox .planContent {
      height: 0;
      min-height: 0px;
      overflow: hidden;
      transition: 0.5s;
    }
    .planInnerBox #basicUserContent.showContent {
      height: 115px;
      // 160
    }
    .planInnerBox #proUserContent.showContent {
      height: 370px;
      // 480
    }
    .planInnerBox #BusinessUserContent.showContent {
      height: 350px;
    }
    .planFooter {
      min-height: auto;
    }
    #basicUser .priceBox {
      min-height: 60px;
    }
    .planInnerBox .planIcon {
      width: 50%;
    }
    .subscribePage {
      .left,
      .right {
        display: none;
      }
    }
    #basicDropdown,
    #proDropdown,
    #BusinessDropdown {
      display: inline-block;
    }
    .dropBox {
      background: rgba(255, 255, 255, 0.8);
      color: #024f83;
      padding: 2px 10px;
      border-radius: 3px;
      display: inline-block;
    }
  }

  @media screen and (max-width: 370px) {
    .planInnerBox #basicUserContent.showContent {
      height: 160px;
      // 160
    }
    .planInnerBox #proUserContent.showContent {
      height: 480px;
      // 480
    }
    .planInnerBox #BusinessUserContent.showContent {
      height: 430px;
    }
  }

  #basicDropdown,
  #proDropdown,
  #BusinessDropdown {
    transition: 0.5s;
    transform: rotate(90deg);
  }
  #basicDropdown.Up,
  #proDropdown.Up,
  #BusinessDropdown.Up {
    transform: rotate(270deg);
  }
</style>

<style lang="scss" scoped>
  .newGroupFlowTips {
    display: flex;
    justify-content: center;
    align-items: center;
    .success {
      color: #28a745;
      margin: 0 0 0 5px;
    }
    .error {
      color: #dc3545;
      margin: 0 0 0 5px;
    }
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .planInnerBox .tableButton.cancel {
    margin: 0 20px;
  }
</style>
