<template>
  <div>
    <div class="download-container">
      <div class="icon">
        <!-- 使用 Font Awesome 圖標 -->
        <i class="fas fa-file-alt" style="font-size: 3rem"></i>
      </div>
      <div class="file-info">
        <h2>mybatteryexpert.com</h2>
        <p>Operation Guide</p>
        <p>PDF, 978KB, 2024.04.16</p>
      </div>
      <a :href="downloadUrl" download class="download-button"> Download </a>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        downloadUrl:
          '/docs/operation_guide/mybatteryexpert-operation-guide-ver-20240416.pdf', // relative path to the PDF file
      };
    },
  };
</script>

<style scoped>
  .download-container {
    background-color: #004b79; /* 背景色 */
    color: #ffffff;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    max-width: 450px;
    margin: 30px auto;
  }

  .icon {
    margin-bottom: 30px;
  }

  .file-info p {
    margin: 5px 0;
  }

  .download-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 1rem;
    text-decoration: none;
    background-color: #3384e1;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 20px;
  }

  .download-button:hover {
    background-color: #2a6db3;
  }
</style>
