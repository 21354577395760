<template>
  <div class="logoArea">
    <img
      v-if="subdomain === 'napa'"
      id="logo"
      src="@/assets/logo-napa.png"
      alt="logo-napa"
    />
    <img v-else id="logo" src="@/assets/logo.svg" />
    <h1 v-if="subdomain === 'napa'">{{ $t("home.title.napa") }}</h1>
    <h1 v-else>{{ $t("home.title.general") }}</h1>
  </div>
</template>

<script>
export default {
  computed: {
    subdomain() {
      return this.$root.subdomain;
    },
  },
};
</script>

<style lang="scss" scoped>
.logoArea {
  margin: 1.5rem 0 0.5rem;
  font-size: 2.5rem;
  color: white;
  font-family: $font-family;
  font-weight: 500;
  line-height: 1.2;
  img {
    max-width: 80%;
    width: 350px;
  }
}
</style>
