<template>
  <div class="testReportBottom">
    <dataTable :columns="columns" :device="'blacklist'"></dataTable>
  </div>
</template>

<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  data() {
    return {
      columns: [
        {
          label: this.$t("tableColumns.blacklist.address"),
          field: "recipient",
          width: "185px",
          sortable: false,
          hidden: false,
          disable: false,
          type: "String",
        },
        {
          label: this.$t("tableColumns.blacklist.numberOfEmail"),
          field: "count",
          width: "170px",
          sortable: false,
          hidden: false,
          disable: false,
          type: "Number",
        },
      ],
    };
  },
};
</script>
