<template>
  <div class="frame">
    <div class="container">
      <div class="formHeader">
        <h1>{{ $t(page + '.title') }}</h1>
        <p>{{ $t(page + '.subtitle') }}</p>
      </div>

      <!-- 下載檔案類型用(form表單) -->
      <form
        v-if="this.export"
        id="formFormat"
        :action="api"
        method="POST"
        @submit="emitValue"
      >
        <div class="formBody">
          <div v-for="(item, index) in formInput" :key="item.title">
            <label class="formInputBox">
              <p>{{ $t(item.title) }}</p>
              <input
                @input="checkInput(item.check, index)"
                :autocomplete="autocomplete"
                v-model.number="inputValue[index]"
                class="formInput"
                :type="item.type"
                :placeholder="$t(item.placeholder)"
                :name="item.id"
                required
              />
            </label>
            <!-------------- error message --------------->
            <!-- point輸入必須為數字 -->
            <div
              v-show="error.RegPoint && item.check === 'point'"
              class="invalidInput"
            >
              {{ $t('invalidError.createPoint.onlyNumber') }}
            </div>
            <!-- setNumber輸入必須為數字 -->
            <div
              v-show="error.RegSetNumber && item.check === 'setNumber'"
              class="invalidInput"
            >
              {{ $t('invalidError.createPoint.onlyNumber') }}
            </div>
            <!-- 組數最多不能高於10000 -->
            <div
              v-show="error.setNumberError && item.check === 'setNumber'"
              class="invalidInput"
            >
              <!-- Generate up to 10,000 set number of point code at a time -->
              {{ $t('invalidError.createPoint.lessThan') }}
            </div>
            <!------------------------------------------->
          </div>
          <input
            v-show="false"
            name="token"
            class="formInput"
            type="text"
            :value="formToken"
          />
        </div>
        <hr class="formLine" />
        <m-button
          class="tableButton"
          raised
          :disabled="buttonDisabled"
          :outlined="buttonDisabled"
          :dense="!buttonDisabled"
        >
          {{ $t('button.' + buttonName) }}
          <b-spinner
            v-if="loading"
            small
            label="Loading..."
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
      </form>
      <!-- ---------------------------------------------------------------------- -->
      <!-- 其餘(ajax) -->
      <form v-else id="formFormat" @submit.prevent="emitValue">
        <div class="formBody">
          <div v-for="(item, index) in formInput" :key="item.title">
            <label class="formInputBox">
              <p>{{ $t(item.title) }}</p>
              <div class="input-group">
                <input
                  @input="checkInput(item.check, index)"
                  :autocomplete="autocomplete"
                  v-model="inputValue[index]"
                  class="formInput"
                  :type="item.type"
                  :placeholder="$t(item.placeholder)"
                  required
                  :name="item.id"
                />
                <div
                  v-if="item.id === 'password' || item.id === 'confirmPassword'"
                  @click="fnEyeSwitch(index)"
                  id="passwordToggle"
                >
                  <span>
                    <i
                      id="eye"
                      :class="eyeSwitch[index]"
                      class="fas fa-eye"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
              </div>
            </label>
            <!-------------- error message --------------->
            <!-- create shop/company -->
            <div
              class="subscribeInput groupIdFormat"
              :class="error.RegGroupID ? 'invalidInput' : ''"
              v-show="page === 'createShop' || page === 'createCompany'"
            >
              <p>{{ $t('invalidError.createGruop.title1') }}</p>
              <p>{{ $t('invalidError.createGruop.title2') }}</p>
              <br />
              <p>{{ $t('invalidError.createGruop.title3') }}</p>
              <p>{{ $t('invalidError.createGruop.list1') }}</p>
              <p>{{ $t('invalidError.createGruop.list2') }}</p>
              <p>{{ $t('invalidError.createGruop.list3') }}</p>
              <p>{{ $t('invalidError.createGruop.list4') }}</p>
              <p>{{ $t('invalidError.createGruop.list5') }}</p>
            </div>
            <div class="invalidInput" v-show="error.existGroupID">
              {{ $t('invalidError.createGruop.existID') }}
            </div>
            <!-- change password -->
            <div
              class="invalidInput"
              v-show="error.RegPassword && item.check === 'password'"
            >
              {{ $t('invalidError.changePassword.title') }}
              <p>{{ $t('invalidError.changePassword.list1') }}</p>
              <p>{{ $t('invalidError.changePassword.list2') }}</p>
              <p>{{ $t('invalidError.changePassword.list3') }}</p>
              <p>{{ $t('invalidError.changePassword.list4') }}</p>
              <p>{{ $t('invalidError.changePassword.list5') }}</p>
              <p>{{ $t('invalidError.changePassword.list6') }}</p>
              <p>{{ $t('invalidError.changePassword.list7') }}</p>
            </div>
            <div
              class="invalidInput"
              v-show="error.confirmPassword && item.check === 'confirm'"
            >
              {{ $t('invalidError.changePassword.confirmPasswordError') }}
            </div>
            <!-- stored point -->
            <div v-show="error.invalidCode" class="invalidInput">
              {{ $t('invalidError.stroedPoint.invalid') }}
            </div>
            <div v-show="error.codeAlreadyUsed" class="invalidInput">
              {{ $t('invalidError.stroedPoint.used') }}
            </div>
            <!-- register user -->
            <div
              v-show="error.mailErrorToggle && item.check === 'email'"
              class="invalidInput"
            >
              {{ $t('invalidError.registerUser.mailError') }}
            </div>
            <!------------------------------------------->
          </div>
          <div v-for="(item, index) in formSelect" :key="item.title">
            <label class="formInputBox">
              <p>{{ $t(item.title) }}</p>
              <select
                @change="showCityList(selectCountry[index])"
                required
                class="timezoneBox"
                v-model="selectCountry[index]"
              >
                <option value="" selected disabled>
                  {{ $t(`formFormat.selectText1`) }}
                </option>
                <option
                  v-for="country in timezoneCountry"
                  :key="country"
                  :value="country"
                >
                  {{ $t(`formFormat.country.${country}`) }}
                </option>
              </select>
            </label>
            <label
              class="formInputBox"
              v-if="formSelect !== null && cityListToggle"
            >
              <select class="timezoneBox" required v-model="selectValue[index]">
                <option value="" selected disabled>
                  {{ $t(`formFormat.selectText2`) }}
                </option>
                <option v-for="city in cityList" :key="city" :value="city">
                  {{ city }}
                </option>
              </select>
            </label>
          </div>
        </div>
        <hr class="formLine" />
        <div
          v-if="page === 'register' && subdomain !== 'napa'"
          class="subscribeInput"
        >
          {{ $t('register.notice') }}
        </div>
        <m-button
          class="tableButton"
          raised
          :disabled="buttonDisabled"
          :outlined="buttonDisabled"
          :dense="!buttonDisabled"
        >
          {{ $t('button.' + buttonName) }}
          <b-spinner
            v-if="loading"
            small
            label="Loading..."
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </m-button>
        <p v-if="page === 'register'" class="formFooter">
          <b-form-checkbox v-model="checkPolicy">
            {{ $t('register.check1') }}
            <router-link to="/privacyPolicy" tag="span">
              <a target="_blank">{{ $t('nav.PrivacyPolicy') }}</a> </router-link
            >.
          </b-form-checkbox>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
  import { apiURL } from '../api';

  export default {
    props: {
      page: String,
      formInput: {
        type: Array,
        default: null,
      },
      formSelect: {
        type: Array,
        default: null,
      },
      loading: Boolean,
      error: Object,
      export: {
        type: Boolean,
        default: false,
      },
      checkPolicy: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        inputValue: [],
        selectValue: [''],
        selectCountry: [''],
        cityList: [],
        cityListToggle: false,
        eyeSwitch: [],
        formToken: window.localStorage.token,
        api: apiURL + '/pointcode/produce',
        timezoneCountry: [
          'AD',
          'AE',
          'AT',
          'AU',
          'BA',
          'BE',
          'BG',
          'BH',
          'BW',
          'CH',
          'CY',
          'CZ',
          'DE',
          'DK',
          'EE',
          'EG',
          'ES',
          'FI',
          'FR',
          'GB',
          'GR',
          'HU',
          'IE',
          'IQ',
          'IT',
          'JO',
          'KE',
          'KW',
          'LB',
          'LT',
          'LU',
          'MA',
          'MC',
          'ME',
          'MU',
          'NA',
          'NL',
          'NO',
          'NZ',
          'OM',
          'PL',
          'PS',
          'PT',
          'QA',
          'RS',
          'RU',
          'SA',
          'SE',
          'SK',
          'SM',
          'TR',
          'TW',
          'TZ',
          'UG',
          'US',
          'YE',
          'ZA',
          'ZM',
          'ZW',
        ],
      };
    },
    beforeMount() {
      if (this.formInput !== null) {
        this.formInput.map(el => {
          this.inputValue.push(''); // input value初值，有幾個就加幾個
          if (el.type === 'password') {
            // for password eye
            this.eyeSwitch.push({
              'fa-eye-slash': true,
            });
          } else {
            this.eyeSwitch.push({});
          }
        });
      }
    },
    methods: {
      emitValue() {
        if (this.formSelect === null) {
          this.$emit('submit', { input: this.inputValue });
        } else if (this.formInput === null) {
          this.$emit('submit', { select: this.selectValue });
        } else {
          this.$emit('submit', {
            input: this.inputValue,
            select: this.selectValue,
          });
        }
      },
      checkInput(checkType, selectIndex) {
        switch (checkType) {
          case 'id':
            this.error.existGroupID = false;
            this.fnCheckId(selectIndex);
            break;
          case 'password':
            this.fnCheckPassword(selectIndex);
            break;
          case 'confirm':
            this.fnCheckConfirm(selectIndex);
            break;
          case 'storedPoint':
            this.error.invalidCode = false;
            this.error.codeAlreadyUsed = false;
            break;
          case 'email':
            this.fnCheckAccount(selectIndex);
            break;
          case 'point':
            this.fnCheckPoint(selectIndex);
            break;
          case 'setNumber':
            this.fnCheckSetNumber(selectIndex);
            break;
          default:
            break;
        }
      },
      fnCheckId(selectIndex) {
        if (this.inputValue[selectIndex] === '') {
          this.error.RegGroupID = false;
          return;
        }
        const reg = /^[A-Za-z0-9_-]{3,20}$/; //eslint-disable-line
        if (reg.test(this.inputValue[selectIndex])) {
          this.error.RegGroupID = false;
        } else {
          this.error.RegGroupID = true;
        }
      },
      fnCheckPassword(selectIndex) {
        if (this.inputValue[selectIndex] === '') {
          this.error.RegPassword = false;
          return;
        }
        const reg =
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,\-.\/:;<=>?@[\\\]^_`{|}~]).{8,}$/; //eslint-disable-line
        if (reg.test(this.inputValue[selectIndex])) {
          this.error.RegPassword = false;
        } else {
          this.error.RegPassword = true;
        }

        let account, password;
        if (this.page === 'register') {
          account = this.inputValue[0];
          password = this.inputValue[1];
        } else if (this.page === 'changePassword') {
          account = this.$store.state.account;
          password = this.inputValue[0];
        }
        let id = account.substring(0, account.indexOf('@'));
        if (password.includes(id)) this.error.RegPassword = true;

        if (
          this.inputValue[selectIndex] !== '' &&
          this.inputValue[selectIndex + 1] !== '' &&
          this.inputValue[selectIndex] !== this.inputValue[selectIndex + 1]
        ) {
          this.error.confirmPassword = true;
        } else {
          this.error.confirmPassword = false;
        }
      },
      fnCheckConfirm(selectIndex) {
        if (
          this.inputValue[selectIndex] === this.inputValue[selectIndex - 1] ||
          this.inputValue[selectIndex] === ''
        ) {
          this.error.confirmPassword = false;
        } else {
          this.error.confirmPassword = true;
        }
      },
      fnCheckAccount(selectIndex) {
        if (this.inputValue[selectIndex] === '') {
          this.error.mailErrorToggle = false;
          return;
        }
        const reg =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
        if (reg.test(this.inputValue[selectIndex])) {
          this.error.mailErrorToggle = false;
        } else {
          this.error.mailErrorToggle = true;
        }
      },
      fnEyeSwitch(selectIndex) {
        this.eyeSwitch[selectIndex]['fa-eye-slash'] =
          !this.eyeSwitch[selectIndex]['fa-eye-slash'];
      },
      fnCheckPoint(selectIndex) {
        if (this.inputValue[selectIndex] === '') {
          this.error.RegPoint = false;
          return;
        }
        const reg = /[^\d]/g;
        if (reg.test(this.inputValue[selectIndex])) {
          this.error.RegPoint = true;
        } else {
          this.error.RegPoint = false;
        }
      },
      fnCheckSetNumber(selectIndex) {
        if (this.inputValue[selectIndex] === '') {
          this.error.RegSetNumber = false;
          this.error.setNumberError = false;
          return;
        }

        const reg = /[^\d]/g;
        if (reg.test(this.inputValue[selectIndex])) {
          this.error.RegSetNumber = true;
        } else {
          this.error.RegSetNumber = false;
        }

        if (this.inputValue[selectIndex] > 10000) {
          this.error.setNumberError = true;
        } else {
          this.error.setNumberError = false;
        }
      },
      showCityList(selectCountry) {
        this.selectValue = [''];
        this.cityListToggle = true;
        this.cityList = this.$moment.tz.zonesForCountry(selectCountry);
      },
    },
    computed: {
      //multi-tenant
      subdomain() {
        return this.$root.subdomain;
      },
      // 頁面button文字
      buttonName() {
        switch (this.page) {
          case 'createShop':
          case 'createCompany':
            return 'create';
          case 'storedPoint':
            return 'stored';
          case 'changePassword':
          case 'register':
            return 'submit';
          case 'createPoint':
            return 'export';
          case 'setTimezone':
            return 'update';
          default:
            return 'ok';
        }
      },
      // 哪些頁面不允許記憶密碼
      autocomplete() {
        switch (this.page) {
          case 'createShop':
          case 'createCompany':
          case 'changePassword':
          case 'storedPoint':
          case 'register':
            return 'new-password';
          default:
            return '';
        }
      },
      // 哪些時候按鈕不可點擊
      buttonDisabled() {
        let check = false;
        if (this.loading) {
          check = true;
        }
        if (!this.checkPolicy) {
          check = true;
        }
        Object.keys(this.error).map(el => {
          if (this.error[el]) {
            check = true;
          }
        });
        return check;
      },
    },
    watch: {
      eyeSwitch: {
        handler: function (e) {
          e.map((el, index) => {
            if (!el['fa-eye-slash']) {
              this.formInput[index].type = 'text';
            } else {
              this.formInput[index].type = 'password';
            }
          });
        },
        deep: true,
      },
    },
  };
</script>

<style lang="scss">
  #formFormat {
    button {
      letter-spacing: $button_letter_space;
      font-weight: $button_font_weight;
      width: 100%;
      padding: 0.5rem 1rem;
      font-size: 1.25rem;
      line-height: 1.5;
      border-radius: 0.3rem;
      height: 46px;
      cursor: pointer;
      background: $sub_background_color;
      color: $sub_button_color;
      transition: 0.3s;
      font-family: $font-family;
      &:hover::before {
        opacity: 0.08;
      }
      &:disabled {
        color: $sub_background_color;
        background: $main_background_color;
      }
    }
  }

  .formHeader {
    margin-top: 4rem;
    color: $white_background;
    font-family: $font-family;
    h1 {
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      box-sizing: border-box;
    }
    p {
      font-size: 1.25rem;
      font-weight: 300;
      margin-bottom: 1rem;
    }
  }

  .formBody {
    margin-top: 2rem;
    .formInputBox {
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.25rem;
      margin-bottom: 1rem;
      display: block;
      color: $white_background;
      p {
        margin-bottom: 0.5rem;
        text-align: left;
      }
      .formInput,
      .timezoneBox {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        box-sizing: border-box;
        &:focus {
          outline: auto #024f83;
        }
      }
    }
  }

  .formLine {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }

  .formFooter {
    user-select: none;
    font-size: 0.875rem;
    letter-spacing: 0.3px;
    font-weight: 400;
    line-height: 1.5;
    margin-top: 0.5rem;
    color: $title_color;
    a {
      text-decoration: underline;
      font-weight: 700;
      color: $link_color;
      transition: 0.3s;
      &:hover {
        color: $link_hover_color;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .invalidInput,
  .groupIdFormat {
    text-align: left;
    padding: 0.75rem 1rem;
  }
</style>
