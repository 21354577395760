<template>
  <div class="frame">
    <div class="testReportBottom">
      <dataTable
        :columns="columns"
        :device="'pointsLogs'"
        data-test="dataTable"
        @on-row-click="handleRowClick"
      >
      </dataTable>
    </div>
    <!-- popup -->
    <b-modal
      id="detailsModal"
      title="Detail"
      size="lg"
      dialog-class="custom-modal-width"
      centered
      hide-footer
    >
      <b-table
        sstriped
        hover
        bordered
        :items="tableItems"
        :fields="tableFields"
        small
      >
      </b-table>

      <hr />

      <div
        class="d-flex justify-content-between flex-row-reverse align-items-center my-3"
      >
        <div class="text-end">
          <strong>Total Points: {{ totalPoints }}</strong>
        </div>
      </div>

      <b-alert variant="success" show>
        <template>
          <strong>Business Account Plan : 20 points / month<br /></strong>
          • default to manage 5 Shop accounts for free.
          <br />
          • + 5 points for each additional shop account.
        </template>
      </b-alert>

      <b-alert variant="info" show>
        <strong>Shop Account Plan: 5 point / month<br /></strong>
        • default to manage 3 basic accounts for free.
        <br />
        • + 2 points for each additional basic account
      </b-alert>

      <b-alert variant="warning" show>
        <strong>NOTE:<br /></strong>
        1. All payment in a system will be made at the highest tier.
        <br />
        2. The Shop Account will have to pay for the shop tier fee and any
        additional basic account fee under it.
        <br />
        3. The Business Account will have to pay for the business tier fee and
        any additional shop/basic account fee under it.
      </b-alert>

      <b-button variant="secondary" @click="closeDetailsModal()"
        >Close</b-button
      >
    </b-modal>
  </div>
</template>

<script>
  import dataTable from '@/components/dataTable.vue';
  import { APIpointsLogsDetail } from '@/api';

  export default {
    components: {
      dataTable,
    },
    data() {
      return {
        columns: [],
        tableFields: [
          {
            key: 'accountName',
            label: this.$t('tableColumns.pointsDetail.accountName'),
          },
          {
            key: 'pricingPlan',
            label: this.$t('tableColumns.pointsDetail.pricingPlan'),
          },
          {
            key: 'freeQuota',
            label: this.$t('tableColumns.pointsDetail.freeQuota'),
          },
          {
            key: 'freeUsed',
            label: this.$t('tableColumns.pointsDetail.freeUsed'),
          },
          {
            key: 'defaultFee',
            label: this.$t('tableColumns.pointsDetail.defaultFee'),
          },
          {
            key: 'additionalCost',
            label: this.$t('tableColumns.pointsDetail.additionalCost'),
          },
          {
            key: 'additionalUsed',
            label: this.$t('tableColumns.pointsDetail.additionalUsed'),
          },
          {
            key: 'additionalFee',
            label: this.$t('tableColumns.pointsDetail.additionalFee'),
          },
          {
            key: 'subtotal',
            label: this.$t('tableColumns.pointsDetail.subtotal'),
          },
        ],
        tableItems: [],
        totalPoints: 0,
      };
    },
    mounted() {
      this.columns.push({
        label: this.$t('tableColumns.pointsLogs.accountName'),
        field: 'accountName',
        width: '20%',
        sortable: false,
        disable: true,
        type: 'String',
      });
      this.columns.push({
        label: this.$t('tableColumns.pointsLogs.pointsCharged'),
        field: 'pointsCharged',
        width: '20%',
        sortable: false,
        disable: true,
        type: 'String',
      });
      this.columns.push({
        label: this.$t('tableColumns.pointsLogs.pointsSpent'),
        field: 'pointsSpent',
        width: '20%',
        sortable: false,
        disable: true,
        type: 'String',
      });
      this.columns.push({
        label: this.$t('tableColumns.pointsLogs.pointsBalance'),
        field: 'pointsBalance',
        width: '20%',
        sortable: false,
        disable: true,
        type: 'String',
      });
      this.columns.push({
        label: this.$t('tableColumns.pointsLogs.date'),
        field: 'date',
        width: '20%',
        sortable: false,
        disable: true,
        type: 'String',
      });
    },
    methods: {
      // methods here
      vxAccountPlan(plan) {
        switch (plan) {
          case '0':
            return 'Basic';
          case '1':
            return 'Shop';
          case '2':
            return 'Business';
          default:
            return 'Unknown';
        }
      },
      handleRowClick(row) {
        const { id } = row;
        this.fetchDetail(id);
      },
      fetchDetail(id) {
        // fetch detail here
        APIpointsLogsDetail(id)
          .then(res => {
            if (res.data?.pointsLogs === null) {
              return;
            }
            const response = res.data.pointsLogs;
            this.tableItems = response.pointsDetails.map(item => {
              const freeQuota =
                item.pricingPlan === '2' ? 5 : item.pricingPlan === '1' ? 3 : 0;
              const defaultFee =
                item.pricingPlan === '2'
                  ? 20
                  : item.pricingPlan === '1'
                  ? 5
                  : 0;
              const freeUsed =
                item.accountUsed > freeQuota ? freeQuota : item.accountUsed;
              const additionalCost =
                item.pricingPlan === '2' ? 5 : item.pricingPlan === '1' ? 2 : 0;
              const additionalUsed =
                item.accountUsed > freeQuota ? item.accountUsed - freeQuota : 0;
              const additionalFee =
                item.accountUsed - freeQuota > 0
                  ? (item.accountUsed - freeQuota) * additionalCost
                  : 0;
              return {
                accountName: item.accountName,
                pricingPlan: this.vxAccountPlan(item.pricingPlan),
                freeQuota: freeQuota,
                freeUsed: freeUsed,
                defaultFee: defaultFee,
                additionalCost: additionalCost,
                additionalUsed: additionalUsed,
                additionalFee: additionalFee,
                subtotal: defaultFee + additionalFee,
              };
            });
            this.totalPoints = -response.pointsLog.pointsChange;
            this.$bvModal.show('detailsModal');
          })
          .catch(err => {
            // handle error here
            console.error(err);
          });
      },
      closeDetailsModal() {
        this.$bvModal.hide('detailsModal');
      },
    },
    computed: {
      subdomain() {
        return this.$root.subdomain;
      },
      vxAccountLevel() {
        return this.$store.state.accountInformation.accountLevel;
      },
      vxConstantsAccountLevel() {
        return this.$store.state.constants.csAccountLevel;
      },
    },
  };
</script>

<style>
  .custom-modal-width {
    max-width: 80% !important; /* Adjust the max-width as needed */
  }
  .b-table th {
    white-space: normal;
    word-wrap: break-word; /* 确保长单词换行 */
  }
</style>
