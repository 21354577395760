<template>
  <div class="frame">
    <div class="container">
      <div class="formHeader">
        <h1>Server Tasks</h1>
      </div>
      <div class="tasksTitle">
        <h5>Tasks List</h5>
        <m-button
          @click="refreshTasksList"
          raised
          :disabled="loading"
          :outlined="loading"
          style="border: 1px solid white"
        >
          <i
            class="fas fa-sync-alt"
            :class="loading ? 'taskAnimation' : ''"
          ></i>
        </m-button>
      </div>
      <ul class="tasksContainer textLeft">
        <li class="title">
          <div>Creation time</div>
          <div>Tasks</div>
          <div class="statusLabel">Status</div>
        </li>
        <li v-for="task in tasks" :key="task.startTime">
          <div>{{ task.time }}</div>
          <div>{{ $t(`serverTasks.action.${task.action}`) }}</div>
          <div class="statusLabel">
            <i
              v-if="task.state === '1'"
              class="fas fa-check green icon"
              style="color: green"
            ></i>
            <i
              v-else-if="task.state === '-1'"
              class="fas fa-times icon"
              style="color: red; font-size: 20px; margin: 0 5px 0 7px"
            ></i>
            <b-spinner
              v-else
              small
              label="Loading..."
              :variant="'info'"
              class="icon"
            ></b-spinner>
            <span class="tabeltUp">{{
              $t(`serverTasks.state.${task.state}`)
            }}</span>
            <i
              v-if="task.state === '-1'"
              v-b-tooltip.bottom.hover
              :title="$t('serverTasks.info')"
              class="fas fa-info-circle info"
            ></i>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { APIgetServerTasksList } from "../api";

export default {
  mounted() {
    this.refreshTasksList();
  },
  data() {
    return {
      // tasksId & status之後可以改為代碼，統一由前端顯示
      // 回傳由id大到小，在用time(開始時間UTC+0)與現在的UTC+0的時間做比較就可以知道是多久前執行
      tasks: [],
      loading: false,
    };
  },
  methods: {
    async refreshTasksList() {
      this.loading = true;
      this.tasks = [];
      const { data: APIData } = await APIgetServerTasksList();
      for (let i of APIData.tasks) {
        this.tasks.push({
          startTime: i.time,
          action: i.task,
          state: i.status,
          time: "",
        });
      }
      this.loading = false;
    },
  },
  watch: {
    tasks: function () {
      let now = new Date().toISOString(); // UTC+0的時間
      let nowSeconds = new Date(now).getTime(); // 現在的毫秒數

      for (let i of this.tasks) {
        let time = new Date(i.startTime).getTime();
        let creationTime = (nowSeconds - time) / 1000 / 60 / 60;

        if (creationTime < 1) {
          creationTime = Math.floor(creationTime / 0.0166);
          if (creationTime <= 0) creationTime = 1;
          creationTime = `${creationTime} ${this.$t(
            "serverTasks.time.m"
          )} ${this.$t("serverTasks.time.unit")}`;
        }
        if (creationTime >= 1 && creationTime < 24) {
          creationTime = `${Math.floor(creationTime)} ${this.$t(
            "serverTasks.time.h"
          )} ${this.$t("serverTasks.time.unit")}`;
        }
        if (creationTime >= 24 && creationTime < 720) {
          creationTime = creationTime / 24;
          creationTime = `${Math.floor(creationTime)} ${this.$t(
            "serverTasks.time.D"
          )} ${this.$t("serverTasks.time.unit")}`;
        }
        if (creationTime >= 720) {
          creationTime = creationTime / 24 / 30;
          creationTime = `${Math.floor(creationTime)} ${this.$t(
            "serverTasks.time.M"
          )} ${this.$t("serverTasks.time.unit")}`;
        }
        i.time = creationTime;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  @media (max-width: 768px) {
    width: 100%;
  }
}
.tasksTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 12px;
  color: white;
  font-size: 24px;
  background: rgb(2, 79, 131);
}
ul > li {
  display: flex;
  justify-content: space-evenly;
  div {
    @media (max-width: 992px) {
      min-width: 206px;
      margin: 15px 0px;
    }
    @media (max-width: 768px) {
      min-width: 125px;
      margin: 15px 0px;
      .tabeltUp {
        display: none;
      }
    }
    min-width: 250px;
    margin: 15px 10px;
  }
  .statusLabel {
    display: flex;
    align-items: center;
    position: relative;
    min-height: 20px;
    .info {
      position: absolute;
      right: -8px;
      color: #024f83;
    }
    @media (max-width: 768px) {
      min-width: 30px;
      margin: 15px 0px;
    }
  }
  background: white;
}
li.title {
  font-weight: 600;
  border-bottom: solid 1px grey;
}
.textLeft {
  text-align: left;
}
.icon {
  margin: 0 5px;
}

.taskAnimation {
  color: white;
  animation: taskLoading 1.3s linear infinite;
}

@keyframes taskLoading {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
