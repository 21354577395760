<template>
  <div>
    <b-nav pills class="navbar">
      <b-navbar-brand>
        <b-nav-text>
          <div v-b-toggle.sidebar-main>
            <i class="fas fa-bars"></i>
          </div>
          <router-link class="navTitle" to="/home"
            >{{ $t("nav.logo")
            }}<span v-if="subdomain !== 'napa'">{{ $t("nav.free") }}</span>
          </router-link>
        </b-nav-text>
      </b-navbar-brand>

      <b-button
        :disabled="loading"
        @click="checkProfileToggle"
        class="navToggleButton"
        data-item="headshoot"
      >
        <div class="loading">
          <i
            v-if="vxHeadshotDefault"
            style="width: 100%; height: 100%"
            class="far fa-user"
            data-item="headshoot"
          ></i>
          <img
            v-else
            data-item="headshoot"
            class="headShoot"
            :src="vxHeadshotImage"
            @error="photoError"
          />
        </div>
        <div class="profileBox" v-show="loading">
          <b-spinner
            small
            label="Loading..."
            :variant="'light'"
            class="buttonLoad"
          ></b-spinner>
        </div>
      </b-button>

      <b-collapse id="navToggle" class="shadow">
        <div class="profileGroup" data-item="headshoot" v-show="!loading">
          <li class="profile changeImage">
            <span class="imageBox" @click="$bvModal.show('uploadImageToggle')">
              <!-- <span class="imageBox"> -->
              <i v-if="vxHeadshotDefault" class="far fa-user"></i>
              <img v-else class="headShoot" :src="vxHeadshotImage" />
              <span>{{ $t("nav.edit") }}</span>
            </span>
            <span>
              <p>{{ vxAccount }}</p>
            </span>
          </li>
          <b-dropdown-divider
            v-if="vxAccountLevel !== vxConstantsAccountLevel.admin"
          ></b-dropdown-divider>

          <router-link
            v-if="vxAccountLevel !== vxConstantsAccountLevel.admin"
            to="/subscribePlan"
            tag="li"
            class="profileclick"
          >
            <a>{{ $t("nav.plan") }} : {{ vxAccountPlan }}</a>
          </router-link>

          <router-link
            v-if="vxAccountLevel !== vxConstantsAccountLevel.admin"
            to="/storedPoints"
            tag="li"
            class="profileclick"
          >
            <a>{{ $t("nav.deposit") }} : {{ deposit }} {{ $t("nav.point") }}</a>
          </router-link>

          <router-link
            v-if="
              vxAccountLevel === vxConstantsAccountLevel.business ||
              vxAccountLevel === vxConstantsAccountLevel.shop
            "
            to="/setTimezone"
            tag="li"
            class="profileclick"
          >
            <a
              >{{ $t("nav.timezone") }}:
              {{ $store.state.accountInformation.timezone }}</a
            >
          </router-link>

          <b-dropdown-group
            v-if="vxAccountShopId"
            id="dropdown-group-shop"
            :header="$t('nav.shop')"
          >
            <li v-if="vxAccountShopId" class="profile group">
              {{ $t("nav.id") }} : {{ vxAccountShopId }}
            </li>
            <li
              v-if="vxAccountLevel !== vxConstantsAccountLevel.basic"
              class="profile group"
            >
              {{ $t("nav.techNumber") }} : {{ techNumber }}
              {{ $t("nav.unit") }}
            </li>
          </b-dropdown-group>

          <b-dropdown-group
            v-if="vxAccountCompanyId"
            id="dropdown-group-shop"
            :header="$t('nav.business')"
          >
            <li v-if="vxAccountCompanyId" class="profile group">
              {{ $t("nav.id") }} : {{ vxAccountCompanyId }}
            </li>
            <li
              v-if="vxAccountLevel === vxConstantsAccountLevel.business"
              class="profile group"
            >
              {{ $t("nav.shopNumber") }} : {{ techNumber }}
              {{ $t("nav.unit") }}
            </li>
          </b-dropdown-group>

          <b-dropdown-divider></b-dropdown-divider>

          <router-link to="/changePassword" tag="li" class="navItem">
            <a>{{ $t("nav.changePassword") }}</a>
          </router-link>

          <b-dropdown dropleft :text="$t('nav.Language')" class="profileclick">
            <!-- 切換語系的判斷沒改 -->
            <b-dropdown-item @click="en" id="en">English</b-dropdown-item>
            <!-- <b-dropdown-item @click="tw" id="tw">中文</b-dropdown-item>
            <b-dropdown-item @click="fr" id="fr">français</b-dropdown-item> -->
          </b-dropdown>

          <router-link to="/privacyPolicy" tag="li" class="navItem">
            <a target="_blank">{{ $t("nav.PrivacyPolicy") }}</a>
          </router-link>

          <li class="navItem">
            <a href="javascript:;" id="signOut" @click.prevent="fnSignOut">
              {{ $t("nav.SignOut") }}
            </a>
          </li>

          <b-dropdown-divider></b-dropdown-divider>
          <li class="profile" style="text-align: center">
            {{ $t("nav.version") }}
          </li>
        </div>
      </b-collapse>
    </b-nav>
  </div>
</template>

<script>
import { APIgetPersonalProfile } from "../api";
export default {
  beforeMount() {
    this.$store.dispatch("setHeadshotDefault", false);
  },
  async mounted() {
    try {
      this.$store.dispatch("setHeadshotDefault", true);
      await this.$store.dispatch("fetchAndSetHeadshotImage");
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to fetch and set headshot image", error);
    }
    let id = this.vxLanguage;
    document.getElementById(id).classList.add("active");
    document.querySelector(".profileGroup").addEventListener(
      "click",
      (e) => {
        e.stopPropagation();
      },
      false
    );

    document.onclick = (e) => {
      if (e.target.dataset.item !== "headshoot" && this.profileToggle) {
        this.checkProfileToggle();
      }
    };
  },
  beforeDestroy() {
    document.onclick = null;
  },
  data() {
    return {
      loading: false,
      techNumber: 0,
      deposit: 0,
      profileToggle: false,
    };
  },
  methods: {
    photoError() {
      // 圖片error event 使用預設圖
      this.$store.dispatch("setHeadshotDefault", true);
    },
    checkProfileToggle() {
      this.profileToggle = !this.profileToggle;
      if (this.profileToggle) {
        this.loading = true;
        this.fnGetPersonalProfile();
      } else {
        this.profileToggle = false;
        document.getElementById("navToggle").classList.remove("show");
        document.getElementById("navToggle").classList.remove("displayBlock");
      }
    },
    fnGetPersonalProfile() {
      if (this.vxAccountLevel !== this.vxConstantsAccountLevel.admin) {
        APIgetPersonalProfile()
          .then((res) => {
            this.techNumber = res.data.curMemberCount;
            this.deposit = res.data.point;
          })
          .catch((err) => {
            if (err.response != undefined && err.response.status === 401) {
              this.$bvModal.show("setCheckToken");
            } else {
              switch (err.response.data.error) {
                case "account does not exist":
                  this.$bvModal.show("accountNotExistToggle");
                  break;
                default:
                  this.$bvModal.show("setsomethingWrong");
                  break;
              }
            }
          })
          .then(() => {
            if (this.profileToggle) {
              document.getElementById("navToggle").classList.add("show");
              document
                .getElementById("navToggle")
                .classList.add("displayBlock");
            }
            this.loading = false;
          });
      } else {
        this.loading = false;
        document.getElementById("navToggle").classList.add("show");
        document.getElementById("navToggle").classList.add("displayBlock");
      }
    },
    en() {
      this.$store.dispatch("setLanguage", "en");
      this.$i18n.locale = this.vxLanguage;
      // 加一個語言包陣列，透過陣列跑迴圈，一個個remove掉"active"ssList.remove("active");
      // });
      document.getElementById("en").classList.add("active");
      this.languageBoxToggle = false;
      this.$router.go(0);
    },
    tw() {
      this.$store.dispatch("setLanguage", "tw");
      this.$i18n.locale = this.vxLanguage;
      document.getElementById("tw").classList.add("active");
      this.languageBoxToggle = false;
      this.$router.go(0);
    },
    fr() {
      this.$store.dispatch("setLanguage", "fr");
      this.$i18n.locale = this.vxLanguage;
      document.getElementById("fr").classList.add("active");
      this.languageBoxToggle = false;
      this.$router.go(0);
    },
    fnSignOut() {
      this.$store.dispatch("setAccountInformation", {
        accountLevel: "Technician",
      });
      this.$store.dispatch("setAccount", "");
      this.$store.dispatch("setSignInToggle", false);
      this.$store.dispatch("setHeadshotImage", "");
      localStorage.removeItem("token");
      localStorage.removeItem("vuex");
      this.$router.push("/");
    },
  },
  computed: {
    vxHeadshotImage() {
      return this.$store.state.headshotImage;
    },
    vxHeadshotDefault() {
      return this.$store.state.headshotDefault;
    },
    vxAccountPlan() {
      let plan;
      switch (this.$store.state.accountInformation.pricingPlan) {
        case "0":
          plan = "Basic";
          break;
        case "1":
          plan = "Pro";
          break;
        case "2":
          plan = "Enterprise";
          break;
      }
      let retuernData = "";
      if (plan === this.vxConstantsDataPlan.basic)
        retuernData = this.$t("subscribePlan.basic.title");
      if (plan === this.vxConstantsDataPlan.shop)
        retuernData = this.$t("subscribePlan.pro.title");
      if (plan === this.vxConstantsDataPlan.business)
        retuernData = this.$t("subscribePlan.enterprise.title");
      return retuernData;
    },
    vxAccount() {
      return this.$store.state.account;
    },
    vxAccountLevel() {
      return this.$store.state.accountInformation.accountLevel;
    },
    vxAccountShopId() {
      return this.$store.state.accountInformation.shopId;
    },
    vxAccountCompanyId() {
      return this.$store.state.accountInformation.companyId;
    },
    vxLanguage() {
      return this.$store.state.language;
    },
    vxConstantsAccountLevel() {
      return this.$store.state.constants.csAccountLevel;
    },
    vxConstantsDataPlan() {
      return this.$store.state.constants.csDataPlan.old;
    },
    subdomain() {
      return this.$root.subdomain;
    },
  },
};
</script>

<style lang="scss">
.testReportTop {
  width: 95%;
  margin: 1rem auto 0;
}
.testReportBottom {
  width: 95%;
  margin: 1rem auto 3rem;
  .rounded-sm {
    width: 100%;
    margin: auto;
  }
}
.testReportBT {
  max-height: 400px;
  overflow: hidden;
  background: $dark_background;
  border-radius: 0.45rem;
}
.BTleft {
  margin-right: 15px;
}
.BTright {
  margin-left: 15px;
}
.vgt-table tr > td {
  height: 45px;
  > div {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mx-table .cell {
  vertical-align: middle;
}

@media (max-width: 1200px) {
  .testReportBT {
    margin: 15px auto;
  }
}
@media (max-width: 991px) {
  .testReportTop {
    width: 98%;
  }
}

.STbox {
  height: 400px;
  overflow: hidden;
  background: $dark_background;
  border-radius: 0.45rem;
  margin: 0 10px;
  position: relative;
  &:nth-child(1) {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  .STtiltle {
    position: absolute;
    font-size: 1.15rem;
    font-weight: 700;
    color: white;
    font-family: $font-family;
    top: 5%;
    left: 0;
    right: 0;
  }
}
@media (max-width: 1440px) {
  .chartST {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 15px 0;
  }
}
@media (max-width: 767px) {
  .chartST {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.navbar {
  background: $nav_background;
  padding: 4px 1rem;
  position: fixed;
  width: 100%;
  z-index: 100;
  display: block;
}

.navbar-brand {
  float: left;
  .navbar-text {
    display: flex;
    i {
      color: white;
    }
    .navTitle {
      font-size: 16px;
      margin-left: 20px;
      color: #aaa;
      span {
        color: red;
        border-radius: 2rem;
        border: solid 1px red;
        padding: 2px 4px;
        margin: 0 5px;
      }
    }
  }
}

.navToggleButton {
  border-radius: 50%;
  float: right;
  padding: 0;
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  i {
    font-size: 20px;
    line-height: 38px;
  }
  .profileBox,
  .loading {
    width: 38px;
    height: 38px;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .profileBox {
    position: absolute;
    top: 0;
    .buttonLoad {
      font-size: 12px;
      margin: 0;
    }
  }
  .headShoot {
    width: 110%;
  }
}

.dropdown-header {
  font-size: 16px;
  font-family: $font-family;
}

.profile {
  padding: 5px 15px;
  margin: 5px 0 5px 10px;
  font-family: $font-family;
  cursor: default;
}

.navItem a,
.profileclick a,
.profileclick button {
  padding: 10px 0;
  display: block;
  &:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  &:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
  }
}

.navItem a {
  text-align: center;
}

.profileclick a {
  padding-left: 25px;
}

.profileclick button {
  color: #007bff;
  font-family: $font-family;
  &.btn-secondary:focus {
    color: #007bff;
    background: #fff;
  }
  &.btn-secondary:not(:disabled):not(.disabled):active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff;
  }
  &.dropdown-toggle.btn-secondary {
    padding: 3px 0;
    margin: 0;
    border-radius: 0;
  }
}

.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}

.b-dropdown.profileclick {
  width: 100%;
}

#_bv_dropdown-group-shop_group_dd_header {
  cursor: default;
}

.profile.group {
  margin-left: 25px;
}

.nav-link {
  padding: 0;
  i {
    width: 100%;
    height: 100%;
    line-height: 32px;
  }
}

.profileGroup {
  transition: 0.5s;
  background: #fff;
  border-radius: 3px;
  text-align: left;
  padding: 10px 15px 10px 10px;
}

#navToggle {
  position: absolute;
  right: 16px;
  top: 50px;
}

.displayBlock {
  display: block !important;
}

.changeImage {
  display: flex;
  align-items: center;
  .imageBox {
    border: solid 1px #aaa;
    margin-right: 20px;
    width: 50px;
    background: #ddd;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    border-radius: 50px;
    position: relative;
    transition: 0.2s;
    overflow: hidden;
    cursor: pointer;
    span {
      transition: 0.2s;
      opacity: 0;
      font-size: 12px;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      line-height: 20px;
      text-align: center;
    }
    &:hover span {
      background: rgba(204, 204, 204, 0.7);
    }
    &:hover span {
      opacity: 1;
    }
    .headShoot {
      width: 110%;
    }
  }
}

#uploadImageToggle___BV_modal_body_ {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.uploadImageBox {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #aaa;
  background: #ddd;
  border-radius: 50%;
  overflow: hidden;
  i {
    font-size: 55px;
  }
  img {
    width: 110%;
  }
}
.controlBox {
  p {
    margin: 15px 0;
  }
}
</style>
