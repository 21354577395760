<template>
  <div>
    <div class="userTitle">
      {{ title }}
    </div>
    <!-- 有data -->
    <div v-if="haveData">
      <div class="userNumber" :class="isSmallBox ? 'smallBox' : ''">
        <div class="Number">{{ today }}</div>
        <div class="Percen" :class="percenColor">
          {{ count }}
        </div>
      </div>
      <div class="userBottom">
        <span class="userDate">on {{ data_date }}</span>
        <slot name="text">text</slot>
      </div>
    </div>
    <!-- 無data -->
    <div v-else>
      <div class="userNumber">
        <div class="Percen">
          <slot name="nodata"></slot>
          <div class="nodata">
            {{ $t('home.nodata') }}
          </div>
        </div>
      </div>
      <div class="userBottom">
        <span class="userDate"></span>
        <slot name="text">text</slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      data_userChart: {
        type: Object,
        default: () => {
          return {
            today: 0,
            yesterday: 0,
          };
        },
      },
      data_date: String,
      title: String,
      haveData: {
        type: Boolean,
        default: true,
      },
      isSmallBox: Boolean,
    },
    computed: {
      today() {
        return this.data_userChart.today;
      },
      yesterday() {
        return this.data_userChart.yesterday;
      },
      // today跟yesterday的差
      count() {
        let num = this.today - this.yesterday;
        if (num >= 0) {
          num = `+${num}`;
        }
        return num;
      },
      percenColor() {
        if (this.today - this.yesterday === 0) {
          return this.percenClass[0];
        }
        if (this.today - this.yesterday > 0) {
          return this.percenClass[1];
        } else {
          return this.percenClass[2];
        }
      },
    },
    data() {
      return {
        percenClass: ['zero', 'plus', 'less'],
      };
    },
  };
</script>

<style lang="scss" scoped>
  .userTitle {
    font-size: 20px;
    font-weight: 500;
    text-align: left;
  }
  .userDate {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
  }
  .Percen.plus {
    color: rgb(0, 173, 57);
  }

  .Percen.less {
    color: rgb(255, 77, 77);
  }

  .Percen.zero {
    color: rgb(243, 209, 124);
  }

  .userNumber {
    display: flex;
    justify-content: space-around;
    .Number {
      font-size: 46px;
    }
    .Percen {
      line-height: 60px;
      font-size: 24px;
    }
    .nodata {
      color: #8baccb;
      user-select: none;
    }
  }

  .smallBox {
    @media (min-width: 1200px) {
      flex-direction: column;
    }
  }

  .userBottom {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0 0;
  }

  .info {
    font-size: 16px;
    line-height: 20px;
    width: 20px;
    height: 20px;
    color: $sub_background;
    cursor: default;
  }

  .userTitle,
  .userNumber {
    padding: 5px 0;
  }
</style>
