<template>
  <div>
    <nav id="languageBar">
      <div id="language" @click="fnLanguageToggle">
        {{ $t("nav.Language") }}
      </div>
      <ul id="languageBox" v-show="languageBoxToggle">
        <li @click="en" id="en">English</li>
        <!-- <li @click="tw" id="tw">中文</li>
        <li @click="fr" id="fr">français</li> -->
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      languageBoxToggle: false,
    };
  },
  mounted() {
    let id = this.vxLanguage;
    document.getElementById(id).classList.add("active");
  },
  methods: {
    en() {
      this.$store.dispatch("setLanguage", "en");
      this.$i18n.locale = this.vxLanguage;
      Array.from(document.getElementsByTagName("li")).forEach((el) => {
        el.classList.remove("active");
      });
      document.getElementById("en").classList.add("active");
      this.languageBoxToggle = false;
    },
    tw() {
      this.$store.dispatch("setLanguage", "tw");
      this.$i18n.locale = this.vxLanguage;
      Array.from(document.getElementsByTagName("li")).forEach((el) => {
        el.classList.remove("active");
      });
      document.getElementById("tw").classList.add("active");
      this.languageBoxToggle = false;
    },
    fr() {
      this.$store.dispatch("setLanguage", "fr");
      this.$i18n.locale = this.vxLanguage;
      this.languageBoxToggle = false;
    },
    fnLanguageToggle() {
      this.languageBoxToggle = !this.languageBoxToggle;
    },
  },
  computed: {
    vxLanguage() {
      return this.$store.state.language;
    },
  },
};
</script>

<style lang="scss" scoped>
#languageBar {
  display: flex;
  justify-content: flex-end;
  margin: 10px;
  position: relative;
  font-family: $font-family;
}

#languageBox {
  border: solid 1px #ced4da;
  display: inline-block;
  padding: 8px 0;
  border-radius: 0.25rem;
  position: absolute;
  z-index: 15;
  right: 0;
  top: 150%;
  background: white;
  li {
    cursor: pointer;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #6c757d;
    background: white;
    &:hover {
      color: white;
      background: $sub_light_background;
    }
  }
  li.active {
    color: white;
    background: $dark_background;
  }
}
</style>

<style lang="scss">
#language {
  user-select: none;
  cursor: pointer;
  color: #ddd;
  transition: 0.2s;
  &::after {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  &:hover {
    color: $title_color;
  }
}
</style>
