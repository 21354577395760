const APP_VERSION = require("../../public/version.json");
export default {
  //#region sidebar
  sidebar: {
    Home: "Home____",
    deviceManagement: "Device management____",
    changePassword: "Change password____",
    ListAllUsers: "Manage member list____",
    createPoints: "Create point code____",
    testReport: "Test report____",
    "bt2200-record-bt": "BT2200 Battery test____",
    "bt2200-record-st": "BT2200 System test____", // new
    "bt2300plus-record-bt": "BT2300+ Battery test____",
    "bt2300plus-record-st": "BT2300+ System test____",
    "btw300-record-bt": "BTW300 Battery test____",
    "btw300-record-st": "BTW300 System test____",
    "btw200-record-bt": "BTW200 Battery test____",
    "btw200-record-st": "BTW200 Cranking/Charging test____",
    "btw200-record-ivt": "BTW200 In vehicle test____",
    "btw200-record-vm": "BTW200 Smart charging monitor____",
    "bt2400-record-bt": "BT2400 Battery test____",
    "bt2400-record-st": "BT2400 System test____",
    "bt2400-record-ivt": "BT2400 In vehicle test____",
    "ba427plus-record-bt": "BA427+ Battery test____", // new
    "ba427plus-record-st": "BA427+ System test____", // new
  },
  //#endregion

  //#region nav bar
  nav: {
    free: "Free trial____",
    logo: "Dashboard____",
    plan: "Data Plan____",
    deposit: "Deposit____",
    Language: "Language____",
    PrivacyPolicy: "Privacy policy____",
    SignOut: "Sign out____",
    version:
      process.env.VUE_APP_NODE_ENV !== "PROD"
        ? APP_VERSION.DEV_VERSION
        : APP_VERSION.PROD_VERSION,
    edit: "Edit____", // new
    point: "point____", // new
    id: "ID____", // new
    techNumber: "Basic Account number____", // new
    shopNumber: "Shop Account number____", // new
    unit: "members____", //new
    shop: "Shop____",
    business: "Business____",
    pointsCalculation: "Billing Calculation____", // new
  },
  //#endregion

  //#region modal
  modal: {
    // title
    error: "Error____",
    notice: "Notice____",
    success: "Success____",
    warning: "Warning____",
    inviteUser: "Invite User____", // update
    inviteShop: "Invite Shop____", //  new
    addadevice: "Add a device____",
    subscribeDetail: "Subscription details____",
    changeHead: "Change your personal headshot____", // new
    invalid: "Invalid Action____",
    // content
    signIn: {
      forgotPassword: {
        error: {
          text1: "Invalid email address.____",
          text2: "Please enter a valid address.____",
        },
        success: {
          text1: "We have sent you an email to reset your password.____",
          text2: "Please check inbox.____",
        },
      },
      resetPassword: {
        text1:
          "The first time you sign in, you need to change your password.____",
        text2: "Go to Change Password.____",
      },
      verified: {
        text1: "This account has not been verified.____",
        text2: "Please check inbox to verify your account.____",
        text3: "If you don't receive email, please check your spam folder.____",
      },
      sendEmail: {
        text1: "The verification email is sent to your email:____",
        text2: "Please check inbox to verify your account.____",
        text3: "If you don't receive email, please check your spam folder.____",
      },
      pwExpired: "Your password has expired and must be changed.____",
    },
    changePassword: {
      success: {
        text1: "Password changed successfully____",
        text2: "Please use this password to sign in.____", // update
      },
      error: {
        text1: "Same as your old password, please re-enter____",
      },
    },
    createShop: {
      success: {
        text1: "Successfully created, please wait...____",
      },
      error: {
        text1: "You already have a Shop.____",
      },
    },
    createConp: {
      // new
      success: {
        text1: "Successfully created, please wait...____",
      },
      error: {
        text1: "You already have a Business.____",
      },
    },
    registerUser: {
      success: {
        text1: "A verification link has been sent to your email.____",
        text2: "Please check inbox to verify your email.____",
        text3: "If you don't receive email, please check your spam folder.____",
      },
      error: {
        text1: "Sorry.____",
        text2: "The email address is already registered.____",
        text3: "Please use a different email address.____",
      },
    },
    deviceManagement: {
      deviceOnly: {
        text1:
          "This page is only for BT2200、BT2300 Plus、BT2400 and BA427 Plus.____",
        text2: "never show again____",
      },
      noSelect: "Please select a device first.____",
      onlySelect: "Only one device can be removed at a time.____",
      remove: {
        success: {
          text1: "will be removed.____",
          text2: "Would you like to continue?____",
        },
        error: "not exist.____",
      },
      addDevice: {
        text1:
          "Please select a method to complete the device registration.____",
        text2: "Scan 2D barcode (QR code)____",
        text3: "Scan 1D barcode (code 39)____",
        text4: "Manually enter the registration code____",
        text5:
          "Please scan the barcode to complete the device registration.____", // new
        text6: "This code will expire after 20 minutes.____",
        text7:
          "Please manually enter the registration code to complete the device registration.____",
        text8: "Note:____", // new
        text9:
          "* BT2200/BT2400/BA427Plus only support 2D Barcode and Manually input.____", // new
      },
    },
    shopMemberList: {
      inviteLable: {
        // new
        shop: "Email____",
        company: "Shop ID____",
        shopPlaceholder: "Account____",
        companyPlaceholder: "shop ID____",
      },
      invite: "Please enter the account email to invite.____",
      inviteShop: "Please enter the Shop ID to invite.____", // new
      success: "Invitation email has been sent.____",
      error: {
        text1: "Mail format error.____",
        text2:
          "Please check the spelling of your request. It has to be 100% identical to a registered and valid name in the system. ____",
        text3:
          "Your request is invalid. The request of adding a new account is denied due to the limitation of the system.____",
        text4:
          "The request has been denied because of a conflict. The user has joined another Shop/Business. The user may not join 2 Shop/Business at the same time. Please contact the user before inviting the user.____",
        text5:
          "Each account is defaulted to support 100 Shop Manager Accounts for a Business Manager Account or 20 Basic Users for a Shop Manager.____",
        text6:
          "If you like to manage a program exceeding the numbers, please contact your agent for a customeized program.____",
      },
      noSelect: "Please select an account first.____",
      onlySelect: "Only one account can be selected at a time.____",
      delete: {
        success: {
          text1: "will be deleted.____",
          text2: "Would you like to continue?____",
        },
        error: "not exist.____",
      },
      detach: {
        success: {
          text1: "will be removed from the Shop.____",
          text2: "Would you like to continue?____",
        },
        companySuccess: {
          text1: "Shop ID____",
          text2: "will be removed from the Business.____",
        },
        error: "You can't remove yourself from the shop.____",
        errorOnlyKickShop: "You can only remove the Shop Account.____", // new
      },
      disband: {
        text1: "Are you sure you want to dismantle the Shop?____",
        text2:
          "The group and its data will NO longer exist once the Shop has been dismantled.____",
        text3:
          "You will NOT be able to review the test data uploaded by Accounts invited by you.____",
        text4:
          "If you like to continue dismantling the Shop, please enter “confirm” in the below box.____",
        text5: "Are you sure?____",
        text6: "Are you sure you want to dismantle the Business group?____",
        text7:
          "The group and its data will NO longer exist once the Business group has been dismantled.____",
        text8:
          "You will NOT be able to review the test data uploaded by Accounts invited by you.____",
        text9:
          "If you like to continue dismantling the Business group, please enter “confirm” in the below box.____",
        success: "Successfully dismantled, please wait...____",
      },
      // edit頁面暫時移除
      editAccount: {
        text1: "Will jump to edit page.____",
        text2: "Would you like to continue?____",
      },
      haveDevice: {
        text1: "There are still devices registered under this account.____",
        text2: "Please deregister devices before you delete the account.____",
      },
      handover: {
        text1: "Are you sure you want to hand over to____",
        success1: "The request will be valid only for 24 hours.____",
        success2: " Please notify User____",
        success3:
          "to complete the process by clicking the hyper link in his/her notification email.____",
      },
    },
    subscribe: {
      planTitle: "Data Plan____",
      basic: "Basic____",
      shop: "Shop____",
      business: "Business____",
      planSubTitle: "Account____",
      memberTitle: "Default to Manage Basic Account____",
      shopManagerTitle: "Default to Manage Shop Account____",
      memberSubTitle: "members____",
      priceTitle: "Incurred points____",
      priceSubTitle: "point / per month____",
      free: "Free / per month____",
      info: {
        basic: {
          text1: "Downgrade to a Basic Account plan.____",
          text2: "Your Shop will be dismissed after the downgrade____",
          text3: "The stored test data will no longer exist.____",
        },
        pro: {
          text1:
            "Subscribe to a Shop Account plan and you will enjoy your free trial until end of the month.____",
          text2:
            "On the first calendar day of next month, your account will be charged for your data plan.____",
        },
        enterprise: {
          text1:
            "Subscribe to a Business Account plan and you will enjoy your free trial until end of the month.____",
          text2:
            "On the first calendar day of next month, your account will be charged for your Business group’s data plan and your members’ account fees____",
          shopInComp: "* Business Account will pay the fee.____",
        },
        important: {
          text1:
            "* The system will send a notice when the points in your deposit is lower than the requirement for your monthly data plan.____",
          shop: "Remind!!! Your Shop will be dismissed after the downgrade to a Basic plan. The stored test data will no longer exist. You will not be able to review the data.____",
          business:
            "Remind!!! Your Business will be dismissed after the downgrade to a Basic plan. The stored test data will no longer exist. You will not be able to review the data.____",
          text3:
            "Please contact your agent to purchase points, recharging your account with the points.____",
        },
      },
      check: "Are you sure you want to subscribe?____",
      success: {
        text1: "You have successfully subscribed to____",
        text2: "Account Plan.____",
        pro: "Default to Manage Basic Account :____",
        proInfo1: "2 Point / per additional Basic Account mamber____",
        proInfo2: "can managing up to 20 Basic Account members____",
        company: "Default to Manage Shop Account :____",
        companyInfo1: "5 Point / per additional Shop Account mamber____",
        companyInfo2: "can managing up to 100 Shop Account members____",
        unit: "members.____",
        text5: "On the first calendar day of next month____",
        text6: "your account will be charged for your data plan.____",
        shopInComp:
          "Please be notified you will have to pay for the subscription fees of your Business group’s members. Please refer to the member list and their subscription plans and their fees. The system will also send a billing notice in the beginning of each month.____",
      },
    },
    createPoint: {
      success: {
        text1: "Export your point code____",
        text2: "Point:____",
        text3: "Set Number:____",
        text4: "please wait...____",
      },
    },
    storedPoint: {
      success: "Deposit Point Success.____",
    },
    headshot: {
      //new
      upload: "Upload____",
      text: "Please use JPG or JPEG photo type____",
      success: "Upload Success____",
    },
    tokenError:
      "Sorry. The token is unauthorized or has expired. Please sign in again.____",
    somethingWrong: "Sorry. Something went wrong. Please try again.____",
    unauthorized: "Invalid action. account is unauthorized.____", // update
    accountNotExist:
      "The specified account does not exist, please check again.____",
  },
  //#endregion

  //#region button
  button: {
    ok: "OK____",
    close: "Close____",
    yes: "YES____",
    no: "NO____",
    resentMail: "Resend verification email____",
    submit: "Submit____",
    create: "Create____",
    addDevice: "Add a Device____",
    removeDevice: "Remove a Device____",
    refresh: "Refresh____",
    columns: "Columns____",
    export: "Export____",
    edit: "Edit____",
    handOver: "Hand over____",
    deleteAccount: "Delete____",
    inviteUser: "Invite user____", // update
    inviteShop: "Invite Shop____", //new
    sendInvitation: "send invitation____",
    disband: "Dismantle____",
    detach: "Remove User____",
    remove: "Remove Shop____", //new
    batteryTest: "Battery test____",
    systemTest: "System test____",
    back: "back____",
    subscribe: "Subscribe____",
    stored: "stored value____",
    save: "Save____", //new
    download: "Download____", //new
    buildShop: "Build Your Shop____", //new
    buildComp: "Build Your Business____", //new
    exportCSV: "CSV____",
    exportExcel: "Excel____",
  },
  //#endregion

  //#region Table - filter
  filter: {
    addFilter: "Add Filter____",
    choose: "Choose____",
    search: "Search____",
    cont: "Contains____", // new
    ncont: "Not Contains____", // new
    eql: "=____", // new
    lte: "<=____", // new
    gte: ">=____", // new
    between: "Between____", // new
    value: "Value____", // new
    min: "Min____", // new
    max: "Max____", // new
    to: "to____", // new
    timeTypeLocal: "Local Time____",
    timeTypeUTC: "UTC____",
    optionSelect: "Select____",
    filterTypeString: "String____",
    filterTypeNumber: "Number____",
  },
  //#endregion

  //#region Page - signIn
  signIn: {
    signIn: "Sign in____",
    forgotPassword: {
      title: "Forgot password____",
      text1: "Please enter your email address.____",
      text2: "We will send you an email to reset your password.____",
    },
    register: "Register____",
    text1Placeholder: "Email address____",
    text2Placeholder: "Password____",
  },
  //#endregion

  //#region Page - change password
  changePassword: {
    title: "Change password____",
    subtitle: "Please complete the form below to change your password.____",
    inputText1: "Password____",
    inputText2: "Confirm password____",
    password: "********____",
  },
  //#endregion

  //#region Page - create shop
  createShop: {
    title: "Build a new Shop____",
    subtitle: "Please complete the form below to build a new Shop.____",
    inputText1: "Shop ID____",
    inputText1Placeholder: "please enter your Shop ID____",
  },
  //#endregion

  //#region Page - create conp    // new
  createCompany: {
    title: "Build a new Business____",
    subtitle: "Please complete the form below to build a new Business.____",
    inputText1: "Business ID____",
    inputText1Placeholder: "please enter your Business ID____",
  },
  //#endregion

  //#region Page - register
  register: {
    title: "Register a new user____",
    subtitle: "Please complete the form below to register a new user.____",
    inputText1: "Email address____",
    placeholderInputText1: "you@example.com____", // new
    inputText2: "Password____", // new
    placeholderInputText2: "********____", // new
    inputText3: "Confirm Password____", // new
    placeholderInputText3: "********____", // new
    check1: "I have read and agreed to the____",
    notice:
      "If you are using BTW200, please make sure that the account you fill in is consistent with the account you created on the APP.____",
  },
  //#endregion

  //#region Page - device management
  deviceManagement: {
    errorListTitle: "Device registration error code list:____",
    list1: "01: The server is busy reading data. Please try again later.____",
    list2: "03: The server is busy writing data. Please try again later.____",
    list3:
      "06: The account does not exist. Please check your account first.____",
    list4: "07: The device has been registered successfully before.____",
    list5:
      "08: The device has been registered by someone else. Please deregister first.____",
    list6:
      "09: The registration code does not exist or already expires. Please try again.____",
  },
  //#endregion

  //#region Page - subscribe
  subscribePlan: {
    title: "Subscribe your Data Plan____",
    subtitle: "Choose the Data Plan you want and subscribe.____",
    subscribed: "Subscribed____",
    basic: {
      title: "Basic Account____",
      price: "Free____",
      text1: "Store the uploaded test data for 6 months.____",
      text2: "Review the uploaded data.____",
      text3: "Manage up to 3 devices.____",
    },
    pro: {
      title: "Shop Account____",
      priceSubTitle: "point / per month____",
      defaultManage: "default to manage : ____",
      memberUnit: "Basic Account members____",
      extra: "+2 point / additional Basic Account____",
      limit: "can managing up to 20 Basic Account members____",
      priceRule: {
        text1: "Store the uploaded test data for 12 months.____",
        text2: "Review members’ uploaded test data.____",
        text3: "Manage up to 3 devices____",
        text4: "Export the stored test data.____",
        text5:
          "Build up a Shop, managing up to 20 Basic Accounts’ uploaded test data.____",
        // new
        text6:
          "Enable a supportive Dashboard with statistical information,assisting a Shop/fleet management.You have to build up your Shop and invite users to join your Shop.____",
      },
    },
    enterprise: {
      title: "Business Account____",
      priceSubTitle: "point / per month____",
      defaultManage: "default to manage : ____",
      memberUnit: "Shop Account members____",
      extra: "+5 point / additional Shop Account____",
      limit: "can managing up to 100 Shop Account members____",
      priceRule: {
        text1: "Review members’ uploaded test data____",
        text2: "Export the stored test data.____",
        text3:
          "Enable a supportive Dashboard with statistical information, assisting a Shop/fleet management. You have to build up your Business group by inviting Shop Accounts and receiving their confirmation.____",
        text4: "Manage Shops status by Manage Member List.____",
        text5: "A Business could manage up to 100 Shop’s test data.____",
      },
    },
    icon: {
      text1: "Export____",
      text2: "Data Table____",
      text3: "Data Chart____",
      text4: "Dashboard____",
      text5: "Group____",
      text6: "Management____",
      text7: "Bigdata____",
    },
  },
  //#endregion

  //#region Page - create point
  createPoint: {
    title: "Create Point Code____",
    subtitle: "Please complete the form below to create point code.____",
    inputText1: "Point____",
    inputText1Placeholder: "What is the value of a point code____",
    inputText2: "Set number____",
    inputText2Placeholder: "How many sets of point code____",
  },
  //#endregion

  //#region Page - download excel     // new
  download: {
    title: "Your monthly report is downloading____",
    subtitle:
      "If download is not processing.please click the button below.____",
  },
  //#endregion

  //#region Page - stored point
  storedPoint: {
    title: "Stored point____",
    subtitle: "Please complete the form below to stored point.____",
    inputText1: "Point code____",
    inputText1Placeholder: "please enter your point code____",
  },
  //#endregion

  //#region Page - point calculation // new
  bill: {
    title: "Billing Calculation____",
    data: "Data Plan____",
    charged: "Charged Point____",
    shopDescription:
      "Each Shop Account is default to manage 3 Basic Accounts. It will incur extra monthly fee adding additional Basic Account(s) under its management.____",
    businessDescription:
      "Each Business Account is default to manage 5 Shop Accounts. It will incur extra monthly fee to additional Shop Account(s) under its management.____",
    plan: {
      basic: "Basic Account Plan____",
      shop: "Shop Account Plan____",
      business: "Business Account Plan____",
    },
    table: {
      shopID: "shop ID____",
      desc: "Decription____",
      fee: "Monthly Fee____",
      unit: "Points____",
      default: "Default____",
      extra: "Additional Accounts____",
      extraShop: "Additional Shop Accounts____",
      shopSubTotal: "Shop Sub Total (A)____",
      busSubTotal: "Business sub total (B)____",
      subTotal: "Business total (A+B)____",
      shopTotal: "Shop Total____",
      basicTotal: "Basic Total____",
    },
    note: {
      title: "Note____",
      shopFee:
        "2 points per Basic Accounts additional to default Basic account number.____",
      busFee:
        "5 points per Shop Accounts additional to default Shop account number.____",
      business:
        "Business Account's data plan (fee) includes all accounts in its hierarchy.It will be a combination of Shop Sub Total and Business Sub Total.____",
      shopCom: "Business Account will pay the fee.____",
    },
  },
  //#endregion

  //#region invalid
  invalidError: {
    changePassword: {
      title: "The password must satisfy the following rules:____",
      list1: "* At least 8 characters in length.____",
      list2: "* At least 1 numeric character.____",
      list3: "* At least 1 lowercase character.____",
      list4: "* At least 1 uppercase character.____",
      list5: "* At least 1 special character.____",
      list6: "* Not contain Email.____",
      list7: "* Different from your previous password.____",
      confirmPasswordError: "The password confirmation does not match.____",
    },
    createGruop: {
      // new
      // title: "The ID must satisfy the following rules:____",
      title1: "A Business/Shop ID is an unique ID for your Business/Shop.____",
      title2:
        "It will help the system to consolidate account list and accounts’ uploaded information and data.____",
      title3: "Please be advised that the ID is composed of____",
      list1: "* English alphabet.____",
      list2: "* Number.____",
      list3: "* At least 3 characters in length.____",
      list4: "* Up to 20 characters in length.____",
      list5: "* Not contain special character except - or _____",
      existID: "This ID has existed, please re-enter.____",
    },
    registerUser: {
      mailError:
        "Please type your email address in the format you@example.com.____",
    },
    signIn: {
      mailPwError: "The email or password is incorrect.____",
    },
    subscribe: {
      pointNotEnough: {
        text1:
          "Your upgrade request has been denied due to insufficient deposit.____",
        text2: "Current deposit:____", // update
        text3: "point(s)____", // update
        text4:
          "You may contact your agent for a consultancy of your data plan and its cost.____", // update
        text5: "Please go to ____", // new
        text6: "Billing Calculation____", // new
        text7: " and check your data plan and the monthly fee.____", // new
      },
      stillShopManager: {
        text1:
          "Please go detach Techs from your Shop before downgrading your data plan.____",
        text2:
          "Warning!!! Your Shop will no longer exist once you downgrade your data plan to Basic User plan. You will NOT be able to review your Tech’s test data.____",
      },
      samePlan: {
        // new
        text: "Your subscription plan has not been changed yet. Please confirm the number of Tech/Shop Manager in your Shop/Business.____",
        shop: "Current Shop’s Tech number : ____",
        company: "Shop Manager number: ____",
        unit: "members.____",
      },
      haveGroup:
        "Warning! You are currently a Shop/Business Account. Please handover your position to your successor before changing your status or dismantling the Shop/Business. If you don’t handover your position to your successor, the stored data will not be retrieved after the request take plan.____",
      shopManagerInCompBefore:
        "Your Business group has been suspended for unpaid monthly fees. Please contact your Business' Business Account holder to proceed the payment.____", // new
      // new
      inAnyGruop:
        "Please leave your Shop/Business before you can change the data plan. Please contact your manager.____",
      // new
      needToRecover: {
        text1:
          "We noticed that you were a manager of a suspended Shop/Business. If you like to retrieve the stored data and resume collecting test data, please go to the subscription page and recharge it with points.____",
        text2:
          "If you do NOT know where to purchase the points, please contact your agent where you buy the product.____",
      },
    },
    createPoint: {
      onlyNumber: "Only fill in numbers____",
      lessThan: "Please enter less than 10000____",
    },
    stroedPoint: {
      invalid: "invalid point code.____",
      used: "This point code already used.____",
    },
  },
  //#endregion

  //#region table  //new
  tableColumns: {
    deviceManagement: {
      psn: "Device S/N____",
      model: "Model____",
      account: "Account____",
      shopId: "Shop ID____", // new
      companyId: "Business ID____", // new
    },
    accountList: {
      account: "Account____",
      verification: "Verification status____",
      accountLevel: "Account level____",
      pricingPlan: "Data Plan____",
      shopId: "Shop ID____",
      companyId: "Business ID____", // new
    },
    bt2200BT: {
      psn: "S/N____",
      testType: "Test Type____",
      judgement: "Judgement____",
      batteryType: "Battery Type____",
      voltage: "Voltage(V)____",
      setCapacity: "Set Capacity____", //new
      rating: "Rating____", //new
      measuredCca: "Measured____",
      temperature: "Temperature(°C)____",
      soc: "State of Charge(%)____",
      soh: "State of Health(%)____",
      testCode: "Code____",
      vin: "VIN Number____",
      testTime: "Test Date____",
      fwVersion: "Firmware Version____",
      account: "Account____",
      shopId: "Shop ID____",
      uploadTime: "Upload Time____",
      batteryCharged: "Battery Charged____",
    },
    bt2200ST: {
      psn: "S/N____",
      testType: "Test Type____",
      vin: "VIN____",
      crankingResult: "Cranking Result____",
      crankingVoltage: "Cranking Voltage(V)____",
      altIdleResult: "Alt Idle Result____",
      altIdleVoltage: "Alt Idle Voltage(V)____",
      rippleResult: "Ripple Result____",
      rippleVoltage: "Ripple Voltage(V)____",
      altLoadResult: "Alt Load Result____",
      altLoadVoltage: "Alt Load Voltage(V)____",
      testTime: "Test Time____",
      fwVersion: "Firmware Version____",
      account: "Account____",
      shopId: "Shop ID____",
      uploadTime: "Upload Time____",
    },
    bt2300plusBT: {
      psn: "S/N____",
      testType: "Test Type____",
      roNum: "RO Number____",
      vin: "VIN____",
      batteryType: "Battery Type____",
      testTime: "Date/Time____",
      nextChargeDate: "Next Maintenance Charge Date____",
      judgement: "Battery Condition____",
      setCapacity: "Set Capacity____", //new
      rating: "Rating____", //new
      measuredCca: "Tested CCA____",
      startVol: "Starting Volts(V)____",
      ocvAfterLoad: "OCV After Initial Load(V)____",
      endVol: "Ending Volts(V)____",
      chargeTime: "Charge Time(s)____",
      capacityAh: "Capacity (AH)____",
      temperature: "Temperature(°C)____",
      soh: "SOH(%)____",
      ir: "IR(mΩ)____",
      cellShort: "Cell Short____",
      surfaceCharge: "Surface Charge____",
      testCode: "Warranty Code____",
      fwVersion: "Software Version____",
      account: "Account____",
      shopId: "Shop ID____",
      uploadTime: "Upload Time____",
    },
    bt2300plusST: {
      psn: "S/N____",
      testType: "Test Type____",
      roNum: "RO Number____",
      vin: "VIN____",
      testTime: "Date/Time____",
      crankingResult: "Cranking Result____",
      crankingVoltage: "Cranking Voltage(V)____",
      altIdleResult: "Alt Idle Result____",
      altIdleVoltage: "Alt Idle Voltage(V)____",
      rippleResult: "Ripple Result____",
      rippleVoltage: "Ripple Voltage(V)____",
      altLoadResult: "Alt Load Result____",
      altLoadVoltage: "Alt Load Voltage(V)____",
      temperature: "Temperature(°C)____",
      fwVersion: "Software Version____",
      account: "Account____",
      shopId: "Shop ID____",
      uploadTime: "Upload Time____",
    },
    btw200BT: {
      psn: "S/N____",
      deviceName: "Device Name____",
      batterySn: "Battery SN____",
      systemVoltage: "System Voltage____",
      testTime: "Test Time____",
      testType: "Test Type____",
      vin: "VIN____",
      judgement: "Judgement____",
      batteryType: "Battery Type____",
      setCapacity: "Set Capacity____",
      measuredCca: "Measured CCA____",
      rating: "Rating____",
      soh: "SOH(%)____",
      soc: "SOC(%)____",
      voltage: "Voltage(V)____",
      isBatteryCharged: "Is Battery Charged____",
      account: "Account____",
      shopId: "Shop ID____",
      appVersion: "APP Version____",
      fwVersion: "FW Version____",
      uploadTime: "Upload Time____",
    },
    btw200ST: {
      psn: "S/N____",
      deviceName: "Device Name____",
      testTime: "Test Time____",
      testType: "Test Type____",
      crankingJudgement: "Cranking Judgement____",
      crankingVoltage: "Cranking Voltage(V)____",
      crankingMinVoltage: "Cranking Min Voltage(V)____",
      crankingTime: "Cranking Time(s)____",
      altIdleJudgement: "Alt Idle Judgement____",
      altIdleVoltage: "Alt Idle Voltage(V)____",
      rippleJudgement: "Ripple Judgement____",
      rippleVoltage: "Ripple Voltage(V)____",
      altLoadJudgement: "Alt Load Judgement____",
      altLoadVoltage: "Alt Load Voltage(V)____",
      smartChargingControl: "Smart Charging Control____",
      voltageMonitorLowest: "Voltage Monitor Lowest(V)____",
      voltageMonitorHighest: "Voltage Monitor Highest(V)____",
      rippleLowestJudgement: "Ripple Lowest Judgement____",
      rippleLowest: "Ripple Lowest(V)____",
      rippleHighestJudgement: "Ripple Highest Judgement____",
      rippleHighest: "Ripple Highest(V)____",
      monitorTime: "Monitor Time(s)____",
      account: "Account____",
      shopId: "Shop ID____",
      appVersion: "APP Version____",
      fwVersion: "FW Version____",
      uploadTime: "Upload Time____",
    },
    btw200IVT: {
      psn: "S/N____",
      deviceName: "Device Name____",
      batterySn: "Battery SN____",
      systemVoltage: "System Voltage____",
      testTime: "Test Time____",
      testType: "Test Type____",
      vin: "VIN____",
      judgement: "Judgement____",
      batteryType: "Battery Type____",
      setCapacity: "Set Capacity____",
      measuredCca: "Measured CCA____",
      rating: "Rating____",
      soh: "SOH(%)____",
      soc: "SOC(%)____",
      voltage: "Voltage(V)____",
      isBatteryCharged: "Is Battery Charged____",
      crankingJudgement: "Cranking Judgement____",
      crankingVoltage: "Cranking Voltage(V)____",
      crankingMinVoltage: "Cranking Min Voltage(V)____",
      crankingTime: "Cranking Time(s)____",
      altIdleJudgement: "Alt Idle Judgement____",
      altIdleVoltage: "Alt Idle Voltage(V)____",
      rippleJudgement: "Ripple Judgement____",
      rippleVoltage: "Ripple Voltage(V)____",
      altLoadJudgement: "Alt Load Judgement____",
      altLoadVoltage: "Alt Load Voltage(V)____",
      systemTestTime: "System Test Time____",
      smartChargingControl: "Smart Charging Control____",
      voltageMonitorLowest: "Voltage Monitor Lowest(V)____",
      voltageMonitorHighest: "Voltage Monitor Highest(V)____",
      rippleLowestJudgement: "Ripple Lowest Judgement____",
      rippleLowest: "Ripple Lowest(V)____",
      rippleHighestJudgement: "Ripple Highest Judgement____",
      rippleHighest: "Ripple Highest(V)____",
      monitorTime: "Monitor Time(s)____",
      monitorTestTime: "Monitor Test Time____",
      account: "Account____",
      shopId: "Shop ID____",
      appVersion: "APP Version____",
      fwVersion: "FW Version____",
      uploadTime: "Upload Time____",
    },
    btw200VM: {
      psn: "S/N____",
      deviceName: "Device Name____",
      testTime: "Test Time____",
      voltageMonitorLowest: "Voltage Monitor Lowest(V)____",
      voltageMonitorHighest: "Voltage Monitor Highest(V)____",
      monitorTime: "Monitor Time(s)____",
      account: "Account____",
      shopId: "Shop ID____",
      appVersion: "APP Version____",
      fwVersion: "FW Version____",
      uploadTime: "Upload Time____",
    },
    btw300BT: {
      psn: "S/N____",
      testTime: "Test Time____",
      testType: "Test Type____",
      vin: "VIN____",
      hybrid: "Hybird____",
      judgement: "Judgement____",
      batteryType: "Battery Type____",
      setCapacity: "Set Capacity____",
      measure: "Measured CCA____",
      rating: "Rating____",
      soh: "SOH(%)____",
      soc: "SOC(%)____",
      voltage: "Voltage(V)____",
      isBatteryCharged: "Is Battery Charged____",
      account: "Account____",
      shopId: "Shop ID____",
      appVersion: "APP Version____",
      fwVersion: "FW Version____",
      uploadTime: "Upload Time____",
    },
    btw300ST: {
      psn: "S/N____",
      testTime: "Test Time____",
      testType: "Test Type____",
      vin: "VIN____",
      crankingResult: "Cranking Judgement____",
      crankingVoltage: "Cranking Voltage(V)____",
      altIdleResult: "Alt Idle Judgement____",
      altIdleVoltage: "Alt Idle Voltage(V)____",
      rippleResult: "Ripple Judgement____",
      rippleVoltage: "Ripple Voltage(V)____",
      altLoadResult: "Alt Load Judagement____",
      altLoadVoltage: "Alt Load Voltage(V)____",
      account: "Account____",
      shopId: "Shop ID____",
      appVersion: "APP Version____",
      fwVersion: "FW Version____",
      uploadTime: "Upload Time____",
    },
    bt2400BT: {
      psn: "S/N____",
      testType: "Test Type____",
      vin: "VIN____",
      systemVolCh: "System Voltage Channel____",
      batteryType: "Battery Type____",
      rating: "Rating____",
      capacity: "Capacity____",
      batInVehicle: "Battery In Vehicle____",
      batCharged: "Battery Charged____",
      voltage: "Voltage(V)____",
      soh: "State of Health(%)____",
      soc: "State of Charge(%)____",
      ir: "IR(mΩ)____",
      measuredCca: "Measured CCA____",
      temperature: "Temperature(°C)____",
      judgement: "Judgement____",
      testTime: "Test Time____",
      fwVersion: "Firmware Version____",
      account: "Account____",
      shopId: "Shop ID____",
      uploadTime: "Upload Time____",
    },
    bt2400ST: {
      psn: "S/N____",
      testType: "Test Type____",
      vin: "VIN____",
      systemVolCh: "System Voltage Channel____",
      crankingResult: "Cranking Result____",
      crankingVoltage: "Cranking Voltage(V)____",
      crankingTime: "Cranking Time(s)____",
      altIdleResult: "Alt Idle Result____",
      altIdleVoltage: "Alt Idle Voltage(V)____",
      rippleResult: "Ripple Result____",
      rippleVoltage: "Ripple Voltage(V)____",
      altLoadResult: "Alt Load Result____",
      altLoadVoltage: "Alt Load Voltage(V)____",
      dieselCheck: "Diesel Check____",
      testTime: "Test Time____",
      fwVersion: "Firmware Version____",
      account: "Account____",
      shopId: "Shop ID____",
      uploadTime: "Upload Time____",
    },
    bt2400IVT: {
      psn: "S/N____",
      testType: "Test Type____",
      vin: "VIN____",
      systemVolCh: "System Voltage Channel____",
      batteryType: "Battery Type____",
      rating: "Rating____",
      capacity: "Capacity____",
      batInVehicle: "Battery In Vehicle____",
      batCharged: "Battery Charged____",
      voltage: "Voltage(V)____",
      soh: "State of Health(%)____",
      soc: "State of Charge(%)____",
      ir: "IR(mΩ)____",
      measuredCca: "Measured CCA____",
      judgement: "Judgement____",
      testTime: "Battery Test Time____",
      crankingResult: "Cranking Result____",
      crankingVoltage: "Cranking Voltage(V)____",
      crankingTime: "Cranking Time(s)____",
      altIdleResult: "Alt Idle Result____",
      altIdleVoltage: "Alt Idle Voltage(V)____",
      rippleResult: "Ripple Result____",
      rippleVoltage: "Ripple Voltage(V)____",
      altLoadResult: "Alt Load Result____",
      altLoadVoltage: "Alt Load Voltage(V)____",
      temperature: "Temperature(°C)____",
      dieselCheck: "Diesel Check____",
      sysTestTime: "System Test Time____",
      fwVersion: "Firmware Version____",
      account: "Account____",
      shopId: "Shop ID____",
      uploadTime: "Upload Time____",
    },
    ba427plusBT: {
      psn: "S/N____",
      testType: "Test Type____",
      roNum: "RO Number____",
      vin: "VIN____",
      recipient: "Recipient____",
      batteryType: "Battery Type____",
      rating: "Rating____",
      capacity: "Capacity____",
      batInVehicle: "Battery In Vehicle____",
      batCharged: "Battery Charged____",
      voltage: "Voltage(V)____",
      soh: "State of Health(%)____",
      soc: "State of Charge(%)____",
      ir: "IR(mΩ)____",
      measuredCca: "Measured CCA____",
      temperature: "Temperature(°C)____",
      judgement: "Judgement____",
      testCode: "Test code____",
      testTime: "Test Time____",
      fwVersion: "Firmware Version____",
      account: "Account____",
      shopId: "Shop ID____",
      uploadTime: "Upload Time____",
    },
    ba427plusST: {
      psn: "S/N____",
      testType: "Test Type____",
      roNum: "RO Number____",
      vin: "VIN____",
      recipient: "Recipient____",
      crankingResult: "Cranking Result____",
      crankingVoltage: "Cranking Voltage(V)____",
      altIdleResult: "Alt Idle Result____",
      altIdleVoltage: "Alt Idle Voltage(V)____",
      rippleResult: "Ripple Result____",
      rippleVoltage: "Ripple Voltage(V)____",
      altLoadResult: "Alt Load Result____",
      altLoadVoltage: "Alt Load Voltage(V)____",
      testTime: "Test Time____",
      fwVersion: "Firmware Version____",
      account: "Account____",
      shopId: "Shop ID____",
      uploadTime: "Upload Time____",
    },
    noMatch: "No matching records found____",
    next: "Next____",
    prev: "Prev____",
    of: "of____",
  },
  filterOption: {
    bt2200BT: {
      testType: {
        1: "Battery Test____",
        2: "Start-Stop Test____",
      },
      judgement: {
        0: "GOOD & PASS____",
        1: "GOOD & RECHARGE____",
        2: "BAD & REPLACE____",
        3: "CAUTION____",
        4: "RECHARGE & RETEST____",
        5: "BAD CELL REPLACE____",
      },
      batteryType: {
        0: "FLOODED____",
        1: "AGM FLAT PLATE____",
        2: "AGM SPIRAL____",
        3: "VRLA/GEL____",
        5: "EFB____",
      },
      rating: {
        // new
        0: "SAE____",
        1: "EN____",
        2: "EN2____",
        3: "IEC____",
        4: "JIS____",
        5: "DIN____",
        7: "CA/MCA____",
      },
      batteryCharged: {
        1: "Yes____",
        2: "No____",
      },
    },
    bt2200ST: {
      testType: {
        3: "System Test____",
      },
      crankingResult: {
        4: "NO DETECTED____",
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      crankingVoltage: {
        "--.--": "--.--____",
      },
      altIdleResult: {
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      rippleResult: {
        4: "NO DETECTED____",
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      rippleVoltage: {
        "--.--": "--.--____",
      },
      altLoadResult: {
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
    },
    bt2300plusBT: {
      testType: {
        1: "Battery Test____",
        2: "Start-Stop Test____",
      },
      judgement: {
        0: "GOOD & PASS____",
        1: "GOOD & RECHARGE____",
        2: "BAD & REPLACE____",
        3: "CAUTION____",
        4: "RECHARGE & RETEST____",
        5: "BAD CELL REPLACE____",
      },
      batteryType: {
        0: "FLOODED____",
        1: "AGM FLAT PLATE____",
        2: "AGM SPIRAL____",
        3: "VRLA/GEL____",
        4: "SS-AGM____",
        5: "SS-EFB____",
      },
      rating: {
        // new
        0: "CCA____",
        1: "SAE____",
        2: "EN____",
        3: "IEC____",
        4: "JIS____",
        5: "DIN____",
        6: "CA____",
      },
      cellShort: {
        0: "NO____",
        1: "YES____",
      },
      surfaceCharge: {
        0: "NO____",
        1: "YES____",
      },
    },
    bt2300plusST: {
      testType: {
        1: "System Test____",
      },
      crankingResult: {
        0: "NO DETECT____",
        1: "LOW____",
        2: "NORMAL____",
        3: "HIGH____",
      },
      altIdleResult: {
        0: "NO DETECT____",
        1: "LOW____",
        2: "NORMAL____",
        3: "HIGH____",
      },
      rippleResult: {
        0: "NO DETECT____",
        1: "LOW____",
        2: "NORMAL____",
        3: "HIGH____",
      },
      altLoadResult: {
        0: "NO DETECT____",
        1: "LOW____",
        2: "NORMAL____",
        3: "HIGH____",
      },
    },
    btw300BT: {
      testType: {
        1: "Battery Test____",
        2: "Start-Stop Test____",
      },
      judgement: {
        0: "GOOD & PASS____",
        1: "GOOD & RECHARGE____",
        2: "BAD & REPLACE____",
        3: "CAUTION____",
        4: "RECHARGE & RETEST____",
        5: "BAD CELL REPLACE____",
      },
      batteryType: {
        1: "FLOODED____",
        2: "AGM FLAT PLATE____",
        3: "AGM SPIRAL____",
        4: "VRLA/GEL____",
        5: "PS FLOODED____",
        6: "PS AGM____",
      },
      rating: {
        0: "CCA/SAE____",
        1: "EN____",
        2: "IEC____",
        3: "JIS____",
        4: "DIN____",
        5: "CA/MCA____",
      },
      isBatteryCharged: {
        0: "N/A____",
        1: "NO____",
        2: "YES____",
      },
    },
    btw300ST: {
      testType: {
        1: "System Test____",
      },
      crankingResult: {
        0: "NO DETECT____",
        1: "LOW____",
        2: "NORMAL____",
        3: "HIGH____",
      },
      altIdleResult: {
        0: "NO DETECT____",
        1: "LOW____",
        2: "NORMAL____",
        3: "HIGH____",
      },
      rippleResult: {
        0: "NO DETECT____",
        1: "LOW____",
        2: "NORMAL____",
        3: "HIGH____",
      },
      altLoadResult: {
        0: "NO DETECT____",
        1: "LOW____",
        2: "NORMAL____",
        3: "HIGH____",
      },
    },
    btw200BT: {
      systemVoltage: {
        0: "12V____",
      },
      testType: {
        1: "Battery Test____",
        2: "Start-Stop Test____",
      },
      judgement: {
        0: "GOOD & PASS____",
        1: "GOOD & RECHARGE____",
        2: "BAD & REPLACE____",
        3: "CAUTION____",
        4: "RECHARGE & RETEST____",
        5: "BAD CELL REPLACE____",
      },
      batteryType: {
        1: "FLOODED____",
        2: "AGM FLAT PLATE____",
        3: "AGM SPIRAL____",
        4: "VRLA/GEL____",
        5: "PS FLOODED____",
        6: "PS AGM____",
        7: "EFB____",
      },
      rating: {
        0: "CCA/SAE____",
        1: "EN____",
        2: "IEC____",
        3: "JIS____",
        4: "DIN____",
        5: "CA/MCA____",
      },
      isBatteryCharged: {
        0: "N/A____",
        1: "NO____",
        2: "YES____",
      },
    },
    btw200ST: {
      testType: {
        1: "Cranking Test____",
        2: "Charging Test____",
      },
      crankingJudgement: {
        0: "N/A____",
        1: "LOW____",
        2: "PASS____",
      },
      crankingVoltage: {
        "--.--": "--.--____",
      },
      crankingTime: {
        "-": "-____",
      },
      altIdleJudgement: {
        0: "PASS____",
        1: "FAIL____",
      },
      rippleJudgement: {
        0: "N/A____",
        1: "PASS____",
        2: "HIGH____",
      },
      rippleVoltage: {
        "--.--": "--.--____",
      },
      altLoadJudgement: {
        0: "PASS____",
        1: "FAIL____",
      },
      smartChargingControl: {
        0: "YES____",
        1: "NO____",
      },
      rippleLowestJudgement: {
        0: "N/A____",
        1: "PASS____",
        2: "HIGH____",
      },
      rippleLowest: {
        "--.--": "--.--____",
      },
      rippleHighestJudgement: {
        0: "N/A____",
        1: "PASS____",
        2: "HIGH____",
      },
      rippleHighest: {
        "--.--": "--.--____",
      },
    },
    btw200IVT: {
      systemVoltage: {
        0: "12V____",
      },
      testType: {
        1: "In Vehicle Test____",
      },
      judgement: {
        0: "GOOD & PASS____",
        1: "GOOD & RECHARGE____",
        2: "BAD & REPLACE____",
        3: "CAUTION____",
        4: "RECHARGE & RETEST____",
        5: "BAD CELL REPLACE____",
      },
      batteryType: {
        1: "FLOODED____",
        2: "AGM FLAT PLATE____",
        3: "AGM SPIRAL____",
        4: "VRLA/GEL____",
        5: "PS FLOODED____",
        6: "PS AGM____",
        7: "EFB____",
      },
      rating: {
        0: "CCA/SAE____",
        1: "EN____",
        2: "IEC____",
        3: "JIS____",
        4: "DIN____",
        5: "CA/MCA____",
      },
      isBatteryCharged: {
        0: "N/A____",
        1: "NO____",
        2: "YES____",
      },
      crankingJudgement: {
        0: "N/A____",
        1: "LOW____",
        2: "PASS____",
      },
      crankingVoltage: {
        "--.--": "--.--____",
      },
      crankingTime: {
        "-": "-____",
      },
      altIdleJudgement: {
        0: "PASS____",
        1: "FAIL____",
      },
      rippleJudgement: {
        0: "N/A____",
        1: "PASS____",
        2: "HIGH____",
      },
      rippleVoltage: {
        "--.--": "--.--____",
      },
      altLoadJudgement: {
        0: "PASS____",
        1: "FAIL____",
      },
      smartChargingControl: {
        0: "YES____",
        1: "NO____",
      },
      rippleLowestJudgement: {
        0: "N/A____",
        1: "PASS____",
        2: "HIGH____",
      },
      rippleLowest: {
        "--.--": "--.--____",
      },
      rippleHighestJudgement: {
        0: "N/A____",
        1: "PASS____",
        2: "HIGH____",
      },
      rippleHighest: {
        "--.--": "--.--____",
      },
    },
    bt2400BT: {
      testType: {
        1: "Battery Test____",
        2: "Start-Stop Test____",
      },
      systemVolCh: {
        1: "6V____",
        2: "12V____",
        3: "24V____",
      },
      batteryType: {
        0: "FLOODED____",
        1: "AGM FLAT PLATE____",
        2: "AGM SPIRAL____",
        3: "VRLA/GEL____",
        4: "SS-AGM____",
        5: "EFB____",
      },
      rating: {
        0: "CCA/SAE____",
        1: "EN____",
        2: "EN2____",
        3: "IEC____",
        4: "JIS____",
        5: "DIN____",
        6: "CA/MCA____",
      },
      batInVehicle: {
        1: "Yes____",
        2: "No____",
      },
      batCharged: {
        1: "Yes____",
        2: "No____",
      },
      ir: {
        5: "LOW____",
        7: "HIGH____",
      },
      judgement: {
        0: "GOOD & PASS____",
        1: "GOOD & RECHARGE____",
        2: "BAD & REPLACE____",
        3: "CAUTION____",
        4: "RECHARGE & RETEST____",
        5: "BAD CELL REPLACE____",
      },
    },
    bt2400ST: {
      testType: {
        3: "System Test____",
      },
      systemVolCh: {
        1: "6V____",
        2: "12V____",
        3: "24V____",
      },
      crankingResult: {
        4: "NOT DETECTED____", // update
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      crankingVoltage: {
        "--.--": "--.--____",
      },
      altIdleResult: {
        4: "NOT DETECTED____", // update
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      rippleResult: {
        4: "NOT DETECTED____", // update
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      rippleVoltage: {
        "--.--": "--.--____",
      },
      altLoadResult: {
        4: "NOT DETECTED____", // update
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      dieselCheck: {
        1: "Yes____",
        2: "No____",
      },
    },
    bt2400IVT: {
      testType: {
        4: "In Vehicle Test____",
      },
      systemVolCh: {
        1: "6V____",
        2: "12V____",
        3: "24V____",
      },
      batteryType: {
        0: "FLOODED____",
        1: "AGM FLAT PLATE____",
        2: "AGM SPIRAL____",
        3: "VRLA/GEL____",
        4: "SS-AGM____",
        5: "EFB____",
      },
      rating: {
        0: "CCA/SAE____",
        1: "EN____",
        2: "EN2____",
        3: "IEC____",
        4: "JIS____",
        5: "DIN____",
        6: "CA/MCA____",
      },
      batInVehicle: {
        1: "Yes____",
        2: "No____",
      },
      batCharged: {
        1: "Yes____",
        2: "No____",
      },
      ir: {
        5: "LOW____",
        7: "HIGH____",
      },
      judgement: {
        0: "GOOD & PASS____",
        1: "GOOD & RECHARGE____",
        2: "BAD & REPLACE____",
        3: "CAUTION____",
        4: "RECHARGE & RETEST____",
        5: "BAD CELL REPLACE____",
      },
      crankingResult: {
        4: "NOT DETECTED____", // update
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      altIdleResult: {
        4: "NOT DETECTED____", // update
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      rippleResult: {
        4: "NOT DETECTED____", // update
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      altLoadResult: {
        4: "NOT DETECTED____", // update
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      dieselCheck: {
        1: "Yes____",
        2: "No____",
      },
    },
    ba427plusBT: {
      testType: {
        1: "Battery Test____",
        2: "Start-Stop Test____",
      },
      batteryType: {
        0: "FLOODED____",
        1: "AGM FLAT PLATE____",
        2: "AGM SPIRAL____",
        3: "VRLA/GEL____",
        4: "START-STOP AGM____", //update
        5: "EFB____",
      },
      rating: {
        0: "CCA____",
        2: "EN____",
        3: "IEC____",
        4: "JIS____",
        5: "DIN____",
        6: "CA____",
        7: "MCA____",
      },
      batInVehicle: {
        1: "Yes____",
        2: "No____",
      },
      batCharged: {
        1: "Yes____",
        2: "No____",
      },
      ir: {
        "--.--": "--.--____",
      },
      temperature: {
        0: "OVER 140℉/60℃____",
        1: "UNDER -4℉/-20℃____",
      },
      judgement: {
        0: "GOOD & PASS____",
        1: "GOOD & RECHARGE____",
        2: "BAD & REPLACE____",
        4: "RECHARGE RETEST____", //update
        5: "BAD CELL REPLACE____",
      },
    },
    ba427plusST: {
      testType: {
        3: "System Test____",
      },
      crankingResult: {
        4: "NO DETECT____",
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      crankingVoltage: {
        "--.--": "--.--____",
      },
      altIdleResult: {
        4: "NO DETECT____",
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      rippleResult: {
        4: "NO DETECT____",
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
      rippleVoltage: {
        "--.--": "--.--____",
      },
      altLoadResult: {
        4: "NO DETECT____",
        5: "LOW____",
        6: "NORMAL____",
        7: "HIGH____",
      },
    },
  },
  //#endregion

  //#region chart  //new
  chart: {
    title: {
      testTypeCounter: "Test Type Counter____",
      judgmentResultCounter: "Judgment Result Counter____",
      crankingResultCounter: "Cranking Result Counter____",
      rippleResultCounter: "Ripple Result Counter____",
      altIdleResultCounter: "Alt Idle Result Counter____",
      altLoadResultCounter: "Alt Load Result Counter____",
      month: "Month____",
      result: "Result____",
      total: "Total Result____",
      monthly: "Monthly Report____",
      firmwareVersion: "Firmware Version____",
      hourlyDay: "Hourly Day____",
      top5Account: "TOP5 Account Usage____",
      top5Shop: "TOP5 Shop____",
      testType: "Test Type Result____",
      batteryTypeMonth: "Battery Type Month Result____",
      batteryTypeYear: "Battery Type Result____",
      new: "New User____",
      active: "Active User____",
      inactive: "Inactive User____",
      totalUesr: "Total User____", // new
      newShop: "New Shop____", // new
      activeShop: "Active Shop____", // new
      totalShop: "Total Shop____", // new
    },
    label: {
      testedTimes: "Tested Times____",
      batteryTest: "BATTERY TEST____",
      startStopTest: "START-STOP TEST____",
      gp: "GOOD & PASS____",
      gr: "GOOD & RECHARGE____",
      rr: "RECHARGE & RETEST____",
      rnr: "RECHARGE RETEST____", // update
      ca: "CAUTION____",
      br: "BAD & REPLACE____",
      bcr: "BAD CELL REPLACE____",
      goodAndPass: "GOOD & PASS____",
      goodAndRecharge: "GOOD & RECHARGE____",
      rechargeAndRetest: "RECHARGE & RETEST____",
      rechargeRetest: "RECHARGE RETEST____", // update
      caution: "CAUTION____",
      badAndReplace: "BAD & REPLACE____",
      badCellReplace: "BAD CELL REPLACE____",
      noDetect: "NO DETECT____",
      noDetected: "NO DETECTED____",
      notDetected: "NOT DETECTED____", // new
      low: "LOW____",
      normal: "NORMAL____",
      high: "HIGH____",
      na: "N/A____",
      pass: "PASS____",
      fail: "FAIL____",
      ivtTest: "IN VEHICLE TEST____",
      total: "TOTAL____",
      replace: "REPLACE____",
      gd: "GOOD____",
      bd: "BAD____",
      oth: "OTHER____",
      month: "Month____",
      quarter: "Quarter____",
      records: "Records____",
      time: "Time____",
      account: "Account____",
      shop: "Shop ID____",
      batteryType: "Battery Type____",
      timePeriod: "Time Period____",
    },
    info: {
      new: "User number count for who just join the shop in the last 24 hours.____",
      active:
        "User number count for who has uploaded at least single test data in the last 24 hours.____",
      // new
      total:
        "User number count for who has ever registered and joined in the shop.____",
      newShop:
        "Shop number count for who just join the Business group in the last 24 hours.____", // new
      activeShop:
        "Shop number count for who has uploaded at least single test data in the last 24 hours.____", // new
      totalShop:
        "Shop number count for who has ever registered and joined in the Business group.____", // new
    },
    testType: {
      "Battery Test": "Battery Test____",
      "Start-Stop Test": "Start-Stop Test____",
      "System Test": "System Test____",
      "Cranking Test": "Cranking Test____",
      "Charging Test": "Charging Test____",
      "In Vehicle Test": "In Vehicle Test____",
      "Smart Charging Monitor": "Smart Charging Monitor____",
    },
    batteryType: {
      FLOODED: "FLOODED____",
      "AGM FLAT PLATE": "AGM FLAT PLATE____",
      "AGM SPIRAL": "AGM SPIRAL____",
      "VRLA/GEL": "VRLA/GEL____",
      "SS-AGM": "SS-AGM____",
      "Start-Stop AGM": "START-STOP AGM____",
      "SS-EFB": "SS-EFB____",
      "PS FLOODED": "PS FLOODED____",
      "PS AGM": "PS AGM____",
      EFB: "EFB____",
    },
    month: {
      full: {
        1: "January____",
        2: "February____",
        3: "March____",
        4: "April____",
        5: "May____",
        6: "June____",
        7: "July____",
        8: "August____",
        9: "September____",
        10: "October____",
        11: "November____",
        12: "December____",
      },
      short: {
        1: "Jan____",
        2: "Feb____",
        3: "Mar____",
        4: "Apr____",
        5: "May____",
        6: "Jun____",
        7: "Jul____",
        8: "Aug____",
        9: "Sep____",
        10: "Oct____",
        11: "Nov____",
        12: "Dec____",
      },
    },
    list: {
      shop: "Shop____",
      business: "Business____",
    },
  },
  //#endregion

  //#region Page - home  //new
  home: {
    title: "My Battery Expert____",
    updated: "Last Updated Time____",
    hour: "hours____",
    minute: "minutes ago____",
    nodata: "No data uploaded____",
    leaderboard: {
      title: "Weekly LeaderBoard____",
      subtitle:
        "The counter will be reset at 00:00 every Sunday. (Timezone: UTC+0)____",
      total: "Total____",
    },
  },
  //#endregion
};
