<template>
  <div>
    <div class="formHeader">
      <h1>{{ $t("home.leaderboard.title") }}</h1>
      <p>
        {{ $t("home.leaderboard.subtitle") }}
      </p>
    </div>
    <b-overlay
      spinner-variant="info"
      :show="overlay"
      variant="light"
      :opacity="0.4"
      blur="none"
      class="rankOverlay"
    >
      <div class="rankBox">
        <b-list-group class="rank_list">
          <b-list-group-item
            v-for="(list, index) in rankData"
            :key="list.account"
            class="d-flex align-items-center rankitem"
            :class="list.account === vxAccount ? 'myself' : ''"
          >
            <i v-if="list.rank === 1" class="fas fa-crown gold desktopMode"></i>
            <div class="rank_title desktopMode" :class="rankColor[index]">
              {{ rankName[index] }}
            </div>
            <b-avatar
              size="lg"
              :src="rankImage[index]"
              class="mr-3 desktopMode"
            ></b-avatar>
            <b-avatar
              size="lg"
              :badge-variant="rankDotColor[index]"
              :src="rankImage[index]"
              badge-offset="-6px"
              badge-left
              badge-top
              :badge="rankName[index]"
              class="mr-3 deviceMode"
            ></b-avatar>
            <span class="mr-auto rankAccount">{{ list.account }}</span>
            <div class="rankTotal">
              <p class="title">{{ $t("home.leaderboard.total") }}</p>
              <p class="number">{{ list.total }}</p>
            </div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ordinal from "ordinal-numbers"; // rank name
import { APIgetLeaderBoard } from "../../api";

export default {
  mounted() {
    this.getLeaderBoardInfomation();
  },
  data() {
    return {
      rankData: [
        // 預設data
        {
          rank: 1,
          account: "NO1@ranklist.com",
          total: 0,
        },
        {
          rank: 2,
          account: "NO2@ranklist.com",
          total: 0,
        },
        {
          rank: 3,
          account: "NO3@ranklist.com",
          total: 0,
        },
      ],
      overlay: true,
    };
  },
  methods: {
    async getLeaderBoardInfomation() {
      try {
        const { data: APIdata } = await APIgetLeaderBoard();
        this.rankData = APIdata.rankList;
      } catch (error) {
        if (error.response != undefined && error.response.status === 401) {
          this.$bvModal.show("setCheckToken");
        } else {
          this.$bvModal.show("setsomethingWrong");
        }
      } finally {
        this.overlay = false;
      }
    },
  },
  computed: {
    // 自己的帳戶圖片抓vuex裡的，達到上傳圖片時能即時更新
    vxHeadshotImage() {
      return this.$store.state.headshotImage;
    },
    // 判斷rank上的帳戶哪個是自己
    vxAccount() {
      return this.$store.state.account;
    },
    // rank排行英文 1st 2nd...等
    rankName() {
      let array = [];
      this.rankData.map((e) => {
        array.push(ordinal(e.rank));
      });
      return array;
    },
    // rank排行device(phone、table)顏色(陣列)
    rankDotColor() {
      let array = [];
      // 目前顏色使用bootstrap提供的顏色，若要改其他顏色需要自定義
      this.rankData.map((e) => {
        if (e.rank <= 3) {
          // 前三名
          array.push("warning");
        } else {
          array.push("info");
        }
      });
      return array;
    },
    // rank排行英文顏色(陣列)
    rankColor() {
      let array = [];
      this.rankData.map((e) => {
        switch (e.rank) {
          case 1:
            // 金
            array.push("gold");
            break;
          case 2:
            // 銀
            array.push("silver");
            break;
          case 3:
            // 銅
            array.push("bronze");
            break;
        }
      });
      return array;
    },
    // rank頭貼顯示
    rankImage() {
      let array = [];
      this.rankData.map((e) => {
        if (e.account === this.vxAccount) {
          array.push(this.vxHeadshotImage);
        } else {
          let time = performance.now(); // 避免快取
          array.push(
            `https://s3.mybatteryexpert.com/${e.account}.jpg?random=${time}`
          );
        }
      });
      return array;
    },
  },
};
</script>

<style lang="scss" scoped>
.rankOverlay {
  width: 80%;
  max-width: 900px;
  margin: 60px auto;
}
.rank_list {
  font-family: "Noto Sans", sans-serif, "Noto Sans TC", sans-serif;
  .gold {
    color: #ffd700;
  }
  .silver {
    color: #c0c0c0;
  }
  .bronze {
    color: #b87333;
  }
  .myself {
    background: rgb(152, 193, 221);
    color: white;
  }
  .fa-crown {
    text-shadow: 1px 1px 0px rgb(85, 85, 85);
    position: absolute;
    left: 30px;
    top: 20%;
    transform: rotate(-30deg);
  }
  .rankAccount {
    font-size: 18px;
  }
  .rankTotal {
    padding: 0 20px;
    p {
      margin: 5px 0;
    }
    .title {
      font-size: 18px;
    }
    .number {
      font-size: 22px;
    }
  }
  .deviceMode {
    display: none;
  }
}
.rank_title {
  width: 50px;
  margin: 0 25px;
  font-size: 24px;
  font-weight: 800;
  text-shadow: 1px 1px 1px rgb(85, 85, 85);
  color: #17a2b8;
}

@media screen and (max-width: 728px) {
  .rankOverlay {
    width: 95%;
  }
  .rank_list {
    .rankTotal {
      padding: 0;
      .title {
        font-size: 16px;
      }
      .number {
        font-size: 18px;
      }
    }
    .desktopMode {
      display: none;
    }
    .deviceMode {
      display: inline-flex;
    }
    .rankAccount {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 530px) {
  .rankitem {
    display: flex;
    flex-direction: column;
  }
  .rank_list {
    .rankTotal {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .number {
        font-size: 22px;
      }
      .title {
        font-size: 18px;
      }
    }
    .mr-auto {
      margin-right: unset !important;
    }
    .deviceMode {
      margin-bottom: 10px;
    }
  }
}
</style>
