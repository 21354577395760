<template>
  <div>
    <div id="updateTime" class="lastUpdateTime">
      Last Updated Time: May 8, 2019
    </div>
    <div class="container">
      <div id="privacyPolicy">
        <h1 class="policyTitle">PRIVACY POLICY</h1>
        <p>
          We, DHC SPECIALTY CORPORATION, (“we”), offer battery and system
          testing management service to users of our connected battery and
          system tester (or charger) devices (“device(s)”), through our cloud
          service (the “mybatteryexpert.com”) (collectively referred to as the
          “Service”).
        </p>
        <p>
          This Privacy Policy explains how we work with collect, use, share and
          protect the information in relation to our Service and your choices
          about the collection and use of your information.
        </p>
        <h3 class="policyTitle">INFORMATION WE COLLECT</h3>
        <p>We collect information from you including but not limited to:</p>
        <ul class="policySubTitle">
          Information you provide us directly:
          <li>
            basic user information, including your name, user ID, email address,
            company name, dealer code.
          </li>
          <li>
            device and application information, including serial numbers,
            software version, hardware model, OS information.
          </li>
          <li>
            battery and system charge and test results, including battery brand,
            battery type, battery rating, test time, any other information
            included in a test report.
          </li>
        </ul>
        <ul class="policySubTitle">
          Automatic collected Information:
          <li>
            information for customized setting, including cookies, sessions,
            token, local storage, language.
          </li>
          <li>
            information for troubleshooting, analyzing and optimizing, including
            system logs, access logs, error logs, application logs, IP address,
            browser type, web requests.
          </li>
        </ul>
        <h3 class="policyTitle">HOW WE USE PERSONAL INFORMATION</h3>
        <p>
          We use your personal information to operate, provide, and improve our
          products and services and to refine our products and services to your
          particular needs.
        </p>
        <ul class="policySubTitle">
          We may use the information we collect to:
          <li>
            for any other purpose in connection with our products and Service.
          </li>
          <li>
            communications with you. For example, we may send Service-related
            emails (e.g., account verification, technical and security notices).
            Note that you may not opt out of Service-related e-mails.
          </li>
          <li>personalize your online experience with our Service.</li>
          <li>
            Measure, Support and Improve our Service. We use your personal
            information to measure use of, analyze the performance of, fix
            errors in, provide support for, improve, and develop our Service.
          </li>
          <li>
            use the information provided to track and analyze trends in battery
            performance.
          </li>
        </ul>
        <h3 class="policyTitle">SHARING OF PERSONAL INFORMATION</h3>
        <p>
          We will not rent or sell your information to third parties without
          your consent, except as noted in this Policy. Parties with whom we may
          share your information:
        </p>
        <h4 class="policySubTitle">Third-Party Service Providers:</h4>
        <p>
          We employ other companies and individuals to perform functions on our
          behalf. Examples include: Examples include: sending communications,
          processing payments, assessing credit and compliance risks, analyzing
          data, providing marketing and sales assistance (including advertising
          and event management), conducting customer relationship management,
          providing training, security check, troubleshooting, and architecture
          optimization. These third party service providers have access to
          personal information needed to perform their functions but may not use
          it for other purposes. Further, they must process that information in
          accordance with this Privacy Notice and as permitted by applicable
          data protection law.
        </p>
        <h4 class="policySubTitle">
          As Required by Law and Other Extraordinary Disclosures:
        </h4>
        <ul>
          we may be required to disclose your Personal Information, including:
          <li>
            it believes it is reasonably necessary to comply with legal process
            (such as a court order, subpoena, search warrant, etc.) or other
            legal requirements of any governmental authority.
          </li>
          <li>
            it would potentially mitigate our liability in an actual or
            potential lawsuit or investigation.
          </li>
          <li>it is otherwise necessary to protect our rights or property.</li>
          <li>
            it is necessary to protect the legal rights or property of others.
          </li>
        </ul>
        <h3 class="policyTitle">
          LOCATION OF STORAGE AND PROCESSING OF PERSONAL INFORMATION
        </h3>
        <p>
          Your personal information is stored and processed in Northern Virginia
          unless stated otherwise in the rest of this document. By using the
          Service, you consent to the transfer of information to Northern
          Virginia and the use and disclosure of information as described in
          this Privacy Policy.
        </p>
        <h3 class="policyTitle">SECURITY</h3>
        <p>
          We implement appropriate physical, electronic and organizational
          measures to protect your data. These measures are in accordance with
          recognized industry standards during and after transmission. Data
          transmissions over the Internet cannot be guaranteed to be 100%
          secure. While we will do our best to protect your personal
          information, we cannot guarantee that your information will be
          transmitted securely to our Service. If we learn of a security
          system's breach, we will investigate the situation and take all steps
          required by applicable law. This includes notifying the authority to
          protect the data, where applicable, and, where appropriate, notifying
          individuals whose personal data may have been compromised. we may take
          additional steps in accordance with any applicable laws and
          regulations.
        </p>
        <h3 class="policyTitle">YOUR CHOICES ABOUT YOUR INFORMATION</h3>
        <p>
          You can view, update and delete the information about yourself by
          logging in our Service or contact us for assistance. When you update
          or delete any information, we usually keep a copy of the prior version
          for a commercially reasonable time for backup, archival and audit
          purposes.
        </p>
        <h3 class="policyTitle">CHANGES TO THIS PRIVACY POLICY</h3>
        <p>
          In the future, we may change our Privacy Policy and we will post the
          new Privacy Policy on our Service. Your continued use of our Service
          after any modification to this Privacy Policy will constitute your
          acceptance of such modification. We encourage you to review our policy
          periodically to remain current on our policies.
        </p>
        <h3 class="policyTitle">CONTACT</h3>
        <p>
          If you have any questions, comments, or concerns about this Privacy
          Policy, please contact us at
          <a href="mailto:service@dhc.com.tw">service@dhc.com.tw.</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    subdomain() {
      return this.$root.subdomain;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  line-height: 2.2;
}

.container {
  align-items: center;
  background: rgba(250, 250, 250, 0.9);
  margin-bottom: 60px;
  padding-bottom: 25px;
  box-shadow: 5px 10px 15px #024f83;
}

#privacyPolicy {
  max-width: 83.333%;
  padding: 20px;
  font-family: $font-family;
}

.policyTitle {
  color: #024f83;
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 0.8rem;
  margin-top: 2rem;
}

ul {
  text-align: left;
  li {
    text-align: left;
    list-style: circle;
    margin-left: 4rem;
  }
}

.policySubTitle {
  font-size: 1rem;
  text-align: left;
  color: #012e4c;
  font-weight: 700;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  line-height: 1.2;
  p,
  li {
    font-weight: normal;
    text-align: left;
    color: #000;
    font-size: 0.875rem;
    letter-spacing: 0.3px;
  }
  li {
    list-style: circle;
    margin-left: 4rem;
  }
}

ul li:nth-child(1) {
  margin-top: 1rem;
}

p {
  margin-bottom: 1rem;
  text-align: left;
}

#updateTime {
  position: absolute;
  right: 10px;
  top: 5px;
}
</style>
