<script>
import { HorizontalBar, mixins } from "vue-chartjs";
import stacked100 from "chartjs-plugin-stacked100";
const { reactiveProp } = mixins;

export default {
  extends: HorizontalBar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    chartTitle: {
      type: Array,
      default: () => {
        return ["title", "date"];
      },
    },
    chartLabel: {
      type: Array,
      default: () => {
        return ["X", "Y"];
      },
    },
    chart: {
      type: String,
      default: "",
    },
  },
  mounted() {
    this.addPlugin({
      id: stacked100,
    });

    this.horizontalBarOption();

    this.renderChart(this.chartData, this.option);
  },
  data() {
    return {
      option: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: [], // 每張圖表不一樣
          fontSize: 18,
          fontColor: "white",
          fontFamily: "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
        },
        legend: {
          display: false,
        },
      },
    };
  },
  methods: {
    renderHorizontalBarChart() {
      this.renderChart(this.chartData, this.option);
    },
    horizontalBarOption() {
      switch (this.chart) {
        case "firmware":
          this.option = Object.assign({}, this.option, {
            aspectRatio: 2.2,
            tooltips: {
              titleFontSize: 0,
              yAlign: "top",
              titleFontFamily:
                "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
              bodyFontFamily:
                "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
            },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  display: false,
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  display: false,
                },
              ],
            },
            layout: {
              padding: {
                left: 20,
                right: 20,
                top: 0,
                bottom: 0,
              },
            },
            plugins: {
              stacked100: {
                enable: true,
                replaceTooltipLabel: true,
              },
              datalabels: {
                display: false,
              },
            },
          });
          this.option.legend.fullWidth = false;
          break;
        case "userRank":
          this.option = Object.assign({}, this.option, {
            aspectRatio: 1,
            tooltips: {
              mode: "y",
              intersect: false,
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: this.chartLabel[0],
                    fontSize: 11,
                    fontColor: "white",
                    fontFamily:
                      "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                    padding: 0,
                    lineHeight: 1,
                  },
                  ticks: {
                    fontFamily:
                      "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                    precision: 0,
                    beginAtZero: true,
                    fontColor: "#ddd",
                  },
                  gridLines: {
                    color: "rgba(136,136,136,.5)",
                    zeroLineColor: "rgba(136,136,136,.5)",
                  },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                  scaleLabel: {
                    display: true,
                    labelString: this.chartLabel[1],
                    fontSize: 11,
                    fontColor: "white",
                    fontFamily:
                      "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                    padding: 0,
                    lineHeight: 1,
                  },
                  ticks: {
                    fontFamily:
                      "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                    beginAtZero: true,
                    fontColor: "#ddd",
                  },
                  gridLines: {
                    color: "rgba(136,136,136,.5)",
                    zeroLineColor: "rgba(136,136,136,.5)",
                  },
                },
              ],
            },
            plugins: {
              datalabels: {
                color: "rgba(1,122,185,.9)",
                font: {
                  weight: "bold",
                  size: 14,
                  family: "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                },
              },
            },
          });
          break;
      }
      this.option.title.text = this.chartTitle; //根據不同頁面補上
    },
  },
  watch: {
    chartData() {
      this.horizontalBarOption();
      this.$data._chart.destroy();
      this.renderHorizontalBarChart();
    },
    // "chartData.datasets": function() {
    //   this.$data._chart.destroy();
    //   this.renderHorizontalBarChart();
    // }
  },
};
</script>
