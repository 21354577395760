import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { APIgetUserHeadshot } from '../api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    constants: {
      csDataPlan: {
        old: {
          basic: 'Basic',
          shop: 'Pro',
          business: 'Enterprise',
        },
        new: {
          basic: 'Basic Account',
          shop: 'Shop Account',
          business: 'Business Account',
        },
      },
      csAccountLevel: {
        basic: 'Technician',
        shop: 'Shop Manager',
        business: 'Company Manager',
        admin: 'DHC',
      },
    },
    account: '', // 修改密碼用，主要給認證後無修改密碼、密碼過期call API用
    accountInformation: {
      // 未登入前不會更改此狀態
      accountLevel: 'Technician',
    },
    signInToggle: false, // true登入/false未登入
    language: 'en',
    loading: false,
    headshotDefault: false, // 判斷是否為預設圖
    headshotImage: '', // 大頭照路徑
  },
  plugins: [
    createPersistedState({
      reducer(val) {
        return {
          account: val.account,
          signInToggle: val.signInToggle,
          accountInformation: val.accountInformation,
          language: val.language,
        };
      },
    }),
  ],
  mutations: {
    setSignInToggle: (state, data) => (state.signInToggle = data),
    setAccount: (state, data) => (state.account = data),
    setAccountInformation: (state, data) => (state.accountInformation = data),
    setLanguage: (state, data) => (state.language = data),
    setLoading: (state, data) => (state.loading = data),
    setHeadshotDefault: (state, data) => (state.headshotDefault = data),
    setHeadshotImage: (state, data) => (state.headshotImage = data),
    setHeadshotImageAsync(state, img) {
      state.headshotImage = img;
    },
  },
  actions: {
    setSignInToggle(context, data) {
      context.commit('setSignInToggle', data);
    },
    setAccount(context, data) {
      context.commit('setAccount', data);
    },
    setAccountInformation(context, data) {
      context.commit('setAccountInformation', data);
    },
    setLanguage(context, data) {
      context.commit('setLanguage', data);
    },
    setLoading(context, data) {
      context.commit('setLoading', data);
    },
    setHeadshotDefault(context, data) {
      context.commit('setHeadshotDefault', data);
    },
    setHeadshotImage(context, data) {
      context.commit('setHeadshotImage', data);
    },
    async fetchAndSetHeadshotImage({ commit }) {
      try {
        const res = await APIgetUserHeadshot();
        let time = performance.now(); // 避免cache
        let img = `${res.data.imgUrl}?random=${time}`;
        commit('setHeadshotImageAsync', img);
        commit('setHeadshotDefault', false);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          'Failed to fetch and set headshot image in action',
          error
        );
        throw error; // 抛出错误以便组件捕获
      }
    },
  },
  modules: {},
});
