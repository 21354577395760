<template>
  <div class="frame">
    <div class="testReportBottom">
      <dataTable
        :columns="columns"
        :device="'deviceManager'"
        data-test="dataTable"
      >
      </dataTable>
    </div>

    <div class="deviceErrorCode">
      <h5>{{ $t("deviceManagement.errorListTitle") }}</h5>
      <h6>{{ $t("deviceManagement.list1") }}</h6>
      <h6>{{ $t("deviceManagement.list2") }}</h6>
      <h6>{{ $t("deviceManagement.list3") }}</h6>
      <h6>{{ $t("deviceManagement.list4") }}</h6>
      <h6>{{ $t("deviceManagement.list5") }}</h6>
      <h6>{{ $t("deviceManagement.list6") }}</h6>
      <h6>{{ $t("deviceManagement.list7") }}</h6>
      <h6>{{ $t("deviceManagement.list8") }}</h6>
      <h6>{{ $t("deviceManagement.list9") }}</h6>
    </div>
    <!-- modal -->
    <!-- 只能為IOT設備 -->
    <b-modal
      v-if="subdomain !== 'napa'"
      id="IOTDeviceToggle"
      centered
      :title="$t('modal.notice')"
    >
      <h6>{{ $t("modal.deviceManagement.deviceOnly.text1") }}</h6>
      <template v-slot:modal-footer="{ ok }">
        <b-form-checkbox v-model="IOTcheck">
          {{ $t("modal.deviceManagement.deviceOnly.text2") }}
        </b-form-checkbox>
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="ok(saveIOTcheck())"
        >
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  mounted() {
    if (window.localStorage.IOTcheck) {
      if (!JSON.parse(window.localStorage.IOTcheck)) {
        this.$bvModal.show("IOTDeviceToggle");
      }
    } else {
      this.$bvModal.show("IOTDeviceToggle");
    }

    //#region determine which columns to display by account level
    // psn
    this.columns.push({
      label: this.$t("tableColumns.deviceManagement.psn"),
      field: "psn",
      width: this.columnWidth,
      sortable: false,
      disable: false,
      type: "String",
    });

    // model
    let modelOptions = [];
    if (this.subdomain === "napa") {
      modelOptions = ["4"];
    } else {
      modelOptions = ["0", "1", "2", "3"];
    }
    this.columns.push({
      label: this.$t("tableColumns.deviceManagement.model"),
      field: "model",
      width: this.columnWidth,
      sortable: false,
      disable: false,
      type: "Option",
      options: modelOptions,
    });

    // account, display when Shop Manager or Company Manager
    if (
      this.vxAccountLevel === this.vxConstantsAccountLevel.shop ||
      this.vxAccountLevel === this.vxConstantsAccountLevel.business ||
      this.vxAccountLevel === this.vxConstantsAccountLevel.admin
    ) {
      this.columns.push({
        label: this.$t("tableColumns.deviceManagement.account"),
        field: "account",
        width: this.columnWidth,
        sortable: false,
        disable: false,
        type: "String",
      });
    }

    // shopId, display when Company Manager
    if (
      this.vxAccountLevel === this.vxConstantsAccountLevel.business ||
      this.vxAccountLevel === this.vxConstantsAccountLevel.admin
    ) {
      this.columns.push({
        label: this.$t("tableColumns.deviceManagement.shopId"),
        field: "shopId",
        width: this.columnWidth,
        sortable: false,
        disable: false,
        type: "String",
      });
    }

    // companyId, display when DHC
    if (this.vxAccountLevel === this.vxConstantsAccountLevel.admin) {
      this.columns.push({
        label: this.$t("tableColumns.deviceManagement.companyId"),
        field: "companyId",
        width: this.columnWidth,
        sortable: false,
        disable: false,
        type: "String",
      });
    }
    //#endregion
  },
  data() {
    return {
      IOTcheck: false,
      columns: [],
    };
  },
  methods: {
    saveIOTcheck() {
      window.localStorage.setItem("IOTcheck", this.IOTcheck);
    },
  },
  computed: {
    subdomain() {
      return this.$root.subdomain;
    },
    vxAccountLevel() {
      return this.$store.state.accountInformation.accountLevel;
    },
    vxConstantsAccountLevel() {
      return this.$store.state.constants.csAccountLevel;
    },
    columnWidth() {
      // number of columns
      let numOfColumns;
      switch (this.vxAccountLevel) {
        case "Technician":
          numOfColumns = 2; // psn, model
          break;
        case "Shop Manager":
          numOfColumns = 3; // psn, model, account
          break;
        case "Company Manager":
          numOfColumns = 4; // psn, model, account, shopId
          break;
        case "DHC":
          numOfColumns = 5; // psn, model, account, shopId
          break;
      }

      // column ratio ( 100% / numOfColumns )
      let columnRatio = 100 / numOfColumns;

      // to the second decimal place (33.3333333... => 33.33)
      columnRatio = Math.floor(columnRatio * 100) / 100;

      // transform to string (33.33 => '33.33%')
      let width = columnRatio.toString() + "%";

      return width;
    },
  },
};
</script>

<style lang="scss"></style>
