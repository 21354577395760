<template>
  <div class="frame">
    <div class="container">
      <div class="formHeader">
        <h1>{{ $t('download.title') }}</h1>
        <p>{{ $t('download.subtitle') }}</p>

        <div id="download">
          <m-button @click="fnExport" class="tableButton" raised dense>
            {{ $t('button.download') }}
          </m-button>
        </div>
      </div>
    </div>
    <m-button
      class="gobackButton"
      @click="$router.push(routerPushURL)"
      outlined
      raised
      >back to MybatteryExpert.com</m-button
    >
  </div>
</template>

<script>
  import { APIexportPackage } from '../api';
  export default {
    mounted() {
      window.onload = this.fnExport();
    },
    methods: {
      fnExport() {
        const webUrl = window.location.href;
        let date = webUrl.substring(webUrl.indexOf('?date') + 6, webUrl.length);
        let lang = this.vxLanguage; // 語系
        let token = localStorage.getItem('token');

        let obj = {
          form: JSON.stringify({
            token,
            date,
            lang,
          }),
        };
        APIexportPackage(obj)
          .then(res => {
            const fileName = 'TestRecords.xlsx';
            // 創建一個 Blob 物件
            const blob = new Blob([res.data], {
              type: res.headers['content-type'],
            });
            // 創建一個連結以下載 Blob
            const a = document.createElement('a');
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();

            // 釋放資源
            window.URL.revokeObjectURL(url);
          })
          .catch(err => {
            if (err.response != undefined && err.response.status === 401) {
              this.$bvModal.show('setCheckToken');
            } else {
              this.$bvModal.show('setsomethingWrong');
            }
          });
      },
    },
    computed: {
      vxLanguage() {
        return this.$store.state.language;
      },
      vxAccountLevel() {
        return this.$store.state.accountInformation.accountLevel;
      },
      vxConstantsAccountLevel() {
        return this.$store.state.constants.csAccountLevel;
      },
      routerPushURL() {
        let url = '/home';
        if (this.vxAccountLevel === this.vxConstantsAccountLevel.shop)
          url = '/home/shopDashboard';
        if (this.vxAccountLevel === this.vxConstantsAccountLevel.business)
          url = '/home/businessDashboard';
        return url;
      },
    },
  };
</script>

<style lang="scss" spcped>
  #download,
  .gobackButton {
    font-family: $font-family;
    margin-top: 50px;
  }
  .gobackButton {
    letter-spacing: 0.3px;
  }
</style>
