<template>
  <div class="frame">
    <div class="container">
      <div class="formHeader">
        <h1>{{ $t('bill.title') }}</h1>
      </div>
      <div class="billBody">
        <b-overlay
          spinner-variant="info"
          :show="overlay"
          rounded="sm"
          variant="light"
          :opacity="0.6"
          blur="none"
        >
          <div class="billTop">
            <div class="dataPlan">
              <h4>{{ $t('bill.data') }}</h4>
              <p>{{ billDataPlan }}</p>
            </div>
            <div class="chargedPoint">
              <h4>{{ $t('bill.charged') }}</h4>
              <p>{{ chargedPoint }}</p>
            </div>
          </div>
          <div class="goToStoredPoint">
            <m-button raised dense @click="$router.push('/storedPoints')">
              {{ $t('button.goDeposit') }}
            </m-button>
          </div>
          <div class="billBottom">
            <div class="businessAccount" v-if="isBusinessAccount">
              <table class="listTable">
                <tr>
                  <th class="billTitle" colspan="3">
                    {{ $t('bill.plan.shop') }}
                  </th>
                </tr>
                <tr>
                  <td class="billDescr" colspan="3">
                    {{ $t('bill.shopDescription') }}
                  </td>
                </tr>
                <!-- ---------------------- -->
                <tbody
                  id="shopListBody"
                  v-for="list in shopList"
                  :key="list.shopId"
                >
                  <tr class="billTitle">
                    <td>{{ $t('bill.table.shopID') }}</td>
                    <td>{{ $t('bill.table.desc') }}</td>
                    <td>{{ $t('bill.table.unit') }}</td>
                  </tr>
                  <tr>
                    <td rowspan="3">{{ list.shopId }}</td>
                    <td class="billDefault">{{ $t('bill.table.default') }}</td>
                    <td rowspan="1">5</td>
                  </tr>
                  <tr>
                    <td class="billDefault">{{ $t('bill.table.extra') }}</td>
                    <td rowspan="2">{{ list.extraMember * shopExtraFee }}</td>
                  </tr>
                  <tr>
                    <td>{{ list.extraMember }}</td>
                  </tr>
                </tbody>
                <!-- ---------------------- -->
                <tr class="billSubTotal">
                  <td colspan="2">{{ $t('bill.table.shopSubTotal') }}</td>
                  <td colspan="1">{{ shopTotal }}</td>
                </tr>

                <tr>
                  <td colspan="3" style="padding: 5px"></td>
                </tr>

                <tr>
                  <th class="billTitle" colspan="3">
                    {{ $t('bill.plan.business') }}
                  </th>
                </tr>
                <tr>
                  <td class="billDescr" colspan="3">
                    {{ $t('bill.businessDescription') }}
                  </td>
                </tr>
                <tr class="billTitle">
                  <td colspan="2">{{ $t('bill.table.desc') }}</td>
                  <td colspan="1">{{ $t('bill.table.unit') }}</td>
                </tr>
                <tr>
                  <td class="billDefault" colspan="2">
                    {{ $t('bill.table.default') }}
                  </td>
                  <td>20</td>
                </tr>

                <tr>
                  <td class="billDefault" colspan="2">
                    {{ $t('bill.table.extraShop') }}
                  </td>
                  <td rowspan="2">{{ extraMember * BuninessExtraFee }}</td>
                </tr>
                <tr>
                  <td colspan="2">{{ extraMember }}</td>
                </tr>
                <tr class="billSubTotal">
                  <td colspan="2">{{ $t('bill.table.busSubTotal') }}</td>
                  <td colspan="1">{{ businessTotal }}</td>
                </tr>

                <tr class="billTotal">
                  <th colspan="2">{{ $t('bill.table.businessFee') }}</th>
                  <th colspan="1">{{ chargedPoint }}</th>
                </tr>
              </table>

              <ul class="billNote">
                {{
                  $t('bill.note.title')
                }}
                <li>
                  {{ $t('bill.note.shopFee') }}
                </li>
                <li>
                  {{ $t('bill.note.busFee') }}
                </li>
                <li>
                  {{ $t('bill.note.business') }}
                </li>
              </ul>
            </div>
            <div class="shopAccount" v-else-if="isShopAccount">
              <table class="listTable">
                <tr>
                  <th class="billTitle" colspan="3">
                    {{ $t('bill.plan.shop') }}
                  </th>
                </tr>
                <tr>
                  <td class="billDescr" colspan="3">
                    {{ $t('bill.shopDescription') }}
                  </td>
                </tr>
                <tr class="billTitle">
                  <td colspan="2">{{ $t('bill.table.desc') }}</td>
                  <td colspan="1">{{ $t('bill.table.unit') }}</td>
                </tr>
                <tr>
                  <td class="billDefault" colspan="2">
                    {{ $t('bill.table.default') }}
                  </td>
                  <td>5</td>
                </tr>
                <tr>
                  <td class="billDefault" colspan="2">
                    {{ $t('bill.table.extra') }}
                  </td>
                  <td rowspan="2">{{ extraMember * shopExtraFee }}</td>
                </tr>
                <tr>
                  <td colspan="2">{{ extraMember }}</td>
                </tr>

                <tr class="billTotal">
                  <th colspan="2">{{ $t('bill.table.fee') }}</th>
                  <th colspan="1">{{ chargedPoint }}</th>
                </tr>
              </table>

              <ol class="billNote">
                {{
                  $t('bill.note.title')
                }}
                <li v-if="vxAccountCompanyId">
                  {{ $t('bill.note.shopCom') }}
                </li>
                <li v-else>
                  {{ $t('bill.note.shopFee') }}
                </li>
              </ol>
            </div>
            <div class="basicAccount" v-else-if="isBasicAccount">
              <table class="listTable">
                <tr>
                  <th class="billTitle" colspan="2">
                    {{ $t('bill.plan.basic') }}
                  </th>
                </tr>
                <!-- <tr>
                  <td colspan="2">It free to use.</td>
                </tr> -->
                <tr class="billTitle">
                  <td>{{ $t('bill.table.desc') }}</td>
                  <td>{{ $t('bill.table.unit') }}</td>
                </tr>
                <tr>
                  <td class="billDefault">{{ $t('bill.table.default') }}</td>
                  <td>0</td>
                </tr>

                <tr class="billTotal">
                  <th>{{ $t('bill.table.fee') }}</th>
                  <th>0</th>
                </tr>
              </table>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </div>
</template>

<script>
  import { APIcheckBill } from '../api';
  export default {
    created() {
      const webUrl = window.location.href;
      let recover = '';
      if (webUrl.indexOf('?recover') >= 0) {
        recover = webUrl.substring(
          webUrl.indexOf('?recover') + 9,
          webUrl.length
        );
      }
      this.checkAccountBill(recover);
    },
    data() {
      return {
        shopDefaultMember: 3, // shop default member
        shopDefaultFee: 5, // shop default 收費
        shopExtraFee: 2, // shop extra member 收費(per member)
        BusinessDefaultMember: 5, // business default member
        BusinessDefaultFee: 20, // business default 收費
        BuninessExtraFee: 5, // business extra member 收費(per member)

        BusinessBefore: false,
        shopBefore: false,
        chargedPoint: '',
        extraMember: '',
        shopList: [],
        overlay: true,
      };
    },
    methods: {
      async checkAccountBill(recover) {
        try {
          const { data: APIdata } = await APIcheckBill({ recover });
          this.chargedPoint = APIdata.total;
          if (APIdata.extraMember) this.extraMember = APIdata.extraMember;
          if (APIdata.detail) this.shopList = APIdata.detail;
          if (APIdata.isCompanyBefore)
            this.BusinessBefore = APIdata.isCompanyBefore;
          if (APIdata.isShopBefore) this.shopBefore = APIdata.isShopBefore;
        } catch (error) {
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            switch (error.response.data.error) {
              case 'unauthorized':
                this.$bvModal.hide('disbandGroup');
                this.$bvModal.show('unauthorizedErrorToggle');
                break;
              default:
                this.$bvModal.hide('disbandGroup');
                this.$bvModal.show('setsomethingWrong');
            }
          }
        } finally {
          this.overlay = false;
        }
      },
    },
    computed: {
      vxAccountPlan() {
        let plan;
        switch (this.$store.state.accountInformation.pricingPlan) {
          case '0':
            plan = 'Basic';
            break;
          case '1':
            plan = 'Pro';
            break;
          case '2':
            plan = 'Enterprise';
            break;
        }
        return plan;
      },
      vxAccountCompanyId() {
        return this.$store.state.accountInformation.companyId;
      },
      vxConstantsDataPlan() {
        return this.$store.state.constants.csDataPlan.old;
      },
      billDataPlan() {
        let title = this.vxAccountPlan;
        if (this.BusinessBefore)
          title = this.$t('subscribePlan.enterprise.title');
        if (this.shopBefore) title = this.$t('subscribePlan.pro.title');

        if (title === this.vxConstantsDataPlan.basic)
          title = this.$t('subscribePlan.basic.title');
        if (title === this.vxConstantsDataPlan.shop)
          title = this.$t('subscribePlan.pro.title');
        if (title === this.vxConstantsDataPlan.business)
          title = this.$t('subscribePlan.enterprise.title');
        return title;
      },
      shopTotal() {
        let total = 0;
        this.shopList.map(el => {
          total += el.total;
        });
        return total;
      },
      businessTotal() {
        let total = 0;
        total = this.chargedPoint - this.shopTotal;
        return total;
      },
      isBasicAccount() {
        let boolean = false;
        if (this.vxAccountPlan === this.vxConstantsDataPlan.basic)
          boolean = true;
        return boolean;
      },
      isShopAccount() {
        let boolean = false;
        if (this.vxAccountPlan === this.vxConstantsDataPlan.shop)
          boolean = true;
        if (this.shopBefore) boolean = true;
        return boolean;
      },
      isBusinessAccount() {
        let boolean = false;
        if (this.vxAccountPlan === this.vxConstantsDataPlan.business)
          boolean = true;
        if (this.BusinessBefore) boolean = true;
        return boolean;
      },
    },
  };
</script>

<style lang="scss" scoped>
  table,
  tr,
  td,
  th {
    border: solid 1px #000;
    border-collapse: collapse;
    text-align: center;
    vertical-align: middle;
  }

  .billDescr {
    padding: 5px;
    text-align: left;
  }

  .billNote {
    list-style: disc;
    padding: 8px;
    line-height: 22px;
    text-align: left;
    li {
      margin-left: 20px;
    }
  }

  .listTable {
    width: 100%;
  }

  .businessAccount,
  .shopAccount,
  .basicAccount {
    width: 90%;
    margin: auto;
    .billTitle {
      padding: 6px;
      font-weight: 600;
      background: rgb(54, 96, 146);
      color: white;
    }
    .billTotal {
      padding: 6px;
      font-weight: 600;
      background: rgb(83, 141, 213);
      color: white;
    }
    .billSubTotal {
      padding: 6px;
      font-weight: 600;
      background: rgb(221, 221, 221);
    }
    .billDefault {
      background: rgb(185, 211, 238);
    }
  }

  .goToStoredPoint {
    width: 90%;
    margin: 15px auto;
    text-align: right;
    .mdc-button {
      font-size: 12px;
    }
  }

  .billBody {
    width: 100%;
    max-width: 900px;
    margin: 60px auto;
    background: rgba(255, 255, 255, 0.9);
    line-height: 20px;
    .billTop {
      width: 90%;
      margin: auto;
      padding: 20px 0 0;
      display: flex;
      .dataPlan,
      .chargedPoint {
        width: 50%;
        h4 {
          text-align: left;
          padding: 15px 0;
        }
        p {
          font-size: 22px;
        }
      }
    }
    .billBottom {
      padding: 0 0 30px;
    }
  }

  @media screen and (max-width: 500px) {
    .billBody {
      .billTop {
        flex-direction: column;
        align-items: center;
        .dataPlan,
        .chargedPoint {
          width: 100%;
        }
      }
    }
  }
</style>
