<template>
  <div class="frame">
    <div class="testReportBottom">
      <dataTable
        :columns="columns"
        :device="'signinLogs'"
        data-test="dataTable"
      >
      </dataTable>
    </div>
  </div>
</template>

<script>
  import dataTable from '@/components/dataTable.vue';
  export default {
    components: {
      dataTable,
    },
    data() {
      return {
        columns: [
          {
            label: this.$t('tableColumns.signinLogs.account'),
            field: 'account',
            sortable: false,
            disable: false,
            type: 'String',
          },
          {
            label: this.$t('tableColumns.signinLogs.successful'),
            field: 'successful',
            sortable: false,
            disable: false,
            type: 'Option',
            options: [0, 1],
          },
          {
            label: this.$t('tableColumns.signinLogs.signinTime'),
            field: 'signinTime',
            sortable: false,
            disable: false,
            type: 'Time',
          },
        ],
      };
    },
    computed: {
      subdomain() {
        return this.$root.subdomain;
      },
      vxAccountLevel() {
        return this.$store.state.accountInformation.accountLevel;
      },
      vxConstantsAccountLevel() {
        return this.$store.state.constants.csAccountLevel;
      },
    },
  };
</script>

<style></style>
