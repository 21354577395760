<template>
  <div class="frame">
    <div class="testReportBottom">
      <dataTable
        :columns="columns"
        :device="'actionLogs'"
        data-test="dataTable"
      >
      </dataTable>
    </div>
  </div>
</template>

<script>
import dataTable from "@/components/dataTable.vue";
export default {
  components: {
    dataTable,
  },
  data() {
    return {
      columns: [
        {
          label: this.$t("tableColumns.actionLogs.target"),
          field: "target",
          sortable: false,
          disable: false,
          type: "String",
        },
        {
          label: this.$t("tableColumns.actionLogs.action"),
          field: "action",
          sortable: false,
          disable: false,
          type: "String",
        },
        {
          label: this.$t("tableColumns.actionLogs.triggeredBy"),
          field: "triggeredBy",
          sortable: false,
          disable: false,
          type: "String",
        },
        {
          label: this.$t("tableColumns.actionLogs.time"),
          field: "time",
          sortable: false,
          disable: false,
          hidden: false,
          type: "Time",
        },
        {
          label: this.$t("tableColumns.actionLogs.comment"),
          field: "comment",
          sortable: false,
          disable: false,
          type: "String",
        },
      ],
    };
  },
  computed: {
    subdomain() {
      return this.$root.subdomain;
    },
    vxAccountLevel() {
      return this.$store.state.accountInformation.accountLevel;
    },
    vxConstantsAccountLevel() {
      return this.$store.state.constants.csAccountLevel;
    },
  },
};
</script>

<style></style>
