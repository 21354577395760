<script>
  import { Bar, mixins } from 'vue-chartjs';
  import annotation from 'chartjs-plugin-annotation';
  const { reactiveProp } = mixins;

  export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object,
        default: () => {},
      },
      chartTitle: {
        type: String,
        default: '',
      },
      chartLabel: {
        type: Array,
        default: () => {
          return ['X', 'Y'];
        },
      },
      avg: {
        type: Number,
        default: 0,
      },
      chart: {
        type: String,
        default: '',
      },
      options: {
        type: Object,
        default: () => {},
      },
    },
    mounted() {
      this.addPlugin({
        id: annotation,
      });

      this.barOption();

      this.renderChart(this.chartData, this.option);
    },
    data() {
      return {
        option: {
          maintainAspectRatio: false,
          tooltips: {
            titleFontFamily:
              "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
            bodyFontFamily:
              "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
          },
          title: {
            display: true,
            text: '', //不同圖表會有不同title
            fontSize: 18,
            fontColor: 'white',
            fontFamily: "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
          },
          plugins: {
            labels: false,
            datalabels: {},
          },
        },
      };
    },
    methods: {
      renderBarChart() {
        this.renderChart(this.chartData, this.option);
      },
      barOption() {
        switch (this.chart) {
          case 'testReport':
            this.option = Object.assign({}, this.option, {
              legend: {
                onClick: function () {
                  return;
                },
                position: 'bottom',
                labels: {
                  boxWidth: 12,
                  fontSize: 12,
                  fontColor: 'white',
                  fontFamily:
                    "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                },
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      autoSkip: false,
                      fontColor: '#ddd',
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                    },
                    gridLines: {
                      color: 'rgba(136,136,136,.5)',
                      zeroLineColor: 'rgba(136,136,136,.5)',
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      precision: 0,
                      beginAtZero: true,
                      fontColor: '#ddd',
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                    },
                    gridLines: {
                      color: 'rgba(136,136,136,.5)',
                      zeroLineColor: 'rgba(136,136,136,.5)',
                    },
                  },
                ],
              },
              layout: {
                padding: {
                  left: 10,
                  right: 10,
                  top: 10,
                  bottom: 10,
                },
              },
            });
            this.option.plugins.datalabels = {
              color: 'white',
              display: context => {
                return context.dataset.data[context.dataIndex] > 4;
              },
              font: {
                weight: 'bold',
                size: 14,
                family: "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
              },
              formatter: Math.round,
            };
            break;
          case 'hourlyDay':
            this.option = Object.assign({}, this.option, {
              aspectRatio: 1,
              responsive: true,
              legend: {
                display: false,
              },
              scales: {
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: this.chartLabel[0], // 不同圖表會有不同label 尚未填寫
                      fontSize: 11,
                      fontColor: 'white',
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                      padding: 0,
                      lineHeight: 1,
                    },
                    ticks: {
                      fontSize: 11,
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                      fontColor: '#ddd',
                    },
                    gridLines: {
                      color: 'rgba(136,136,136,.5)',
                      zeroLineColor: 'rgba(136,136,136,.5)',
                    },
                  },
                ],
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: this.chartLabel[1], // 不同圖表會有不同label 尚未填寫
                      fontSize: 11,
                      fontColor: 'white',
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                      padding: 0,
                      lineHeight: 1,
                    },
                    ticks: {
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                      precision: 0,
                      beginAtZero: true,
                      fontColor: '#ddd',
                    },
                    gridLines: {
                      color: 'rgba(136,136,136,.5)',
                      zeroLineColor: 'rgba(136,136,136,.5)',
                    },
                  },
                ],
              },
              annotation: {
                annotations: [
                  {
                    type: 'line',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    value: this.avg,
                    borderColor: '#FF4D4D',
                    borderWidth: 2,
                    label: {
                      backgroundColor: 'rgba(255,77,77,.8)',
                      fontColor: '#fff',
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                      fontSize: 12,
                      enabled: true,
                      content: this.avg,
                      position: 'left',
                      cornerRadius: 25,
                      xPadding: 5,
                      yPadding: 5,
                    },
                  },
                ],
              },
            });
            this.option.tooltips.mode = 'x';
            this.option.tooltips.intersect = false;
            this.option.plugins.datalabels = {
              align: 'center',
              anchor: 'end',
              color: 'white',
              font: {
                weight: 'bold',
                size: 14,
              },
              formatter: function () {
                return '';
              },
            };
            break;
          case 'batteryType':
            this.option = Object.assign({}, this.option, {
              aspectRatio: 1,
              responsive: true,
              legend: {
                position: 'bottom',
                onHover: function () {
                  this.ctx.canvas.style.cursor = 'pointer';
                },
                onLeave: function () {
                  this.ctx.canvas.style.cursor = 'default';
                },
                labels: {
                  boxWidth: 12,
                  fontSize: 12,
                  fontColor: 'white',
                  fontFamily:
                    "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                },
              },
              scales: {
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: this.chartLabel[0], // 不同圖表會有不同label 尚未填寫
                      fontSize: 11,
                      fontColor: 'white',
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                      padding: 0,
                      lineHeight: 1,
                    },
                    ticks: {
                      fontSize: 11,
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                      fontColor: '#ddd',
                    },
                    gridLines: {
                      color: 'rgba(136,136,136,.5)',
                      zeroLineColor: 'rgba(136,136,136,.5)',
                    },
                  },
                ],
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: this.chartLabel[1], // 不同圖表會有不同label 尚未填寫
                      fontSize: 11,
                      fontColor: 'white',
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                      padding: 0,
                      lineHeight: 1,
                    },
                    ticks: {
                      fontFamily:
                        "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                      precision: 0,
                      beginAtZero: true,
                      fontColor: '#ddd',
                    },
                    gridLines: {
                      color: 'rgba(136,136,136,.5)',
                      zeroLineColor: 'rgba(136,136,136,.5)',
                    },
                  },
                ],
              },
            });
            this.option.tooltips.mode = 'x';
            this.option.tooltips.intersect = false;
            this.option.plugins.datalabels = {
              align: 'center',
              anchor: 'end',
              color: 'white',
              font: {
                weight: 'bold',
                size: 14,
              },
              formatter: function () {
                return '';
              },
            };
            break;
        }
        this.option.title.text = this.chartTitle; //根據不同頁面補上
      },
    },
    watch: {
      chartData() {
        this.barOption();
        this.$data._chart.destroy();
        this.renderBarChart();
      },
      // "chartData.datasets": function() {
      //   this.$data._chart.destroy();
      //   this.renderBarChart();
      // }
    },
  };
</script>
