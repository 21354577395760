import Vue from 'vue';
import VueI18n from 'vue-i18n';
import langTW from './lang/tw';
import langEN from './lang/en';
import langFR from './lang/fr';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import moment from 'moment-timezone';
import tanent from './tenant';
Vue.prototype.$moment = moment;

// material-components-vue
import Button from 'material-components-vue/dist/button';
// --------------------------

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.config.productionTip = false;

Vue.use(VueI18n);

// material-components-vue
Vue.use(Button);
// --------------------------

const messages = {
  en: {
    ...langEN,
  },
  tw: {
    ...langTW,
  },
  fr: {
    ...langFR,
  },
};

const i18n = new VueI18n({
  locale: store.state.language,
  messages,
});

// 檢查token用
let checkDownload = false;
let checkBilling = false;
let date = '';
const constants = store.state.constants;
const csLevel = constants.csAccountLevel;
const csPlan = constants.csDataPlan.old;

router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('token');
  const vuex = window.localStorage.getItem('vuex');
  let goToPath = to.path.toLowerCase();
  let pathLastIndex = to.path.length - 1;

  // 檢查網址最後是否為/，如果是移除掉
  if (goToPath.charAt(pathLastIndex) === '/' && pathLastIndex > 0)
    goToPath = goToPath.substring(0, pathLastIndex);

  // 不用token、vuex、signin toggle也可以進的畫面
  if (to.path.toLowerCase() === '/register') return next();
  if (to.path.toLowerCase() === '/') return next();
  if (to.path.toLowerCase() === '/privacypolicy') return next();
  if (token && vuex) {
    const signInSwitch = JSON.parse(
      window.localStorage.getItem('vuex')
    ).signInToggle;
    alreadySignIn(goToPath, signInSwitch);
  } else {
    // Other page
    noneSignIn(goToPath);
  }

  function noneSignIn(path) {
    switch (path) {
      case '/download': {
        checkDownload = true;
        let webUrl = window.location.href;
        date = webUrl.substring(webUrl.indexOf('?date') + 6, webUrl.length);
        break;
      }
      case '/pointscalculation':
        checkBilling = true;
        break;
      default:
        store.commit('setSignInToggle', false);
    }
    next('/');
  }

  function alreadySignIn(path, signInSwitch) {
    const level = store.state.accountInformation.accountLevel;
    let plan;
    switch (store.state.accountInformation.pricingPlan) {
      case '0':
        plan = 'Basic';
        break;
      case '1':
        plan = 'Pro';
        break;
      case '2':
        plan = 'Enterprise';
        break;
    }

    if (
      path !== '/buildyourshop' &&
      plan === csPlan.shop &&
      level !== csLevel.shop
    ) {
      //if data plan is already shop but account level is still not shop account.
      next('/buildYourShop');
    } else if (
      path !== '/buildyourcompany' &&
      plan === csPlan.business &&
      level !== csLevel.business
    ) {
      //if data plan is already business but account level is still not business account.
      next('/buildyourcompany');
    }
    const haveShopId = store.state.accountInformation.shopId;
    const haveBusinessId = store.state.accountInformation.companyId;
    const shopb4 = store.state.accountInformation.isShopBefore;
    const businessb4 = store.state.accountInformation.isCompanyBefore;

    // 以下的path必須要寫小寫
    switch (path) {
      // 登入後不給進的頁面
      case '/signinLogs':
      case '/actionLogs':
      case '/createpoints':
      case '/batchcreate':
        if (level === csLevel.admin) return next();
        next('/home');
        break;
      case '/':
      case '/register':
        if (signInSwitch) return next('/home');
        localStorage.removeItem('token');
        localStorage.removeItem('vuex');
        next('/');
        break;
      // 登入後給進的頁面(判斷)
      case '/listallusers':
      case '/settimezone':
      case '/servertasks':
        if (level === csLevel.basic) return next('/home');
        break;
      case '/download':
        if ((level === csLevel.basic) & !(shopb4 || businessb4))
          return next('/home');
        break;
      // case "/buildyourshop":
      case '/buildyourcompany':
        if (plan === csPlan.basic) return next('/subscribePlan');
        if (haveShopId) return next('/subscribePlan');
        if (haveBusinessId) return next('/subscribePlan');
        break;
    }
    if (checkBilling) {
      checkBilling = false;
      next('/pointsCalculation');
      return;
    }
    if (
      checkDownload &&
      (level === csLevel.shop ||
        level === csLevel.business ||
        shopb4 ||
        businessb4)
    ) {
      checkDownload = false;
      next(`/download?date=${date}`);
      return;
    }
    // 登入後給進的頁面(判斷)
    switch (path) {
      case '/home':
        if (level === csLevel.basic && haveShopId && !(shopb4 || businessb4)) {
          return next('/home/leaderboard');
        }
        if (level === csLevel.shop) return next('/home/shopDashboard');
        if (level === csLevel.business) return next('/home/businessDashboard');
        next();
        break;
      case '/home/shopdashboard':
        if (level === csLevel.basic && haveShopId) {
          return next('/home/leaderboard');
        }
        if (level === csLevel.shop || level === csLevel.admin) return next();
        if (level === csLevel.business) return next('/home/businessDashboard');
        next('/home');
        break;
      case '/home/leaderboard':
        if (
          (level === csLevel.basic || level === csLevel.admin) &&
          haveShopId &&
          !(shopb4 || businessb4)
        ) {
          return next();
        }
        if (level === csLevel.shop) return next('/home/shopDashboard');
        if (level === csLevel.business) return next('/home/businessDashboard');
        next('/home');
        break;
      case '/home/businessdashboard':
        if (level === csLevel.basic && haveShopId) {
          return next('/home/leaderboard');
        }
        if (level === csLevel.shop) return next('/home/shopDashboard');
        if (level === csLevel.business || level === csLevel.admin) {
          return next();
        }
        next('/home');
        break;
      default:
        next();
    }

    switch (path) {
      case '/buildyourshop':
        if (plan === csPlan.business) return next('/subscribePlan');
        break;
      case '/buildyourcompany':
        if (plan === csPlan.shop) return next('/subscribePlan');
        break;
    }
  }
});
Vue.mixin(tanent);
new Vue({
  //eslint-disable-line
  router,
  store,
  i18n,
  axios,
  render: h => h(App),
}).$mount('#app');
