<script>
  import { Doughnut, mixins } from 'vue-chartjs';
  import chartjsPluginDoughnutlabel from 'chartjs-plugin-doughnutlabel';
  import labels from 'chartjs-plugin-labels';
  const { reactiveProp } = mixins;

  export default {
    extends: Doughnut,
    mixins: [reactiveProp],
    props: {
      chartData: {
        type: Object,
        default: () => {},
      },
      chartTitle: {
        type: String,
        default: '',
      },
      labelData: {
        type: Array,
        default: () => {
          return [];
        },
      },
      // 為了想把設定檔放進這，使用prop來判斷是哪個頁面，使用哪種設定檔
      page: {
        type: String,
        default: 'Home',
      },
      // 判斷是否為pie圖
      pie: {
        type: Boolean,
        default: false,
      },
      // 判斷doughnut圖表內是否有文字，文字是哪個圖表
      month: {
        type: Boolean,
        default: false,
      },
      total: {
        type: Boolean,
        default: false,
      },
    },
    mounted() {
      this.gradient = this.$refs.canvas
        .getContext('2d')
        .createLinearGradient(0, 0, 0, 450);
      this.gradient2 = this.$refs.canvas
        .getContext('2d')
        .createLinearGradient(0, 0, 0, 450);

      this.addPlugin({
        id: [chartjsPluginDoughnutlabel, labels],
      });

      this.doughnutOption();

      this.renderChart(this.chartData, this.option);
    },
    data() {
      return {
        option: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            text: '', //根據不同頁面補上，ST圖表為空
            display: true,
            fontSize: 18,
            fontColor: 'white',
            fontFamily: "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
          },
          tooltips: {
            // mouse enter
            callbacks: {
              label: (tooltipItem, data) => {
                let dataset = data.datasets[tooltipItem.datasetIndex];
                let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                let total = meta.total;
                let currentValue = dataset.data[tooltipItem.index];
                let percentage = parseFloat(
                  ((currentValue / total) * 100).toFixed(1)
                );
                return currentValue + ' (' + percentage + '%)';
              },
              title: (tooltipItem, data) => {
                return data.labels[tooltipItem[0].index];
              },
            },
            titleFontFamily:
              "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
            bodyFontFamily:
              "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
          },
          plugins: {
            datalabels: {
              display: false,
            },
            // doughnut空心中的label資訊
            doughnutlabel: {
              labels: [],
            },
            // doughnut outside text
            labels: {
              render: function (args) {
                if (args.percentage <= 13) {
                  return '';
                } else {
                  return `${args.value}( ${args.percentage} %)`;
                }
              },
              fontColor: 'white',
              position: 'outside',
              fontSize: 14,
              fontFamily: "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
              arc: true,
            },
          },
        },
      };
    },
    methods: {
      renderDoughnutChart() {
        this.renderChart(this.chartData, this.option);
      },
      doughnutOption() {
        switch (this.page) {
          case 'Home':
            this.option = Object.assign({}, this.option, {
              cutoutPercentage: 75,
              legend: {
                display: false,
              },
              layout: {
                padding: {
                  left: 30,
                  right: 30,
                  top: 0,
                  bottom: 35,
                },
              },
            });
            if (this.month) {
              this.option.plugins.doughnutlabel.labels = [
                {
                  text: this.$t('chart.label.total'),
                  font: {
                    size: '15',
                    family:
                      "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                  },
                  color: 'white',
                },
                {
                  text: this.labelData[0],
                  font: {
                    size: '27',
                    family:
                      "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                  },
                  color: 'white',
                },
                {
                  text: this.$t('chart.label.replace'),
                  font: {
                    size: '15',
                    family:
                      "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                  },
                  color: 'white',
                },
                {
                  text: this.labelData[1] + '%',
                  font: {
                    size: '27',
                    family:
                      "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                  },
                  color: 'white',
                },
              ];
            }
            if (this.total) {
              this.option.plugins.doughnutlabel.labels = [
                {
                  text: this.$t('chart.label.total'),
                  font: {
                    size: '15',
                    family:
                      "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                  },
                  color: 'white',
                },
                {
                  text: this.labelData[0],
                  font: {
                    size: '27',
                    family:
                      "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                  },
                  color: 'white',
                },
              ];
            }
            break;
          case 'testReportBT': //BT
            this.option = Object.assign({}, this.option, {
              legend: {
                position: 'bottom',
                onClick: function () {
                  return;
                },
                labels: {
                  boxWidth: 12,
                  fontSize: 12,
                  fontColor: 'white',
                  fontFamily:
                    "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                },
              },
              layout: {
                padding: {
                  left: 10,
                  right: 10,
                  top: 10,
                  bottom: 10,
                },
              },
            });
            break;
          case 'testReportST': //ST
            this.option = Object.assign({}, this.option, {
              legend: {
                position: 'bottom',
                onClick: function () {
                  return;
                },
                labels: {
                  boxWidth: 12,
                  fontSize: 12,
                  fontColor: 'white',
                  fontFamily:
                    "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                },
              },
              layout: {
                padding: {
                  left: 10,
                  right: 10,
                  top: 20,
                  bottom: 10,
                },
              },
            });
            break;
        }
        this.option.title.text = this.chartTitle; //根據不同頁面補上
        // 如果是pie chart，修改圓餅圖厚薄度
        if (this.pie) {
          this.option.cutoutPercentage = 0;
          this.option.layout.padding = {
            left: 30,
            right: 30,
            top: -10,
            bottom: 55,
          };
        }
      },
    },
    computed: {},
    watch: {
      chartData() {
        // 重新set option
        this.doughnutOption();
        // 移除原有圖表
        this.$data._chart.destroy();
        // 新圖表
        this.renderDoughnutChart();
      },
      // 只監聽data不監聽option
      // "chartData.datasets": function() {
      //   this.doughnutOption();
      //   this.$data._chart.destroy();
      //   this.renderDoughnutChart();
      // },
    },
  };
</script>
