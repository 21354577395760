var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-sidebar",
        {
          attrs: {
            width: "270px",
            "no-header": "",
            id: "sidebar-main",
            "backdrop-variant": "dark",
            "no-close-on-esc": "",
            "no-close-on-route-change": "",
            shadow: "",
            backdrop: "",
          },
        },
        [
          _c("div", { staticClass: "gap" }),
          _c(
            "nav",
            [
              _c(
                "router-link",
                { staticClass: "sidebarItem", attrs: { to: "/home" } },
                [
                  _c("span", { staticClass: "sidebarIcon" }, [
                    _c("i", { staticClass: "fas fa-home" }),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("sidebar.Home")) + " "),
                ]
              ),
              _c(
                "router-link",
                {
                  staticClass: "sidebarItem",
                  attrs: { to: "/deviceManagement" },
                },
                [
                  _c("span", { staticClass: "sidebarIcon" }, [
                    _c("i", { staticClass: "fas fa-car-battery" }),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("sidebar.deviceManagement"))),
                ]
              ),
              _vm.vxAccountLevel !== _vm.vxConstantsAccountLevel.basic
                ? _c(
                    "router-link",
                    {
                      staticClass: "sidebarItem",
                      attrs: { to: "/listAllUsers" },
                    },
                    [
                      _c("span", { staticClass: "sidebarIcon" }, [
                        _c("i", { staticClass: "fas fa-user-friends" }),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("sidebar.ListAllUsers")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.subdomain !== "napa" &&
              (_vm.vxAccountLevel === _vm.vxConstantsAccountLevel.admin ||
                _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.business ||
                (_vm.vxAccountLevel === _vm.vxConstantsAccountLevel.shop &&
                  !_vm.vxAccountCompanyId) ||
                (!_vm.vxAccountCompanyId &&
                  !_vm.vxAccountShopId &&
                  _vm.vxAccountLevel !== _vm.vxConstantsAccountLevel.admin))
                ? _c(
                    "router-link",
                    { staticClass: "sidebarItem", attrs: { to: "/blacklist" } },
                    [
                      _c("span", { staticClass: "sidebarIcon" }, [
                        _c("i", { staticClass: "fas fa-envelope" }),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("sidebar.blackList")) + " "),
                    ]
                  )
                : _vm._e(),
              _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.business ||
              (_vm.vxAccountLevel === _vm.vxConstantsAccountLevel.shop &&
                !_vm.vxAccountCompanyId)
                ? _c(
                    "router-link",
                    {
                      staticClass: "sidebarItem",
                      attrs: { to: "/pointsCalculation" },
                    },
                    [
                      _c("span", { staticClass: "sidebarIcon" }, [
                        _c("i", { staticClass: "fas fa-file-invoice-dollar" }),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("sidebar.pointsCalculation"))),
                    ]
                  )
                : _vm._e(),
              _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.admin
                ? _c(
                    "router-link",
                    {
                      staticClass: "sidebarItem",
                      attrs: { to: "/createPoints" },
                    },
                    [
                      _c("span", { staticClass: "sidebarIcon" }, [
                        _c("i", { staticClass: "fas fa-file-alt" }),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("sidebar.createPoints")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.admin
                ? _c(
                    "router-link",
                    {
                      staticClass: "sidebarItem",
                      attrs: { to: "/batchCreate" },
                    },
                    [
                      _c("span", { staticClass: "sidebarIcon" }, [
                        _c("i", { staticClass: "fas fa-file-upload" }),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("sidebar.batchCreate")) + " "),
                    ]
                  )
                : _vm._e(),
              _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.admin
                ? _c(
                    "router-link",
                    {
                      staticClass: "sidebarItem",
                      attrs: { to: "/signinLogs" },
                    },
                    [
                      _c("span", { staticClass: "sidebarIcon" }, [
                        _c("i", { staticClass: "fas fa-user-clock" }),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("sidebar.signinLogs")) + " "),
                    ]
                  )
                : _vm._e(),
              _vm.vxAccountLevel === _vm.vxConstantsAccountLevel.admin
                ? _c(
                    "router-link",
                    {
                      staticClass: "sidebarItem",
                      attrs: { to: "/actionLogs" },
                    },
                    [
                      _c("span", { staticClass: "sidebarIcon" }, [
                        _c("i", { staticClass: "fas fa-history" }),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("sidebar.actionLogs")) + " "),
                    ]
                  )
                : _vm._e(),
              _vm.subdomain !== "napa"
                ? _c(
                    "router-link",
                    {
                      staticClass: "sidebarItem",
                      attrs: { to: "/operation-guide" },
                    },
                    [
                      _c("span", { staticClass: "sidebarIcon" }, [
                        _c("i", { staticClass: "fa fa-book" }),
                      ]),
                      _vm._v(
                        " " + _vm._s(_vm.$t("sidebar.operationGuide")) + " "
                      ),
                    ]
                  )
                : _vm._e(),
              _c("div", { staticClass: "sidebarTitle" }, [
                _vm._v(_vm._s(_vm.$t("sidebar.testReport"))),
              ]),
              _vm._l(_vm.testreportArray, function (report) {
                return _c(
                  "router-link",
                  {
                    key: report,
                    staticClass: "sidebarItem",
                    attrs: { to: { name: report } },
                  },
                  [
                    _c("span", { staticClass: "sidebarIcon" }, [
                      _c("i", { staticClass: "fas fa-desktop" }),
                    ]),
                    _c("p", {
                      domProps: {
                        textContent: _vm._s(_vm.$t("sidebar." + report)),
                      },
                    }),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }