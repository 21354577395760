<template>
  <div>
    <b-sidebar
      :width="'270px'"
      no-header
      id="sidebar-main"
      :backdrop-variant="'dark'"
      no-close-on-esc
      no-close-on-route-change
      shadow
      backdrop
    >
      <div class="gap"></div>
      <nav>
        <router-link class="sidebarItem" to="/home">
          <span class="sidebarIcon">
            <i class="fas fa-home"></i>
          </span>
          {{ $t("sidebar.Home") }}
        </router-link>

        <router-link class="sidebarItem" to="/deviceManagement">
          <span class="sidebarIcon">
            <i class="fas fa-car-battery"></i>
          </span>
          {{ $t("sidebar.deviceManagement") }}</router-link
        >

        <router-link
          v-if="vxAccountLevel !== vxConstantsAccountLevel.basic"
          class="sidebarItem"
          to="/listAllUsers"
        >
          <span class="sidebarIcon">
            <i class="fas fa-user-friends"></i>
          </span>
          {{ $t("sidebar.ListAllUsers") }}
        </router-link>

        <router-link
            v-if="
            subdomain !== 'napa' &&
            (
              vxAccountLevel === vxConstantsAccountLevel.admin ||
              vxAccountLevel === vxConstantsAccountLevel.business ||
              (
              vxAccountLevel === vxConstantsAccountLevel.shop &&
              !vxAccountCompanyId
              ) ||
              (
              !vxAccountCompanyId &&
              !vxAccountShopId &&
              vxAccountLevel !== vxConstantsAccountLevel.admin
              )
            )
            "
          class="sidebarItem"
          to="/blacklist"
        >
          <span class="sidebarIcon">
            <i class="fas fa-envelope"></i>
          </span>
          {{ $t("sidebar.blackList") }}
        </router-link>

        <router-link
          v-if="
            vxAccountLevel === vxConstantsAccountLevel.business ||
            (vxAccountLevel === vxConstantsAccountLevel.shop &&
              !vxAccountCompanyId)
          "
          class="sidebarItem"
          to="/pointsCalculation"
        >
          <span class="sidebarIcon">
            <i class="fas fa-file-invoice-dollar"></i>
          </span>
          {{ $t("sidebar.pointsCalculation") }}</router-link
        >

        <router-link
          v-if="vxAccountLevel === vxConstantsAccountLevel.admin"
          class="sidebarItem"
          to="/createPoints"
        >
          <span class="sidebarIcon">
            <i class="fas fa-file-alt"></i>
          </span>
          {{ $t("sidebar.createPoints") }}
        </router-link>

        <router-link
          v-if="vxAccountLevel === vxConstantsAccountLevel.admin"
          class="sidebarItem"
          to="/batchCreate"
        >
          <span class="sidebarIcon">
            <i class="fas fa-file-upload"></i>
          </span>
          {{ $t("sidebar.batchCreate") }}
        </router-link>

        <router-link
          v-if="vxAccountLevel === vxConstantsAccountLevel.admin"
          class="sidebarItem"
          to="/signinLogs"
        >
          <span class="sidebarIcon">
            <i class="fas fa-user-clock"></i>
          </span>
          {{ $t("sidebar.signinLogs") }}
        </router-link>

        <router-link
          v-if="vxAccountLevel === vxConstantsAccountLevel.admin"
          class="sidebarItem"
          to="/actionLogs"
        >
          <span class="sidebarIcon">
            <i class="fas fa-history"></i>
          </span>
          {{ $t("sidebar.actionLogs") }}
        </router-link>

        <!-- Test Reports -->
        <div class="sidebarTitle">{{ $t("sidebar.testReport") }}</div>

        <router-link
          v-for="report of testreportArray"
          :key="report"
          :to="{ name: report }"
          class="sidebarItem"
        >
          <span class="sidebarIcon">
            <i class="fas fa-desktop"></i>
          </span>
          <p v-text="$t('sidebar.' + report)"></p>
        </router-link>
      </nav>
    </b-sidebar>
  </div>
</template>

<script>
import { APIcheck } from "../api";

export default {
  mounted() {
    APIcheck()
      .then((res) => {
        this.testreportArray = res.data.list;
      })
      .catch((err) => {
        if (err.response != undefined && err.response.status === 401) {
          this.$bvModal.show("setCheckToken");
        } else {
          this.$bvModal.show("setsomethingWrong");
        }
      });
  },
  data() {
    return {
      testreportArray: [],
    };
  },
  methods: {},
  computed: {
    vxAccountLevel() {
      return this.$store.state.accountInformation.accountLevel;
    },
    vxAccountShopId() {
      return this.$store.state.accountInformation.shopId;
    },
    vxConstantsAccountLevel() {
      return this.$store.state.constants.csAccountLevel;
    },
    vxAccountCompanyId() {
      return this.$store.state.accountInformation.companyId;
    },
    subdomain() {
      return this.$root.subdomain;
    },
  },
};
</script>

<style lang="scss">
#sidebar-main {
  background: #024f83 !important;
}
</style>

<style lang="scss" scoped>
.sidebarTitle {
  text-align: left;
  font-weight: 500;
  color: $title_color;
  font-size: 1.05rem;
  padding: 10px 16px 10px 13px;
  margin-top: 1.5rem;
  font-family: $font-family;
}

.sidebarItem {
  display: flex;
  align-items: center;
  text-align: left;
  font-weight: 400;
  font-family: $font-family;
  color: $sidebar_color;
  padding: 10px 0px 10px 10px;
  border-left: solid 3px transparent;
  // font-size: 0.875rem;
  font-size: 13px;
  letter-spacing: 0.1px;
  line-height: 21px;
  &:hover {
    color: rgb(242, 224, 95);
  }
}

.lock {
  display: flex;
  justify-content: space-between;
  cursor: default;
}

nav .router-link-active {
  color: rgb(242, 224, 95);
  border-color: rgb(242, 224, 95);
  &:hover {
    color: rgb(242, 224, 95);
  }
}

nav {
  transition: 0.5s;
  // width: 247px;
}

.changeHamburger {
  transform: translateX(-100%);
}

nav a {
  display: flex;
  padding: 10px;
  &:hover {
    color: #2c3e50;
  }
}
.sidebarIcon {
  width: 30px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
}

.lockIcon {
  position: absolute;
  right: 5px;
}

.marginBottom {
  margin-bottom: 1rem;
}
</style>
