var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "download-container" }, [
      _vm._m(0),
      _vm._m(1),
      _c(
        "a",
        {
          staticClass: "download-button",
          attrs: { href: _vm.downloadUrl, download: "" },
        },
        [_vm._v(" Download ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "icon" }, [
      _c("i", {
        staticClass: "fas fa-file-alt",
        staticStyle: { "font-size": "3rem" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "file-info" }, [
      _c("h2", [_vm._v("mybatteryexpert.com")]),
      _c("p", [_vm._v("Operation Guide")]),
      _c("p", [_vm._v("PDF, 978KB, 2024.04.16")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }