<template>
  <div class="frame">
    <formFormat
      :page="page"
      :formInput="formInput"
      :error.sync="errorMessage"
      export
      @submit="showEmitValue"
      data-test="formFormat"
    ></formFormat>
    <!-------------- modal area --------------->
    <!-- create success -->
    <b-modal id="createPointSuccess" centered :title="$t('modal.success')">
      <h6>{{ $t('modal.createPoint.success.text1') }}</h6>
      <h6>{{ $t('modal.createPoint.success.text2') }} {{ pointCode.point }}</h6>
      <h6>
        {{ $t('modal.createPoint.success.text3') }} {{ pointCode.setNumber }}
      </h6>
      <br />
      <h6>{{ $t('modal.createPoint.success.text4') }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import formFormat from '@/components/FormFormat.vue';

  export default {
    components: {
      formFormat,
    },
    data() {
      return {
        page: 'createPoint',
        loading: false,
        errorMessage: {
          RegPoint: false,
          RegsetNumber: false,
          setNumberError: false,
        },
        formInput: [
          {
            id: 'point',
            title: 'createPoint.inputText1',
            placeholder: 'createPoint.inputText1Placeholder',
            type: 'number',
            check: 'point',
          },
          {
            id: 'setNumber',
            title: 'createPoint.inputText2',
            placeholder: 'createPoint.inputText2Placeholder',
            type: 'number',
            check: 'setNumber',
          },
        ],
        pointCode: {
          point: '',
          setNumber: '',
        },
      };
    },
    methods: {
      showEmitValue(inputValue) {
        this.pointCode.point = inputValue.input[0];
        this.pointCode.setNumber = inputValue.input[1];
        this.$bvModal.show('createPointSuccess');
      },
    },
  };
</script>
