<template>
  <div class="frame">
    <div class="testReportBottom">
      <dataTable
        :columns="columns"
        :device="'manageMemberList'"
        data-test="dataTable"
      ></dataTable>
    </div>
  </div>
</template>

<script>
import dataTable from "@/components/dataTable.vue";

export default {
  components: {
    dataTable,
  },
  mounted() {
    if (this.subdomain.toLowerCase() === "napa") {
      this.columns.push({
        label: this.$t("tableColumns.accountList.storeNumber"),
        field: "storeNumber",
        sortable: false,
        hidden: false,
        width: "225px",
        disable: false,
        type: "String",
      });
    }
    if (
      this.vxAccountLevel === this.vxConstantsAccountLevel.business ||
      this.vxAccountLevel === this.vxConstantsAccountLevel.admin
    ) {
      this.columns.push({
        label: this.$t("tableColumns.accountList.shopId"),
        field: "shopId",
        sortable: false,
        hidden: false,
        width: "225px",
        disable: false,
        type: "String",
      });
    }

    if (this.vxAccountLevel === this.vxConstantsAccountLevel.admin) {
      this.columns.push({
        label: this.$t("tableColumns.accountList.companyId"),
        field: "companyId",
        sortable: false,
        hidden: false,
        width: "225px",
        disable: false,
        type: "String",
      });
      this.columns.push({
        label: this.$t("tableColumns.accountList.status"),
        field: "status",
        sortable: false,
        hidden: false,
        width: "180px",
        disable: false,
        type: "Option",
        options: ["1", "2"],
      });
      this.columns.push({
        label: this.$t("tableColumns.accountList.isLocked"),
        field: "isLocked",
        sortable: false,
        hidden: false,
        width: "180px",
        disable: false,
        type: "Option",
        options: ["0", "1"],
      });
      this.columns.push({
        label: this.$t("tableColumns.accountList.lockedAt"),
        field: "lockedAt",
        sortable: false,
        hidden: false,
        width: "225px",
        disable: false,
        type: "String",
      });
    }
  },
  data() {
    return {
      columns: [
        {
          label: this.$t("tableColumns.accountList.account"),
          field: "account",
          sortable: false,
          hidden: false,
          width: "225px",
          disable: false,
          type: "String",
        },
        {
          label: this.$t("tableColumns.accountList.verification"),
          field: "verification",
          sortable: false,
          hidden: false,
          width: "180px",
          disable: false,
          type: "Option",
          options: ["0", "1"],
        },
        {
          label: this.$t("tableColumns.accountList.pricingPlan"),
          field: "pricingPlan",
          sortable: false,
          hidden: false,
          width: "180px",
          disable: false,
          type: "Option",
          options: ["0", "1", "2"],
        },
      ],
    };
  },
  computed: {
    vxAccountLevel() {
      return this.$store.state.accountInformation.accountLevel;
    },
    vxConstantsAccountLevel() {
      return this.$store.state.constants.csAccountLevel;
    },
    vxAccountCompanyId() {
      return this.$store.state.accountInformation.companyId;
    },
    subdomain() {
      return this.$root.subdomain;
    },
  },
};
</script>

<style lang="scss">
.buttonLoad {
  margin: 0 0 0 5px;
}

.export,
.columns {
  .dropdown-item:active {
    background: transparent;
    color: black;
  }
}

.export {
  .dropdown-item {
    padding: 0.75rem;
  }
}

.columns {
  .dropdown-menu {
    max-height: 300px;
    overflow-y: scroll;
  }
  .dropdown-item {
    padding: 0;
    label {
      display: block;
      a {
        display: flex;
        padding: 0.65rem;
      }
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    input:checked ~ .checkmark:after {
      display: block;
    }
    // custom checkbox
    .checkmark {
      margin-right: 5px;
      height: 15px;
      width: 15px;
      display: block;
      background-color: #eee;
      &::after {
        content: "\02713";
        font-weight: 700;
        font-size: 20px;
        transform: translateY(-5px);
        display: none;
      }
    }
  }
}
.vgt-wrap__footer .footer__navigation__page-btn.disabled {
  visibility: hidden;
}

.inviteMail-Group {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  label,
  button {
    margin: 0 5px;
  }
  input {
    margin-left: 5px;
    border: solid 1px transparent;
    border-bottom: solid 1px black;
  }
  input.error {
    border: solid 1px red;
  }
}
</style>
