<template>
  <div>
    <div class="dashboardTop">
      <div class="dashboardTitle">
        <h1>{{ vxShopID }} {{ $t('chart.list.shop') }}</h1>
        <h1>
          {{ dashboardTimezone
          }}<span
            v-if="
              dashboardTimezone !== '' &&
              vxTimezone !== DATAnewestUpdateTime.timezone
            "
            style="font-size: 16px"
            >({{ $t('formFormat.willUpdate') }})</span
          >
        </h1>
      </div>
      <div>
        <ul class="groupList"></ul>
      </div>
    </div>
    <div class="frameContainer">
      <!-- 機種切換按鈕，API load完後才能點擊 -->
      <b-row class="dashboardButtons">
        <b-col lg="6" md="12" class="button-group fixFlex">
          <m-button
            v-for="(button, index) of buttonArray"
            :key="button.name"
            @click="fnDeviceChange(button.name, index)"
            :disabled="button.disabled || loading"
            :raised="button.action"
            :outlined="!button.action"
            dense
            class="tableButton"
          >
            <div class="loading" v-if="button.loading">
              <b-spinner small label="Loading..." :variant="'info'"></b-spinner>
            </div>
            <p v-else>{{ getButtonModelName(button.name) }}</p>
          </m-button>
        </b-col>

        <b-col lg="6" md="12" class="fixFlex">
          <!-- no data時為false -->
          <div v-if="DataTimeToggle" class="lastUpdateTime">
            {{ newestUpdateTime }}
          </div>
          <div v-else class="lastUpdateTime"></div>
        </b-col>
      </b-row>

      <b-row class="chartBox">
        <b-col sm="12" md="6" lg="6" xl="4" class="p0">
          <UserBox
            class="chartUser chartArea"
            :data_userChart="DATAactiveUser.newUser"
            :data_date="DATAactiveUser.date"
            :title="$t('chart.title.new')"
            :haveData="NoDataUserToggle"
          >
            <i
              slot="text"
              v-b-tooltip.bottom.hover
              :title="$t('chart.info.new')"
              class="fas fa-info-circle info"
            ></i>
          </UserBox>
        </b-col>

        <b-col sm="12" md="6" lg="6" xl="4" class="p0">
          <UserBox
            class="chartUser chartArea"
            :data_userChart="DATAactiveUser.activeUser"
            :data_date="DATAactiveUser.date"
            :title="$t('chart.title.active')"
            :haveData="NoDataUserToggle"
          >
            <i
              slot="text"
              v-b-tooltip.bottom.hover
              :title="$t('chart.info.active')"
              class="fas fa-info-circle info"
            ></i>
          </UserBox>
        </b-col>

        <b-col lg="12" xl="4" class="p0">
          <UserBox
            class="chartUser chartArea"
            :data_userChart="DATAactiveUser.totalUser"
            :data_date="DATAactiveUser.date"
            :title="$t('chart.title.totalUesr')"
            :haveData="NoDataUserToggle"
          >
            <i
              slot="text"
              v-b-tooltip.bottom.hover
              :title="$t('chart.info.total')"
              class="fas fa-info-circle info"
            ></i>
          </UserBox>
        </b-col>
      </b-row>
      <b-row class="chartBox">
        <!-- firmware version v-if nodata = false -->
        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDataFwToggle" class="chartArea chartBarFirmware">
            <HorizontalBarChart
              :chartData="DATAversionByDay"
              :chartTitle="[
                $t('chart.title.firmwareVersion'),
                APIversionByDay.date ? APIversionByDay.date : '',
              ]"
              :chart="'firmware'"
              class="chart"
            ></HorizontalBarChart>
            <ul class="labelsBox labelFirmware">
              <li
                class="label"
                v-for="(label, index) of firmwareLabel"
                :key="index"
              >
                <span
                  class="labelColor"
                  :style="{ background: label.color }"
                ></span>
                <div class="labelText">
                  <p>{{ label.name }}</p>
                  <p>{{ label.num }} ({{ label.percen }}%)</p>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="chartBarFirmware chartArea noDataChart">
            <h3>{{ $t('chart.title.firmwareVersion') }}</h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>

        <!-- Hourly By Day v-if nodata = false -->
        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDataPerDayToggle" class="chartArea chartBarHourlyByDay">
            <BarChart
              :chartData="DATAusageByDay"
              :chartTitle="
                $t('chart.title.hourlyDay') +
                ' ' +
                (this.APIusageByDay.date ? this.APIusageByDay.date : '')
              "
              :chartLabel="[$t('chart.label.time'), $t('chart.label.records')]"
              :avg="usageByDay_Avg"
              :chart="'hourlyDay'"
              class="chart"
            ></BarChart>
          </div>
          <div v-else class="chartBarHourlyByDay chartArea noDataChart">
            <h3>{{ $t('chart.title.hourlyDay') }}</h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>
        <!-- account By Day v-if nodata = false -->

        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDataTop4Toggle" class="chartArea chartBarAccount">
            <HorizontalBarChart
              :chartData="DATAaccountByDay"
              :chartTitle="[
                $t('chart.title.top5Account'),
                APIaccountByDay.date ? APIaccountByDay.date : '',
              ]"
              :chartLabel="[
                $t('chart.label.records'),
                $t('chart.label.account'),
              ]"
              :chart="'userRank'"
              class="chart"
            ></HorizontalBarChart>
          </div>
          <div v-else class="chartBarAccount chartArea noDataChart">
            <h3>{{ $t('chart.title.top5Account') }}</h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>

        <!-- Monthly report -->
        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDataNMonthToggle" class="chartArea chartLineMonths">
            <LineChart
              :chartData="DATAtestResultByXMonth"
              :chartTitle="$t('chart.title.monthly')"
              :chartLabel="[$t('chart.label.month'), $t('chart.label.records')]"
              :chartPercen="toolTipsArrayPercen"
              class="chart"
            ></LineChart>
            <div class="chooseMonthlyBox">
              <div class="chooseTimeType">
                <label class="timeType"
                  ><input
                    @change="fnChangeTimeType"
                    type="radio"
                    value="month"
                    v-model="selectTimeType"
                    :disabled="loading"
                  />
                  {{ $t('chart.label.month') }}</label
                >
                <label class="timeType"
                  ><input
                    @change="fnChangeTimeType"
                    type="radio"
                    value="quarter"
                    v-model="selectTimeType"
                    :disabled="loading"
                  />
                  {{ $t('chart.label.quarter') }}</label
                >
              </div>
              <b-spinner
                v-show="drewing"
                small
                label="Loading..."
                :variant="'light'"
                class="buttonLoad drawingLoad"
              ></b-spinner>
              <div class="chooseTimePeriod">
                <span class="timePeriod"
                  >{{ $t('chart.label.timePeriod') }}:</span
                >
                <select
                  @change="reDrawMonthlyChart"
                  class="timePeriod"
                  v-model="selectTimePeriod"
                  :disabled="loading"
                >
                  <option
                    :selected="index === 0"
                    :value="period"
                    v-for="(period, index) in timePeriod"
                    :key="period"
                  >
                    {{ period }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div
            lg="4"
            md="6"
            v-else
            class="chartLineMonths chartArea noDataChart"
          >
            <h3>{{ $t('chart.title.monthly') }}</h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>

        <!-- month result and total result -->
        <b-col lg="12" xl="8" class="p0">
          <div class="chartDoughnut-group">
            <!-- Month report v-if nodata = false -->
            <b-col md="12" lg="6" class="p0">
              <div v-if="NoDataPerMonthToggle" class="chartDoughnutMonth">
                <DoughnutChart
                  :chartData="DATAtestResultByMonth"
                  :chartTitle="
                    testResultByMonth_Title + ' ' + $t('chart.title.result')
                  "
                  :labelData="[
                    testResultByMonth_Total,
                    testResultByMonthPercen,
                  ]"
                  month
                  class="chart"
                ></DoughnutChart>
              </div>
              <div v-else class="chartDoughnutMonth noDataChart">
                <h3>
                  {{ testResultByMonth_ErrorTitle }}
                  {{ $t('chart.title.result') }}
                </h3>
                <p>{{ $t('home.nodata') }}</p>
              </div>
            </b-col>
            <!-- Total report v-if nodata = false -->
            <b-col md="12" lg="6" class="p0">
              <div v-if="NoDataTotalToggle" class="chartDoughnutTotal">
                <DoughnutChart
                  :chartData="DATAtotalTestResult"
                  :chartTitle="$t('chart.title.total')"
                  :labelData="[totalTestResult_Total]"
                  total
                  class="chart"
                ></DoughnutChart>
              </div>
              <div v-else class="chartDoughnutTotal noDataChart">
                <h3>{{ $t('chart.title.total') }}</h3>
                <p>{{ $t('home.nodata') }}</p>
              </div>
            </b-col>

            <!-- 與Total report共用狀態 -->
            <ul v-if="NoDataTotalToggle" class="labelsBox totalChartLabel">
              <li
                class="label"
                v-for="(label, index) of labelResult"
                :key="index"
              >
                <span
                  class="labelColor"
                  :style="{ background: label.color }"
                ></span>
                <p>{{ label.label }}</p>
              </li>
            </ul>
          </div>
        </b-col>

        <!-- test type(Quarter) -->
        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDataTestResultPerQuarter" class="chartArea chartPieType">
            <DoughnutChart
              :chartData="DATAtestType"
              :chartTitle="testResultPerQuarter_Title"
              pie
              class="chart"
            ></DoughnutChart>
            <ul class="pieLabelsBox">
              <li
                class="label"
                v-for="(label, index) of testResultPerQuarter_Label"
                :key="index"
              >
                <span
                  class="labelColor"
                  :style="{ background: label.color }"
                ></span>
                <p>{{ label.label }}</p>
              </li>
            </ul>
          </div>
          <div v-else class="chartPieType chartArea noDataChart">
            <h3>
              {{ APIchartPerQuarterErrorTitle }}
              {{ $t('chart.title.testType') }}
            </h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>

        <!-- battery type(month) -->
        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDatabatteryTypeMonth" class="chartArea chartBarType">
            <BarChart
              :chartData="DATAbatteryTypeMonth"
              :chartTitle="$t('chart.title.batteryTypeMonth')"
              :chartLabel="[
                $t('chart.label.batteryType'),
                $t('chart.label.records'),
              ]"
              :chart="'batteryType'"
              class="chart"
            ></BarChart>
          </div>
          <div v-else class="chartBarType chartArea noDataChart">
            <h3>{{ $t('chart.title.batteryTypeMonth') }}</h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>

        <!-- battery type(year) -->
        <b-col xl="4" lg="6" class="p0">
          <div v-if="NoDatabatteryTypeYear" class="chartArea chartPieType">
            <DoughnutChart
              :chart-data="DATAbatteryTypeYear"
              :chartTitle="
                (this.batteryTypeYear_Title ? this.batteryTypeYear_Title : '') +
                ' ' +
                $t('chart.title.batteryTypeYear')
              "
              pie
              class="chart"
            ></DoughnutChart>
            <ul class="pieLabelsBox">
              <li
                class="label"
                v-for="(label, index) of batteryTypeYear_Label"
                :key="index"
              >
                <span
                  class="labelColor"
                  :style="{ background: label.color }"
                ></span>
                <p>{{ label.label }}</p>
              </li>
            </ul>
          </div>
          <div v-else class="chartPieType chartArea noDataChart">
            <h3>
              {{ batteryTypeYear_Title }}
              {{ $t('chart.title.batteryTypeYear') }}
            </h3>
            <p>{{ $t('home.nodata') }}</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import DoughnutChart from '@/components/chart/DoughnutChart.vue';
  import BarChart from '@/components/chart/BarChart.vue';
  import HorizontalBarChart from '@/components/chart/HorizontalBarChart.vue';
  import LineChart from '@/components/chart/LineChart.vue';
  import UserBox from '@/components/chart/UserBox.vue';

  import {
    APITop4AccountPerDay,
    APIActiveUser,
    APITestResultPerMonth,
    APITestResultPerNMonth,
    APITotalTestResult,
    APIUsagePerDay,
    APIFwVersionPerDay,
    APITestTypePerQuarter,
    APIBatteryTypeMonth,
    APIBatteryTypeYear,
    APInewestUpdateTime,
    APIcheck,
  } from '../../api';

  export default {
    name: 'Home',
    components: {
      UserBox,
      DoughnutChart,
      BarChart,
      HorizontalBarChart,
      LineChart,
    },
    created() {
      APIcheck({ isDashboard: true })
        .then(res => {
          // 由於big data dashboard尚未整理出 btw300，所以先挑出來，不show按鈕
          let array = res.data.list.filter(el => {
            return el !== 'btw300-record-bt' && el !== 'btw300-record-st';
          });
          return array;
        })
        .then(res => {
          this.buttonArray = this.checkButtonArray(res);
        })
        .then(() => {
          if (this.buttonArray.length === 0) {
            this.NoDataPerMonthToggle = false;
            this.NoDataTotalToggle = false;
            this.NoDataNMonthToggle = false;
            this.NoDataFwToggle = false;
            this.NoDataPerDayToggle = false;
            this.NoDataTop4Toggle = false;
            this.NoDataUserToggle = false;
            this.DataTimeToggle = false;
            this.NoDataTestResultPerQuarter = false;
            this.NoDatabatteryTypeMonth = false;
            this.NoDatabatteryTypeYear = false;
            return false;
          }
          this.device = this.buttonArray[0].name;

          // 第一次載入時的機種判斷，以陣列第一筆為第一次載入(尚未新增btw300)
          this.buttonArray[0].action = true;
          this.checkDevice(this.device);
        })
        .catch(err => {
          if (err.response != undefined && err.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            this.$bvModal.show('setsomethingWrong');
          }
        });
    },
    data() {
      return {
        loading: true,
        drewing: false,
        buttonArray: [],
        getFullMonth: [
          this.$t('chart.month.full.1'),
          this.$t('chart.month.full.2'),
          this.$t('chart.month.full.3'),
          this.$t('chart.month.full.4'),
          this.$t('chart.month.full.5'),
          this.$t('chart.month.full.6'),
          this.$t('chart.month.full.7'),
          this.$t('chart.month.full.8'),
          this.$t('chart.month.full.9'),
          this.$t('chart.month.full.10'),
          this.$t('chart.month.full.11'),
          this.$t('chart.month.full.12'),
        ],
        getShoutMonth: [
          this.$t('chart.month.short.1'),
          this.$t('chart.month.short.2'),
          this.$t('chart.month.short.3'),
          this.$t('chart.month.short.4'),
          this.$t('chart.month.short.5'),
          this.$t('chart.month.short.6'),
          this.$t('chart.month.short.7'),
          this.$t('chart.month.short.8'),
          this.$t('chart.month.short.9'),
          this.$t('chart.month.short.10'),
          this.$t('chart.month.short.11'),
          this.$t('chart.month.short.12'),
        ],
        getQuarter: ['Q1', 'Q2', 'Q3', 'Q4'],
        labelColor: [
          'rgba(46,139,87,1)',
          'rgba(255,167,63,1)',
          'rgba(255, 186, 102,1)',
          'rgba(255,214,0,1)',
          'rgba(235, 77, 71,1)',
          'rgba(217,30,24,1)',
        ],
        // ----
        chartPerMonthTitle: '',
        DATAtestResultByMonth: {}, // 1
        APItestResultByMonth: {},
        APIchartPerMonthErrorTitle: '',
        // ----
        DATAtotalTestResult: {}, // 2
        APItotalTestResult: {},
        // ----
        DATAtestResultByXMonth: {}, // 3
        APItestResultByXMonth: [],
        testResultByXMonth_Month: [],
        testResultByXMonth_Year: [],
        selectTimeType: 'month',
        selectTimePeriod: 6,
        // ----
        DATAversionByDay: {}, // 4
        APIversionByDay: {},
        versionByDay_Color: [
          'rgba(1,122,185,.8)',
          'rgba(102,175,213,.8)',
          'rgba(178,215,234,.8)',
          // "rgba(153,100,153,1)",
          // "rgba(180,213,195,1)"
        ],
        // ----
        DATAusageByDay: {}, // 5
        APIusageByDay: {},
        // ----
        DATAaccountByDay: {}, // 6
        APIaccountByDay: {},
        // ----
        DATAactiveUser: {
          newUser: {
            today: '',
            yesterday: '',
          },
          activeUser: {
            today: '',
            yesterday: '',
          },
          totalUser: {
            today: '',
            yesterday: '',
          },
        },
        // ----
        DATAnewestUpdateTime: {},
        // -----
        testResultPerQuarter_Color: [
          'rgba(1,122,185,.8)',
          'rgba(51,148,199,.8)',
          'rgba(102,175,213,.8)',
          'rgba(153,201,227,.8)',
          'rgba(178,215,234,.8)',
          'rgba(204,228,241,.8)',
          'rgba(224,238,246,.55)',
        ],
        APItestResultPerQuarter: {},
        DATAtestType: {},
        APIchartPerQuarterErrorTitle: '',
        // -----
        APIbatteryTypeMonthResult: {},
        DATAbatteryTypeMonth: {},
        // -----
        APIbatteryTypeYearResult: {},
        DATAbatteryTypeYear: {},
        // -----
        NoDataUserToggle: true,
        NoDataPerMonthToggle: true,
        NoDataTotalToggle: true,
        NoDataNMonthToggle: true,
        NoDataFwToggle: true,
        NoDataPerDayToggle: true,
        NoDataTop4Toggle: true,
        DataTimeToggle: true,
        NoDataTestResultPerQuarter: true,
        NoDatabatteryTypeMonth: true,
        NoDatabatteryTypeYear: true,
        device: '',
      };
    },
    methods: {
      getButtonModelName(name) {
        switch (name.toLowerCase()) {
          case 'bt2400-napa':
            return 'st2400pro';
          default:
            return name;
        }
      },
      checkButtonArray(buttonArray) {
        let array = [];
        const modelNameRegex = /([^]+)-record-[^]+/;
        buttonArray.map((el, index) => {
          let theNext = buttonArray[index + 1];
          const matchElModel = modelNameRegex.exec(el)[1];
          const matchTheNextModel = theNext
            ? modelNameRegex.exec(theNext)[1]
            : null;
          if (
            index === buttonArray.length - 1 ||
            matchElModel !== matchTheNextModel
          ) {
            array.push({
              name: matchElModel,
              loading: false,
              disabled: false,
              action: false,
            });
          }
        });
        return array;
      },
      fnDeviceChange(device, index) {
        this.buttonArray[index].loading = true;
        this.buttonArray.map(el => {
          el.disabled = true;
          el.action = false;
        });
        this.buttonArray[index].action = true;
        this.loading = true;
        this.checkDevice(device, index);
        this.device = device;
      },
      chartOpen() {
        this.NoDataPerMonthToggle = true;
        this.NoDataTotalToggle = true;
        this.NoDataNMonthToggle = true;
        this.NoDataFwToggle = true;
        this.NoDataPerDayToggle = true;
        this.NoDataTop4Toggle = true;
        this.NoDataUserToggle = true;
        this.DataTimeToggle = true;
        this.NoDataTestResultPerQuarter = true;
        this.NoDatabatteryTypeMonth = true;
        this.NoDatabatteryTypeYear = true;
      },

      checkDevice(device, index) {
        let chartAPI = null;
        let timeRange = {
          unit: this.selectTimeType,
          period: this.selectTimePeriod,
        };
        chartAPI = Promise.all([
          APITestResultPerMonth(device),
          APITotalTestResult(device),
          APITestResultPerNMonth(device, timeRange),
          APIFwVersionPerDay(device),
          APIUsagePerDay(device),
          APITop4AccountPerDay(device),
          APIActiveUser(device),
          APInewestUpdateTime(),
          APITestTypePerQuarter(device),
          APIBatteryTypeMonth(device),
          APIBatteryTypeYear(device),
        ]);
        this.drawChart(chartAPI, index);
      },

      drawChart(device, index) {
        if (device) {
          device
            .then(res => {
              this.chartOpen();
              if (res[0].data.error) {
                // 當月甜甜圈圖表區塊顯示
                this.APIchartPerMonthErrorTitle = res[0].data.month;
                this.NoDataPerMonthToggle = false;
              } else {
                this.APItestResultByMonth = res[0].data;
                // --------------------
                this.DATAtestResultByMonth = {
                  labels: this.labelItem,
                  datasets: [
                    {
                      backgroundColor: this.labelColor,
                      borderWidth: 0,
                      data: this.testResultByMonth_Array,
                    },
                  ],
                };
              }
              if (res[1].data.error) {
                // Total甜甜圈圖表區塊顯示
                this.NoDataTotalToggle = false;
              } else {
                this.APItotalTestResult = res[1].data;
                // --------------------
                this.DATAtotalTestResult = {
                  labels: this.labelItem,
                  datasets: [
                    {
                      backgroundColor: this.labelColor,
                      borderWidth: 0,
                      data: this.totalTestResult_Array,
                    },
                  ],
                };
              }
              if (res[2].data.error) {
                // 6個月圖表區塊顯示
                this.NoDataNMonthToggle = false;
              } else {
                this.APItestResultByXMonth = res[2].data;

                this.testResultByXMonth_Month = [];
                this.testResultByXMonth_Year = [];

                // XMonth month
                this.APItestResultByXMonth.forEach((el, index) => {
                  if (index === 0) {
                    return;
                  } else {
                    if (this.selectTimeType === 'month') {
                      this.testResultByXMonth_Month.push(el.month);
                    } else if (this.selectTimeType === 'quarter') {
                      this.testResultByXMonth_Month.push(el.quarter);
                    }
                    if (el.year) {
                      this.testResultByXMonth_Year.push(el.year);
                    } else {
                      this.testResultByXMonth_Year.push('');
                    }
                  }
                });

                if (this.selectTimeType === 'month') {
                  this.testResultByXMonth_Month =
                    this.testResultByXMonth_Month.map(el => {
                      return [this.getShoutMonth[el - 1]];
                    });
                } else if (this.selectTimeType === 'quarter') {
                  this.testResultByXMonth_Month =
                    this.testResultByXMonth_Month.map(el => {
                      return [this.getQuarter[el - 1]];
                    });
                }

                this.testResultByXMonth_Month.map((el, index) => {
                  el.push(this.testResultByXMonth_Year[index]);
                });

                // --------------------
                this.DATAtestResultByXMonth = {
                  labels: this.testResultByXMonth_Month,
                  datasets: [
                    {
                      borderColor: 'rgb(0, 173, 57)',
                      label: this.$t('chart.label.gd'),
                      data: this.testResultByXMonth_Good,
                      fill: false,
                    },
                    {
                      borderColor: 'rgb(255, 77, 77)',
                      label: this.$t('chart.label.bd'),
                      data: this.testResultByXMonth_Bad,
                      fill: false,
                    },
                    {
                      borderColor: 'rgb(243, 209, 124)',
                      label: this.$t('chart.label.oth'),
                      data: this.testResultByXMonth_Other,
                      fill: false,
                    },
                    {
                      borderColor: 'white',
                      label: this.$t('chart.label.total'),
                      data: this.testResultByXMonth_Total,
                      fill: false,
                    },
                  ],
                };
              }
              if (res[3].data.error) {
                // 版本圖表區塊顯示
                this.NoDataFwToggle = false;
              } else {
                this.APIversionByDay = res[3].data;
                // firmware
                // --------------------
                this.DATAversionByDay = {
                  datasets: this.versionByDay_List,
                  labels: 'firmware',
                };
              }
              if (res[4].data.error) {
                // 當日時段圖表區塊顯示
                this.NoDataPerDayToggle = false;
              } else {
                this.APIusageByDay = res[4].data;
                // --------------------
                this.DATAusageByDay = {
                  datasets: [
                    {
                      backgroundColor: [
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                        'rgba(1,122,185,.8)',
                      ],
                      data: this.APIusageByDay.usage,
                    },
                  ],
                  labels: [
                    '00-02',
                    '02-04',
                    '04-06',
                    '06-08',
                    '08-10',
                    '10-12',
                    '12-14',
                    '14-16',
                    '16-18',
                    '18-20',
                    '20-22',
                    '22-00',
                  ],
                };
              }
              if (res[5].data.error) {
                // 前4名圖表區塊顯示
                this.NoDataTop4Toggle = false;
              } else {
                // account by day
                this.APIaccountByDay = res[5].data;
                // --------------------
                this.DATAaccountByDay = {
                  labels: this.accountByDay_Array,
                  datasets: [
                    {
                      backgroundColor: [
                        'rgba(238,238,238,.6)',
                        'rgba(238,238,238,.6)',
                        'rgba(238,238,238,.6)',
                        'rgba(238,238,238,.6)',
                        'rgba(238,238,238,.6)',
                      ],
                      data: this.accountByDay_Data,
                      barPercentage: 0.7,
                    },
                  ],
                };
              }
              if (res[6].data.error) {
                // userbox圖表區塊顯示
                this.NoDataUserToggle = false;
              } else {
                this.DATAactiveUser = res[6].data;
              }
              if (res[7].data.error) {
                // time區塊顯示
                this.DataTimeToggle = false;
              } else {
                this.DATAnewestUpdateTime = res[7].data;
              }
              if (res[8].data.error) {
                this.NoDataTestResultPerQuarter = false;
                this.APIchartPerQuarterErrorTitle =
                  res[8].data.year + ' Q' + res[8].data.quarter;
              } else {
                // test result per quarter
                this.APItestResultPerQuarter = res[8].data;

                this.DATAtestType = {
                  labels: this.testResultPerQuarter_List,
                  datasets: [
                    {
                      backgroundColor: this.testResultPerQuarter_Color,
                      borderWidth: 0,
                      data: this.testResultPerQuarter_Data,
                    },
                  ],
                };
              }
              if (res[9].data.error) {
                this.NoDatabatteryTypeMonth = false;
              } else {
                this.APIbatteryTypeMonthResult = res[9].data;

                // -----new-----
                this.DATAbatteryTypeMonth = {
                  datasets: [
                    {
                      label: this.batteryTypeMonth_Title[0],
                      backgroundColor: 'rgba(153,201,227,.8)',
                      data: this.batteryTypeLastMonth_Data,
                    },
                    {
                      label: this.batteryTypeMonth_Title[1],
                      backgroundColor: 'rgba(1,122,185,.8)',
                      data: this.batteryTypeNowMonth_Data,
                    },
                  ],
                  labels: this.batteryTypeMonth_List,
                };
              }
              if (res[10].data.error) {
                this.NoDatabatteryTypeYear = false;
              } else {
                this.APIbatteryTypeYearResult = res[10].data;
                this.DATAbatteryTypeYear = {
                  labels: this.batteryTypeYear_List,
                  datasets: [
                    {
                      backgroundColor: this.testResultPerQuarter_Color,
                      borderWidth: 0,
                      data: this.batteryTypeYear_Data,
                    },
                  ],
                };
              }
            })
            .then(() => {
              if (index || index === 0) {
                this.buttonArray[index].loading = false;
                this.buttonArray.map(el => {
                  el.disabled = false;
                });
              }
            })
            .catch(err => {
              if (err.response != undefined && err.response.status === 401) {
                this.$bvModal.show('setCheckToken');
              } else {
                this.$bvModal.show('setsomethingWrong');
              }
            })
            .then(() => {
              this.loading = false;
            });
        } else {
          // chartAPI === false 未綁定任何機台
          this.NoDataPerMonthToggle = false;
          this.NoDataTotalToggle = false;
          this.NoDataNMonthToggle = false;
          this.NoDataFwToggle = false;
          this.NoDataPerDayToggle = false;
          this.NoDataTop4Toggle = false;
          this.NoDataUserToggle = false;
          this.DataTimeToggle = false;
          this.NoDataTestResultPerQuarter = false;
          this.NoDatabatteryTypeMonth = false;
          this.NoDatabatteryTypeYear = false;
          return;
        }
      },

      fnChangeTimeType() {
        if (this.selectTimeType === 'month') {
          this.selectTimePeriod = 6;
        } else {
          this.selectTimePeriod = 4;
        }
        this.reDrawMonthlyChart();
      },

      reDrawMonthlyChart() {
        this.loading = true;
        this.drewing = true;
        let data = {
          unit: this.selectTimeType,
          period: this.selectTimePeriod,
        };

        let promise = null;
        let device = '';
        this.buttonArray.map(el => {
          if (el.action) {
            device = el.name;
          }
        });
        promise = APITestResultPerNMonth(device, data);
        promise
          .then(res => {
            if (res.data.error) {
              // 6個月圖表區塊顯示
              this.NoDataNMonthToggle = false;
            } else {
              this.APItestResultByXMonth = res.data;

              this.testResultByXMonth_Month = [];
              this.testResultByXMonth_Year = [];

              // XMonth month
              this.APItestResultByXMonth.forEach((el, index) => {
                if (index === 0) {
                  return;
                } else {
                  if (this.selectTimeType === 'month') {
                    this.testResultByXMonth_Month.push(el.month);
                  } else if (this.selectTimeType === 'quarter') {
                    this.testResultByXMonth_Month.push(el.quarter);
                  }
                }

                if (el.year) {
                  this.testResultByXMonth_Year.push(el.year);
                } else {
                  this.testResultByXMonth_Year.push('');
                }
              });
              if (this.selectTimeType === 'month') {
                this.testResultByXMonth_Month =
                  this.testResultByXMonth_Month.map(el => {
                    return [this.getShoutMonth[el - 1]];
                  });
              } else if (this.selectTimeType === 'quarter') {
                this.testResultByXMonth_Month =
                  this.testResultByXMonth_Month.map(el => {
                    return [this.getQuarter[el - 1]];
                  });
              }

              this.testResultByXMonth_Month.map((el, index) => {
                el.push(this.testResultByXMonth_Year[index]);
              });

              // --------------------
              this.DATAtestResultByXMonth = {
                labels: this.testResultByXMonth_Month,
                datasets: [
                  {
                    borderColor: 'rgb(0, 173, 57)',
                    label: this.$t('chart.label.gd'),
                    data: this.testResultByXMonth_Good,
                    fill: false,
                  },
                  {
                    borderColor: 'rgb(255, 77, 77)',
                    label: this.$t('chart.label.bd'),
                    data: this.testResultByXMonth_Bad,
                    fill: false,
                  },
                  {
                    borderColor: 'rgb(243, 209, 124)',
                    label: this.$t('chart.label.oth'),
                    data: this.testResultByXMonth_Other,
                    fill: false,
                  },
                  {
                    borderColor: 'white',
                    label: this.$t('chart.label.total'),
                    data: this.testResultByXMonth_Total,
                    fill: false,
                  },
                ],
              };
            }
          })
          .catch(err => {
            if (err.response != undefined && err.response.status === 401) {
              this.$bvModal.show('setCheckToken');
            } else {
              this.$bvModal.show('setsomethingWrong');
            }
          })
          .then(() => {
            this.loading = false;
            this.drewing = false;
          });
      },
    },
    computed: {
      vxShopID() {
        return this.$store.state.accountInformation.shopId;
      },
      vxTimezone() {
        return this.$store.state.accountInformation.timezone;
      },
      dashboardTimezone() {
        let string = this.DATAnewestUpdateTime.timezone;
        if (string === undefined || string === '') {
          return '';
        }
        return string;
      },
      labelItem() {
        if (this.device === 'ba427plus') {
          return [
            this.$t('chart.label.gp'),
            this.$t('chart.label.gr'),
            this.$t('chart.label.rnr'),
            this.$t('chart.label.ca'),
            this.$t('chart.label.br'),
            this.$t('chart.label.bcr'),
          ];
        } else if (this.device.includes('napa')) {
          return [
            this.$t('chart.label.gp'),
            this.$t('chart.label.gr'),
            this.$t('chart.label.rr'),
            this.$t('chart.label.marginal'),
            this.$t('chart.label.br'),
            this.$t('chart.label.bcr'),
          ];
        } else {
          return [
            this.$t('chart.label.gp'),
            this.$t('chart.label.gr'),
            this.$t('chart.label.rr'),
            this.$t('chart.label.ca'),
            this.$t('chart.label.br'),
            this.$t('chart.label.bcr'),
          ];
        }
      },
      // --------------------
      testResultByMonth_Title() {
        if (this.APItestResultByMonth.month) {
          return this.getFullMonth[this.APItestResultByMonth.month - 1];
        } else {
          return this.$t('chart.title.month');
        }
      },
      testResultByMonth_ErrorTitle() {
        if (this.APIchartPerMonthErrorTitle) {
          return this.getFullMonth[this.APIchartPerMonthErrorTitle - 1];
        } else {
          return this.$t('chart.title.month');
        }
      },
      testResultByMonth_Array() {
        if (this.APItestResultByMonth.testResult === undefined) {
          return;
        }
        return [
          parseInt(this.APItestResultByMonth.testResult.goodAndPass, 10),
          parseInt(this.APItestResultByMonth.testResult.goodAndRecharge, 10),
          parseInt(this.APItestResultByMonth.testResult.rechargeAndRetest, 10),
          parseInt(this.APItestResultByMonth.testResult.caution, 10),
          parseInt(this.APItestResultByMonth.testResult.badAndReplace, 10),
          parseInt(this.APItestResultByMonth.testResult.badCellReplace, 10),
        ];
      },
      testResultByMonth_Total() {
        if (this.testResultByMonth_Array === undefined) {
          return;
        }
        return this.testResultByMonth_Array.reduce((sum, cur) => (sum += cur));
      },
      testResultByMonthPercen() {
        if (this.APItestResultByMonth.testResult === undefined) {
          return;
        }
        let total = 0;
        total =
          ((parseInt(this.APItestResultByMonth.testResult.caution, 10) +
            parseInt(this.APItestResultByMonth.testResult.badAndReplace, 10) +
            parseInt(this.APItestResultByMonth.testResult.badCellReplace, 10)) /
            this.testResultByMonth_Total) *
          100;
        total = total.toFixed(0);
        return total;
      },
      // -------------------
      totalTestResult_Label() {
        let array = [];
        if (this.APItotalTestResult.testResult) {
          Object.keys(this.APItotalTestResult.testResult).forEach(el => {
            if (this.device === 'ba427plus') {
              if (el !== 'caution') {
                array.push(el);
              }
            } else {
              array.push(el);
            }
          });
        }
        return array;
      },
      totalTestResult_Array() {
        if (this.APItotalTestResult.testResult === undefined) {
          return;
        }
        return [
          parseInt(this.APItotalTestResult.testResult.goodAndPass, 10),
          parseInt(this.APItotalTestResult.testResult.goodAndRecharge, 10),
          parseInt(this.APItotalTestResult.testResult.rechargeAndRetest, 10),
          parseInt(this.APItotalTestResult.testResult.caution, 10),
          parseInt(this.APItotalTestResult.testResult.badAndReplace, 10),
          parseInt(this.APItotalTestResult.testResult.badCellReplace, 10),
        ];
      },
      totalTestResult_Total() {
        if (this.totalTestResult_Array === undefined) {
          return;
        }
        let total = this.totalTestResult_Array.reduce(
          (sum, cur) => (sum += cur)
        );
        let unit = ['', 'k', 'M', 'G', 'T'];
        let unitIndex = 0;
        while (total > 1000) {
          unitIndex++;
          total = total / 1000;
        }
        return `${Math.floor(total * 100) / 100}${unit[unitIndex]}`;
      },
      labelResult() {
        if (!this.DATAtotalTestResult.labels) {
          return;
        }
        let array = [];

        this.totalTestResult_Label.map((el, index) => {
          let obj = {};
          if (this.device === 'ba427plus' && index >= 3) {
            obj = {
              label: this.$t('chart.label.' + el),
              color:
                this.DATAtotalTestResult.datasets[0].backgroundColor[index + 1],
            };
          } else if (this.device === 'ba427plus' && index >= 2) {
            obj = {
              label: this.$t('chart.label.rechargeRetest'),
              color:
                this.DATAtotalTestResult.datasets[0].backgroundColor[index],
            };
          } else if (this.device.includes('bt2400-napa')) {
            obj = {
              label:
                el === 'caution'
                  ? this.$t('chart.label.marginal')
                  : this.$t('chart.label.' + el),
              color:
                this.DATAtotalTestResult.datasets[0].backgroundColor[index],
            };
          } else {
            obj = {
              label: this.$t('chart.label.' + el),
              color:
                this.DATAtotalTestResult.datasets[0].backgroundColor[index],
            };
          }
          array.push(obj);
        });
        return array;
      },
      // -------------------
      testResultByXMonth_Good() {
        let array = [];
        this.APItestResultByXMonth.forEach((el, index) => {
          if (index > 0) {
            array.push(el.good);
          }
        });
        return array;
      },
      testResultByXMonth_Bad() {
        let array = [];
        this.APItestResultByXMonth.forEach((el, index) => {
          if (index > 0) {
            array.push(el.bad);
          }
        });
        return array;
      },
      testResultByXMonth_Other() {
        let array = [];
        this.APItestResultByXMonth.forEach((el, index) => {
          if (index > 0) {
            array.push(el.other);
          }
        });
        return array;
      },
      testResultByXMonth_Total() {
        let array = [];
        this.APItestResultByXMonth.forEach((el, index) => {
          if (index > 0) {
            array.push(el.good + el.bad + el.other);
          }
        });
        return array;
      },
      toolTipsArray() {
        let array = [];
        if (this.APItestResultByXMonth.length === 0) return array;
        this.APItestResultByXMonth.map(el => {
          array.push([el.good, el.bad, el.other, el.good + el.bad + el.other]);
        });
        return array;
      },
      toolTipsArrayPercen() {
        if (this.toolTipsArray.length === 0) return [];
        let array = [];
        this.toolTipsArray.map((el, index) => {
          if (index > 0) {
            let innerArray = [];
            for (let i = 0; i < el.length; i++) {
              innerArray.push(
                this.toolTipsArray[index][i] - this.toolTipsArray[index - 1][i]
              );
            }
            array.push(innerArray);
          }
        });
        return array;
      },
      timePeriod() {
        if (this.selectTimeType === 'month') {
          let array = [];
          for (let i = 6; i <= 24; i++) {
            array.push(i);
          }
          return array;
        } else {
          let array = [];
          for (let i = 4; i <= 8; i++) {
            array.push(i);
          }
          return array;
        }
      },
      // -------------------
      versionByDay_List() {
        let array = [];
        this.APIversionByDay.list.map((el, index) => {
          array.push({
            label: el.version,
            data: [el.count],
            backgroundColor: this.versionByDay_Color[index],
            barPercentage: 0.8,
          });
        });
        return array;
      },
      firmwareLabel() {
        if (!this.DATAversionByDay.datasets) {
          return;
        }
        let array = [];
        let total = 0;
        let percen = 0;
        this.DATAversionByDay.datasets.map((el, index) => {
          let obj = {};
          // Firmware版本最多只顯示三個，數量大於三時，以Other顯示
          if (index >= 3) {
            total = total + el.data[0];
            percen = percen + this.firmwarePercen[index] * 1;
            percen = percen.toFixed(1) * 1;
            if (index === this.DATAversionByDay.datasets.length - 1) {
              obj = {
                name: this.$t('chart.label.oth'),
                num: total,
                percen,
                color: 'transparent',
              };
              array.push(obj);
            }
          }
          if (index < 3) {
            obj = {
              name: el.label,
              num: el.data[0],
              percen: this.firmwarePercen[index],
              color: el.backgroundColor,
            };
            array.push(obj);
          }
        });
        return array;
      },
      firmwarePercen() {
        if (!this.DATAversionByDay.datasets) {
          return;
        }
        let array = [];
        let total = 0;
        this.DATAversionByDay.datasets.map(el => {
          total = total + el.data[0];
        });
        this.DATAversionByDay.datasets.map(el => {
          array.push(((el.data[0] / total) * 100).toFixed(1));
        });
        return array;
      },
      // -------------------
      usageByDay_Avg() {
        if (this.APIusageByDay.usage === undefined) return 0;
        let avg = Math.ceil(
          this.APIusageByDay.usage.reduce((sum, current) => (sum += current)) /
            this.APIusageByDay.usage.length
        );
        this.APIusageByDay.usage.map((el, index) => {
          if (el > avg) {
            this.DATAusageByDay.datasets[0].backgroundColor[index] =
              'rgba(238,238,238,.6)';
          }
        });
        return avg;
      },
      // -------------------
      accountByDay_Array() {
        let array = [];
        this.APIaccountByDay.list.map(el => {
          let dataArray = [];
          let dataName = el.account;
          let dataMail = dataName.substring(dataName.indexOf('@'));
          let accountName = dataName.replace(
            dataName.substring(dataName.indexOf('@')),
            ''
          );
          dataArray.push(accountName, dataMail);
          array.push(dataArray);
        });
        return array;
      },
      accountByDay_Data() {
        let array = [];
        this.APIaccountByDay.list.map(el => {
          array.push(el.count);
        });
        return array;
      },
      // -------------------
      testResultPerQuarter_Title() {
        if (this.APItestResultPerQuarter.year === undefined) {
          return this.$t('chart.title.testType');
        }
        return (
          this.APItestResultPerQuarter.year +
          ' Q' +
          this.APItestResultPerQuarter.quarter +
          ' ' +
          this.$t('chart.title.testType')
        );
      },
      testResultPerQuarter_Data() {
        let object = this.APItestResultPerQuarter.testType;
        let array = [];
        for (const key in object) {
          array.push(object[key]);
        }
        return array;
      },
      testResultPerQuarter_Label() {
        let object = this.APItestResultPerQuarter.testType;
        let array = [];
        let index = 0;
        for (const key in object) {
          let obj = {
            label: this.$t('chart.testType.' + key),
            color: this.testResultPerQuarter_Color[index],
          };
          index++;
          array.push(obj);
        }
        return array;
      },
      testResultPerQuarter_List() {
        let object = this.APItestResultPerQuarter.testType;
        let array = [];
        for (const key in object) {
          array.push(this.$t(`chart.testType.${key}`));
        }
        return array;
      },
      // -------------------
      batteryTypeNowMonth_Data() {
        let object = this.APIbatteryTypeMonthResult[1];
        let array = [];
        for (const key in object) {
          if (key !== 'month') {
            array.push(object[key]);
          }
        }
        return array;
      },
      batteryTypeLastMonth_Data() {
        let object = this.APIbatteryTypeMonthResult[0];
        let array = [];
        for (const key in object) {
          if (key !== 'month') {
            array.push(object[key]);
          }
        }
        return array;
      },
      batteryTypeMonth_List() {
        let object = this.APIbatteryTypeMonthResult[0];
        let array = [];
        for (const key in object) {
          if (key !== 'month') {
            array.push(this.$t('chart.batteryType.' + key));
          }
        }
        return array;
      },
      batteryTypeMonth_Title() {
        let object = this.APIbatteryTypeMonthResult[0];
        let array = [];
        for (const key in object) {
          if (key === 'month') {
            array.push(object[key]);
          }
        }
        object = this.APIbatteryTypeMonthResult[1];
        for (const key in object) {
          if (key === 'month') {
            array.push(object[key]);
          }
        }
        // return array;
        let title = array.map(el => {
          return this.getFullMonth[el - 1];
        });
        return title;
      },
      // -------------------
      batteryTypeYear_List() {
        let object = this.APIbatteryTypeYearResult;
        let array = [];
        for (const key in object) {
          if (key !== 'year') {
            array.push(this.$t('chart.batteryType.' + key));
          }
        }
        return array;
      },
      batteryTypeYear_Data() {
        let object = this.APIbatteryTypeYearResult;
        let array = [];
        for (const key in object) {
          if (key !== 'year') {
            array.push(object[key]);
          }
        }
        return array;
      },
      batteryTypeYear_Popular() {
        let max = Math.max.apply(null, this.batteryTypeYear_Data);
        let index = this.batteryTypeYear_Data.indexOf(max);
        return this.batteryTypeYear_List[index];
      },
      batteryTypeYear_Title() {
        return this.APIbatteryTypeYearResult.year;
      },
      batteryTypeYear_Label() {
        let object = this.APIbatteryTypeYearResult;
        let array = [];
        let index = 0;
        for (const key in object) {
          if (key !== 'year') {
            let obj = {
              label: this.$t('chart.batteryType.' + key),
              color: this.testResultPerQuarter_Color[index],
            };
            index++;
            array.push(obj);
          }
        }
        return array;
      },
      // -------------------
      newestUpdateTime() {
        let updateTime = new Date(this.DATAnewestUpdateTime.time);
        updateTime = new Date(updateTime).getTime();

        let nowTime = Date.now();
        let timeAgo = (nowTime - updateTime) / 1000;

        let hour = Math.floor(timeAgo / 3600);
        let minute = Math.floor(timeAgo % 3600) / 60;

        if (!updateTime) {
          return;
        } else {
          return `${this.$t('home.updated')} : ${hour} ${this.$t(
            'home.hour'
          )} ${Math.floor(minute)} ${this.$t('home.minute')}`;
        }
      },
      // -------------------
    },
  };
</script>

<style lang="scss">
  #line-chart,
  #bar-chart,
  #radar-chart,
  #horizontalbar-chart,
  #horizontalbar-chart {
    height: 400px !important;
  }
  [v-cloak] {
    display: none;
  }

  .drawingLoad {
    position: absolute;
  }

  .dashboardTop {
    background: rgba(200, 200, 200, 0.3);
    font-family: 'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif;
    margin-bottom: 20px;
    border-bottom: solid 1px white;
    user-select: none;
    .ShopListForArrow {
      position: relative;
      padding: 0 60px 0 60px;
      .shopListArrow {
        padding: 0 10px;
        position: absolute;
        color: white;
        font-size: 20px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      }
      .shopListArrow.nextPage {
        right: 10px;
      }
      .shopListArrow.prevPage {
        left: 10px;
      }
    }
    .dashboardTitle {
      font-size: 32px;
      color: white;
      font-weight: 500;
      text-align: left;
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
    }
    .groupListBox {
      overflow: hidden;
      display: flex;
    }
    .groupList {
      transition: 0.3s;
      display: inline-flex;
    }
    .groupItem {
      color: white;
      padding: 8px 20px;
      border-radius: 5px 5px 0 0;
      letter-spacing: 0.2px;
      font-size: 16px;
      border-top: solid 1px #ddd;
      border-right: solid 1px #ddd;
      position: relative;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &:nth-of-type(1) {
        border-left: solid 1px #ddd;
      }
      .loading {
        position: absolute;
        z-index: -1;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
      }
      .loading.active {
        z-index: 2;
        opacity: 1;
      }
      p {
        position: relative;
        z-index: 1;
      }
      p.active {
        z-index: -1;
        opacity: 0;
      }
    }
    .groupItem.active {
      color: black;
      background: white;
      border-top: solid 1px #ddd;
      border-left: solid 1px #ddd;
      border-right: solid 1px #ddd;
    }
    .groupItem[disabled] {
      cursor: default;
      opacity: 0.7;
      &.active {
        background: white;
      }
    }
  }

  .frame {
    transition: 0.3s;
    margin-bottom: 40px;
  }

  .frameContainer {
    width: 95%;
    margin: auto;
  }

  @media screen and (max-width: 991px) {
    .frame {
      margin-bottom: 40px;
    }

    .frameContainer {
      width: 90%;
    }
  }

  .dashboardButtons {
    align-items: center;
  }
  @media screen and (max-width: 991px) {
    .dashboardButtons {
      flex-direction: column-reverse;
      margin-top: 20px;
    }
  }

  .p0 {
    padding: 0;
  }

  #horizontalbar-chart {
    position: relative;
    z-index: 1;
  }

  .chartBox {
    display: flex;
    flex-wrap: wrap;
    font-family: $font-family;
    .chartDoughnut-group {
      background: $dark_background;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      padding: 20px;
      margin: 10px;
      border-radius: 0.5rem;
      .noDataChart {
        height: 400px;
      }
    }
    .chartArea {
      box-sizing: border-box;
      // padding: 20px;
      padding: 30.5px 20px;
      margin: 10px;
      border-radius: 0.5rem;
      background-color: $dark_background;
      color: $title_color;
    }
    .chartUser {
      box-sizing: border-box;
      padding: 20px 20px 10px;
      flex-grow: 1;
    }
    .chartDoughnutMonth {
      box-sizing: border-box;
      margin-right: 0px;
      border-radius: 0.5rem 0 0 0.5rem;
    }
    .chartDoughnutTotal {
      box-sizing: border-box;
      margin-left: 0px;
      border-radius: 0 0.5rem 0.5rem 0;
    }
    .chartLineMonths {
      padding: 27.5px 20px 27.5px 20px;
    }
    .chartBarFirmware {
      position: relative;
      .labelFirmware {
        position: absolute;
        top: 40%;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        flex-direction: column;
        li {
          display: flex;
          border-bottom: dashed 1px;
          justify-content: space-between;
          padding: 5px 0;
          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
    .noDataChart {
      height: 461px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      h3 {
        flex-grow: 1;
        color: white;
        padding-top: 10px;
        font-size: 1.15rem;
        font-weight: 700;
      }
      p {
        flex-grow: 3;
        line-height: 15rem;
        font-size: 1.5rem;
        color: #8baccb;
        user-select: none;
      }
    }
  }

  .labelFirmware {
    .labelText {
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      p {
        padding: 5px 0;
        letter-spacing: 0.5px;
      }
    }
  }

  .labelsBox {
    width: 100%;
    display: flex;
    font-family: $font-family;
    justify-content: space-around;
    user-select: none;
    li {
      display: flex;
      font-size: 0.8rem;
      color: $title_color;
      margin: 0 5px;
      cursor: default;
    }
    .labelColor {
      margin: 0 5px;
      display: block;
      height: 15px;
      width: 15px;
    }
  }

  .totalChartLabel {
    min-height: 50px;
    align-items: center;
  }

  .chartPieType {
    position: relative;
  }

  .pieLabelsBox {
    position: absolute;
    bottom: 8px;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    font-family: $font-family;
    li {
      flex-grow: 1;
      display: flex;
      font-size: 0.8rem;
      color: $title_color;
      margin: 5px;
      cursor: default;
      user-select: none;
    }
    .labelColor {
      margin: 0 5px;
      display: block;
      height: 15px;
      width: 15px;
    }
  }

  .doughnutTypeLabelsBox {
    flex-wrap: wrap;
    li {
      flex-grow: 1;
      width: 30%;
      margin: 5px;
    }
  }

  .chooseMonthlyBox {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 9px;
    .timeType,
    .timePeriod {
      margin: 0 5px;
      user-select: none;
    }
  }

  .lastUpdateTime {
    color: $title_color;
    text-align: right;
    margin: 20px 20px 20px 0;
    font-family: $font-family;
  }

  .tableButton {
    transition: 0.5s;
    .mdc-button__label {
      text-align: center;
      display: flex;
      align-items: center;
    }
    p,
    .loading {
      width: 100%;
      min-width: 90px;
    }
    p {
      letter-spacing: 0.2px;
      font-family: $font-family;
    }
  }

  .tableButtonUpdate {
    position: relative;
    transition: 0.5s;
    font-family: $font-family;
    .mdc-button__label {
      text-align: center;
      display: flex;
      align-items: center;
    }
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    p {
      letter-spacing: 0.2px;
    }
  }

  @media screen and (max-width: 1200px) {
    .chartBox {
      .chartLineMonths {
        padding: 20px;
      }
    }
    .chartBarAccount {
      height: 475px;
    }
  }
  @media screen and (max-width: 991px) {
    .lastUpdateTime {
      text-align: left;
      margin: 20px 0 20px 0;
    }
    .dashboardButtons .button-group {
      justify-content: start;
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 768px) {
    .chartDoughnut-group {
      .labelsBox {
        flex-wrap: wrap;
        li {
          margin: 5px;
        }
      }
    }
    .dashboardTop {
      .ShopListForArrow {
        padding: 0 7%;
        .shopListArrow.nextPage {
          right: 5px;
        }
        .shopListArrow.prevPage {
          left: 5px;
        }
      }
    }
  }

  // for ie
  .fixFlex {
    flex: 1 1 auto;
  }
</style>
