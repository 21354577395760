<template>
  <div class="frame">
    <formFormat
      :page="page"
      :formInput="formInput"
      :loading="loading"
      :error.sync="errorMessage"
      @submit="showEmitValue"
      data-test="formFormat"
    ></formFormat>
    <!-------------- modal area --------------->
    <!-- 加值成功 -->
    <b-modal id="storedPointSuccess" centered :title="$t('modal.success')">
      <h6>{{ $t('modal.storedPoint.success') }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t('button.ok') }}
        </m-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import formFormat from '@/components/FormFormat.vue';
  import { APIdepositPoint } from '../api';

  export default {
    components: {
      formFormat,
    },
    data() {
      return {
        page: 'storedPoint',
        loading: false,
        errorMessage: {
          invalidCode: false,
          codeAlreadyUsed: false,
        },
        formInput: [
          {
            id: 'pointCode',
            title: 'storedPoint.inputText1',
            placeholder: 'storedPoint.inputText1Placeholder',
            type: 'text',
            check: 'storedPoint',
          },
        ],
      };
    },
    methods: {
      async showEmitValue(inputValue) {
        try {
          this.loading = true;
          let requestData = {};
          this.formInput.map((el, i) => {
            requestData[el.id] = inputValue.input[i];
          });
          const { data: APIdata } = await APIdepositPoint(requestData);
          if (APIdata.result === 1) {
            this.$bvModal.show('storedPointSuccess');
            inputValue[0] = '';
            this.loading = false;
          }
        } catch (error) {
          this.loading = false;
          if (error.response != undefined && error.response.status === 401) {
            this.$bvModal.show('setCheckToken');
          } else {
            switch (error.response.data.error) {
              case 'unauthorized':
                this.$bvModal.show('unauthorizedErrorToggle');
                break;
              case 'account does not exist':
                this.$bvModal.show('accountNotExistToggle');
                break;
              case 'invalid point code':
              case 'point code not exists':
                this.errorMessage.invalidCode = true;
                break;
              case 'point code already used':
                this.errorMessage.codeAlreadyUsed = true;
                break;
              default:
                this.$bvModal.show('setsomethingWrong');
                break;
            }
          }
        }
      },
    },
  };
</script>
