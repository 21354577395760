var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "frame" }, [
    _c(
      "div",
      {
        staticClass:
          "alert alert-warning d-flex align-items-center testReportBottom",
        attrs: { role: "alert" },
      },
      [
        _c("i", { staticClass: "bi bi-info-circle-fill me-2" }),
        _c("div", { staticClass: "ml-2 text-left" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("modal.deviceManagement.deviceOnly.text1")) +
              " "
          ),
          _c("br"),
          _vm._v(
            " " +
              _vm._s(_vm.$t("modal.deviceManagement.deviceOnly.text2")) +
              " "
          ),
        ]),
      ]
    ),
    _c(
      "div",
      { staticClass: "testReportBottom" },
      [
        _c("dataTable", {
          attrs: {
            columns: _vm.columns,
            device: "deviceManager",
            "data-test": "dataTable",
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "deviceErrorCode" }, [
      _c("h5", [_vm._v(_vm._s(_vm.$t("deviceManagement.errorListTitle")))]),
      _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list1")))]),
      _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list2")))]),
      _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list3")))]),
      _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list4")))]),
      _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list5")))]),
      _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list6")))]),
      _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list7")))]),
      _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list8")))]),
      _c("h6", [_vm._v(_vm._s(_vm.$t("deviceManagement.list9")))]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }