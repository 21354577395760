<script>
import { Line, mixins } from "vue-chartjs";
import datalabels from "chartjs-plugin-datalabels";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: () => {},
    },
    chartTitle: {
      type: String,
      default: "",
    },
    chartLabel: {
      type: Array,
      default: () => {
        return ["X", "Y"];
      },
    },
    chartPercen: {
      type: Array,
      default: () => {
        return [];
      },
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.addPlugin({
      id: datalabels,
    });

    this.lineOption();

    this.renderChart(this.chartData, this.option);
  },
  data() {
    return {
      option: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: "bottom",
          onHover: function () {
            this.ctx.canvas.style.cursor = "pointer";
          },
          onLeave: function () {
            this.ctx.canvas.style.cursor = "default";
          },
          labels: {
            boxWidth: 12,
            fontSize: 12,
            fontColor: "white",
            fontFamily: "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
          },
        },
        title: {
          display: true,
          text: this.chartTitle,
          fontSize: 18,
          fontColor: "white",
          fontFamily: "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: -10,
          },
        },
        elements: {
          // 曲線/折線設定
          line: {
            tension: 0.000001,
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              barPercentage: 0.5,
              scaleLabel: {
                display: true,
                labelString: this.chartLabel[0], // 多國語系修改
                fontSize: 11,
                fontFamily:
                  "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                fontColor: "white",
                padding: 0,
                lineHeight: 1,
              },
              ticks: {
                fontColor: "#ddd",
                fontFamily:
                  "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
              },
              gridLines: {
                color: "rgba(136,136,136,.5)",
                zeroLineColor: "rgba(136,136,136,.5)",
              },
            },
          ],
          yAxes: [
            {
              stacked: false,
              scaleLabel: {
                display: true,
                labelString: this.chartLabel[1], // 多國語系修改
                fontSize: 11,
                fontColor: "white",
                fontFamily:
                  "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                padding: 0,
                lineHeight: 1,
              },
              ticks: {
                precision: 0,
                fontColor: "#ddd",
                fontFamily:
                  "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
                callback: function (label) {
                  if (label >= 1000) {
                    return label / 1000 + "k";
                  } else {
                    return label;
                  }
                },
              },
              gridLines: {
                color: "rgba(136,136,136,.5)",
                zeroLineColor: "rgba(136,136,136,.5)",
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            align: "left",
            anchor: "end",
            // 偏移量
            offset: 20 - 20,
            color: function (context) {
              let label = context.dataset.label;

              switch (label) {
                case "BAD":
                  return "rgb(255,77,77)";
                case "GOOD":
                  return "rgb(0,200,27)";
                case "OTHER":
                  return "rgb(220,220,220)";
              }
            },
            font: {
              size: 13,
              weight: "bold",
            },
            formatter: function () {
              return "";
            },
          },
        },
      },
    };
  },
  methods: {
    renderLineChart() {
      this.renderChart(this.chartData, this.option);
    },
    lineOption() {
      let percenData = this.chartPercen;
      this.option = this.option = Object.assign({}, this.option, {
        tooltips: {
          mode: "x",
          intersect: false,
          callbacks: {
            label: function (tooltipItem, data) {
              let monthIndex = tooltipItem.index;
              let dataIndex = tooltipItem.datasetIndex;
              let percen = percenData[monthIndex][dataIndex];
              if (percen > 0) {
                percen = `+${percen}`;
              }
              return `${data.datasets[dataIndex].label}: ${tooltipItem.yLabel} (${percen})`;
            },
          },
          titleFontFamily:
            "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
          bodyFontFamily: "'Noto Sans', sans-serif, 'Noto Sans TC', sans-serif",
        },
      });
    },
  },
  watch: {
    chartData() {
      // 重新set option
      this.lineOption();
      // 移除原有圖表
      this.$data._chart.destroy();
      // 新圖表
      this.renderLineChart();
    },
    // "chartData.datasets": function() {
    //   this.$data._chart.destroy();
    //   this.renderLineChart();
    // }
  },
};
</script>
