export default {
  computed: {
    subdomain() {
      // Extract subdomain from the URL
      const hostname = window.location.hostname;
      const subdomain = hostname.split('.')[0];
      return subdomain.toLowerCase();
    },
  },
};
