<template>
  <div class="frame">
    <div class="container">
      <div class="formHeader">
        <h1>{{ $t("batchCreate.title") }}</h1>
        <p>{{ $t("batchCreate.subtitle") }}</p>
      </div>
      <div>
        <div class="formBody">
          <div class="progressBar">
            <b-progress
              :max="100"
              :animated="progress === 100 || upload.error ? false : true"
              :variant="
                progress === 100 ? 'success' : upload.error ? 'danger' : 'info'
              "
            >
              <b-progress-bar
                :value="progress"
                :label="`${progress}%`"
              ></b-progress-bar>
            </b-progress>
            <span v-if="doNothing">{{
              $t("batchCreate.status.doNothing")
            }}</span>
            <span v-if="upload.active">{{
              $t("batchCreate.status.active")
            }}</span>
            <span v-if="upload.success">{{
              $t("batchCreate.status.success")
            }}</span>
            <span v-if="upload.error">{{
              $t("batchCreate.status.error")
            }}</span>
          </div>

          <div class="invalidInput" v-if="upload.error">
            <p v-if="errorStatus.shopID || errorStatus.businessID">
              {{ whatIdError }}: {{ id }} {{ $t("batchCreate.error.unit") }}
            </p>
            <p v-if="errorStatus.account">
              {{ $t("batchCreate.error.account") }}: {{ account }}
              {{ $t("batchCreate.error.unit") }}
            </p>
            <p v-if="errorStatus.excel">
              {{ $t("batchCreate.error.excel") }}
            </p>
            <p v-if="errorStatus.redCell">
              {{ $t("batchCreate.error.excel") }}
            </p>
          </div>

          <hr class="formLine" />
          <label class="inputButton w100p">
            <input
              @input="uploadfile"
              :disabled="loading"
              class="disabledButton"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
            <p :class="loading ? 'disabled' : ''">
              {{ $t("button.selectFileAndUpload") }}
            </p>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APIuploadBatchExcel } from "../api.js";
export default {
  data() {
    return {
      loading: false,
      progress: 0,
      upload: {
        active: false,
        success: false,
        error: false,
      },
      errorStatus: {
        account: false,
        shopID: false,
        businessID: false,
        excel: false,
        redCell: false,
      },
      id: "",
      account: "",
    };
  },
  methods: {
    async uploadfile(e) {
      try {
        if (e.target.files.length === 1) {
          this.upload.error = false;
          this.removerAllErrorStatus();
          this.progress = 0;
          let formData = new FormData();
          formData.append("fileName", e.target.files[0]);
          this.upload.active = true;
          this.loading = true;
          const { data: APIdata } = await APIuploadBatchExcel(
            formData,
            this.onUploadProgress
          );
          if (APIdata.result === 1) {
            this.progress = 100;
            this.upload.success = true;
            this.upload.active = false;
            this.loading = false;
          }
        }
      } catch (error) {
        this.upload.active = false;
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.$bvModal.show("setCheckToken");
        } else {
          this.upload.error = true;
          switch (error.response.data.error) {
            case "account exists":
              this.errorStatus.account = true;
              this.account = error.response.data.item;
              break;
            case "companyId exists":
              this.errorStatus.businessID = true;
              this.id = error.response.data.item;
              break;
            case "shopId exists":
              this.errorStatus.shopID = true;
              this.id = error.response.data.item;
              break;
            case "invalid excel":
              this.errorStatus.excel = true;
              break;
            case "red cell":
              this.errorStatus.redCell = true;
              break;
            default:
              this.$bvModal.show("setsomethingWrong");
          }
        }
      }
    },
    onUploadProgress(progressEvent) {
      this.progress = Math.round(
        (progressEvent.loaded * 95) / progressEvent.total
      );
    },
    removerAllErrorStatus() {
      this.errorStatus = {
        account: false,
        shopID: false,
        businessID: false,
        excel: false,
        redCell: false,
      };
    },
  },
  computed: {
    doNothing() {
      let toggle = true;
      for (let el of Object.entries(this.upload)) {
        if (el[1]) {
          toggle = false;
        }
      }
      return toggle;
    },
    whatIdError() {
      let id = this.$t("batchCreate.error.shopID");
      if (this.errorStatus.businessID) {
        id = this.$t("batchCreate.error.businessID");
      }
      return id;
    },
  },
};
</script>

<style lang="scss" scoped>
.formHeader p {
  font-size: 1.15rem;
  line-height: 27px;
}
.inputButton p {
  letter-spacing: 0.2px;
  font-weight: 500;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  height: 46px;
  cursor: pointer;
  background: #024f83;
  color: #f3f4f7;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-family: Noto Sans, sans-serif, Noto Sans TC;
  border: none;
  user-select: none;
  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    background: #024979;
  }
  &.disabled {
    color: #024f83;
    background: #017ab9;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.37);
    cursor: default;
    &:hover {
      box-shadow: none;
    }
  }
}
.progressBar {
  width: 80%;
  margin: 40px auto 20px;
  display: flex;
  align-items: center;
  .progress {
    width: 80%;
    margin-right: 15px;
  }
  span {
    color: white;
    font-size: 14px;
  }
}
.w100p {
  width: 100%;
}
</style>
