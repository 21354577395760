const APP_VERSION = require("../../public/version.json");
export default {
  serverTasks: {
    // new
    action: {
      0: "Copy Data",
      1: "Other",
    },
    state: {
      0: "Started Successfully",
      1: "Completed Successfully",
      "-1": "Server Error",
    },
    time: {
      M: "months",
      D: "days",
      h: "hours",
      m: "minutes",
      unit: "ago",
    },
    info: "If you have any concerns about this Server Error, please contact us at service@dhc.com.tw.",
  },

  //#region sidebar
  sidebar: {
    Home: "Home",
    deviceManagement: "Device management",
    ListAllUsers: "Manage member list",
    blackList: "Email blacklist",
    pointsCalculation: "Billing Calculation", // new
    createPoints: "Create point code",
    batchCreate: "Batch create Excel upload",
    signinLogs: "Signin Logs",
    actionLogs: "Action Logs",
    testReport: "Test report",
    "bt2200-record-bt": "BT2200 Battery test",
    "bt2200-record-st": "BT2200 System test", // new
    "bt2300plus-record-bt": "BT2300+ Battery test",
    "bt2300plus-record-st": "BT2300+ System test",
    "btw300-record-bt": "BTW300 Battery test",
    "btw300-record-st": "BTW300 System test",
    "btw200-record-bt": "BTW200 Battery test",
    "btw200-record-st": "BTW200 Cranking/Charging test",
    "btw200-record-ivt": "BTW200 In vehicle test",
    "btw200-record-vm": "BTW200 Smart charging monitor",
    "bt2400-record-bt": "BT2400 Battery test",
    "bt2400-record-st": "BT2400 System test",
    "bt2400-record-ivt": "BT2400 In vehicle test",
    "bt2400-napa-record-bt": "ST2400PRO battery test",
    "bt2400-napa-record-st": "ST2400PRO system test",
    "bt2400-napa-record-ivt": "ST2400PRO in vehicle test",
    "bt2400-record-tg31": "BT2400 Truck/Group 31 test", // new
    "bt2400-record-pk24": "BT2400 24V Pack test", // new
    "ba427plus-record-bt": "BA427+ Battery test", // new
    "ba427plus-record-st": "BA427+ System test", // new
    "btw350-record-bt": "BTW350 Battery test",
    "btw350-record-st": "BTW350 Cranking/Charging test", // new
    "btw350-record-ivt": "BTW350 In vehicle test", // new
    "btw350-record-vm": "BTW350 Smart charging monitor", // new
    "btw350-record-cc": "BTW350 Cable diagnosis", // new
  },
  //#endregion

  //#region nav bar
  nav: {
    free: "Free trial",
    logo: "Dashboard",
    plan: "Data Plan",
    deposit: "Deposit",
    Language: "Language",
    PrivacyPolicy: "Privacy policy",
    SignOut: "Sign out",
    version:
      process.env.VUE_APP_NODE_ENV !== "PROD"
        ? APP_VERSION.DEV_VERSION
        : APP_VERSION.PROD_VERSION,
    edit: "Edit", // new
    point: "point", // new
    id: "ID", // new
    techNumber: "Basic Account number", // new
    shopNumber: "Shop Account number", // new
    unit: "members", //new
    shop: "Shop",
    business: "Business",
    changePassword: "Change password",
    timezone: "Time zone", // new
  },
  //#endregion

  //#region modal
  modal: {
    // title
    error: "Error",
    notice: "Notice",
    success: "Success",
    warning: "Warning",
    inviteUser: "Invite User", // update
    inviteShop: "Invite Shop", //  new
    addadevice: "Add a device",
    subscribeDetail: "Subscription details",
    changeHead: "Change your personal headshot", // new
    invalid: "Invalid Action",
    // content
    signIn: {
      forgotPassword: {
        error: {
          text1: "Invalid email address.",
          text2: "Please enter a valid address.",
        },
        success: {
          text1: "We have sent you an email to reset your password.",
          text2: "Please check inbox.",
        },
      },
      resetPassword: {
        text1: "The first time you sign in, you need to change your password.",
        text2: "Go to Change Password.",
      },
      verified: {
        text1: "This account has not been verified.",
        text2: "Please check inbox to verify your account.",
        text3: "If you don't receive email, please check your spam folder.",
      },
      sendEmail: {
        text1: "The verification email is sent to your email:",
        text2: "Please check inbox to verify your account.",
        text3: "If you don't receive email, please check your spam folder.",
      },
      pwExpired: "Your password has expired and must be changed.",
    },
    changePassword: {
      success: {
        text1: "Password changed successfully",
        text2: "Please use this password to sign in.", // update
      },
      error: {
        text1: "Same as your old password, please re-enter",
      },
    },
    createShop: {
      success: {
        text1: "Successfully created, please wait...",
      },
      error: {
        text1: "You already have a Shop.",
      },
    },
    createCompany: {
      // new
      success: {
        text1: "Successfully created, please wait...",
      },
      error: {
        text1: "You already have a Business.",
      },
    },
    registerUser: {
      success: {
        text1: "A verification link has been sent to your email.",
        text2: "Please check inbox to verify your email.",
        text3: "If you don't receive email, please check your spam folder.",
      },
      error: {
        text1: "Sorry.",
        text2: "The email address is already registered.",
        text3: "Please use a different email address.",
      },
    },
    deviceManagement: {
      deviceOnly: {
        text1:
          "This page is only for BT2200、BT2300 Plus、BT2400 and BA427 Plus.",
        text2: "never show again",
      },
      noSelect: "Please select a device first.",
      onlySelect: "Only one device can be removed at a time.",
      remove: {
        success: {
          text1: "will be removed.",
          text2: "Would you like to continue?",
        },
        error: "not exist.",
      },
      addDevice: {
        text1: "Please select a method to complete the device registration.",
        text2: "Scan 2D barcode (QR code)",
        text3: "Scan 1D barcode (code 39)",
        text4: "Manually enter the registration code",
        text5: "Please scan the barcode to complete the device registration.", // new
        text6: "This code will expire after 20 minutes.",
        text7:
          "Please manually enter the registration code to complete the device registration.",
      },
    },
    shopMemberList: {
      inviteLab: {
        id: "Invite with Shop ID",
        account: "Invite with Shop Account",
      },
      inviteLable: {
        // new
        shop: "Email",
        company: "Shop ID",
        companyAccount: "Shop Account",
        shopPlaceholder: "Account",
        companyPlaceholder: "Shop ID",
        companyAccountPlaceholder: "Shop Account's account",
      },
      invite: "Please enter the account email to invite.",
      inviteShopID: "Please enter the Shop ID to invite.", // new
      inviteShopAccount: "Please enter the Shop Account to invite.", // new
      success: "Invitation email has been sent.",
      error: {
        text1: "Mail format error.",
        text2:
          "Please check the spelling of your request. It has to be 100% identical to a registered and valid name in the system. ",
        text3:
          "Your request is invalid. The request of adding a new account is denied due to the limitation of the system.",
        text4:
          "The request has been denied because of a conflict. The user has joined another Shop/Business. The user may not join 2 Shop/Business at the same time. Please contact the user before inviting the user.",
        text5:
          "Each account is defaulted to support 100 Shop Manager Accounts for a Business Manager Account or 20 Basic Users for a Shop Manager.",
        text6:
          "If you like to manage a program exceeding the numbers, please contact your agent for a customeized program.",
        text7: "You can only invite the Shop Account.",
      },
      noSelect: "Please select an account first.",
      onlySelect: "Only one account can be selected at a time.",
      delete: {
        error: "not exist.",
      },
      detach: {
        success: {
          text1: "will be removed from the Shop.",
          text2: "Would you like to continue?",
          text3: "Test data will remain in your group's database.",
        },
        companySuccess: {
          text1: "Shop ID",
          text2: "will be removed from the Business.",
        },
        createTask: "The server is creating a Copy Data task",
        error: "You can't remove yourself from the shop.",
        errorOnlyKickShop: "You can only remove the Shop Account.", // new
      },
      disband: {
        text1: "Are you sure you want to dismantle the Shop?",
        text2: "The group and its data will no longer exist,",
        text3:
          "You will not be able to review any of the test reports uploaded by you or your members.",
        text4:
          "If you want to continue, please enter “confirm” in the box below and press “DISMANTLE”.",
        text5: "Are you sure?",
        text6: "Are you sure you want to dismantle the Business group?",
        text7: "The group and its data will no longer exist,",
        text8:
          "You will not be able to review any of the test reports uploaded by you or your members.",
        text9:
          "If you want to continue, please enter “confirm” in the box below and press “DISMANTLE”.",
        success: "Successfully dismantled, please wait...",
      },
      // edit頁面暫時移除
      editAccount: {
        text1: "Will jump to edit page.",
        text2: "Would you like to continue?",
      },
      haveDevice: {
        text1: "There are still devices registered under this account.",
        text2: "Please deregister devices before you delete the account.",
      },
      handover: {
        text1: "The role of group administrator will be handed over to",
        text2:
          "Remaining deposit (points) will also be transferred. Continue ?",
        success1: "The request will be valid only for 24 hours.",
        success2: " Please notify User",
        success3:
          "to complete the process by clicking the hyper link in his/her notification email.",
      },
    },
    unlockUser: {
      text1: "Are you sure you want to unlock: ",
      text2: " ?",
      comment: "(Optional) Please enter the reason for unlocking the account.",
      isAlreadyLocked: "The account is already unlocked.",
    },
    subscribe: {
      planTitle: "Data Plan",
      basic: "Basic",
      shop: "Shop",
      business: "Business",
      planSubTitle: "Account",
      memberTitle: "Default to Manage Basic Account",
      shopManagerTitle: "Default to Manage Shop Account",
      extraMemberTitle: "Additional Account (Paid)", // new
      memberSubTitle: "members",
      priceTitle: "Monthly Fee",
      priceSubTitle: "point / per month",
      unit: "points",
      free: "Free / per month",
      info: {
        basic: {
          text1: "Downgrade to a Basic Account plan.",
          text2: "Your Shop will be dismissed after the downgrade",
          text3: "The stored test data will no longer exist.",
        },
        pro: {
          text1:
            "Subscribe to a Shop Account plan and you will enjoy your free trial until end of the month.",
          text2:
            "On the first calendar day of next month, your account will be charged for your data plan.",
        },
        enterprise: {
          text1:
            "Subscribe to a Business Account plan and you will enjoy your free trial until end of the month.",
          text2:
            "On the first calendar day of next month, your account will be charged for your Business group’s data plan and your members’ account fees",
          shopInComp: "* Business Account will pay the fee.",
        },
        important: {
          text1:
            "* The system will send a notice when the points in your deposit is lower than the requirement for your monthly data plan.",
          shop: "Remind!!! Your Shop will be dismissed after the downgrade to a Basic plan. The stored test data will no longer exist. You will not be able to review the data.",
          business:
            "Remind!!! Your Business will be dismissed after the downgrade to a Basic plan. The stored test data will no longer exist. You will not be able to review the data.",
          text3:
            "Please contact your agent to purchase points, recharging your account with the points.",
        },
        restore: {
          // new
          text1: "You can check the details via ",
          text2: "billing calculation.",
        },
        startNewGroup: {
          // new
          warning: {
            text1:
              "The group and its data will NO longer exist once the group has been dismantled. You will NOT be able to review the test data.",
            text2:
              "If you like to dismantle the group, please click “OK” in the below box.",
          },
          disbanding: "Dismantling",
          disbanded: "Dismantle Successfully",
          resubscribe: "Subscribing a New Data Plan",
          subscribed: "New Data Plan in Place",
        },
      },
      success: {
        text1: "You have successfully subscribed to",
        text2: "Account Plan.",
        pro: "Default to Manage Basic Account :",
        proInfo1: "2 Point / per additional Basic Account mamber",
        proInfo2: "can managing up to 20 Basic Account members",
        company: "Default to Manage Shop Account :",
        companyInfo1: "5 Point / per additional Shop Account mamber",
        companyInfo2: "can managing up to 100 Shop Account members",
        unit: "members.",
        text5: "On the first calendar day of next month",
        text6: "your account will be charged for your data plan.",
        shopInComp:
          "Please be notified you will have to pay for the subscription fees of your Business group’s members. Please refer to the member list and their subscription plans and their fees. The system will also send a billing notice in the beginning of each month.",
      },
    },
    createPoint: {
      success: {
        text1: "Export your point code",
        text2: "Point:",
        text3: "Set Number:",
        text4: "please wait...",
      },
    },
    storedPoint: {
      success: "Deposit Point Success.",
    },
    headshot: {
      //new
      upload: "Upload",
      text: "Please use JPG or JPEG photo type",
      success: "Upload Success",
    },
    setTimezone: {
      success: {
        text1: "Your request has been accepted.",
        text2: "Changes will take effect in the coming 24 hours.",
      },
    },
    blacklist: {
      text1: "This email address will be removed from blacklist.",
      text2: "Would you like to continue?",
    },
    tokenError:
      "Sorry. The token is unauthorized or has expired. Please sign in again.",
    somethingWrong: "Sorry. Something went wrong. Please try again.",
    unauthorized: "Invalid action. account is unauthorized.", // update
    accountNotExist:
      "The specified account does not exist, please check again.",
  },
  //#endregion

  //#region button
  button: {
    ok: "OK",
    close: "Close",
    yes: "YES",
    no: "NO",
    resentMail: "Resend verification email",
    submit: "Submit",
    create: "Create",
    addDevice: "Add a Device",
    removeDevice: "Remove a Device",
    columns: "Columns",
    export: "Export",
    handOver: "Hand over",
    inviteUser: "Invite user", // update
    inviteShop: "Invite Shop", //new
    sendInvitation: "send invitation",
    disband: "Dismantle",
    detach: "Remove User",
    remove: "Remove Shop", //new
    batteryTest: "Battery test",
    systemTest: "System test",
    back: "back",
    subscribe: "Subscribe",
    stored: "CONFIRM",
    save: "Save", //new
    download: "Download", //new
    buildShop: "Build Your Shop", //new
    buildComp: "Build Your Business", //new
    exportCSV: "CSV",
    exportExcel: "Excel",
    restore: "RESUME", // new
    createNew: "CREATE NEW", // new
    goDeposit: "DEPOSIT POINTS", // new
    selectFileAndUpload: "Click Button To Choose File And Upload", //new
    update: "Update", //new
    tasklist: "Check server tasks", //new
    delete: "Delete",
    unlock: "Unlock",
  },
  //#endregion

  //#region Table - filter
  filter: {
    addFilter: "Add Filter",
    choose: "Choose",
    search: "Search",
    cont: "Contains", // new
    ncont: "Not Contains", // new
    eql: "=", // new
    lte: "<=", // new
    gte: ">=", // new
    between: "Between", // new
    value: "Value", // new
    min: "Min", // new
    max: "Max", // new
    to: "to", // new
    timeTypeLocal: "Local Time",
    timeTypeUTC: "UTC",
    optionSelect: "Select",
    filterTypeString: "String",
    filterTypeNumber: "Number",
  },
  //#endregion

  //#region Page - signIn
  signIn: {
    signIn: "Sign in",
    forgotPassword: {
      title: "Forgot password",
      text1: "Please enter your email address.",
      text2: "We will send you an email to reset your password.",
    },
    register: "Register",
    text1Placeholder: "Email address",
    text2Placeholder: "Password",
  },
  //#endregion

  //#region Page - change password
  changePassword: {
    title: "Change Password",
    subtitle: "Please complete the form below to change your password.",
    currentPasswordText: "Current Password",
    newPasswordText: "New Password",
    confirmNewPasswordText: "Confirm New Password",
    password: "********",
  },
  //#endregion

  //#region Page - create shop
  createShop: {
    title: "Build a new Shop",
    subtitle: "Please complete the form below to build a new Shop.",
    inputText1: "Shop ID",
    inputText1Placeholder: "please enter your Shop ID",
  },
  //#endregion

  //#region Page - create conp    // new
  createCompany: {
    title: "Build a new Business",
    subtitle: "Please complete the form below to build a new Business.",
    inputText1: "Business ID",
    inputText1Placeholder: "please enter your Business ID",
  },
  //#endregion

  //#region Page - formFormat
  formFormat: {
    country: {
      AD: "Andorra",
      AE: "United Arab Emirates",
      AT: "Austria",
      AU: "Australia",
      BA: "Bosnia and Herzegovina",
      BE: "Belgium",
      BG: "Bulgaria",
      BH: "Bahrain",
      BW: "Botswana",
      CH: "Switzerland",
      CY: "Cyprus",
      CZ: "Czech Republic",
      DE: "Germany",
      DK: "Denmark",
      EE: "Estonia",
      EG: "Egypt",
      ES: "Spain",
      FI: "Finland",
      FR: "France",
      GB: "United Kingdom",
      GR: "Greece",
      HU: "Hungary",
      IE: "Ireland",
      IQ: "Iraq",
      IT: "Italy",
      JO: "Jordan",
      KE: "Kenya",
      KW: "Kuwait",
      LB: "Lebanon",
      LT: "Lithuania",
      LU: "Luxembourg",
      MA: "Morocco",
      MC: "Monaco",
      ME: "Montenegro",
      MU: "Mauritius",
      NA: "Namibia",
      NL: "Netherlands",
      NO: "Norway",
      NZ: "New Zealand",
      OM: "Oman",
      PL: "Poland",
      PS: "Palestinian Territory",
      PT: "Portugal",
      QA: "Qatar",
      RS: "Serbia",
      RU: "Russia",
      SA: "Saudi Arabia",
      SE: "Sweden",
      SK: "Slovakia",
      SM: "San Marino",
      TR: "Turkey",
      TW: "Taiwan",
      TZ: "Tanzania",
      UG: "Uganda",
      US: "United States",
      YE: "Yemen",
      ZA: "South Africa",
      ZM: "Zambia",
      ZW: "Zimbabwe",
    },
    selectTimezone: "Time zone",
    selectText1: "Choose your country or region",
    selectText2: "Choose your city",
    willUpdate: "will be updated",
  },
  //#endregion

  //#region Page - register
  register: {
    title: "Register a new user",
    subtitle: "Please complete the form below to register a new user.",
    inputText1: "Email address",
    placeholderInputText1: "you@example.com", // new
    inputText2: "Password", // new
    placeholderInputText2: "********", // new
    inputText3: "Confirm Password", // new
    placeholderInputText3: "********", // new
    check1: "I have read and agreed to the",
    notice:
      "If you are using BTW200, please make sure that the account you fill in is consistent with the account you created on the APP.",
    napa: {
      inputTextStoreNumber: "Store number",
      placeholderInputTextStoreNumber: "",
    },
  },
  //#endregion

  //#region Page - device management
  deviceManagement: {
    errorListTitle: "Device registration error code list:",
    list1: "01: The server is busy reading data. Please try again later.",
    list2: "03: The server is busy writing data. Please try again later.",
    list3: "06: The account does not exist. Please check your account first.",
    list4: "07: The device has been registered successfully before.",
    list5:
      "08: The device has been registered by someone else. Please deregister first.",
    list6:
      "09: The registration code does not exist or already expires. Please try again.",
    list7:
      "10: Network error. Please make sure the network is available and stable. If the issue persists, please contact the dealer for tech support.",
    list8: "11:  Invalid shop ID. Please contact dealer for tech support.",
    list9: "12: Unknown error. Please contact dealer for tech support.",
  },
  //#endregion

  //#region Page - subscribe
  subscribePlan: {
    title: "Subscribe your Data Plan",
    subtitle: "Choose the Data Plan you want and subscribe.",
    subscribed: "Subscribed",
    basic: {
      title: "Basic Account",
      price: "Free",
      text1: "Store the uploaded test data for 6 months.",
      text2: "Review the uploaded data.",
      text3: "Manage up to 3 devices.",
    },
    pro: {
      title: "Shop Account",
      priceSubTitle: "point / per month",
      defaultManage: "default to manage : ",
      memberUnit: "Basic Account members",
      extra: "+2 point / additional Basic Account",
      limit: "can managing up to 20 Basic Account members",
      priceRule: {
        text1: "Store the uploaded test data for 12 months.",
        text2: "Review members’ uploaded test data.",
        text3: "Manage up to 3 devices",
        text4: "Export the stored test data.",
        text5:
          "Build up a Shop, managing up to 20 Basic Accounts’ uploaded test data.",
        // new
        text6:
          "Enable a supportive Dashboard with statistical information,assisting a Shop/fleet management.You have to build up your Shop and invite users to join your Shop.",
      },
    },
    enterprise: {
      title: "Business Account",
      priceSubTitle: "point / per month",
      defaultManage: "default to manage : ",
      memberUnit: "Shop Account members",
      extra: "+5 point / additional Shop Account",
      limit: "can managing up to 100 Shop Account members",
      priceRule: {
        text1: "Review members’ uploaded test data",
        text2: "Export the stored test data.",
        text3:
          "Enable a supportive Dashboard with statistical information, assisting a Shop/fleet management. You have to build up your Business group by inviting Shop Accounts and receiving their confirmation.",
        text4: "Manage Shops status by Manage Member List.",
        text5: "A Business could manage up to 100 Shop’s test data.",
      },
    },
    icon: {
      text1: "Export",
      text2: "Data Table",
      text3: "Data Chart",
      text4: "Dashboard",
      text5: "Group",
      text6: "Management",
      text7: "Bigdata",
    },
  },
  //#endregion

  //#region Page - create point
  createPoint: {
    title: "Create Point Code",
    subtitle: "Please complete the form below to create point code.",
    inputText1: "Point",
    inputText1Placeholder: "What is the value of a point code",
    inputText2: "Set number",
    inputText2Placeholder: "How many sets of point code",
  },
  //#endregion

  //#region Page - batch create
  batchCreate: {
    title: "Batch Create! Please upload the Excel of account list",
    subtitle:
      "Remind! Once the list has been successfully uploaded and processed, the account information will NOT be altered or changed. Please review the information, including the email, spelling, the ID before make the upload.",
    status: {
      doNothing: "No file uploaded yet",
      active: "Analyzing the Excel and creating the Group",
      success: "Successed!",
      error: "Failed",
    },
    error: {
      shopID: "Shop ID",
      businessID: "Business ID",
      account: "Account",
      unit: "already exists.",
      excel: "The uploaded Excel sheet has an empty column.",
      redCell: "The uploaded Excel sheet has an issue highlighted in RED.",
    },
  },
  //#endregion

  //#region Page - download excel     // new
  download: {
    title: "Your monthly report is downloading",
    subtitle:
      "If download is not processing. Please click DOWNLOAD button below.",
  },
  //#endregion

  //#region Page - stored point
  storedPoint: {
    title: "Redeem points",
    subtitle: "Please enter point code below to redeem points.",
    inputText1: "Point code",
    inputText1Placeholder: "please enter your point code",
  },
  //#endregion

  //#region Page - point calculation // new
  bill: {
    title: "Billing Calculation",
    data: "Data Plan",
    charged: "Monthly Fee",
    shopDescription:
      "Each Shop Account is default to manage 3 Basic Accounts. It will incur extra monthly fee adding additional Basic Account(s) under its management.",
    businessDescription:
      "Each Business Account is default to manage 5 Shop Accounts. It will incur extra monthly fee to additional Shop Account(s) under its management.",
    plan: {
      basic: "Basic Account Plan",
      shop: "Shop Account Plan",
      business: "Business Account Plan",
    },
    table: {
      shopID: "shop ID",
      desc: "Decription",
      fee: "Monthly Fee",
      businessFee: "Monthly Fee (A+B)",
      unit: "Points",
      default: "Default",
      extra: "Additional Accounts",
      extraShop: "Additional Shop Accounts",
      shopSubTotal: "Shop Sub Total (A)",
      busSubTotal: "Business sub total (B)",
    },
    note: {
      title: "Note",
      shopFee:
        "2 points per Basic Accounts additional to default Basic account number.",
      busFee:
        "5 points per Shop Accounts additional to default Shop account number.",
      business:
        "Business Account's data plan (fee) includes all accounts in its hierarchy.It will be a combination of Shop Sub Total and Business Sub Total.",
      shopCom: "Business Account will pay the fee.",
    },
  },
  //#endregion

  //#region invalid
  invalidError: {
    changePassword: {
      title: "The password must satisfy the following rules:",
      list1: "* At least 8 characters in length.",
      list2: "* At least 1 numeric character.",
      list3: "* At least 1 lowercase character.",
      list4: "* At least 1 uppercase character.",
      list5: "* At least 1 special character.",
      list6: "* Not contain Email.",
      list7: "* Different from your previous password.",
      confirmPasswordError: "The password confirmation does not match.",
    },
    createGruop: {
      // new
      // title: "The ID must satisfy the following rules:",
      title1: "A Business/Shop ID is an unique ID for your Business/Shop.",
      title2:
        "It will help the system to consolidate account list and accounts’ uploaded information and data.",
      title3: "Please be advised that the ID is composed of",
      list1: "* English alphabet.",
      list2: "* Number.",
      list3: "* At least 3 characters in length.",
      list4: "* Up to 20 characters in length.",
      list5: "* Not contain special character except - or _",
      existID: "This ID has existed, please re-enter.",
    },
    registerUser: {
      mailError:
        "Please type your email address in the format you@example.com.",
    },
    signIn: {
      mailPwError: "The email or password is incorrect.",
      lockMessage1: "Attention: You have",
      lockMessage2:
        "remaining sign-in attempts before your account is locked for",
      lockMessage3: "minutes.",
      lockedMessage1: "Your account is locked.",
      lockedMessage2: "Please try signing in again after",
    },
    subscribe: {
      pointNotEnough: {
        text1:
          "Your upgrade request has been denied due to insufficient deposit.",
        text2: "Current deposit:", // update
        text3: "point(s)", // update
        text4:
          "You may contact your agent for a consultancy of your data plan and its cost.", // update
      },
      haveGroup:
        "Remind! Please dismantle your Shop/Business before changing your data plan.",
      inAnyGruop:
        "Remind! Before creating your new Shop/Business, please contact your current Shop/Business manager to release you from the group first.",
      // new
      shopManagerInCompBefore:
        "Your Business group has been suspended for unpaid monthly fees. Please contact your Business' Business Account holder to proceed the payment.", // new
      // new
      needToRecover: {
        text1:
          "We noticed that you were a manager of a suspended Shop/Business. If you like to retrieve the stored data and resume collecting test data, please go to the subscription page and recharge it with points.",
        text2:
          "If you do NOT know where to purchase the points, please contact your agent where you buy the product.",
      },
    },
    createPoint: {
      onlyNumber: "Only fill in numbers",
      lessThan: "Please enter less than 10000",
    },
    stroedPoint: {
      invalid: "invalid point code.",
      used: "This point code already used.",
    },
  },
  //#endregion

  //#region table  //new
  tableColumns: {
    deviceManagement: {
      psn: "Device S/N",
      model: "Model",
      account: "Account",
      shopId: "Shop ID", // new
      companyId: "Business ID", // new
    },
    accountList: {
      account: "Account",
      verification: "Verification status",
      accountLevel: "Account level",
      pricingPlan: "Data Plan",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      storeNumber: "Store Number", // new for napa
      status: "Status",
      isLocked: "Locked",
      lockedAt: "Locked Time",
    },
    blacklist: {
      address: "Email Address",
      numberOfEmail: "Number of email bounces",
    },
    signinLogs: {
      account: "Account",
      successful: "Result",
      signinTime: "Sign in Time",
    },
    actionLogs: {
      target: "Target Account",
      action: "Action",
      triggeredBy: "Triggered By",
      time: "Time",
      comment: "Comment",
    },
    bt2200BT: {
      psn: "S/N",
      testType: "Test Type",
      judgement: "Judgement",
      batteryType: "Battery Type",
      voltage: "Voltage(V)",
      setCapacity: "Set Capacity", //new
      rating: "Rating", //new
      measuredCca: "Measured",
      temperature: "Temperature(°C)",
      soc: "State of Charge(%)",
      soh: "State of Health(%)",
      testCode: "Code",
      vin: "VIN Number",
      testTime: "Test Date",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
      batteryCharged: "Battery Charged",
    },
    bt2200ST: {
      psn: "S/N",
      testType: "Test Type",
      vin: "VIN",
      crankingResult: "Cranking Result",
      crankingVoltage: "Cranking Voltage(V)",
      altIdleResult: "Alt Idle Result",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleResult: "Ripple Result",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadResult: "Alt Load Result",
      altLoadVoltage: "Alt Load Voltage(V)",
      testTime: "Test Time",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
    },
    bt2300plusBT: {
      psn: "S/N",
      testType: "Test Type",
      roNum: "RO Number",
      vin: "VIN",
      batteryType: "Battery Type",
      testTime: "Date/Time",
      nextChargeDate: "Next Maintenance Charge Date",
      judgement: "Battery Condition",
      setCapacity: "Set Capacity", //new
      rating: "Rating", //new
      measuredCca: "Tested CCA",
      startVol: "Starting Volts(V)",
      ocvAfterLoad: "OCV After Initial Load(V)",
      endVol: "Ending Volts(V)",
      chargeTime: "Charge Time(s)",
      capacityAh: "Capacity (AH)",
      temperature: "Temperature(°C)",
      soh: "SOH(%)",
      ir: "IR(mΩ)",
      cellShort: "Cell Short",
      surfaceCharge: "Surface Charge",
      testCode: "Warranty Code",
      fwVersion: "Software Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
      batCharged: "Battery Charged",
    },
    bt2300plusST: {
      psn: "S/N",
      testType: "Test Type",
      roNum: "RO Number",
      vin: "VIN",
      testTime: "Date/Time",
      crankingResult: "Cranking Result",
      crankingVoltage: "Cranking Voltage(V)",
      altIdleResult: "Alt Idle Result",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleResult: "Ripple Result",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadResult: "Alt Load Result",
      altLoadVoltage: "Alt Load Voltage(V)",
      temperature: "Temperature(°C)",
      fwVersion: "Software Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
    },
    btw200BT: {
      psn: "S/N",
      deviceName: "Device Name",
      batterySn: "Battery SN",
      systemVoltage: "System Voltage",
      testTime: "Test Time",
      testType: "Test Type",
      vin: "VIN",
      judgement: "Judgement",
      batteryType: "Battery Type",
      setCapacity: "Set Capacity",
      measuredCca: "Measured CCA",
      rating: "Rating",
      soh: "SOH(%)",
      soc: "SOC(%)",
      voltage: "Voltage(V)",
      isBatteryCharged: "Is Battery Charged",
      batInVehicle: "Battery In Vehicle",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    btw200ST: {
      psn: "S/N",
      deviceName: "Device Name",
      testTime: "Test Time",
      testType: "Test Type",
      crankingJudgement: "Cranking Judgement",
      crankingVoltage: "Cranking Voltage(V)",
      crankingMinVoltage: "Cranking Min Voltage(V)",
      crankingTime: "Cranking Time(s)",
      altIdleJudgement: "Alt Idle Judgement",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleJudgement: "Ripple Judgement",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadJudgement: "Alt Load Judgement",
      altLoadVoltage: "Alt Load Voltage(V)",
      smartChargingControl: "Smart Charging Control",
      voltageMonitorLowest: "Voltage Monitor Lowest(V)",
      voltageMonitorHighest: "Voltage Monitor Highest(V)",
      rippleLowestJudgement: "Ripple Lowest Judgement",
      rippleLowest: "Ripple Lowest(V)",
      rippleHighestJudgement: "Ripple Highest Judgement",
      rippleHighest: "Ripple Highest(V)",
      monitorTime: "Monitor Time(s)",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    btw200IVT: {
      psn: "S/N",
      deviceName: "Device Name",
      batterySn: "Battery SN",
      systemVoltage: "System Voltage",
      testTime: "Test Time",
      testType: "Test Type",
      vin: "VIN",
      judgement: "Judgement",
      batteryType: "Battery Type",
      setCapacity: "Set Capacity",
      measuredCca: "Measured CCA",
      rating: "Rating",
      soh: "SOH(%)",
      soc: "SOC(%)",
      voltage: "Voltage(V)",
      isBatteryCharged: "Is Battery Charged",
      crankingJudgement: "Cranking Judgement",
      crankingVoltage: "Cranking Voltage(V)",
      crankingMinVoltage: "Cranking Min Voltage(V)",
      crankingTime: "Cranking Time(s)",
      altIdleJudgement: "Alt Idle Judgement",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleJudgement: "Ripple Judgement",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadJudgement: "Alt Load Judgement",
      altLoadVoltage: "Alt Load Voltage(V)",
      systemTestTime: "System Test Time",
      smartChargingControl: "Smart Charging Control",
      voltageMonitorLowest: "Voltage Monitor Lowest(V)",
      voltageMonitorHighest: "Voltage Monitor Highest(V)",
      rippleLowestJudgement: "Ripple Lowest Judgement",
      rippleLowest: "Ripple Lowest(V)",
      rippleHighestJudgement: "Ripple Highest Judgement",
      rippleHighest: "Ripple Highest(V)",
      monitorTime: "Monitor Time(s)",
      monitorTestTime: "Monitor Test Time",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    btw200VM: {
      psn: "S/N",
      deviceName: "Device Name",
      testTime: "Test Time",
      voltageMonitorLowest: "Voltage Monitor Lowest(V)",
      voltageMonitorHighest: "Voltage Monitor Highest(V)",
      monitorTime: "Monitor Time(s)",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    btw300BT: {
      psn: "S/N",
      testTime: "Test Time",
      testType: "Test Type",
      vin: "VIN",
      hybrid: "Hybird",
      judgement: "Judgement",
      batteryType: "Battery Type",
      setCapacity: "Set Capacity",
      measure: "Measured CCA",
      rating: "Rating",
      soh: "SOH(%)",
      soc: "SOC(%)",
      voltage: "Voltage(V)",
      isBatteryCharged: "Is Battery Charged",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    btw300ST: {
      psn: "S/N",
      testTime: "Test Time",
      testType: "Test Type",
      vin: "VIN",
      crankingResult: "Cranking Judgement",
      crankingVoltage: "Cranking Voltage(V)",
      altIdleResult: "Alt Idle Judgement",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleResult: "Ripple Judgement",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadResult: "Alt Load Judagement",
      altLoadVoltage: "Alt Load Voltage(V)",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    bt2400BT: {
      psn: "S/N",
      testType: "Test Type",
      vin: "VIN",
      systemVolCh: "System Voltage Channel",
      batteryType: "Battery Type",
      rating: "Rating",
      capacity: "Capacity",
      batInVehicle: "Battery In Vehicle",
      batCharged: "Battery Charged",
      voltage: "Voltage(V)",
      soh: "State of Health(%)",
      soc: "State of Charge(%)",
      ir: "IR(mΩ)",
      measuredCca: "Measured CCA",
      temperature: "Temperature(°C)",
      judgement: "Judgement",
      testTime: "Test Time",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
    },
    bt2400ST: {
      psn: "S/N",
      testType: "Test Type",
      vin: "VIN",
      systemVolCh: "System Voltage Channel",
      crankingResult: "Cranking Result",
      crankingVoltage: "Cranking Voltage(V)",
      crankingTime: "Cranking Time(s)",
      altIdleResult: "Alt Idle Result",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleResult: "Ripple Result",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadResult: "Alt Load Result",
      altLoadVoltage: "Alt Load Voltage(V)",
      dieselCheck: "Diesel Check",
      testTime: "Test Time",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
    },
    bt2400IVT: {
      psn: "S/N",
      testType: "Test Type",
      vin: "VIN",
      systemVolCh: "System Voltage Channel",
      batteryType: "Battery Type",
      rating: "Rating",
      capacity: "Capacity",
      batInVehicle: "Battery In Vehicle",
      batCharged: "Battery Charged",
      voltage: "Voltage(V)",
      soh: "State of Health(%)",
      soc: "State of Charge(%)",
      ir: "IR(mΩ)",
      measuredCca: "Measured CCA",
      judgement: "Judgement",
      testTime: "Battery Test Time",
      crankingResult: "Cranking Result",
      crankingVoltage: "Cranking Voltage(V)",
      crankingTime: "Cranking Time(s)",
      altIdleResult: "Alt Idle Result",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleResult: "Ripple Result",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadResult: "Alt Load Result",
      altLoadVoltage: "Alt Load Voltage(V)",
      temperature: "Temperature(°C)",
      dieselCheck: "Diesel Check",
      sysTestTime: "System Test Time",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
    },
    bt2400TG31: {
      // new
      psn: "S/N",
      testType: "Test Type",
      vin: "VIN",
      systemVolCh: "System Voltage Channel",
      packInfo: "Pack Information",
      batInVehicle: "Battery In Vehicle",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
      noOfBatParallel: "# of Batteries In Parallel",
      batteryType: "Battery Type",
      batCharged: "Battery Charged",
      rating: "Rating",
      capacity: "Capacity",
      voltage: "Voltage(V)",
      soh: "State of Health(%)",
      soc: "State of Charge(%)",
      ir: "IR(mΩ)",
      measuredCca: "Measured CCA",
      judgement: "Judgement",
      testTime: "Test Time",
      temperature: "Temperature(°C)",
    },
    bt2400PK24: {
      // new
      psn: "S/N",
      testType: "Test Type",
      vin: "VIN",
      systemVolCh: "System Voltage Channel",
      packInfo: "Pack Information",
      batInVehicle: "Battery In Vehicle",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
      noOfBatSerial: "# of Batteries In Serial",
      batteryType: "Battery Type",
      batCharged: "Battery Charged",
      rating: "Rating",
      capacity: "Capacity",
      voltage: "Voltage(V)",
      soh: "State of Health(%)",
      soc: "State of Charge(%)",
      ir: "IR(mΩ)",
      measuredCca: "Measured CCA",
      judgement: "Judgement",
      testTime: "Test Time",
      temperature: "Temperature(°C)",
    },
    bt2400NapaBT: {
      psn: "S/N",
      testType: "Test Type",
      vin: "VIN",
      systemVolCh: "System Voltage Channel",
      batteryType: "Battery Type",
      rating: "Rating",
      capacity: "Capacity",
      batInVehicle: "Battery In Vehicle",
      batCharged: "Battery Charged",
      voltage: "Voltage(V)",
      soh: "State of Health(%)",
      soc: "State of Charge(%)",
      ir: "IR(mΩ)",
      measuredCca: "Measured CCA",
      temperature: "Temperature(°C)",
      judgement: "Judgement",
      testTime: "Test Time",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
      upcStatus: "UPC Status",
      upcCode: "UPC Code",
      storeNumber: "Store Number",
      batterySerialNumber: "Battery Serial Number",
      batteryPartNumber: "Battery Part Number",
      batteryDateCode: "Battery Date Code",
    },
    bt2400NapaST: {
      psn: "S/N",
      testType: "Test Type",
      vin: "VIN",
      systemVolCh: "System Voltage Channel",
      crankingResult: "Cranking Result",
      crankingVoltage: "Cranking Voltage(V)",
      crankingTime: "Cranking Time(s)",
      altIdleResult: "Alt Idle Result",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleResult: "Ripple Result",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadResult: "Alt Load Result",
      altLoadVoltage: "Alt Load Voltage(V)",
      dieselCheck: "Diesel Check",
      testTime: "Test Time",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
      upcStatus: "UPC Status",
      upcCode: "UPC Code",
      storeNumber: "Store Number",
      batterySerialNumber: "Battery Serial Number",
      batteryPartNumber: "Battery Part Number",
      batteryDateCode: "Battery Date Code",
    },
    bt2400NapaIVT: {
      psn: "S/N",
      testType: "Test Type",
      vin: "VIN",
      systemVolCh: "System Voltage Channel",
      batteryType: "Battery Type",
      rating: "Rating",
      capacity: "Capacity",
      batInVehicle: "Battery In Vehicle",
      batCharged: "Battery Charged",
      voltage: "Voltage(V)",
      soh: "State of Health(%)",
      soc: "State of Charge(%)",
      ir: "IR(mΩ)",
      measuredCca: "Measured CCA",
      judgement: "Judgement",
      testTime: "Battery Test Time",
      crankingResult: "Cranking Result",
      crankingVoltage: "Cranking Voltage(V)",
      crankingTime: "Cranking Time(s)",
      altIdleResult: "Alt Idle Result",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleResult: "Ripple Result",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadResult: "Alt Load Result",
      altLoadVoltage: "Alt Load Voltage(V)",
      temperature: "Temperature(°C)",
      dieselCheck: "Diesel Check",
      sysTestTime: "System Test Time",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
      upcStatus: "UPC Status",
      upcCode: "UPC Code",
      storeNumber: "Store Number",
      batterySerialNumber: "Battery Serial Number",
      batteryPartNumber: "Battery Part Number",
      batteryDateCode: "Battery Date Code",
    },
    ba427plusBT: {
      psn: "S/N",
      testType: "Test Type",
      roNum: "RO Number",
      vin: "VIN",
      recipient: "Recipient",
      batteryType: "Battery Type",
      rating: "Rating",
      capacity: "Capacity",
      batInVehicle: "Battery In Vehicle",
      batCharged: "Battery Charged",
      voltage: "Voltage(V)",
      soh: "State of Health(%)",
      soc: "State of Charge(%)",
      ir: "IR(mΩ)",
      measuredCca: "Measured CCA",
      temperature: "Temperature(°C)",
      judgement: "Judgement",
      testCode: "Test code",
      testTime: "Test Time",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
    },
    ba427plusST: {
      psn: "S/N",
      testType: "Test Type",
      roNum: "RO Number",
      vin: "VIN",
      recipient: "Recipient",
      crankingResult: "Cranking Result",
      crankingVoltage: "Cranking Voltage(V)",
      altIdleResult: "Alt Idle Result",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleResult: "Ripple Result",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadResult: "Alt Load Result",
      altLoadVoltage: "Alt Load Voltage(V)",
      testTime: "Test Time",
      fwVersion: "Firmware Version",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      uploadTime: "Upload Time",
    },
    btw350BT: {
      psn: "S/N",
      deviceName: "Device Name",
      batterySn: "Battery SN",
      systemVoltage: "System Voltage",
      testTime: "Test Time",
      testType: "Test Type",
      vin: "VIN",
      judgement: "Judgement",
      batteryType: "Battery Type",
      setCapacity: "Set Capacity",
      measuredCca: "Measured CCA",
      rating: "Rating",
      soh: "SOH(%)",
      soc: "SOC(%)",
      ir: "IR(mΩ)",
      voltage: "Voltage(V)",
      isBatteryCharged: "Is Battery Charged",
      batInVehicle: "Battery In Vehicle",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    btw350ST: {
      psn: "S/N",
      deviceName: "Device Name",
      testTime: "Test Time",
      testType: "Test Type",
      crankingJudgement: "Cranking Judgement",
      crankingVoltage: "Cranking Voltage(V)",
      crankingMinVoltage: "Cranking Min Voltage(V)",
      crankingTime: "Cranking Time(s)",
      altIdleJudgement: "Alt Idle Judgement",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleJudgement: "Ripple Judgement",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadJudgement: "Alt Load Judgement",
      altLoadVoltage: "Alt Load Voltage(V)",
      smartChargingControl: "Smart Charging Control",
      voltageMonitorLowest: "Voltage Monitor Lowest(V)",
      voltageMonitorHighest: "Voltage Monitor Highest(V)",
      rippleLowestJudgement: "Ripple Lowest Judgement",
      rippleLowest: "Ripple Lowest(V)",
      rippleHighestJudgement: "Ripple Highest Judgement",
      rippleHighest: "Ripple Highest(V)",
      monitorTime: "Monitor Time(s)",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    btw350IVT: {
      psn: "S/N",
      deviceName: "Device Name",
      batterySn: "Battery SN",
      systemVoltage: "System Voltage",
      testTime: "Test Time",
      testType: "Test Type",
      vin: "VIN",
      judgement: "Judgement",
      batteryType: "Battery Type",
      setCapacity: "Set Capacity",
      measuredCca: "Measured CCA",
      rating: "Rating",
      soh: "SOH(%)",
      soc: "SOC(%)",
      ir: "IR(mΩ)",
      voltage: "Voltage(V)",
      isBatteryCharged: "Is Battery Charged",
      crankingJudgement: "Cranking Judgement",
      crankingVoltage: "Cranking Voltage(V)",
      crankingMinVoltage: "Cranking Min Voltage(V)",
      crankingTime: "Cranking Time(s)",
      altIdleJudgement: "Alt Idle Judgement",
      altIdleVoltage: "Alt Idle Voltage(V)",
      rippleJudgement: "Ripple Judgement",
      rippleVoltage: "Ripple Voltage(V)",
      altLoadJudgement: "Alt Load Judgement",
      altLoadVoltage: "Alt Load Voltage(V)",
      systemTestTime: "System Test Time",
      smartChargingControl: "Smart Charging Control",
      voltageMonitorLowest: "Voltage Monitor Lowest(V)",
      voltageMonitorHighest: "Voltage Monitor Highest(V)",
      rippleLowestJudgement: "Ripple Lowest Judgement",
      rippleLowest: "Ripple Lowest(V)",
      rippleHighestJudgement: "Ripple Highest Judgement",
      rippleHighest: "Ripple Highest(V)",
      monitorTime: "Monitor Time(s)",
      monitorTestTime: "Monitor Test Time",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    btw350VM: {
      psn: "S/N",
      deviceName: "Device Name",
      testTime: "Test Time",
      voltageMonitorLowest: "Voltage Monitor Lowest(V)",
      voltageMonitorHighest: "Voltage Monitor Highest(V)",
      monitorTime: "Monitor Time(s)",
      account: "Account",
      shopId: "Shop ID",
      companyId: "Business ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    btw350CC: {
      psn: "S/N",
      deviceName: "Device Name",
      testTime: "Test Time",
      positive: "Positive Result",
      negative: "Negative Result",
      account: "Account",
      companyId: "Business ID", // new
      shopId: "Shop ID", // new
      appVersion: "APP Version",
      fwVersion: "FW Version",
      uploadTime: "Upload Time",
    },
    noMatch: "No matching records found",
    next: "Next",
    prev: "Prev",
    of: "of",
  },
  filterOption: {
    bt2200BT: {
      testType: {
        1: "Battery Test",
        2: "Start-Stop Test",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
      },
      rating: {
        // new
        0: "SAE",
        1: "EN",
        2: "EN2",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        7: "CA/MCA",
      },
      batteryCharged: {
        1: "Yes",
        2: "No",
      },
    },
    bt2200ST: {
      testType: {
        3: "System Test",
      },
      crankingResult: {
        4: "NO DETECTED",
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      crankingVoltage: {
        "--.--": "--.--",
      },
      altIdleResult: {
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleResult: {
        4: "NO DETECTED",
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleVoltage: {
        "--.--": "--.--",
      },
      altLoadResult: {
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
    },
    bt2300plusBT: {
      testType: {
        1: "Battery Test",
        2: "Start-Stop Test",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        4: "SS-AGM",
        5: "SS-EFB",
      },
      rating: {
        0: "CCA",
        1: "EN",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA",
        8: "SAE",
      },
      cellShort: {
        1: "YES",
        2: "NO",
      },
      surfaceCharge: {
        1: "YES",
        2: "NO",
      },
      batCharged: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
    },
    bt2300plusST: {
      testType: {
        3: "System Test",
      },
      crankingResult: {
        4: "NO DETECT",
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      altIdleResult: {
        4: "NO DETECT",
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleResult: {
        4: "NO DETECT",
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      altLoadResult: {
        4: "NO DETECT",
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
    },
    btw300BT: {
      testType: {
        1: "Battery Test",
        2: "Start-Stop Test",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
      batteryType: {
        1: "FLOODED",
        2: "AGM FLAT PLATE",
        3: "AGM SPIRAL",
        4: "VRLA/GEL",
        5: "PS FLOODED",
        6: "PS AGM",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        2: "IEC",
        3: "JIS",
        4: "DIN",
        5: "CA/MCA",
      },
      isBatteryCharged: {
        0: "N/A",
        1: "NO",
        2: "YES",
      },
    },
    btw300ST: {
      testType: {
        1: "System Test",
      },
      crankingResult: {
        0: "NO DETECT",
        1: "LOW",
        2: "NORMAL",
        3: "HIGH",
      },
      altIdleResult: {
        0: "NO DETECT",
        1: "LOW",
        2: "NORMAL",
        3: "HIGH",
      },
      rippleResult: {
        0: "NO DETECT",
        1: "LOW",
        2: "NORMAL",
        3: "HIGH",
      },
      altLoadResult: {
        0: "NO DETECT",
        1: "LOW",
        2: "NORMAL",
        3: "HIGH",
      },
    },
    btw200BT: {
      systemVoltage: {
        2: "12V",
      },
      testType: {
        1: "Battery Test",
        2: "Start-Stop Test",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
        6: "PS FLOODED",
        7: "PS AGM",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA/MCA",
      },
      isBatteryCharged: {
        1: "YES",
        2: "NO",
        3: "N/A",
      },
      batInVehicle: {
        1: "YES",
        2: "NO",
        3: "N/A",
      },
    },
    btw200ST: {
      testType: {
        5: "Cranking Test",
        6: "Charging Test",
      },
      crankingJudgement: {
        3: "N/A",
        5: "LOW",
        8: "PASS",
      },
      crankingVoltage: {
        "--.--": "--.--",
      },
      crankingTime: {
        "-": "-",
      },
      altIdleJudgement: {
        8: "PASS",
        9: "FAIL",
      },
      rippleJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleVoltage: {
        "--.--": "--.--",
      },
      altLoadJudgement: {
        8: "PASS",
        9: "FAIL",
      },
      smartChargingControl: {
        1: "YES",
        2: "NO",
      },
      rippleLowestJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleLowest: {
        "--.--": "--.--",
      },
      rippleHighestJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleHighest: {
        "--.--": "--.--",
      },
    },
    btw200IVT: {
      systemVoltage: {
        2: "12V",
      },
      testType: {
        4: "In Vehicle Test",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
        6: "PS FLOODED",
        7: "PS AGM",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA/MCA",
      },
      isBatteryCharged: {
        1: "YES",
        2: "NO",
        3: "N/A",
      },
      crankingJudgement: {
        3: "N/A",
        5: "LOW",
        8: "PASS",
      },
      crankingVoltage: {
        "--.--": "--.--",
      },
      crankingTime: {
        "-": "-",
      },
      altIdleJudgement: {
        8: "PASS",
        9: "FAIL",
      },
      rippleJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleVoltage: {
        "--.--": "--.--",
      },
      altLoadJudgement: {
        8: "PASS",
        9: "FAIL",
      },
      smartChargingControl: {
        1: "YES",
        2: "NO",
      },
      rippleLowestJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleLowest: {
        "--.--": "--.--",
      },
      rippleHighestJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleHighest: {
        "--.--": "--.--",
      },
    },
    bt2400BT: {
      testType: {
        1: "Battery Test",
      },
      systemVolCh: {
        1: "6V",
        2: "12V",
        3: "24V",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
        8: "ODYSSEY",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        2: "EN2",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA/MCA",
      },
      batInVehicle: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      batCharged: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      ir: {
        5: "LOW",
        7: "HIGH",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
    },
    bt2400ST: {
      testType: {
        3: "System Test",
      },
      systemVolCh: {
        1: "6V",
        2: "12V",
        3: "24V",
      },
      crankingResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      crankingVoltage: {
        "--.--": "--.--",
      },
      altIdleResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleVoltage: {
        "--.--": "--.--",
      },
      altLoadResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      dieselCheck: {
        1: "Yes",
        2: "No",
      },
    },
    bt2400IVT: {
      testType: {
        4: "In Vehicle Test",
      },
      systemVolCh: {
        1: "6V",
        2: "12V",
        3: "24V",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
        8: "ODYSSEY",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        2: "EN2",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA/MCA",
      },
      batInVehicle: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      batCharged: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      ir: {
        5: "LOW",
        7: "HIGH",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
      crankingResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      altIdleResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      altLoadResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      dieselCheck: {
        1: "Yes",
        2: "No",
      },
    },
    bt2400TG31: {
      testType: {
        7: "Truck/Group 31 Test",
      },
      systemVolCh: {
        1: "6V",
        2: "12V",
      },
      batInVehicle: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      batCharged: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
        8: "ODYSSEY",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        2: "EN2",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA/MCA",
      },
      ir: {
        5: "LOW",
        7: "HIGH",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
        6: "GOOD PACK",
        7: "CHECK PACK",
      },
    },
    bt2400PK24: {
      testType: {
        8: "24V Pack Test",
      },
      systemVolCh: {
        2: "12V",
      },
      batInVehicle: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      batCharged: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
        8: "ODYSSEY",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        2: "EN2",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA/MCA",
      },
      ir: {
        5: "LOW",
        7: "HIGH",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
    },
    bt2400NapaBT: {
      testType: {
        1: "Battery Test",
      },
      systemVolCh: {
        1: "6V",
        2: "12V",
        3: "24V",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
        8: "ODYSSEY",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        2: "EN2",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA/MCA",
      },
      batInVehicle: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      batCharged: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      ir: {
        5: "LOW",
        7: "HIGH",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "MARGINAL",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
      upcStatus: {
        1: "YES",
        2: "NO",
        3: "N/A",
      },
    },
    bt2400NapaST: {
      testType: {
        3: "System Test",
      },
      systemVolCh: {
        1: "6V",
        2: "12V",
        3: "24V",
      },
      crankingResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      crankingVoltage: {
        "--.--": "--.--",
      },
      altIdleResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleVoltage: {
        "--.--": "--.--",
      },
      altLoadResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      dieselCheck: {
        1: "Yes",
        2: "No",
      },
      upcStatus: {
        1: "YES",
        2: "NO",
        3: "N/A",
      },
    },
    bt2400NapaIVT: {
      testType: {
        4: "In Vehicle Test",
      },
      systemVolCh: {
        1: "6V",
        2: "12V",
        3: "24V",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
        8: "ODYSSEY",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        2: "EN2",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA/MCA",
      },
      batInVehicle: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      batCharged: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      ir: {
        5: "LOW",
        7: "HIGH",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "MARGINAL",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
      crankingResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      altIdleResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      altLoadResult: {
        4: "NOT DETECTED", // update
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      dieselCheck: {
        1: "Yes",
        2: "No",
      },
      upcStatus: {
        1: "YES",
        2: "NO",
        3: "N/A",
      },
    },
    ba427plusBT: {
      testType: {
        1: "Battery Test",
        2: "Start-Stop Test",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        4: "START-STOP AGM", //update
        5: "EFB",
        8: "PURE LEAD", // new
      },
      rating: {
        0: "CCA",
        2: "EN",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA",
        7: "MCA",
      },
      batInVehicle: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      batCharged: {
        1: "Yes",
        2: "No",
        3: "N/A",
      },
      ir: {
        "--.--": "--.--",
      },
      temperature: {
        0: "OVER 140℉/60℃",
        1: "UNDER -4℉/-20℃",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        4: "RECHARGE RETEST", //update
        5: "BAD CELL REPLACE",
      },
    },
    ba427plusST: {
      testType: {
        3: "System Test",
      },
      crankingResult: {
        4: "NO DETECT",
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      crankingVoltage: {
        "--.--": "--.--",
      },
      altIdleResult: {
        4: "NO DETECT",
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleResult: {
        4: "NO DETECT",
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
      rippleVoltage: {
        "--.--": "--.--",
      },
      altLoadResult: {
        4: "NO DETECT",
        5: "LOW",
        6: "NORMAL",
        7: "HIGH",
      },
    },
    btw350BT: {
      systemVoltage: {
        2: "12V",
      },
      testType: {
        1: "Battery Test",
        2: "Start-Stop Test",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
        6: "PS FLOODED",
        7: "PS AGM",
        8: "ODYSSEY",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA/MCA",
      },
      isBatteryCharged: {
        1: "YES",
        2: "NO",
        3: "N/A",
      },
      batInVehicle: {
        1: "YES",
        2: "NO",
        3: "N/A",
      },
    },
    btw350ST: {
      testType: {
        5: "Cranking Test",
        6: "Charging Test",
      },
      crankingJudgement: {
        3: "N/A",
        5: "LOW",
        8: "PASS",
      },
      crankingVoltage: {
        "--.--": "--.--",
      },
      crankingTime: {
        "-": "-",
      },
      altIdleJudgement: {
        8: "PASS",
        9: "FAIL",
      },
      rippleJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleVoltage: {
        "--.--": "--.--",
      },
      altLoadJudgement: {
        8: "PASS",
        9: "FAIL",
      },
      smartChargingControl: {
        1: "YES",
        2: "NO",
      },
      rippleLowestJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleLowest: {
        "--.--": "--.--",
      },
      rippleHighestJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleHighest: {
        "--.--": "--.--",
      },
    },
    btw350IVT: {
      systemVoltage: {
        2: "12V",
      },
      testType: {
        4: "In Vehicle Test",
      },
      judgement: {
        0: "GOOD & PASS",
        1: "GOOD & RECHARGE",
        2: "BAD & REPLACE",
        3: "CAUTION",
        4: "RECHARGE & RETEST",
        5: "BAD CELL REPLACE",
      },
      batteryType: {
        0: "FLOODED",
        1: "AGM FLAT PLATE",
        2: "AGM SPIRAL",
        3: "VRLA/GEL",
        5: "EFB",
        6: "PS FLOODED",
        7: "PS AGM",
        8: "ODYSSEY",
      },
      rating: {
        0: "CCA/SAE",
        1: "EN",
        3: "IEC",
        4: "JIS",
        5: "DIN",
        6: "CA/MCA",
      },
      isBatteryCharged: {
        1: "YES",
        2: "NO",
        3: "N/A",
      },
      crankingJudgement: {
        3: "N/A",
        5: "LOW",
        8: "PASS",
      },
      crankingVoltage: {
        "--.--": "--.--",
      },
      crankingTime: {
        "-": "-",
      },
      altIdleJudgement: {
        8: "PASS",
        9: "FAIL",
      },
      rippleJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleVoltage: {
        "--.--": "--.--",
      },
      altLoadJudgement: {
        8: "PASS",
        9: "FAIL",
      },
      smartChargingControl: {
        1: "YES",
        2: "NO",
      },
      rippleLowestJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleLowest: {
        "--.--": "--.--",
      },
      rippleHighestJudgement: {
        3: "N/A",
        7: "HIGH",
        8: "PASS",
      },
      rippleHighest: {
        "--.--": "--.--",
      },
    },
    btw350CC: {
      positive: {
        3: "CAUTION",
        8: "GOOD",
        9: "BAD",
      },
      negative: {
        3: "CAUTION",
        8: "GOOD",
        9: "BAD",
      },
    },
    manageMemberList: {
      verification: {
        0: "Verified",
        1: "In Progress",
      },
      pricingPlan: {
        0: "Basic Account",
        1: "Shop Account",
        2: "Business Account",
      },
      status: {
        1: "Enabled",
        2: "Disabled",
      },
      isLocked: {
        0: "Unlocked",
        1: "Locked",
      },
    },
    deviceManager: {
      model: {
        0: "BT2200",
        1: "BT2300+",
        2: "BT2400",
        3: "BA427+",
        4: "ST2400PRO",
      },
    },
    signinLogs: {
      successful: {
        1: "Successful",
        0: "Failed",
      },
    },
  },
  export: {
    fileName: {
      bt2200BT: "Battery-Test-Data_BT2200",
      bt2200ST: "System-Test-Data_BT2200",
      bt2300plusBT: "Battery-Test-Data_BT2300+",
      bt2300plusST: "System-Test-Data_BT2300+",
      bt2400NapaBT: "Battery-Test-Data_ST2400PRO",
      bt2400NapaST: "System-Test-Data_ST2400PRO",
      bt2400NapaIVT: "In-Vehicle-Test-Data_ST2400PRO",
      bt2400BT: "Battery-Test-Data_BT2400",
      bt2400ST: "System-Test-Data_BT2400",
      bt2400IVT: "In-Vehicle-Test-Data_BT2400",
      bt2400TG31: "Truck-Group31-Test-Data_BT2400",
      bt2400PK24: "24V-Pack-Test-Data_BT2400",
      btw200BT: "Battery-Test-Data_BTW200",
      btw200ST: "System-Test-Data_BTW200",
      btw200IVT: "In-Vehicle-Test-Data_BTW200",
      btw200VM: "Vehicle-Monitor-Data_BTW200",
      btw300BT: "Battery-Test-Data_BTW300",
      btw300ST: "System-Test-Data_BTW300",
      btw350BT: "Battery-Test-Data_BTW350",
      btw350ST: "System-Test-Data_BTW350",
      btw350IVT: "In-Vehicle-Test-Data_BTW350",
      btw350CC: "Cable-Check-Data_BTW350",
      btw350VM: "Vehicle-Monitor-Data_BTW350",
      ba427plusBT: "Battery-Test-Data_BA427+",
      ba427plusST: "System-Test-Data_BA427+",
    },
  },
  //#endregion

  //#region chart  //new
  chart: {
    title: {
      testTypeCounter: "Test Type Counter",
      judgmentResultCounter: "Test Result Composition",
      crankingResultCounter: "Cranking Result Composition",
      rippleResultCounter: "Ripple Result Composition",
      altIdleResultCounter: "Alt Idle Result Composition",
      altLoadResultCounter: "Alt Load Result Composition",
      month: "Month",
      result: "Result Composition",
      total: "Total Result Composition",
      monthly: "Monthly Result Composition",
      firmwareVersion: "Firmware Version",
      hourlyDay: "Hourly Activities",
      top5Account: "TOP5 Account Usage",
      top5Shop: "TOP5 Shop",
      testType: "Test Type Composition",
      batteryTypeMonth: "Monthly Battery Type Composition",
      batteryTypeYear: "Battery Type Composition",
      new: "New User",
      active: "Active User",
      inactive: "Inactive User",
      totalUesr: "Total User", // new
      newShop: "New Shop", // new
      activeShop: "Active Shop", // new
      totalShop: "Total Shop", // new
    },
    label: {
      countBT: "BATTERY TEST",
      countSST: "START-STOP TEST",
      countGP: "GOOD & PASS",
      countGR: "GOOD & RECHARGE",
      countRR: "RECHARGE RETEST",
      countBR: "BAD & REPLACE",
      countBCR: "BAD CELL REPLACE",

      testedTimes: "Tested Times",
      batteryTest: "BATTERY TEST",
      startStopTest: "START-STOP TEST",
      gp: "GOOD & PASS",
      gr: "GOOD & RECHARGE",
      rr: "RECHARGE & RETEST",
      rnr: "RECHARGE RETEST", // update
      ca: "CAUTION",
      marginal: "MARGINAL", //new for bt2400 napa
      br: "BAD & REPLACE",
      bcr: "BAD CELL REPLACE",
      goodAndPass: "GOOD & PASS",
      goodAndRecharge: "GOOD & RECHARGE",
      rechargeAndRetest: "RECHARGE & RETEST",
      rechargeRetest: "RECHARGE RETEST", // update
      caution: "CAUTION",
      badAndReplace: "BAD & REPLACE",
      badCellReplace: "BAD CELL REPLACE",
      noDetect: "NO DETECT",
      noDetected: "NO DETECTED",
      notDetected: "NOT DETECTED", // new
      low: "LOW",
      normal: "NORMAL",
      high: "HIGH",
      na: "N/A",
      pass: "PASS",
      fail: "FAIL",
      ivtTest: "IN VEHICLE TEST",
      total: "TOTAL",
      replace: "REPLACE",
      gd: "GOOD",
      bd: "BAD",
      oth: "OTHER",
      month: "Month",
      quarter: "Quarter",
      records: "Records",
      time: "Time",
      account: "Account",
      shop: "Shop ID",
      batteryType: "Battery Type",
      timePeriod: "Time Period",
      tg31Test: "Truck/Group 31 Test", // new
      pk24Test: "24V Pack Test", // new
    },
    info: {
      new: "User number count for who just join the shop in the last 24 hours.",
      active:
        "User number count for who has uploaded at least single test data in the last 24 hours.",
      // new
      total:
        "User number count for who has ever registered and joined in the shop.",
      newShop:
        "Shop number count for who just join the Business group in the last 24 hours.", // new
      activeShop:
        "Shop number count for who has uploaded at least single test data in the last 24 hours.", // new
      totalShop:
        "Shop number count for who has ever registered and joined in the Business group.", // new
    },
    testType: {
      "Battery Test": "Battery Test",
      "Start-Stop Test": "Start-Stop Test",
      "System Test": "System Test",
      "Cranking Test": "Cranking Test",
      "Charging Test": "Charging Test",
      "In Vehicle Test": "In Vehicle Test",
      "Smart Charging Monitor": "Smart Charging Monitor",
      "Truck/Group 31 Test": "Truck/Group 31 Test",
      "24V Pack Test": "24V Pack Test",
      "Cable Diagnosis": "Cable Diagnosis",
    },
    batteryType: {
      FLOODED: "FLOODED",
      "AGM FLAT PLATE": "AGM FLAT PLATE",
      "AGM SPIRAL": "AGM SPIRAL",
      "VRLA/GEL": "VRLA/GEL",
      "SS-AGM": "SS-AGM",
      "Start-Stop AGM": "START-STOP AGM",
      "SS-EFB": "SS-EFB",
      "PS FLOODED": "PS FLOODED",
      "PS AGM": "PS AGM",
      EFB: "EFB",
      "PURE LEAD": "PURE LEAD",
      ODYSSEY: "ODYSSEY",
    },
    month: {
      full: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
      short: {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec",
      },
    },
    list: {
      shop: "Shop",
      business: "Business",
    },
  },
  //#endregion

  //#region Page - home  //new
  home: {
    title: {
      general: "My Battery Expert",
      napa: "NAPA",
    },
    updated: "Last Updated Time",
    hour: "hours",
    minute: "minutes ago",
    nodata: "No data uploaded",
    leaderboard: {
      title: "Weekly LeaderBoard",
      subtitle:
        "The counter will be reset at 00:00 every Sunday. (Timezone: UTC+0)",
      total: "Total",
    },
  },
  //#endregion

  //#region Page - set timezome  //new
  setTimezone: {
    title: "Change your time zone.",
    subtitle: "Changes will take effect in the coming 24 hours",
  },
  //#endregion
};
