<template>
  <div class="frame">
    <formFormat
      :page="page"
      :formInput="formInput"
      :formSelect="formSelect"
      :loading="loading"
      :error.sync="errorMessage"
      @submit="showEmitValue"
      data-test="formFormat"
    ></formFormat>
    <!-------------- modal area --------------->
    <!-- 註冊成功 -->
    <b-modal
      id="createGroupSuccess"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$t('modal.success')"
    >
      <h6>{{ $t("modal.createShop.success.text1") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!------------------------------------------->
  </div>
</template>

<script>
import formFormat from "@/components/FormFormat.vue";
import { APICreateGroup, APIcheckVaildAndRefresh } from "../api";

export default {
  components: {
    formFormat,
  },
  data() {
    return {
      page: "createShop",
      loading: false,
      errorMessage: {
        existGroupID: false,
        RegGroupID: false,
      },
      formInput: [
        {
          id: "shopId", // 用在call API上
          title: "createShop.inputText1",
          placeholder: "createShop.inputText1Placeholder",
          type: "text",
          check: "id",
        },
      ],
      formSelect: [
        {
          id: "timezone",
          title: "formFormat.selectTimezone",
        },
      ],
    };
  },
  methods: {
    async showEmitValue(inputValue) {
      try {
        this.loading = true;
        let requestData = {};
        this.formInput.map((el, i) => {
          requestData[el.id] = inputValue.input[i];
        });
        this.formSelect.map((el, i) => {
          requestData[el.id] = inputValue.select[i];
        });
        const { data: APICreateGroupResponse } = await APICreateGroup(
          requestData
        );
        if (APICreateGroupResponse.result === 1)
          this.updateAccountInformation();
      } catch (error) {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.$bvModal.show("setCheckToken");
        } else {
          switch (error.response.data.error) {
            case "id is duplicated":
              this.errorMessage.existGroupID = true;
              break;
            case "id has wrong format":
              this.errorMessage.RegGroupID = true;
              break;
            case "invalid action":
            default:
              this.$bvModal.show("setsomethingWrong");
          }
        }
      }
    },

    async updateAccountInformation() {
      try {
        const { data: APIdata } = await APIcheckVaildAndRefresh();
        if (APIdata.result === 1) {
          window.localStorage.setItem("token", "bearer " + APIdata.token);
          this.$store.dispatch("setAccount", APIdata.account);
          this.$store.dispatch("setAccountInformation", {
            account: APIdata.account,
            accountLevel: APIdata.accountLevel,
            companyId: APIdata.companyId,
            shopId: APIdata.shopId,
            pricingPlan: APIdata.pricingPlan,
            isShopBefore: APIdata.isShopBefore,
            isCompanyBefore: APIdata.isCompanyBefore,
            timezone: APIdata.timezone,
          });
        }
        this.$bvModal.show("createGroupSuccess");
        this.$store.dispatch("setSignInToggle", true);
        setTimeout(() => {
          this.loading = false;
          this.$router.push("/listAllUsers");
        }, 2000);
      } catch (error) {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.$bvModal.show("setCheckToken");
        } else {
          switch (error.response.data.error) {
            case "token missing":
              this.$bvModal.show("setCheckToken");
              break;
            case "account does not exist":
              this.$bvModal.show("setCheckToken");
              break;
            default:
              this.$bvModal.show("setsomethingWrong");
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
#register,
#download {
  button {
    letter-spacing: $button_letter_space;
    font-weight: $button_font_weight;
    width: 100%;
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
    height: 46px;
    cursor: pointer;
    background: $sub_background_color;
    color: $sub_button_color;
    transition: 0.3s;
    font-family: $font-family;
    &:hover::before {
      opacity: 0.08;
    }
    &:disabled {
      color: $sub_background_color;
      background: $main_background_color;
    }
  }
}
</style>
