<template>
  <div>
    <formFormat
      :page="page"
      :formInput="formInput"
      :loading="loading"
      :error.sync="errorMessage"
      :checkPolicy="checkPrivacyPolicy"
      @submit="showEmitValue"
      data-test="formFormat"
    ></formFormat>
    <!-------------- modal area --------------->
    <!-- 註冊成功 -->
    <b-modal
      id="registerSuccess"
      no-close-on-backdrop
      no-close-on-esc
      centered
      :title="$t('modal.success')"
    >
      <template v-slot:modal-header>
        <h5>{{ $t("modal.success") }}</h5>
        <button @click="fnAfterRegister">X</button>
      </template>
      <h6>{{ $t("modal.registerUser.success.text1") }}</h6>
      <h6>{{ $t("modal.registerUser.success.text2") }}</h6>
      <h6>{{ $t("modal.registerUser.success.text3") }}</h6>
      <template v-slot:modal-footer="{ ok }">
        <m-button
          class="tableButton cancel"
          raised
          dense
          @click="ok(fnAfterRegister())"
        >
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!-- 註冊失敗 -->
    <b-modal id="registerError" centered :title="$t('modal.error')">
      <h6>{{ $t("modal.registerUser.error.text1") }}</h6>
      <h6>{{ $t("modal.registerUser.error.text2") }}</h6>
      <h6>{{ $t("modal.registerUser.error.text3") }}</h6>
      <template v-slot:modal-footer="{ close }">
        <m-button class="tableButton cancel" raised dense @click="close()">
          {{ $t("button.ok") }}
        </m-button>
      </template>
    </b-modal>
    <!------------------------------------------->
  </div>
</template>

<script>
import formFormat from "@/components/FormFormat.vue";
import { APIcreate } from "../api";

export default {
  components: {
    formFormat,
  },
  data() {
    return {
      page: "register",
      loading: false,
      checkPrivacyPolicy: false,
      errorMessage: {
        mailErrorToggle: false,
        RegPassword: false,
        confirmPassword: false,
      },
      formInput: [
        {
          id: "account",
          title: "register.inputText1",
          placeholder: "register.placeholderInputText1",
          type: "email",
          check: "email",
        },
        {
          id: "password",
          title: "register.inputText2",
          placeholder: "register.placeholderInputText2",
          type: "password",
          check: "password",
        },
        {
          id: "confirmPassword",
          title: "register.inputText3",
          placeholder: "register.placeholderInputText3",
          type: "password",
          check: "confirm",
        },
      ],
    };
  },
  methods: {
    async showEmitValue(inputValue) {
      try {
        this.loading = true;
        let requestData = {};
        this.formInput.map((el, i) => {
          if (el.id !== "confirmPassword") {
            requestData[el.id] = inputValue.input[i];
          }
        });
        const { data: APIdata } = await APIcreate(requestData);
        if (APIdata.result === 1) this.$bvModal.show("registerSuccess");
        if (APIdata.result === 2) this.$bvModal.show("registerError");
      } catch {
        this.$bvModal.show("setsomethingWrong");
      } finally {
        this.loading = false;
      }
    },
    fnAfterRegister() {
      this.$router.push("/");
    },
    checkSubdomainAndModifyInputs() {
      if (this.subdomain === "napa") {
        this.formInput.push({
          id: "storeNumber",
          title: "register.napa.inputTextStoreNumber",
          placeholder: "register.napa.placeholderInputTextStoreNumber",
          type: "text",
        });
      }
    },
  },
  computed: {
    subdomain() {
      return this.$root.subdomain;
    },
  },
  mounted() {
    this.checkSubdomainAndModifyInputs();
  },
};
</script>
